import { Box } from '@mui/material';
import { ComponentProps } from 'react';
import { useMilestoneContext } from './MilestoneProvider';

export function MilestoneInfo({ children, ...props }: ComponentProps<typeof Box>) {
  const { isTitleAlignedWithIcons } = useMilestoneContext();

  return (
    <Box display="flex" flexDirection="column" gap={1} paddingTop="0.3rem" marginLeft={isTitleAlignedWithIcons ? '0' : '2.5rem'} {...props}>
      {children}
    </Box>
  );
}
