import * as React from 'react';
const SvgIcoCircleCheckSolid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlSpace="preserve" viewBox="0 0 19.6 19.6" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M9.8 0C4.4 0 0 4.4 0 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8S15.2 0 9.8 0m5.9 7-7.1 6.8c0 .1-.1.1-.1.2-.2.2-.4.3-.7.3-.2 0-.5-.1-.6-.3L4 10.9c-.4-.4-.4-1 0-1.3.2-.2.4-.3.7-.3s.5.1.7.3L8 12.1l6.6-6.4c.4-.4 1-.3 1.3 0 .2.2.3.4.3.7s-.3.4-.5.6"
      style={{
        fill: '#337292',
      }}
    />
  </svg>
);
export default SvgIcoCircleCheckSolid;
