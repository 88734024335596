import { useMutation, useQuery } from 'react-query';
import { ApiProposalRepository } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useProposalShareTemplateQuery = (proposalId: number) => {
  return useQuery(QueryKeys.getPersonById(proposalId), () => ApiProposalRepository.getDefaultShareTemplate(proposalId));
};

export const usePrefilledSendEmailTemplateQuery = () => {
  return useMutation({
    mutationFn: (payload: { proposalId: number; templateId: string }) => ApiProposalRepository.getProposalShareTemplate(payload),
  });
};
