import { useState, ChangeEvent } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { MarginSide } from '../models/MarginSide.enum';

interface NumberInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  min: number;
  max: number;
  value?: string | number;
  position: MarginSide;
  onChange?: (value: string | number, position: MarginSide) => void;
}

export function FormattedInput({ min, max, value: propValue = '', onChange, position, ...rest }: NumberInputProps) {
  const [value, setValue] = useState<string | number>(propValue);

  const regexToAllowOnlyOneDecimal = /^-?\d*(\.\d{0,1})?$/;
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = parseFloat(event.target.value.toString())
      .toFixed(1)
      .replace(/^(-)?0+(?=\d)/, '$1');

    if (regexToAllowOnlyOneDecimal.test(inputValue) || inputValue === '') {
      // Check if the input value is within the specified range
      if ((inputValue === '' || parseFloat(inputValue) >= min) && (inputValue === '' || parseFloat(inputValue) <= max)) {
        setValue(inputValue);
        if (onChange) {
          onChange(inputValue, position);
        }
        return;
      }
    }

    const defaultIfUnacceptableInput = parseFloat('0.0').toFixed(1);
    setValue(defaultIfUnacceptableInput);
    if (onChange) {
      onChange(defaultIfUnacceptableInput, position);
    }
  };

  return (
    <TextField
      type="number"
      value={value}
      onBlur={handleChange}
      onChange={(event) => {
        if (parseFloat(event.target.value) > max) return;
        setValue(event.target.value);
      }}
      inputProps={{
        min,
        max,
        step: 'any', // Allows decimal values
        pattern: regexToAllowOnlyOneDecimal, // Pattern to allow only one decimal place
      }}
      {...rest}
    />
  );
}
