import { useCallback } from 'react';
import { URLS } from '../../utils/urls';
import { useNavigate } from 'react-router-dom';

const usePreviewRedirect = (documentId: string) => {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate(URLS.previewDocument(documentId));
  }, []);
};

export default usePreviewRedirect;
