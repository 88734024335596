import { useTranslation } from 'react-i18next';
import { Label, Button, Input } from 'components';

type Props = {
  autoDiscoveryValue: string;
  handleInputChange: (event: any) => void;
  onSubmit: () => void;
};

export const AutoDiscovery = ({ autoDiscoveryValue, handleInputChange, onSubmit }: Props) => {
  const { t } = useTranslation();

  const onInputChange = (event) => {
    const { name, value } = event.target;
    handleInputChange({
      target: {
        name,
        value: value.trim(),
      },
    });
  };

  return (
    <div className="sso-form__discovery">
      <div className="sso-form__discovery__input">
        <Label label={t('settings.sso.auto_discovery_url')} />
        <Input value={autoDiscoveryValue} ariaLabel="discovery_url" name="discovery_url" onChange={onInputChange} />
      </div>
      <div>
        <Button disabled={false} type="primary" variant="positive" onClick={onSubmit}>
          {t('settings.sso.auto_discovery_button')}
        </Button>
      </div>
    </div>
  );
};
