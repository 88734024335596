export enum ProspectType {
  Person = 'PERSON',
  Company = 'COMPANY',
}

export interface Prospect {
  id: number;
  name: string;
  type: ProspectType;
  uid: string;
}

export interface ProspectResponse {
  id: number;
  name: string;
  type: ProspectType;
}

export type ProspectsResponse = ProspectResponse[];

export type Prospects = Prospect[];

export interface ProspectRepository {
  getProspects(queryParam): Promise<Prospects>;
}
