export enum PromiseAllSettledStatus {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export interface PromiseAllSettledResult<T> {
  status?: PromiseAllSettledStatus.Fulfilled | PromiseAllSettledStatus.Rejected;
  value?: T;
  reason?: any;
}
