import * as React from 'react';
const SvgIcoCloudUpload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 11.25a.75.75 0 0 1 .75.75v7a.75.75 0 1 1-1.5 0v-7a.75.75 0 0 1 .75-.75"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M11.47 11.303a.75.75 0 0 1 1.06 0l2.167 2.167a.75.75 0 1 1-1.06 1.06L12 12.894l-1.637 1.636a.75.75 0 0 1-1.06-1.06z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M5.59 9.336c.742-2.919 3.383-5.086 6.535-5.086a6.755 6.755 0 0 1 6.75 6.75v.262A4.257 4.257 0 0 1 22.81 15.5a4.256 4.256 0 0 1-4.25 4.25H16a.75.75 0 0 1 0-1.5h2.56a2.756 2.756 0 0 0 2.75-2.75 2.756 2.756 0 0 0-2.75-2.75h-.435a.75.75 0 0 1-.75-.75v-1a5.255 5.255 0 0 0-5.25-5.25 5.25 5.25 0 0 0-5.173 4.395.75.75 0 0 1-.702.627A3.74 3.74 0 0 0 2.69 14.5a3.75 3.75 0 0 0 3.75 3.75H8a.75.75 0 0 1 0 1.5H6.44a5.25 5.25 0 0 1-5.25-5.25c0-2.612 1.91-4.758 4.4-5.164"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoCloudUpload;
