import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import { PaymentAccountEntity } from 'services/api/account/interfaces';

export type BillingContactFormFieldValues = {
  email: string;
};

export type SubmitHandler = SubmitHandlerT<BillingContactFormFieldValues>;

export const transformBillingContactFieldValuesToApiPayload = ({ email }: BillingContactFormFieldValues): PaymentAccountEntity => {
  return {
    email,
  };
};

export function useBillingContactForm(loadedValues: BillingContactFormFieldValues) {
  return useForm<BillingContactFormFieldValues>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    values: loadedValues || undefined,
  });
}
