import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { PlanEntity } from '../services/api/plan/interfaces';
import { PlanRepository } from '../services/api/plan/PlanRepository';

type UsePlanApiDataParams = {
  planRepository: PlanRepository;
  accountId: number;
  enabled?: boolean;
};

type UsePlanApiDataResult = {
  planData?: PlanEntity;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
};

type UsePlanApiData<P> = (params: P) => UsePlanApiDataResult;

export const usePlanApiData: UsePlanApiData<UsePlanApiDataParams> = ({ planRepository, accountId, enabled = true }) => {
  const { data, isLoading, isError, isFetched } = useQuery({
    queryKey: QueryKeys.getAccountPlan(accountId),
    queryFn: () => planRepository.getAccountPlan(accountId),
    enabled,
  });

  return {
    planData: data,
    isLoading,
    isError,
    isFetched,
  };
};
