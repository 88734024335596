import * as React from 'react';
const SvgIcoTune = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.25 12.75v1.5h4.5v-1.5zm0-9v1.5h7.5v-1.5zm7.5 12v-1.5h6v-1.5h-6v-1.5h-1.5v4.5zm-4.5-9v1.5h-3v1.5h3v1.5h1.5v-4.5zm10.5 3v-1.5h-7.5v1.5zm-4.5-3h1.5v-1.5h3v-1.5h-3v-1.5h-1.5z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoTune;
