export type SignatureColor = 'sunset' | 'ocean' | 'sunrise' | 'seahorse' | 'urchin' | 'deepsea' | 'seaglass';
const AVAILABLE_SIGNATURE_COLORS: SignatureColor[] = ['sunset', 'ocean', 'sunrise', 'seahorse', 'urchin', 'deepsea', 'seaglass'];

export function getSignatureColor(str = '') {
  const uniqueCode = [...(str ?? '')].reduce((result, char) => {
    return result + char.charCodeAt(0);
  }, 0);

  const index = uniqueCode % AVAILABLE_SIGNATURE_COLORS.length;

  return AVAILABLE_SIGNATURE_COLORS[index];
}
