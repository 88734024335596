export enum DocumentSettingsEvents {
  DOCUMENT_SETTINGS_LOAD = 'document_settings:get',
  DOCUMENT_SETTINGS_SAVE = 'document_settings:save',
}

export enum SignatureEvents {
  SIGNATURE_GET_ALL = 'get_all_signatures',
  SIGNATURE_UPDATE = 'update_signature',
  SIGNATURE_ADD = 'add_signature',
  SIGNATURE_DELETE = 'delete_signature',
}

export enum BlockEvents {
  BLOCK_ADDED = 'block:added',
  BLOCK_DELETED = 'block:deleted',
  BLOCK_CONTENT_CHANGED = 'block:content_changed',
  BLOCK_POSITION_CHANGED = 'block:position_changed',
  BLOCK_DIMENSION_CHANGED = 'block:dimension_changed',
  BLOCK_LAYER_CHANGED = 'block:layer_changed',
  BLOCK_SETTINGS_SAVE = 'block:settings_changed',
  BLOCK_TABLE_CONTENT_CHANGED = 'block:table_content_changed',
}

export enum SectionEvents {
  SECTION_LOAD = 'section:load_content',
  SECTION_ADD = 'section:added',
  SECTION_DELETE = 'section:deleted',
}

export enum SocketClientDefaultEvents {
  CONNECTION_ERROR = 'connect_error',
}

export enum SocketConnectionErrorTypes {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export enum SocketConnectionEvents {
  CONNECTED = 'socket:connected',
  TOKEN_EXPIRED = 'socket:token_expired',
}

export enum AcknowledgmentResponseStatus {
  OK = 'Ok',
  FAILURE = 'Failure',
}

export enum ErrorCode {
  LOCKED = 'Locked',
}

export type DefaultSocketConnectionErrorResponseType = Error & {
  data?: {
    type?: SocketConnectionErrorTypes;
  };
};

export interface DefaultSocketResponseType {
  status: AcknowledgmentResponseStatus;
  content: string;
  errorCode: ErrorCode;
}
