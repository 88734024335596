import GridDeleteComponent from './GridDeleteComponent';
import './BlockSettingsToolsContainer.less';
import BlockSettingsIconButton from './BlockSettingsIconButton';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { useBlockContent } from '../../Sections/useBlockContent';
import { useEffect, useState, useMemo } from 'react';

interface BlockSettingsToolsProps {
  blockId: string;
}

type BlockSettingsContainerPositionClassType = 'at-the-top' | 'at-the-bottom';

export function BlockSettingsToolsContainer({ blockId }: BlockSettingsToolsProps) {
  const blockContent = useBlockContent(blockId);

  const [positionClass, setPositionClass] = useState<BlockSettingsContainerPositionClassType>('at-the-top');

  const blockConfigY = useMemo(() => blockContent?.blockConfig?.y, [blockContent]);

  useEffect(() => {
    if (blockConfigY !== undefined) {
      if (blockConfigY < 0 && positionClass !== 'at-the-bottom') {
        setPositionClass('at-the-bottom');
      } else if (blockConfigY >= 0 && positionClass !== 'at-the-top') {
        setPositionClass('at-the-top');
      }
    }
  }, [blockConfigY, positionClass]);

  if (!blockContent) {
    return <></>;
  }

  const className = 'block-settings-container ' + positionClass;

  return (
    <div data-testid={`block-settings-container-${blockId}`} className={className}>
      <GridDeleteComponent blockId={blockId} />
      {(blockContent.type === GridBlockType.TEXT || blockContent.type === GridBlockType.IMAGE) && (
        <BlockSettingsIconButton blockId={blockId} type={GridBlockType[blockContent.type]} />
      )}
    </div>
  );
}
