import { useState } from 'react';
import { DocumentCreate } from './document-create';
import { Templates } from './templates';
import './styles.less';

export const DocumentForm = ({ formStage = 'template' }: { formStage?: string }) => {
  const [stage, setStage] = useState(formStage);
  const [selectedTemplate, setSelectedTemplate] = useState<null | number>(null);

  const selectPresetTemplate = (templateId: number) => {
    setSelectedTemplate(templateId);
    setStage('create');
  };

  switch (stage) {
    case 'template':
      return <Templates setStage={setStage} selectPresetTemplate={selectPresetTemplate} />;
    default:
      return <DocumentCreate selectedTemplate={selectedTemplate} />;
  }
};
