import { SignatureEvents } from './SocketEvents';
import SocketClient, { SignaturePropertyUpdate, TSocketCallback } from './SocketClient';

export default class SignatureSocketClient {
  private socketClient: SocketClient;
  constructor(socketClient: SocketClient) {
    this.socketClient = socketClient;
  }
  public publishSignatureContent(data: SignaturePropertyUpdate, event: SignatureEvents, callback: TSocketCallback) {
    this.socketClient.publish(event, data, callback);
  }
}
