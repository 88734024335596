import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { SubscriptionEntity } from 'services/api/account/interfaces';
import { ApiAccountRepository } from 'services/api/account/ApiAccountRepository';

type UseSubscriptionApiDataParams = {
  accountId: number;
  enabled?: boolean;
};

type UseSubscriptionApiDataResult = {
  data?: SubscriptionEntity;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
};

type UseSubscriptionApiData<P> = (params: P) => UseSubscriptionApiDataResult;

export const useSubscriptionApiData: UseSubscriptionApiData<UseSubscriptionApiDataParams> = ({ accountId, enabled = true }) => {
  const accountRepository = new ApiAccountRepository();
  const { data, isLoading, isError, isFetched } = useQuery({
    queryKey: QueryKeys.getSubscription(accountId),
    queryFn: () => accountRepository.getSubscription(accountId),
    enabled,
  });

  return {
    data,
    isLoading,
    isError,
    isFetched,
  };
};
