import * as React from 'react';
const SvgIcoInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M11.999 8a.25.25 0 1 0 .002.5A.25.25 0 0 0 12 8" />
      <path d="M12 21a9 9 0 0 1-9-9v0a9 9 0 0 1 9-9v0a9 9 0 0 1 9 9v0a9 9 0 0 1-9 9" clipRule="evenodd" />
      <path d="M12 12v5" />
    </g>
  </svg>
);
export default SvgIcoInfo;
