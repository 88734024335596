export enum BillingInterval {
  ANNUAL = 'Annually',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  UNKNOWN = '',
}

export type PlanEntity = {
  pausable: boolean | null;
  isTrial: boolean | null;
  id: number | null;
  custom: boolean | null;
  name: string | null;
  type: PlanType | null;
  canSelfDowngrade: boolean | null;
  isSwitchingToTeamPlanADowngrade: boolean | null;
  seats_taken: number | null;
  isTrialExpired: boolean | null;
  tier: PlanTier;
  billingInterval: BillingInterval;
  estimatedRenewalTotal: number;
};

export type CancelReasonEntity = {
  id: number;
  label: string;
  notes: string;
  sortOrder: number;
};

export type CancelReasonsEntity = CancelReasonEntity[];

export type PlanApiResponse = {
  archive: boolean;
  code: string;
  custom: boolean;
  id: number;
  is_trial: boolean;
  name: string;
  pausable: boolean;
  plan_type_id: number | null;
  type: PlanType;
  can_self_downgrade: boolean;
  isSwitchingToTeamPlanADowngrade: boolean;
  seats_taken: number | null;
  is_trial_expired: boolean;
  billing_interval_length: number | null;
  estimated_renewal_total: number;
};

export type UpdatePlanApiResponse = {
  status: string;
  message: string;
};

export type UpdatePlanPayload = {
  promoCode?: string;
  planId: number;
  paidSeats: number;
};

export type UpdatePlanParam = {
  accountId: number;
  payload: UpdatePlanPayload;
};

export type ApplyPromoCodeApiResponse = {
  status: string;
  message: string;
};

export type CancelReasonsApiResponse = {
  id: number;
  name: string;
  notes_label: string;
  sort_order: number;
}[];

export type CancelPlanApiResponse = {
  state: string;
  message: string;
};

export type CancelReasonPayload = {
  id: number;
  reasonDescription: string;
  canContact: boolean;
  comment: string;
};

export type CancelPlanPayload = {
  accountId: number;
  cancelReasonPayload: CancelReasonPayload;
};

export type PlanTier = 'free' | 'basic' | 'team' | 'grande' | 'business' | '' | 'employee';
export type PlanType = 'business' | 'team' | 'custom' | 'basic' | '';

export const availableTiers: PlanTier[] = ['basic', 'team', 'business'];
export const nonLegacyTiers = ['free', 'basic', 'team', 'business'];
export const allTiers: PlanTier[] = ['', 'free', 'basic', 'grande', 'team', 'business', 'employee'];
