import { useContext, useEffect, useState } from 'react';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { resetAllPanels, setActiveTotalToggle } from '../../grid/reduxStore/blockStyleSettingsSlice';
import { useSectionId } from '../../Sections/SectionIdProvider';
import { useAppDispatch } from '../../grid/reduxStore/Store';
import { useBlockContent } from '../../Sections/useBlockContent';
import { GridBlockType } from '../../shared/gridBlockType';
import { TableTypeIdentifier } from '../../grid/reduxStore/table.types';

const useBlockState = (blockId: string) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { selectedBlockIdByWrapper, setSelectedBlockInfoByWrapper } = useContext(SelectionContext);
  const dispatch = useAppDispatch();
  const sectionId = useSectionId();
  const blockContent = useBlockContent(blockId);

  useEffect(() => {
    if (selectedBlockIdByWrapper !== blockId && isEditMode) setIsEditMode(false);
  }, [selectedBlockIdByWrapper]);

  const handleOnMouseDown = (e: MouseEvent) => {
    const isDoubleClick = e.detail === 2;
    const tableType = blockContent?.contentTable?.metadata?.tableType;
    const activeTableSettingsPanelToggle = tableType === TableTypeIdentifier.PRICING_TABLE;

    if (selectedBlockIdByWrapper === blockId) {
      if (isDoubleClick) {
        setIsEditMode(true);
      }
    } else {
      dispatch(setActiveTotalToggle({ activeTotalToggle: activeTableSettingsPanelToggle }));
      if (blockContent?.type !== GridBlockType.TABLE) {
        dispatch(resetAllPanels());
      }
      setSelectedBlockInfoByWrapper({ blockId, sectionId });
    }
  };

  return { handleOnMouseDown, isEditMode };
};

export default useBlockState;
