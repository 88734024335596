import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import BillingContactForm from './BillingContactForm';

interface BillingContactModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
}

const BillingContactModal = ({ isOpen, setIsOpen }: BillingContactModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} data-testid="billing-contact-modal">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('settings.summary.billing_contact.change_billing_contact')}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpen(false);
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
              padding: '5px',
            })}
            data-testid="billing-contact-modal-close"
          >
            <Close sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <BillingContactForm
          onSuccess={() => {
            setIsOpen(false);
          }}
          onFailure={() => {
            // eslint-disable-next-line no-console
            console.log('anything else added post-failure');
          }}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default BillingContactModal;
