import * as React from 'react';
const SvgIcoIntegrations = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M9.499 11.5a.25.25 0 0 0-.25.25V13c0 .83.672 1.501 1.5 1.501h2.502c.829 0 1.5-.672 1.5-1.5V11.75a.25.25 0 0 0-.25-.25zm-1.75.25c0-.967.783-1.75 1.75-1.75h5.002c.967 0 1.75.783 1.75 1.75V13a3 3 0 0 1-3 3.001H10.75a3 3 0 0 1-3.001-3z" />
      <path d="M10.035 7.749a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V8.5a.75.75 0 0 1 .75-.75M13.965 7.749a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V8.5a.75.75 0 0 1 .75-.75" />
      <path d="M10.499 2.366a9.748 9.748 0 0 1 5.837 18.36.75.75 0 0 1-.668-1.343A8.248 8.248 0 1 0 3.746 12a8.6 8.6 0 0 0 2.525 6.096 6.24 6.24 0 0 0 4.4 1.818.58.58 0 0 0 .579-.58v-4.083a.75.75 0 1 1 1.5 0v4.084a2.08 2.08 0 0 1-2.078 2.079 7.74 7.74 0 0 1-5.46-2.256A10.1 10.1 0 0 1 2.246 12 9.75 9.75 0 0 1 10.5 2.366" />
    </g>
  </svg>
);
export default SvgIcoIntegrations;
