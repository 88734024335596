import { VariableEntity } from 'services/entities/VariablesEntity';
import { formatCurrencyValue } from './formatCurrencyValue';

const priceRelatedVariables = ['total'];

export function formatVariableValueWithPricing(variable: VariableEntity): string {
  const isDocumentPriceVariable = priceRelatedVariables.some((term) => variable.name.toLowerCase().includes(term));

  return isDocumentPriceVariable ? formatCurrencyValue(Number(variable.value)) : (variable.value || '').toString();
}
