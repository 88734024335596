import { useSelector } from 'react-redux';
import { useSectionId } from './SectionIdProvider';
import { RootState, rootStore } from '../grid/reduxStore/Store';
import { BlockContent } from '../grid/reduxStore/editorSlice';
import { useCallback } from 'react';

export const useSectionData = () => {
  const sectionId = useSectionId();
  const section = useSelector((state: RootState) => state.gridBlockReducer.sections[sectionId]);
  const getBlockContent = (blockId: string): BlockContent => {
    return section.blocksContent[blockId];
  };

  const getSectionBlocksLayer = useCallback(() => {
    return rootStore.getState().gridBlockReducer.sections[sectionId].blocksLayer;
  }, [sectionId]);

  return {
    blocksContent: section.blocksContent,
    editorConfig: section.editorConfig,
    blocksMetadata: section.blocksMetadata,
    sectionId: sectionId,
    order: section.order,
    getSectionBlocksLayer,
    getBlockContent,
  };
};
