import { IconButton, Tooltip } from '@mui/material';
import IcoInsights from 'components/icon/icons/IcoInsights';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsPanel } from './InsightsPanel';

export function Insights({ documentId }: { documentId: string }) {
  const { t } = useTranslation();
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const onClose = ({ isExpanded }: { isExpanded: boolean }) => {
    document.dispatchEvent(new CustomEvent('insights:closed', { detail: { isExpanded } }));
    setIsPanelOpen(false);
  };

  return (
    <>
      <Tooltip title={t('pipeline.insights.open_insights_button_tooltip')}>
        <IconButton color="primary" onClick={() => setIsPanelOpen(true)} data-testid={`insights-button-${documentId}`}>
          <IcoInsights />
        </IconButton>
      </Tooltip>
      <InsightsPanel isOpen={isPanelOpen} onClose={onClose} documentId={documentId} />
    </>
  );
}
