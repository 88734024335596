import { useMutation } from 'react-query';
import { Person } from 'services/entities/PersonEntity';
import { ApiPersonRepository } from 'services/repositories/implementations/ApiPersonRepository';
import { CreatePersonPayload } from 'services/repositories/interfaces/PersonRepository';

interface ErrorResponse {
  error: 'conflict';
  message: string;
}

export default function useCreatePersonMutation() {
  return useMutation<Person, ErrorResponse, CreatePersonPayload>({
    mutationFn: ApiPersonRepository.createPerson,
  });
}
