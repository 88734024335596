import { useMemo, useState } from 'react';
import { SearchWorkspaces } from '../../../workspaces/blocks/search-workspaces';
import { LinkedAccounts } from '../linked-accounts';
import { useGetWorkspaceAccounts } from '../hooks/useGetWorkspaceAccounts';
import './styles.less';

export const WorkspacesSwitcherBody = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const { data: workspaceAccountData } = useGetWorkspaceAccounts();

  const workspaces = useMemo(() => {
    if (!workspaceAccountData) return [];
    if (searchValue.length > 0) {
      return workspaceAccountData.filter((data) => data.name.toLowerCase().includes(searchValue.toLowerCase()));
    } else return workspaceAccountData;
  }, [workspaceAccountData, searchValue]);

  const handleSearchInput = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  return (
    <div className="workspaces-switcher-body__container" data-testid="workspace-switcher-body">
      <SearchWorkspaces value={searchValue} handleSearchInput={handleSearchInput} />
      <LinkedAccounts accounts={workspaces} />
    </div>
  );
};
