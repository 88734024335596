export enum BlockSides {
  ALL_SIDES = 'All sides',
  TOP = 'Top',
  BOTTOM = 'Bottom',
  LEFT = 'Left',
  RIGHT = 'Right',
}

export function getBlockSidesFromString(sideStrings: string[]): BlockSides[] {
  return sideStrings.map((sideString) => getBlockSideFromString(sideString));
}

export const getBlockSideFromString = (sideString: string): BlockSides => {
  switch (sideString.toUpperCase()) {
    case 'TOP':
      return BlockSides.TOP;
    case 'LEFT':
      return BlockSides.LEFT;
    case 'RIGHT':
      return BlockSides.RIGHT;
    case 'BOTTOM':
      return BlockSides.BOTTOM;
    case 'ALL SIDES':
      return BlockSides.ALL_SIDES;
    default:
      throw new Error('Invalid side' + sideString.toUpperCase());
  }
};
