import { Button } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  buttonText: string;
  buttonIcon?: ReactNode;
  onClick?: () => void;
}

export function PrimaryActionButton({ buttonText, buttonIcon, onClick }: Props) {
  return (
    <Button
      startIcon={buttonIcon}
      data-testid="empty-state-primary-button"
      color="primary"
      variant="contained"
      size="medium"
      sx={{
        textTransform: 'none',
        borderRadius: '100px',
        fontWeight: '600',
        letterSpacing: '0.55px',
      }}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
}

export function SecondaryActionButton({ buttonText, buttonIcon, onClick }: Props) {
  return (
    <Button
      startIcon={buttonIcon}
      data-testid="empty-state-secondary-button"
      variant="outlined"
      size="medium"
      sx={{
        border: '1px solid #00283A',
        background: '#FFFFFF',
        color: '#00283A',
        textTransform: 'none',
        borderRadius: '100px',
        fontWeight: '600',
        letterSpacing: '0.55px',
      }}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
}
