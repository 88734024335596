import * as React from 'react';
const SvgIcoScreenSearchDesktopOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 11h38v27H9z" fill="currentColor" fillOpacity={0.5} />
    <path
      d="m36.05 35.117 2.566-2.45-4.9-4.9a8 8 0 0 0 .964-2.1 8.3 8.3 0 0 0 .32-2.334q0-3.442-2.422-5.805-2.422-2.364-5.745-2.361-3.325 0-5.747 2.361t-2.42 5.805q0 3.442 2.422 5.806t5.745 2.361a8 8 0 0 0 2.305-.322 9 9 0 0 0 2.128-.961zM26.833 28q-1.925 0-3.297-1.372t-1.37-3.295q0-1.925 1.372-3.297 1.372-1.37 3.295-1.37 1.867 0 3.267 1.373t1.4 3.294q0 1.926-1.372 3.297-1.372 1.373-3.295 1.37M4.666 49q-.99 0-1.663-.672-.672-.672-.67-1.661 0-.992.672-1.664t1.661-.67h46.667q.992 0 1.664.672t.67 1.662q0 .99-.673 1.663-.672.672-1.661.67zm4.667-7q-1.924 0-3.297-1.372-1.372-1.372-1.37-3.295V11.667q0-1.926 1.372-3.297Q7.41 6.997 9.333 7h37.333q1.926 0 3.297 1.372 1.373 1.372 1.37 3.295v25.666q0 1.926-1.372 3.297-1.372 1.373-3.295 1.37zm0-4.667h37.333V11.667H9.333z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoScreenSearchDesktopOutline;
