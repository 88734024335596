import { useEffect } from 'react';

import { SecondaryMenuWrapper } from '../component';
import { TextBlockSettings } from './designSettings/textblock';
import { AdvancedSpacing } from './designSettings/AdvancedSpacing';
import { ImageBlockSettings } from './designSettings/ImageBlock/ImageBlockSettings';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { RowSettings, ColumnSettings, TableDefaultSettings } from './TableSettings';
import { setIsAdvancedSpacingModelOpen, TableSettingsTypes } from '../../grid/reduxStore/blockStyleSettingsSlice';
import { RootState } from '../../grid/reduxStore/Store';
import { useSelector } from 'react-redux';

export const SidePanelModelsAvailable = {
  DESIGN_SETTINGS_TEXT: TextBlockSettings,
  DESIGN_SETTINGS_IMAGE: ImageBlockSettings,
  TABLE_SETTINGS_ROWS: RowSettings,
  TABLE_SETTINGS_COLUMNS: ColumnSettings,
  ADVANCED_SPACING_SETTINGS: AdvancedSpacing,
  TABLE_DEFAULT_SETTINGS: TableDefaultSettings,
};

export type SidePanelModelsAvailableType = (() => JSX.Element) | null;

export default function SidePanelModels({ activeTab }: { activeTab: string }) {
  const { isAdvancedSpacingModelOpen, toggledDesignSettingModelType, activeTableSettingsPanel } = useSelector(
    (state: RootState) => state.blockStyleSettings
  );

  let ModelToRender: SidePanelModelsAvailableType = null;
  if (toggledDesignSettingModelType) {
    if (!isAdvancedSpacingModelOpen) {
      switch (toggledDesignSettingModelType) {
        case GridBlockType.TEXT:
          ModelToRender = SidePanelModelsAvailable.DESIGN_SETTINGS_TEXT;
          break;
        case GridBlockType.IMAGE:
          ModelToRender = SidePanelModelsAvailable.DESIGN_SETTINGS_IMAGE;
          break;
      }
    } else {
      ModelToRender = SidePanelModelsAvailable.ADVANCED_SPACING_SETTINGS;
    }
  } else if (activeTableSettingsPanel) {
    switch (activeTableSettingsPanel) {
      case TableSettingsTypes.TABLE_ROWS:
        ModelToRender = SidePanelModelsAvailable.TABLE_SETTINGS_ROWS;
        break;
      case TableSettingsTypes.TABLE_COLUMNS:
        ModelToRender = SidePanelModelsAvailable.TABLE_SETTINGS_COLUMNS;
        break;
      case TableSettingsTypes.TABLE_DEFAULTS:
        ModelToRender = SidePanelModelsAvailable.TABLE_DEFAULT_SETTINGS;
        break;
      default:
        ModelToRender = null;
    }
  }

  useEffect(() => {
    setIsAdvancedSpacingModelOpen(false);
  }, [activeTab]);

  if (!ModelToRender) return null;

  return (
    <SecondaryMenuWrapper isSecondaryMenuActive={true}>
      <ModelToRender />
    </SecondaryMenuWrapper>
  );
}
