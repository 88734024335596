import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { ErrorCode } from '../../../services/socket/SocketEvents';
import DocumentLockedModal from './DocumentLockedModal';
import { useSelector } from 'react-redux';
import { RootState } from '../grid/reduxStore/Store';

interface DocumentLockedModalContextType {
  showLockedModal: boolean;
  setShowLockedModal: (show: boolean) => void;
  checkDocumentLockStatus: (errorCode: ErrorCode) => boolean;
}

interface DocumentLockedModalProviderProps {
  documentId: string;
  isDocumentLocked: boolean | null;
  children: ReactNode;
}

export const DocumentLockedModalContext = createContext<DocumentLockedModalContextType | undefined>(undefined);

export const DocumentLockedModalProvider: React.FC<DocumentLockedModalProviderProps> = ({ documentId, isDocumentLocked, children }) => {
  const [showLockedModal, setShowLockedModalInternal] = useState(isDocumentLocked === null ? false : isDocumentLocked); // turn off modal if isDocumentLocked is null
  const currentSaveStatus = useSelector((state: RootState) => state.saveStatus.currentSaveStatus);
  useEffect(() => {
    if (currentSaveStatus?.errCode === ErrorCode.LOCKED && isDocumentLocked !== null) {
      setShowLockedModal(true);
    }
  }, [currentSaveStatus]);

  const checkDocumentLockStatus = (errorCode: ErrorCode) => {
    if (errorCode === ErrorCode.LOCKED) {
      setShowLockedModal(true);
      return true;
    }
    return false;
  };

  const setShowLockedModal = (show: boolean) => {
    if (isDocumentLocked !== null) {
      setShowLockedModalInternal(show);
    }
  };

  return (
    <DocumentLockedModalContext.Provider value={{ showLockedModal, setShowLockedModal, checkDocumentLockStatus }}>
      <DocumentLockedModal isDocumentLocked={showLockedModal} documentId={documentId} />
      {children}
    </DocumentLockedModalContext.Provider>
  );
};

export const useDocumentLockedModal = () => {
  const context = useContext(DocumentLockedModalContext);
  if (context === undefined) {
    throw new Error('useDocumentLockedModal must be used within a DocumentLockedModalProvider');
  }
  return context;
};
