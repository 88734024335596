import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Stack, Card, CardHeader, Typography, CardContent, useTheme, Button, Skeleton } from '@mui/material';

import { useLocationData } from 'hooks/useLocationData';
import { useBillingInfoApiData } from 'hooks/useBillingInfoApiData';
import { BillingAddress, UpdateBillingPayload } from 'services/api/account/interfaces';

import { PaymentMethodData } from './interface';
import { BillingCard, BillingCardContent, BillingCardHeader } from '../utils/styled';
import PaymentMethodForm from './paymentMethodForm';
import { BillingContext } from './billingProvider';
import { getIconFromCCType } from './utils';

const PaymentMethod = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<PaymentMethodData>();

  const { accountId, accountRepository, listingsRepository, setCurrentPaymentMethod, isPaying, isBillingOptionsFetched } =
    useContext(BillingContext);

  const {
    data: billingData,
    isFetched: isBillingFetched,
    refetch,
  } = useBillingInfoApiData({
    accountRepository,
    accountId,
  });
  const { isFetched: isLocationFetched, locationData, getStatesFromCountry } = useLocationData({ listingsRepository });

  const formatAddressString = (address: BillingAddress): string => {
    const addressPieces = [address.addressLine1];
    if (address.addressLine2) {
      addressPieces.push(address.addressLine2);
    }
    addressPieces.push(`${address.city} ${address.state} ${address.zip}`);
    addressPieces.push(address.country);

    return addressPieces.join(', ');
  };

  const handlePaymentMethodUpdated = (updatedData: UpdateBillingPayload) => {
    if (updatedData.cardInfo.ccNumber) {
      updatedData.cardInfo.lastFour = Number(updatedData.cardInfo.ccNumber.toString().slice(-4));
      updatedData.cardInfo.ccNumber = undefined;
      updatedData.cardInfo.securityCode = undefined;
      setInitialData(updatedData);
    }
    refetch();
    setIsEditing(false);
  };

  useEffect(() => {
    if (isBillingFetched) {
      if (billingData) {
        const formattedData = {
          billingAddress: {
            addressLine1: billingData.address1,
            addressLine2: billingData.address2,
            city: billingData.city,
            state: billingData.state,
            zip: billingData.zip,
            country: billingData.country,
          },
          cardInfo: {
            cardholderName: `${billingData.firstName || ''} ${billingData.lastName || ''}`.trim(),
            type: billingData.cardType,
            expiryMonth: billingData.expiryMonth,
            expiryYear:
              billingData.expiryYear.toString().length > 2 ? Number(billingData.expiryYear.toString().slice(-2)) : billingData.expiryYear,
            lastFour: billingData.lastFour,
          },
        };
        setInitialData(formattedData);
        if (formattedData.cardInfo.cardholderName && formattedData.cardInfo.lastFour) {
          setCurrentPaymentMethod(formattedData);
        } else {
          setCurrentPaymentMethod(null);
        }
      } else {
        const emptyData = {
          billingAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
          },
          cardInfo: {
            cardholderName: '',
            type: '',
            expiryMonth: Number(dayjs().format('MM')),
            expiryYear: Number(dayjs().format('YY')),
          },
        };
        setInitialData(emptyData);
        setCurrentPaymentMethod(null);
      }
    }
  }, [billingData, isBillingFetched]);

  useEffect(() => {
    if (isEditing && contentRef.current) {
      const y = contentRef.current.getBoundingClientRect().top + window.scrollY - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [isEditing]);

  const loadingData = !isLocationFetched || !locationData || !isBillingFetched || !initialData;

  return (
    <BillingCard data-testid="payment-method">
      <BillingCardHeader
        disableTypography
        title={isEditing ? t('settings.billing.edit_payment_method') : t('settings.billing.payment_method')}
      />
      <BillingCardContent ref={contentRef}>
        {loadingData ? (
          <Skeleton width="100%" height="96px" variant="rounded" data-testid="skeleton-payment-method-loading" />
        ) : isEditing ? (
          <PaymentMethodForm
            initialData={initialData}
            setIsEditing={setIsEditing}
            locationData={locationData}
            getStatesFromCountry={getStatesFromCountry}
            updatePaymentMethod={handlePaymentMethodUpdated}
          />
        ) : (
          <Card variant="outlined" sx={{ width: '100%', borderRadius: '12px', '& .MuiCardHeader-avatar': { marginRight: 1 } }}>
            <CardHeader
              disableTypography
              title={
                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Stack direction="row" gap={1}>
                    {!!initialData.cardInfo.type && <img src={getIconFromCCType(initialData.cardInfo.type)} />}
                    {initialData.cardInfo.lastFour ? (
                      <Typography variant="body1">
                        {t('settings.billing.card_type', {
                          last4Digits: initialData.cardInfo.lastFour,
                        })}
                      </Typography>
                    ) : (
                      <Typography variant="body1">{t('settings.billing.no_card_on_file')}</Typography>
                    )}
                  </Stack>
                  <Button
                    variant="text"
                    size="medium"
                    onClick={() => setIsEditing(true)}
                    disabled={isPaying || !isBillingOptionsFetched}
                    sx={{ textTransform: 'initial', padding: '6px 8px' }}
                  >
                    {initialData.cardInfo.lastFour ? t('settings.billing.edit') : t('settings.billing.new')}
                  </Button>
                </Stack>
              }
              sx={{ padding: theme.spacing(2, 1, 0, 2) }}
            />

            <CardContent sx={{ padding: theme.spacing(0, 2, 2), '&:last-child': { paddingBottom: 2 } }}>
              {!!initialData.cardInfo.cardholderName && !!initialData.cardInfo.lastFour && (
                <Typography variant="body1" color="text.secondary">
                  {formatAddressString(initialData.billingAddress)}
                </Typography>
              )}
            </CardContent>
          </Card>
        )}
      </BillingCardContent>
    </BillingCard>
  );
};

export default PaymentMethod;
