import { useQuery } from 'react-query';
import { apiContentLibraryRepository } from 'services/repositories/implementations/ApiContentLibraryRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useGetAssetById = (assetId: string) => {
  return useQuery({
    queryKey: QueryKeys.getAssetByAssetId(assetId),
    queryFn: () => apiContentLibraryRepository.get(assetId),
    enabled: !!assetId,
  });
};
