import { MenuItem } from '@mui/material';
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import React from 'react';
import { cellMenu } from '../defaultData';
import { useTranslation } from 'react-i18next';

interface TableRowTypes {
  options: { sign: string; value: string }[];
  changeCellFormatValue: (value: string) => void;
  selectedValue?: string;
}

function CellDropDown({ options, changeCellFormatValue, selectedValue }: TableRowTypes) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (sign: string) => {
    if (typeof sign === 'string') {
      changeCellFormatValue(sign);
    }
    setAnchorEl(null);
  };

  return (
    <div data-testid="cell-dropdown-wrapper">
      <IconButton
        data-testid="cell-dropdown-button"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'cell-menu-open' : 'cell-menu-closed'}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          margin: 0,
          borderRadius: 0,
          border: 'none',
          transition: 'all 300ms easy',
          padding: 0,
          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      >
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        data-testid="cell-dropdown-mune"
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'cell-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: '20ch',
            },
          },
        }}
      >
        {options?.map(({ sign, value }, index) => (
          <MenuItem
            sx={{ color: '#191C1E', padding: '1em 0.5em' }}
            key={value}
            selected={selectedValue === cellMenu[index].sign}
            onClick={() => handleClose(sign)}
          >
            {t(value)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
export default CellDropDown;
