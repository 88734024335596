import { useMutation } from 'react-query';
import { postAccessUsage } from 'services/settings/respositories/implementations/ApiWorkspaceAccountsRepository';

export const useWorkspaceAccountUsage = () => {
  const mutation = useMutation({
    mutationFn: (subdomain: string) => postAccessUsage(subdomain),
  });

  return { setWorkspaceAccountUsage: mutation.mutateAsync };
};
