import { gridPixelSize } from '../../../shared/gridConfig';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { columns, rows } from '../Table';
import { PreviewBlock } from './PreviewBlock';
import { BlockContent } from '../../../grid/reduxStore/editorSlice';
import { TableColumnType } from '../../../grid/reduxStore/table.types';
import { useApplyCustomRulesToColumns } from '../Table/useApplyCustomRulesToColumns';

interface PreviewTableBlockProps {
  block: BlockContent;
  sectionId: string;
}

const DEFAULT_DECIMAL_PLACE = 2;
export function PreviewTableBlock({ block, sectionId }: PreviewTableBlockProps) {
  const tableColumns: TableColumnType[] = (block?.contentTable?.columns || columns) as TableColumnType[];
  const { applyCustomRulesToColumns } = useApplyCustomRulesToColumns(
    sectionId,
    block.blockConfig.id,
    block.contentTable?.metadata.pricingDecimalPlaces || DEFAULT_DECIMAL_PLACE
  );
  const contentTableRows = block?.contentTable?.rows;
  const filteredRows = contentTableRows?.filter((row) => !row.isRowHidden);

  //TODO: we will need to enable or disable the editing function for each cell (https://proposify2.atlassian.net/browse/SP-1067)
  // const handleIsCellEditable = (params: GridCellParams): boolean => {
  //   const { row, colDef } = params;
  //   const isCellEditingAllowed = row.cellConfig ? row.cellConfig[colDef.field] ?? true : true;

  //   return isCellEditingAllowed;
  // };

  return (
    <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
      <Box>
        <DataGridPro
          rows={filteredRows || rows}
          columns={applyCustomRulesToColumns(tableColumns)}
          isCellEditable={() => false}
          style={{ width: block.blockConfig.width }}
          className={'py-selected-state'}
        />
      </Box>
    </PreviewBlock>
  );
}
