import { useQuery } from 'react-query';
import { ApiProposalRepository } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useCompanyByIdQuery = (companyId: number | undefined | null) => {
  return useQuery(
    QueryKeys.getCompanyById(companyId),
    () => (companyId ? ApiProposalRepository.getCompanyById(companyId) : Promise.reject(new Error('invalid id'))),
    {
      enabled: !!companyId,
    }
  );
};
