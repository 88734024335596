import { Box } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';

export { Milestone } from './Milestone';
export { MilestoneContent } from './MilestoneContent';
export { MilestoneDate } from './MilestoneDate';
export { MilestoneInfo } from './MilestoneInfo';

export function Timeline({ children, ...props }: ComponentProps<typeof Box> & PropsWithChildren) {
  return (
    <Box display="flex" flexDirection="column" gap={2} {...props}>
      {children}
    </Box>
  );
}
