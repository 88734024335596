import { FormEventHandler, ReactNode } from 'react';
import { Field } from './field';
import { Fields } from './fields';
import { Footer } from './footer';
import { Title } from './title';

export function DocumentShareForm({ onSubmit, children }: { onSubmit: FormEventHandler; children: ReactNode }) {
  return <form onSubmit={onSubmit}>{children}</form>;
}

DocumentShareForm.Title = Title;
DocumentShareForm.Fields = Fields;
DocumentShareForm.Field = Field;
DocumentShareForm.Footer = Footer;
