import { useEffect } from 'react';
import { styled, RadioGroup, FormControl, FormLabel, Radio, FormControlLabel, TextField, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CancelSurveyProps } from './interface';

// defined outside scope of component to avoid losing focus on input change due to rerender
const ReasonDescriptionText = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: theme.spacing(1),
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
}));

const contactAnswers = ['yes', 'no'];

const CancelSurvey = ({ formValues, updateField, reasons = [], errors = [] }: CancelSurveyProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    updateField('reasonDescription', '');
  }, [formValues.id]);

  return (
    <Stack component="form" gap={3}>
      <FormControl fullWidth>
        <FormLabel required id="cancel-reason-label">
          {t('settings.plan.cancel_flow.why_cancel')}
        </FormLabel>
        <RadioGroup
          aria-labelledby="cancel-reason-label"
          name="cancel-reason"
          onChange={(event, value) => {
            updateField('id', parseInt(value));
          }}
          value={formValues.id.toString()}
          sx={{ gap: 1, width: '100%' }}
        >
          {reasons.map((reason, key) => (
            <>
              <FormControlLabel
                value={reason.id}
                control={<Radio />}
                label={t(reason.label)}
                key={`cancel-survey-reason-${reason.id}-${key}`}
              />
              {formValues.id === reason.id && (
                <ReasonDescriptionText
                  value={formValues.reasonDescription}
                  onChange={(event) => updateField('reasonDescription', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t(reason.notes)}
                  key={`cancel-survey-reason-${reason.id}_details-${key}`}
                />
              )}
            </>
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel required id="cancel-contact-label">
          {t('settings.plan.cancel_flow.contact')}
        </FormLabel>
        <RadioGroup
          aria-labelledby="cancel-contact-label"
          name="cancel-contact"
          onChange={(event, value: string) => {
            updateField('canContact', value === 'yes');
          }}
          value={formValues.canContact ? 'yes' : 'no'}
          sx={{ gap: 1, width: '100%' }}
        >
          {contactAnswers.map((answer, key) => (
            <FormControlLabel
              value={answer}
              control={<Radio />}
              label={t(`settings.plan.cancel_flow.can_contact.${answer}`)}
              key={`cancel-survey-contact-${answer}-${key}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          multiline={true}
          name="cancel-comment"
          onChange={(event) => {
            updateField('comment', event.target.value);
          }}
          value={formValues.comment}
          label={t('settings.plan.cancel_flow.comment')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      {errors.length > 0 && (
        <Typography variant="body2" color="error">
          {errors.join('\n')}
        </Typography>
      )}
    </Stack>
  );
};

export default CancelSurvey;
