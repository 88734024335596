import React from 'react';
import { Icon } from 'components';
import { TemplateFolder } from 'services/templates/entities/TemplateRepository';
import './styles.less';

interface Props {
  templateData?: TemplateFolder;
  navigateToFolder: (folderData) => void;
}

export const TemplateFolderCard: React.FC<Props> = ({ templateData, navigateToFolder }) => {
  return (
    <div className="template-folder-card__container" onClick={() => navigateToFolder(templateData)}>
      <div className="template-folder-card__body">
        <Icon name="IcoFolder" className="lake" />
      </div>
      <div className="template-folder-card__footer">{templateData?.name}</div>
    </div>
  );
};
