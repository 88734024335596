import type { Person } from 'services/entities/PersonEntity';
import type { EmailPickerOption } from './types';

type Input = Person;
type ValueKey = keyof Input;

export const convertToOption = ({ email, id, fullName, type, ...input }: Input, valueKey: ValueKey = 'uid'): EmailPickerOption => {
  const value = input[valueKey];
  return {
    email,
    value,
    id,
    label: fullName,
    type,
  };
};

export const createNewOption = (email: string): EmailPickerOption => {
  return { label: email, value: email, email: email, id: null, __isNew__: true, type: 'contact' };
};
