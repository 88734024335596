import { RowTypesIdentifier, TableRowType, TotalRowTypesIdentifier } from '../components/editor/grid/reduxStore/table.types';

export const totalRowTypes = {
  SUBTOTAL: TotalRowTypesIdentifier.SUBTOTAL,
  DISCOUNT: TotalRowTypesIdentifier.DISCOUNT,
  TAX: TotalRowTypesIdentifier.TAX,
  TOTAL: TotalRowTypesIdentifier.TOTAL,
};

export const deprecatedTableRowTypes = {
  HEADER: RowTypesIdentifier.HEADER,
  BODY: RowTypesIdentifier.BODY,
  FOOTER: RowTypesIdentifier.FOOTER,
};

const allTableRowTypes = {
  ...deprecatedTableRowTypes,
  ...totalRowTypes,
};
export const TableRowTypes = (isPricing?: boolean) => (!isPricing ? deprecatedTableRowTypes : allTableRowTypes);
export const isRowBelongsToTotal = (row) => Object.values(totalRowTypes).includes(row.rowType);
export const getRowClassName = (params) => {
  if (params.row.rowType === TableRowTypes().HEADER) return 'py-header-row';
  if (params.row.rowType === TableRowTypes().FOOTER) return 'py-footer-row';
  if (isRowBelongsToTotal(params.row)) return `py-total-row ${params.row?.isRowHidden ? 'py-hide-row' : ''}`;
  else return '';
};

export const isTaxOrDiscountRow = (row: TableRowType) =>
  row.rowType === TotalRowTypesIdentifier.TAX || row.rowType === TotalRowTypesIdentifier.DISCOUNT;
