import GrayBlock from './GrayBlock';
import { TrackedBlockCollection } from '../../models/TrackedBlockCollection.model';

const PlaceHolder = ({ trackedBlocks }: TrackedBlockCollection) => {
  const placeholderPosition = { x: trackedBlocks.x, y: trackedBlocks.y };
  const placeholderDimension = { width: trackedBlocks.width, height: trackedBlocks.height };
  return <GrayBlock position={placeholderPosition} dimension={placeholderDimension} />;
};

export default PlaceHolder;
