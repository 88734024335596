import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { CreateApiClient } from './create-api-client';
import { ApiClient } from '../types';

interface PageHeaderProps {
  onNewData: (newData: ApiClient) => void;
}
const PageHeader = ({ onNewData }: PageHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
      <Box>
        <Typography variant="h5" fontFamily="inherit">
          {t('settings.connect_api.title')}
        </Typography>
        <Typography fontFamily="inherit">{t('settings.connect_api.subtitle')}</Typography>
      </Box>
      <CreateApiClient onNewData={onNewData} />
    </Box>
  );
};

export default PageHeader;
