import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Dimensions, Position } from '../../../shared/interfaces';
import { getSignatureColor, SignatureBaseButton } from '../../../shared/components/SignatureBaseButton';
import { AssignedSignee } from 'services/repositories/interfaces/SignatureRepository';

import './Signature.less';

export interface PreviewSignatureProps {
  signatureId: string;
  assignedSignee?: AssignedSignee | null;
  handleClick?: () => void;
  position: Position;
  dimensions: Dimensions;
}

const PreviewUnsignedSignature: React.FunctionComponent<PreviewSignatureProps> = ({
  signatureId,
  assignedSignee = null,
  position,
  dimensions,
  handleClick,
}) => {
  const { t } = useTranslation();
  const signee = assignedSignee;

  const customStylePositioning: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: `translate(${position.x}px, ${position.y}px)`,
  };

  const customStyleDimensions: CSSProperties = {
    width: dimensions.width,
    height: dimensions.height,
  };

  return (
    <div className="unsigned_signature" style={customStylePositioning} onClick={handleClick}>
      <div data-testid="unsigned_signature_draggable" />
      <SignatureBaseButton style={customStyleDimensions} color={getSignatureColor(signee?.email)} data-signature-id={signatureId}>
        {t('signature')}
      </SignatureBaseButton>
    </div>
  );
};

export default PreviewUnsignedSignature;
