import React from 'react';
import { Paragraph } from '../../../components';
import { Icons } from '../../icon/icons';
import './blockSourceButton.less';
import { useDrag } from 'react-dnd';

const { IcoDrag, IcoTextbox } = Icons;

interface TextBlockButtonProps {
  title: string;
}

const TextBlockButton: React.FunctionComponent<TextBlockButtonProps> = ({ title }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TEXT',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <button ref={drag} draggable className="block-source" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="block-source-icon">
        <IcoTextbox />
      </div>
      <div className="block-source-drag-icon">
        <IcoDrag />
      </div>
      <Paragraph size="sm">{title}</Paragraph>
    </button>
  );
};

export default TextBlockButton;
TextBlockButton.displayName = 'TextBlockButton';
