import * as React from 'react';
const SvgIcoImage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M6.998 3.746a3.25 3.25 0 0 0-3.252 3.252v10.004a3.25 3.25 0 0 0 3.252 3.252h10.004a3.25 3.25 0 0 0 3.252-3.252V6.998a3.25 3.25 0 0 0-3.252-3.252zM2.246 6.998a4.75 4.75 0 0 1 4.752-4.752h10.004a4.75 4.75 0 0 1 4.752 4.752v10.004a4.75 4.75 0 0 1-4.752 4.752H6.998a4.75 4.75 0 0 1-4.752-4.752z" />
      <path d="m4.822 12.235-1.295 1.296a.75.75 0 0 1-1.061-1.06l1.296-1.297a3.16 3.16 0 0 1 4.471 0l4.297 4.297a.75.75 0 0 1-1.06 1.061l-4.297-4.297a1.66 1.66 0 0 0-2.35 0" />
      <path d="M16.18 15.236a1.66 1.66 0 0 0-2.351 0l-6.298 6.298a.75.75 0 1 1-1.06-1.06l6.297-6.298a3.16 3.16 0 0 1 4.472 0l3.94 3.94a.75.75 0 0 1-1.06 1.061z" />
    </g>
  </svg>
);
export default SvgIcoImage;
