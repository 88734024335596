import { ApiPlanFeatureRepository } from '../services/repositories/implementations/ApiPlanFeatureRepository';
import { useQuery } from 'react-query';
import { MissingFeatureEntity } from '../services/entities/MissingFeatureEntity';

export function useGetDowngradeFeaturesList(
  planFeatureRepository: ApiPlanFeatureRepository,
  planId: number,
  enabled = false
): MissingFeatureEntity[] {
  const { data: features, isError } = useQuery(['downgradeList', planId], () => planFeatureRepository.getDowngradeFeaturesList(planId), {
    enabled,
  });

  if (isError || typeof features === 'undefined') {
    return [] as MissingFeatureEntity[];
  }

  return features;
}
