import { Heading, Modal } from 'components';
import { useTranslation } from 'react-i18next';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onConfirm: () => Promise<void>;
};

export const EmptyTrashModal = ({ visible, setVisible, onConfirm }: Props) => {
  const { t } = useTranslation();

  const handleConfirm = async () => {
    await onConfirm();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      isVisible={visible}
      handleOk={handleConfirm}
      handleCancel={handleCancel}
      cancelText={t('cancel')}
      affirmText={t('continue')}
      closable={false}
      maskClosable={false}
      escClosable={false}
    >
      <Heading level={4}>{t('pipeline.tabs.trash_document_list.empty_trash_confirmation_title')}</Heading>
      <div className="action-modal__content">{t('pipeline.tabs.trash_document_list.empty_trash_confirmation_message')}</div>
    </Modal>
  );
};
