import React from 'react';

interface MenuItemProps {
  icon: React.ReactNode;
  label: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, label }) => {
  return (
    <div className="menu-item-container">
      <div className="menu-item-icon">{icon}</div>
      <div className="menu-item-label">{label}</div>
    </div>
  );
};

export default MenuItem;
