import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';
import type { ProspectResponse, ProspectRepository, ProspectsResponse } from '../interfaces/ProspectRepository';

export function generateProspectUid(prospect: ProspectResponse) {
  return `${prospect.id}-${prospect.type}`;
}

export const apiProspectsRepository: ProspectRepository = {
  getProspects: async (queryParam: { offset?: number; limit?: number; search?: string } = {}) => {
    const prospectsResponse: ProspectsResponse = await apiClient.get<ProspectsResponse>(Endpoints.getProspects, { params: queryParam });
    return prospectsResponse.map((prospect) => ({ ...prospect, uid: generateProspectUid(prospect) }));
  },
};
