import * as React from 'react';
const SvgIcoError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M8.641 8.641a.75.75 0 0 1 1.06 0l5.658 5.657a.75.75 0 0 1-1.06 1.06L8.64 9.703a.75.75 0 0 1 0-1.06" />
      <path d="M8.641 15.359a.75.75 0 0 1 0-1.06l5.657-5.658a.75.75 0 0 1 1.06 1.06L9.703 15.36a.75.75 0 0 1-1.06 0" />
      <path d="M17.834 6.166a8.25 8.25 0 0 0-11.668 0 8.25 8.25 0 0 0 0 11.668 8.25 8.25 0 0 0 11.668 0 8.25 8.25 0 0 0 0-11.668M5.106 5.106a9.75 9.75 0 0 1 13.788 0 9.75 9.75 0 0 1 0 13.788 9.75 9.75 0 0 1-13.788 0 9.75 9.75 0 0 1 0-13.788" />
    </g>
  </svg>
);
export default SvgIcoError;
