import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import SignatureSocketClient from './SignatureSocketClient';
import {
  BlockEvents,
  DocumentSettingsEvents,
  SectionEvents,
  SignatureEvents,
  SocketClientDefaultEvents,
  SocketConnectionEvents,
} from './SocketEvents';
import { SignatureBox, SignatureBoxApiResponse } from 'services/repositories/interfaces/SignatureRepository';

import { transformSignatureBoxApiResponse } from 'services/repositories/implementations/ApiSignatureRepository';
import DocumentSettingsSocketClient from './DocumentSettingsSocketClient';
import { DocumentSettingsType } from '../../components/editor/SidePanel/document-settings/DocumentDesignSettings/DocumentSettingsContext';
import { SectionsSignaturesType } from '../../components/editor/providers/SignaturesProvider';

type AuthObject = {
  contentId: string | undefined;
  accessToken: string | undefined;
};

type GetAllSignaturesOnMountCallback = (data: SectionsSignaturesType) => void;
type SignatureInsertionType = SignatureBox & { sectionId: string };
export type SignaturePropertyUpdate = Pick<SignatureBox, 'signatureBoxId' | 'properties'>;

export type TSocketCallback = (data: any) => void;

type Events = DocumentSettingsEvents | SignatureEvents | BlockEvents | SectionEvents | SocketConnectionEvents | SocketClientDefaultEvents;

class SocketClient {
  #signatureSocketClient: SignatureSocketClient;
  #documentSettingsClient: DocumentSettingsSocketClient;
  #isConnected = false;
  #socketClient: Socket;

  #connectionParams: Partial<ManagerOptions & SocketOptions> & { auth: AuthObject } = {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 5,
    auth: {
      accessToken: undefined,
      contentId: undefined,
    },
    transports: ['websocket'],
  };
  constructor(serverURL: string, authParams: AuthObject) {
    this.#socketClient = this.getNewSocketClient(serverURL, authParams);
    this.#signatureSocketClient = new SignatureSocketClient(this);
    this.#documentSettingsClient = new DocumentSettingsSocketClient(this);
  }

  private getNewSocketClient(serverURL: string, authParams: AuthObject): Socket {
    this.#connectionParams.auth = authParams;
    return io(serverURL, this.#connectionParams);
  }

  public isConnected(): boolean {
    return this.#isConnected;
  }

  private setIsConnected(): void {
    this.#isConnected = true;
  }

  public reconnectWithNewToken(accessToken: string): void {
    this.#socketClient.disconnect();
    this.#connectionParams.auth.accessToken = accessToken;
    this.#socketClient.auth = this.#connectionParams.auth;
    this.#socketClient.connect();
  }

  public connect(): void {
    this.subscribeOnce(SocketConnectionEvents.CONNECTED, () => this.setIsConnected());
    this.#socketClient.connect();
  }

  public disconnect() {
    this.#socketClient.disconnect();
    this.#isConnected = false;
  }

  public getAllSignaturesOnMount(callback: GetAllSignaturesOnMountCallback) {
    this.subscribeOnce(SignatureEvents.SIGNATURE_GET_ALL, (response: { [sectionId: string]: SignatureBoxApiResponse[] } = {}) => {
      const data: SectionsSignaturesType = {};

      for (const [sectionId, signatures] of Object.entries(response)) {
        data[sectionId] = signatures.map((signature) => {
          return transformSignatureBoxApiResponse(signature);
        });
      }

      callback(data);
    });
  }

  public subscribe(event: Events, callback: any): SocketClient {
    this.#socketClient.on(event, callback);
    return this;
  }

  public subscribeOnce(event: Events, callback: any): SocketClient {
    this.#socketClient.once(event, callback);
    return this;
  }

  public publish(event: Events, data: any, callback: (resp: any) => any): SocketClient {
    this.#socketClient.emit(event, data, callback);
    return this;
  }

  public addSignatureContent(data: SignatureInsertionType, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_ADD, callback);
  }

  public updateSignatureContent(data: SignaturePropertyUpdate, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_UPDATE, callback);
  }

  public deleteSignatureContent(data: SignatureBox, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_DELETE, callback);
  }

  public loadDocumentSettings(callback: TSocketCallback) {
    this.#documentSettingsClient.getDocumentSettings('', DocumentSettingsEvents.DOCUMENT_SETTINGS_LOAD, callback);
  }

  public saveDocumentSettings(data: DocumentSettingsType, callback: TSocketCallback) {
    this.#documentSettingsClient.getDocumentSettings(data, DocumentSettingsEvents.DOCUMENT_SETTINGS_SAVE, callback);
  }
}

export default SocketClient;
