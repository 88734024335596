import { CSSProperties, ReactNode } from 'react';
import { DraggableCore } from 'react-draggable';
import { VariablesGroup } from '../../../../../services/entities/VariablesEntity';
import { BlockContent } from '../../../grid/reduxStore/editorSlice';
import { Resizable } from 're-resizable';

type PreviewBlockProps = {
  configOptions?: object | undefined;
  variables?: VariablesGroup;
  gridBlock: BlockContent;
  children?: ReactNode;
  gridSystemInPx: number;
  gridRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export const PreviewBlock = ({ gridBlock: block, children, gridSystemInPx }: PreviewBlockProps) => {
  const customStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: `translate(${block.blockConfig.x}px, ${block.blockConfig.y}px)`,
  };
  const isMac = navigator.userAgent.includes('Mac');
  return (
    <div style={{ zIndex: block.blockConfig.z, position: 'relative' }}>
      <DraggableCore scale={1} grid={[gridSystemInPx, gridSystemInPx]} disabled={true}>
        <Resizable
          className={`editor__page__draggable ${isMac ? 'is_mac' : ''}`}
          size={{
            width: block.blockConfig.width,
            height: block.blockConfig.height,
          }}
          scale={1}
          boundsByDirection={true}
          minWidth={gridSystemInPx}
          style={customStyle}
          minHeight={gridSystemInPx}
          grid={[gridSystemInPx, gridSystemInPx]}
          enable={false}
        >
          {children}
        </Resizable>
      </DraggableCore>
    </div>
  );
};
