import { useTranslation } from 'react-i18next';
import { useTableManipulation } from '../useTableManipulation';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import React, { useContext, useState } from 'react';
import { SidePanelProviderContext } from '../../../../SidePanel/content';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { isPricingNumberCell, roundDecimalValue } from '../helper';
import { isRowBelongsToTotal, isTaxOrDiscountRow } from '../../../../../../muiTheme/dataGridUtils';
import { ColumnTypeIdentifier, RowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import { Tooltip } from '@mui/material';
import CellDropDown from './CellDropDown';
import { SelectionContext } from '../../../SelectedBlockInfoProvider';

type InputWithTooltipsProps = Partial<GridRenderEditCellParams> & {
  sectionId: string;
  blockId: string;
  pricingDecimalPlaces: number;
};

const InputWithTooltips = ({ params, sectionId, blockId, pricingDecimalPlaces }: InputWithTooltipsProps) => {
  const { t } = useTranslation();
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const { getUpdatedRowsTableDataFromCellEdit, addCustomFieldToRow } = useTableManipulation();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { id, value, field, colDef, row } = params;
  const formaterKey = colDef?.columnType?.toLowerCase();
  const optionsMenu = row?.optionsMenu;

  const [localValue, setLocalValue] = useState(value);
  const apiRef = useGridApiContext();
  const [formattedValue, setFormattedValue] = useState({ value: localValue, blockId: blockId });
  const isPricingInputCell = isPricingNumberCell(row, colDef);

  const isCellDropDownVisible = isTaxOrDiscountRow(row) && colDef.columnType === ColumnTypeIdentifier.PRICE;
  const inputClassName = isPricingInputCell || isRowBelongsToTotal(row) ? 'py-number-input-no-arrow' : 'py-custom-input';
  const inputValue = isCellDropDownVisible ? localValue || '' : localValue;
  const valueFormater = row?.cellConfig?.[formaterKey]?.valueFormater || '';
  const isCellHidden = row?.cellConfig?.[field]?.hideCell;
  const tooltipTitle = isCellHidden && t('document.grid.table.cell_hidden_tooltip');
  const selectedValue = isCellHidden ? 'Hide cell' : valueFormater;
  const columnType = colDef.columnType.toLowerCase();
  const rowType = row.rowType.toLowerCase();

  const onCellValueChange = async (value: string) => {
    if (!blockId) return;
    if (isPricingInputCell) {
      value = value.replace(/e/gi, '');
      const roundedValue = roundDecimalValue(value, pricingDecimalPlaces);
      setFormattedValue({ value: roundedValue, blockId: blockId as string });
    } else {
      setFormattedValue({ value: value, blockId: blockId as string });
    }

    setLocalValue(value);
    apiRef.current.setEditCellValue({
      id,
      field,
      value,
    });

    const updatedTableData = getUpdatedRowsTableDataFromCellEdit(params, value, blockId, sectionId);
    if (!updatedTableData) return;
    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  const handleBlur = async () => {
    let roundedValue = formattedValue.value;
    if (isPricingInputCell && !Number(roundedValue)) {
      roundedValue = 0;
    }

    setLocalValue(roundedValue);
    apiRef.current.setEditCellValue({
      id,
      field,
      value: roundedValue,
    });

    const updatedTableData = getUpdatedRowsTableDataFromCellEdit(params, roundedValue, formattedValue.blockId, sectionId);
    if (!updatedTableData) return;

    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  const handlePressEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && toggledTableSettingsPanel) {
      apiRef.current.stopCellEditMode({ id, field });

      const allRowIds = apiRef.current.getAllRowIds();
      const currentRowIndex = allRowIds.findIndex((rowId) => rowId === id);
      const nextRowId = allRowIds[currentRowIndex + 1];

      apiRef.current.setEditCellValue({ id, field, value: localValue });
      apiRef.current.setRowSelectionModel([nextRowId]);

      setToggledTableSettingsPanel({
        tableApi: {
          selectedModel: apiRef.current.getCellParams(nextRowId, field),
          tableCallbackDetails: toggledTableSettingsPanel.tableApi.tableCallbackDetails,
        },
      });
      apiRef.current.startCellEditMode({
        id: nextRowId,
        field,
      });
    } else if (event.key === 'Tab') {
      await handleBlur();
    } else if (event.key === 'e' && isPricingInputCell) {
      event.stopPropagation();
      await handleBlur();
    }
  };

  const changeCellFormatValue = async (value: string) => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;
    if (!selectedSectionId) throw new Error('Section id cannot be empty');
    try {
      let cellConfigObject = {};
      if (value === 'Hide cell') {
        cellConfigObject = { hideCell: true };
      } else {
        cellConfigObject = { valueFormater: value, hideCell: false };
      }
      const updatedTableData = addCustomFieldToRow({ blockId, sectionId, field: columnType, id, cellConfigObject });
      if (!updatedTableData) return;
      const result = await blockContentChangedHandler(blockId, sectionId, updatedTableData);
      if (result.status === 'Ok') {
        return;
      }
    } catch (error) {
      throw new Error('Error while updating table data');
    }
  };

  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <span className="py-custom-cell-wrapper">
        <input
          data-testid={`${rowType}-${columnType}-input-field`}
          className={inputClassName}
          type={`${colDef.type === 'number' && row.rowType !== RowTypesIdentifier.HEADER ? 'number' : 'string'}`}
          onChange={(e) => onCellValueChange(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={(e) => handlePressEnter(e)}
          value={inputValue}
        />
        {isCellDropDownVisible && (
          <>
            <span className="py-value-formater">{`${valueFormater}`}</span>
            <CellDropDown selectedValue={selectedValue} changeCellFormatValue={changeCellFormatValue} options={optionsMenu} />
          </>
        )}
      </span>
    </Tooltip>
  );
};

export default InputWithTooltips;
