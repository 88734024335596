import { constructCinderUrl } from './constructCinderUrl';

interface GoToCinderOptions {
  replace?: boolean;
  target?: '_blank';
}

export default function goToCinder(path: string, options: GoToCinderOptions = {}): void {
  const url = constructCinderUrl(path);
  if (options.target) {
    window.open(url, options.target);
    return;
  }
  if (options.replace) {
    window.location.replace(url);
  } else {
    window.location.assign(url);
  }
}
