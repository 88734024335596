import * as React from 'react';
const SvgIcoAddShape = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 6.25a.75.75 0 0 1 .75.75v10a.75.75 0 0 1-1.5 0V7A.75.75 0 0 1 5 6.25M19 6.25a.75.75 0 0 1 .75.75v10a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M18 17.75a.25.25 0 0 0-.25.25v2c0 .138.112.25.25.25h2a.25.25 0 0 0 .25-.25v-2a.25.25 0 0 0-.25-.25zm-1.75.25c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 20 21.75h-2A1.75 1.75 0 0 1 16.25 20zM4 17.75a.25.25 0 0 0-.25.25v2c0 .138.112.25.25.25h2a.25.25 0 0 0 .25-.25v-2a.25.25 0 0 0-.25-.25zM2.25 18c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 6 21.75H4A1.75 1.75 0 0 1 2.25 20zM18 3.75a.25.25 0 0 0-.25.25v2c0 .138.112.25.25.25h2a.25.25 0 0 0 .25-.25V4a.25.25 0 0 0-.25-.25zM16.25 4c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 20 7.75h-2A1.75 1.75 0 0 1 16.25 6zM4 3.75a.25.25 0 0 0-.25.25v2c0 .138.112.25.25.25h2A.25.25 0 0 0 6.25 6V4A.25.25 0 0 0 6 3.75zM2.25 4c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 6 7.75H4A1.75 1.75 0 0 1 2.25 6z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M6.25 19a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75M6.25 5A.75.75 0 0 1 7 4.25h10a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 5M8.25 12a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M12 8.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoAddShape;
