import { forwardRef, useEffect, useMemo, useState } from 'react';
import Froala from 'froala-editor';
import FroalaEditor from 'react-froala-wysiwyg';

import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/link.min.js';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import './styles.less';
import { CONFIG } from '../../config';
import { renderToStaticMarkup } from 'react-dom/server';
import { useGetAllEmailTemplates } from 'hooks/useGetAllEmailTemplatesQuery';
import { usePrefilledSendEmailTemplateQuery } from 'hooks/useProposalShareTemplateQuery';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { ShareTemplate } from 'services/proposal/entities/Proposal';

interface RichTextEditorProps {
  value?: string;
  onChange?: (latest: string) => void;
  onTemplateChange?: (data: ShareTemplate) => void;
  placeholder?: string;
  proposalId: number;
}

export const RichTextEditor = forwardRef<FroalaEditor, RichTextEditorProps>(
  ({ value, onChange, onTemplateChange, placeholder, proposalId }, ref) => {
    const featureFlags = useFeatureFlag(['templates-on-new-share-experience']);
    const isTemplatesEnabled = featureFlags['templates-on-new-share-experience'];
    const [key, setKey] = useState(Math.random());
    const { data } = useGetAllEmailTemplates({ enabled: isTemplatesEnabled ?? false });
    const { mutateAsync } = usePrefilledSendEmailTemplateQuery();

    useEffect(() => {
      // setkey to unmount and remount the editor based on the feature flag. without remount, the Froala register command won't work.
      setKey(Math.random());
    }, [isTemplatesEnabled]);

    const config = useMemo(() => {
      return {
        editorClass: 'py-rich-text-editor',
        toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'formatOL', 'formatUL', 'Email templates'],
        placeholderText: placeholder,
        fontFamilySelection: false,
        shortcutsEnabled: ['bold', 'italic', 'underline', 'undo', 'redo'],
        minHeight: 240,
        pasteDeniedTags: ['img', 'picture', 'video', 'source'],
        htmlAllowedTags: ['a', 'b', 'br', 'div', 'em', 'hr', 'i', 'kbd', 'label', 'li', 'ol', 'p', 'span', 'strike', 'strong', 'u', 'ul'],
        key: CONFIG.FROALA_LICENSE_KEY,
        attribution: false,
        pluginsEnabled: ['link', 'url', 'lists'],
        listAdvancedTypes: false,
      };
    }, [placeholder]);

    if (isTemplatesEnabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Froala.RegisterCommand('Email templates', {
        title: 'Email templates',
        type: 'dropdown',
        options: (data ?? []).reduce((obj, { id, title }) => ({ ...obj, [id]: title }), {}),
        undo: true,
        focus: true,
        refreshAfterCallback: true,
        callback: async function (_, val) {
          const previous = this.html.get();
          this.edit.off();
          try {
            const templateData = await mutateAsync({ proposalId, templateId: val });
            onTemplateChange?.(templateData);
          } catch {
            this.html.set(previous);
          }
          this.edit.on();
        },
        refreshOnShow: function refreshOnShow($btn: any, $dropdown: any) {
          const list = $dropdown.find('ul.fr-dropdown-list');

          if (!Object.keys(data ?? {}).length) {
            list.replaceWIth(renderToStaticMarkup(<ul className="fr-dropdown-list">Loading...</ul>));
            return;
          }

          const containerElement = document.createElement('div');
          const html = this.button.buildList(['Email templates']);
          containerElement.innerHTML = html;
          list.replaceWith(containerElement.querySelector('ul.fr-dropdown-list'));
        },
      });
    }

    return <FroalaEditor key={key} ref={ref} tag="textarea" model={value} onModelChange={onChange} config={config} />;
  }
);

RichTextEditor.displayName = 'RichTextEditor';
