import { Endpoints } from 'utils/endpoints';
import { apiClient } from '../../../api';

class ApiClientRepositoryClass {
  public async getAPIClients() {
    return await apiClient.get(Endpoints.getAPIClients);
  }
}

export const ApiClientRepository = new ApiClientRepositoryClass();
