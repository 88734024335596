import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Add from '../../../../components/icon/icons/IcoAdd';
import { CreateAssetModal } from '../create-asset-modal';

export function CreateAssetButton() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        sx={{ width: '200px', height: '60px', elevation: 3, borderRadius: '1rem' }}
        onClick={handleOpen}
        data-testid="create-asset-button"
        disableRipple
      >
        <Add width="24px" height="24px" />
        {t('library.new_asset_button')}
      </Button>
      <CreateAssetModal open={open} handleClose={handleClose} />
    </>
  );
}
