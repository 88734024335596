import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl } from '@mui/material';
import { Grid } from '@mui/material';

import { SecondaryMenuItemTitle } from '../../component/SecondaryMenuTitles';
import { BlockSides } from '../../../GridDndEditor/Block/Helper/BlockSides';

export type DropDownSectionProps = {
  currentSelection: string[];
  handleSelectionChange: (currentSelection: string[]) => void;
  testId?: string;
};

export const DropDownSection = ({ currentSelection = [BlockSides.ALL_SIDES], handleSelectionChange, testId }: DropDownSectionProps) => {
  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);

  const toggleSide = (side: BlockSides) => {
    if (side === BlockSides.ALL_SIDES) {
      if (selectAll) {
        // Deselect all sides if all sides are already selected
        setSelectAll(false);
        handleSelectionChange([]);
      } else {
        // Select all sides if none or some sides are selected
        setSelectAll(true);
        handleSelectionChange([BlockSides.TOP, BlockSides.BOTTOM, BlockSides.LEFT, BlockSides.RIGHT]);
      }
    } else {
      // Toggle the selected side
      let updatedSelection: string[];
      if (currentSelection.length === 4) {
        updatedSelection = [side];
      } else if (currentSelection.includes(side)) {
        // If the side is already selected, remove it
        updatedSelection = currentSelection.filter((s) => s !== side);
      } else {
        // If the side is not selected, add it
        updatedSelection = [...currentSelection, side].filter((s: string) => s !== BlockSides.ALL_SIDES);
      }

      // Check if all sides are selected after toggling
      const allSidesSelected = updatedSelection.length === 4 || updatedSelection.includes(BlockSides.ALL_SIDES);

      setSelectAll(allSidesSelected);

      handleSelectionChange(updatedSelection);
    }
  };

  const selectSX = {
    width: '134px',
    padding: 0,
  };

  const dropDownSX = {
    padding: '8px 12px',
    height: '56px',
  };

  const containerSX = {
    width: '230px',
    height: '40px',
  };

  return (
    <Grid container data-testid={testId} alignItems="center" justifyContent="space-between" sx={containerSX}>
      <SecondaryMenuItemTitle>{t('document.grid.text.design.border')}</SecondaryMenuItemTitle>
      <Grid item>
        <FormControl size="small">
          <Select multiple sx={selectSX} id="drop-down-select" value={currentSelection} renderValue={(selected) => selected.join(', ')}>
            {Object.keys(BlockSides).map((side: string) => (
              <MenuItem
                key={BlockSides[side]}
                sx={dropDownSX}
                value={BlockSides[side]}
                className={currentSelection.includes(BlockSides[side]) || selectAll ? 'Mui-selected' : ''}
                onClick={() => toggleSide(BlockSides[side])}
              >
                {BlockSides[side]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
