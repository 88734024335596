import { useQuery } from 'react-query';
import { ApiSignatureRepository } from 'services/repositories/implementations/ApiSignatureRepository';
import { QueryKeys } from 'utils/queryKeys';

const apiSignatureRepository = new ApiSignatureRepository();

export function useSignaturesByDocumentId(documentId: string) {
  return useQuery({
    queryKey: QueryKeys.getSignaturesByDocumentId(documentId),
    queryFn: () => apiSignatureRepository.getSectionsSignaturesByDocumentId(documentId),
  });
}
