import * as React from 'react';
const SvgIcoCloseX = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.187 5.813a.75.75 0 0 1 0 1.06L6.873 18.188a.75.75 0 1 1-1.06-1.06L17.127 5.813a.75.75 0 0 1 1.06 0"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M5.813 5.813a.75.75 0 0 1 1.06 0l11.314 11.314a.75.75 0 0 1-1.06 1.06L5.813 6.874a.75.75 0 0 1 0-1.061"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoCloseX;
