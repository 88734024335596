import { styled, Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import { usePromoCodeMutation } from 'hooks/usePromoCodeMutation';
import { useCallback, useEffect, useState } from 'react';

interface OfferCardProps {
  onSuccess: (message: string) => void;
  onFailure: (message: string) => void;
  accountId: number;
  promoCode: string;
  subscriptionUuid?: string;
  title: string;
  children?: React.ReactNode;
  buttonLabel: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  flexDirection: 'column',
  gap: theme.spacing(2),
  flex: '1 0 0',
  borderRadius: '24px',
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const OfferTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.contrastText,
}));

const StyledButton = styled(Button)(() => ({
  borderRadius: '100px',
  boxShadow: 'none',
  fontWeight: 600,
  textTransform: 'initial',
}));

const OfferCard = ({ onSuccess, onFailure, accountId, promoCode, subscriptionUuid = '', title, children, buttonLabel }: OfferCardProps) => {
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const { mutateAsync: applyPromoCode, data: data, isSuccess } = usePromoCodeMutation();

  useEffect(() => {
    if (isSuccess) {
      if (data['state'] !== 'error') {
        onSuccess(data['message']);
      } else {
        onFailure(data['message']);
      }
    }
    setIsApplying(false);
  }, [isSuccess]);

  const handleAccept = useCallback(() => {
    if (!isApplying) {
      setIsApplying(true);
      applyPromoCode({ accountId: accountId, promoCode, subscriptionUuid: subscriptionUuid });
    }
  }, [subscriptionUuid, promoCode, accountId]);

  return (
    <StyledCard>
      <OfferTitle variant="h6" gutterBottom={false}>
        {title}
      </OfferTitle>
      <CardContent sx={{ p: 0 }}>{children}</CardContent>
      <CardActions sx={{ px: 0, pt: 2 }}>
        <StyledButton size="medium" color="secondary" variant="contained" onClick={handleAccept} disabled={isApplying}>
          {buttonLabel}
        </StyledButton>
      </CardActions>
    </StyledCard>
  );
};

export default OfferCard;
