import './styles.less';

export function Field({ label, invalid, children }: { label?: string; invalid?: boolean; children: React.ReactNode }) {
  return (
    <div className={`form__field ${invalid ? 'is-error' : ''}`}>
      {label ? <label className="form__field__label">{label}</label> : null}
      <div className="form__field__input-container">{children}</div>
    </div>
  );
}
