import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormHelperText, Grid, Switch } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { SecondaryMenuItemTitle, TableSettings } from '../../../component';
import SvgIcoTableRowPlugsAfter from 'components/icon/icons/IcoTableRowPlusAfter';
import SvgIcoTableRowPlugsBefore from 'components/icon/icons/IcoTableRowPlusBefore';
import SvgIcoTrash from 'components/icon/icons/IcoTrash';
import IcoHideElement from 'components/icon/icons/IcoHideElement';
import IcoShowElement from 'components/icon/icons/IcoShowElement';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';

import { setActiveTableSettingsPanel } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { useAppDispatch } from '../../../../grid/reduxStore/Store';
import { useTableRowTypeChange } from './useTableRowTypeChange';
import { TableRowAddPositions, useTableRowManipulation } from './useTableRowManipulation';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useBlockContentChangedHandler } from 'components/editor/hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from 'components/editor/GridDndEditor/Block/Table/useTableManipulation';

import { ColumnTypeIdentifier, TotalRowTypesIdentifier } from 'components/editor/grid/reduxStore/table.types';
import { deprecatedTableRowTypes, TableRowTypes, totalRowTypes, isRowBelongsToTotal } from '../../../../../../muiTheme/dataGridUtils';
import { FeatureFlags } from 'utils/featureFlags';
import { SidePanelContextColumnsPropsType } from '../../SidePanelModelsProvider';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { SidePanelProviderContext } from '../../SidePanelModelsProvider';

const RowSettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const menuTitleInitialState = t('document.grid.table.setting.rows');
  const menuTitleTotalRows = t('document.grid.table.setting.total_rows');
  const subtotalRowHiidenTooltip = t('document.grid.table.setting.tooltip_hide_subtotal');

  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const sectionId = selectedSectionId as string;
  const blockId = selectedBlockIdByWrapper as string;
  const isPricingTableAvailable = useFeatureFlag([FeatureFlags.spPricingTable])[FeatureFlags.spPricingTable];
  const selectedModel = toggledTableSettingsPanel?.tableApi.selectedModel || undefined;

  const { row, field, colDef } = selectedModel as GridCellParams;
  const selectedRowType = row?.rowType;
  const isTotalsRow = isRowBelongsToTotal(row);
  const isSelectedRowTypeTotal = selectedRowType === TotalRowTypesIdentifier.TOTAL;

  const [isToggleAvailable, setIsToggleAvailable] = useState(true);
  const [isEditingActive, setIsEditingActive] = useState(true);
  const [showRow, setShowRow] = useState(!!row?.isRowHidden);
  const [isSubtotalRowExists, setIsSubtotalRowExists] = useState<boolean>(false);
  const [isTotalRowExists, setIsTotalRowExists] = useState<boolean>(false);

  const { rowType, handleRowTypeChange } = useTableRowTypeChange();
  const { handleRowAdd, handleRowDuplication, handleDeleteRow, updateRowClass } = useTableRowManipulation();
  const { addCustomFieldToRow, getTableData } = useTableManipulation();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const columnType = selectedModel?.colDef?.columnType;
  const isEditingToggleActive =
    isPricingTableAvailable && rowType === TableRowTypes().BODY && columnType === ColumnTypeIdentifier.MULTIPLIER;
  const label = { inputProps: { 'aria-label': 'Allow recipient to edit' } };

  const handleHideSubtotalRow = async () => {
    await updateRowClass();
    setShowRow(!showRow);
  };

  const rowButtons = [
    {
      icon: <SvgIcoTableRowPlugsAfter />,
      disabled: isSelectedRowTypeTotal,
      arialLabel: 'row-plus-after',
      fn: () => handleRowAdd(TableRowAddPositions.AFTER),
    },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    { icon: <SvgIcoContentCopy />, disabled: isSelectedRowTypeTotal, arialLabel: 'content-copy', fn: handleRowDuplication },
    { icon: <SvgIcoTrash />, disabled: isSelectedRowTypeTotal, arialLabel: 'trash', fn: handleDeleteRow },
  ];

  const subtotalRowButtons = [
    { icon: <SvgIcoTableRowPlugsAfter />, arialLabel: 'row-plus-after', fn: () => handleRowAdd(TableRowAddPositions.AFTER) },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      disabled: true,
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    { icon: <SvgIcoContentCopy />, arialLabel: 'content-copy', disabled: true, fn: handleRowDuplication },
    {
      icon: showRow ? <IcoHideElement data-testid="icon-hide" /> : <IcoShowElement data-testid="icon-show" />,
      arialLabel: 'hide-row',
      fn: () => handleHideSubtotalRow(),
    },
  ];

  const activeRowButtons = selectedRowType === TableRowTypes(isPricingTableAvailable)['SUBTOTAL'] ? subtotalRowButtons : rowButtons;
  const selectOptions = isTotalsRow ? totalRowTypes : deprecatedTableRowTypes;
  const menuTitle = isTotalsRow ? menuTitleTotalRows : menuTitleInitialState;
  const selectDisabled = useMemo(() => {
    const selectedRowType = row?.rowType;
    return (
      selectedRowType === TableRowTypes(isPricingTableAvailable)['SUBTOTAL'] ||
      selectedRowType === TableRowTypes(isPricingTableAvailable)['TOTAL']
    );
  }, [row, isPricingTableAvailable]);

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const checkDisabled = (option: string): boolean => {
    if (isSubtotalRowExists && option === TableRowTypes(isPricingTableAvailable)['SUBTOTAL']) {
      return true;
    }
    return isTotalRowExists && option === TableRowTypes(isPricingTableAvailable)['TOTAL'];
  };

  const handleAllowEditingChange = useCallback(async () => {
    if (!selectedModel) return;
    const { id } = row;
    const currentIsEditingActive = !isEditingActive;
    setIsToggleAvailable(false);
    setIsEditingActive(currentIsEditingActive);

    try {
      const cellConfigObject = { isEditingActive: currentIsEditingActive };
      const updatedTableData = addCustomFieldToRow({ blockId, sectionId, field, id, cellConfigObject });
      if (!updatedTableData) return;
      const result = await blockContentChangedHandler(blockId, sectionId, updatedTableData);
      if (result.status === 'Ok') {
        setIsToggleAvailable(true);
      }
    } catch (error) {
      setIsToggleAvailable(true);
      throw new Error('Error while updating table data');
    }
  }, [blockId, sectionId, addCustomFieldToRow, blockContentChangedHandler, selectedModel]);

  useEffect(() => {
    if (row && colDef) {
      const isCellEditingAllowed = row.cellConfig ? row.cellConfig[colDef.field]?.isEditingActive ?? true : true;
      setIsEditingActive(isCellEditingAllowed);
      setShowRow(row?.isRowHidden);
    }
  }, [selectedModel]);

  useEffect(() => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;
    if (!sectionId) throw new Error('Section id cannot be empty');

    const tableContentData = getTableData(selectedBlockIdByWrapper, sectionId);
    if (!tableContentData) return;

    const tableRows = tableContentData?.rows;
    tableRows?.forEach((row) => {
      if (row.rowType === TableRowTypes(isPricingTableAvailable)['TOTAL']) {
        setIsTotalRowExists(true);
      }
      if (row.rowType === TableRowTypes(isPricingTableAvailable)['SUBTOTAL']) {
        setIsSubtotalRowExists(true);
      }
    });
  }, [toggledTableSettingsPanel, sectionId, selectedBlockIdByWrapper]);

  return (
    <TableSettings
      iconButtons={activeRowButtons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={handleRowTypeChange}
      helpText={t('document.grid.table.setting.update_select_row_type')}
      panelTitle={t('document.grid.table.setting.row_type')}
      selectId="select-row-type"
      selectOptions={selectOptions}
      checkDisabled={checkDisabled}
      selectValue={rowType}
      menuTitle={menuTitle}
      testId="table-row-settings"
      disabled={selectDisabled}
    >
      <>
        {isEditingToggleActive && (
          <Grid container alignItems="center" justifyContent="space-between">
            <SecondaryMenuItemTitle>{t('document.grid.table.setting.allow_edit_multiplier')}</SecondaryMenuItemTitle>
            <Grid item>
              <Switch
                disabled={!isToggleAvailable}
                data-testid="pricing-allow-editing-toggle"
                {...label}
                checked={isEditingActive}
                onChange={handleAllowEditingChange}
              />
            </Grid>
          </Grid>
        )}
        {showRow && <FormHelperText data-testid={'subtotal-row-hidden-help-text'}>{subtotalRowHiidenTooltip}</FormHelperText>}
      </>
    </TableSettings>
  );
};

export default RowSettings;
