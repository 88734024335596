import { DocumentSettingsEvents } from './SocketEvents';
import SocketClient, { TSocketCallback } from './SocketClient';

export default class DocumentSettingsSocketClient {
  private socketClient: SocketClient;
  constructor(socketClient: SocketClient) {
    this.socketClient = socketClient;
  }
  public publishDocumentSettings(data: any, event: DocumentSettingsEvents, callback: TSocketCallback) {
    this.socketClient.publish(event, data, callback);
  }
  public getDocumentSettings(data: any, event: DocumentSettingsEvents, callback: TSocketCallback) {
    this.socketClient.publish(event, data, callback);
  }
}
