import { useMemo, useState } from 'react';
import { Loading } from 'components';
import { useGetLinkedAccounts } from '../hooks/useGetLinkedAccounts';
import { LinkedAccounts } from '../linked-accounts';
import { SearchWorkspaces } from '../search-workspaces';
import { useCurrentUser } from 'providers/UserProvider';
import './styles.less';

export const WorkspacesBody = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const {
    data: { accountId },
  } = useCurrentUser();
  const { data: linkedAccountData, isLoading } = useGetLinkedAccounts(accountId);

  const handleSearchInput = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const workspaces = useMemo(() => {
    if (!linkedAccountData) return [];
    if (searchValue.length > 0) {
      return linkedAccountData.filter(
        ({ accountName, accountSubdomain }) =>
          accountName.toLowerCase().includes(searchValue.toLowerCase()) ||
          accountSubdomain.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else return linkedAccountData;
  }, [linkedAccountData, searchValue]);

  return (
    <div className="workspaces-body__container" data-testid="workspaces">
      <SearchWorkspaces value={searchValue} handleSearchInput={handleSearchInput} />

      {isLoading && (
        <div className="workspaces-body__loader">
          <Loading isLoading />
        </div>
      )}
      <LinkedAccounts accounts={workspaces} />
    </div>
  );
};
