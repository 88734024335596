import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IcoDelete from '../../icon/icons/IcoDelete';

interface SectionToolbarProps {
  addButtonOnClick: () => void;
  deleteButtonOnClick?: () => void;
  enableDelete: boolean;
}
export function SectionToolbar({ addButtonOnClick, deleteButtonOnClick, enableDelete }: SectionToolbarProps) {
  return (
    <Box className="button-wrapper">
      <IconButton size="medium" onClick={addButtonOnClick}>
        <AddIcon fill={'none'} stroke={'none'} color={'primary'} />
      </IconButton>
      <IconButton
        disabled={!enableDelete}
        color={'primary'}
        size="medium"
        onClick={() => deleteButtonOnClick && enableDelete && deleteButtonOnClick()}
      >
        <IcoDelete color={'primary'} fill={'black'} stroke={'none'} />
      </IconButton>
    </Box>
  );
}
