import { Heading, Paragraph } from 'components/typography';

interface HeaderProps {
  title: string;
  description?: string;
}

export function Header({ title, description }: HeaderProps) {
  return (
    <>
      <Heading level={4}>{title}</Heading>
      {description ? (
        <Paragraph size="md" data-testid="share-header-client">
          {description}
        </Paragraph>
      ) : null}
    </>
  );
}
