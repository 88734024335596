import React from 'react';
import Typography from 'antd/lib/typography';
import { Props, ParagraphProps, HeadingProps } from './interfaces';
import './styles.less';

const { Title: BaseTitle, Text: BaseText, Paragraph: BaseParagraph } = Typography;

function EnhancedTypography<T>(WrappedComponent: React.ComponentType<T>, style?: React.CSSProperties, className?: string) {
  return function EnhancedTypographyHoC(props: T & Props) {
    return (
      <WrappedComponent
        style={{ ...style }}
        {...props}
        className={`proposify-typography ${props.font ? props.font : 'default-font'} ${className ? className : ''}`}
      />
    );
  };
}

export const Heading: React.FC<Props & HeadingProps> = (props) => {
  const { viewport, style } = props;
  const className = `${viewport ? viewport : 'desktop'}`;

  return EnhancedTypography(BaseTitle, style, className)(props);
};

export const Text: React.FC<Props> = (props, style) => EnhancedTypography(BaseText, style)(props);

export const Paragraph: React.FC<Props & ParagraphProps> = (props) => {
  const { size = 'md', style, className = '' } = props;
  const customClassName = className ? `size-${size} ${className}` : `size-${size}`;

  return EnhancedTypography(BaseParagraph, style, customClassName)(props);
};
