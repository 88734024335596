import React, { useState } from 'react';
import './styles.less';
import { useTranslation } from 'react-i18next';
import { Switch } from 'components';
import { useMarkAsSentMutation } from 'hooks/useMarkAsSentMutation';
import { useMarkProposalAsDraftMutation } from 'hooks/useMarkProposalAsDraftMutation';

export const MarkSentButton: React.FC<{ proposalId: number }> = ({ proposalId }) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const { mutate: markAsSent } = useMarkAsSentMutation();
  const { mutate: markAsDraft } = useMarkProposalAsDraftMutation();

  const onChangeHandler = (value: boolean) => {
    if (value) {
      markAsSent(proposalId);
    } else {
      markAsDraft(proposalId);
    }
    setChecked(value);
  };

  return (
    <div className="mark-as-sent_container">
      <Switch checked={checked} onChange={onChangeHandler} />
      <div>{t('share.mark_as_sent')}</div>
    </div>
  );
};
