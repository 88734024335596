import { RolesRepository } from '../RolesRepository';
import { apiClient } from '../../../api';
import { Endpoints } from 'utils/endpoints';

type GetRolesApiResponse = {
  id: string;
  name: string;
};

class ApiRolesRepositoryClass implements RolesRepository {
  public async getRoles() {
    return await apiClient.get<GetRolesApiResponse[]>(Endpoints.getRole);
  }
}

export const ApiRolesRepository = new ApiRolesRepositoryClass();
