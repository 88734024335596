import { GridBlockType } from './gridBlockType';

export const gridPixelSize = 1;
export const gridPageMaxWidthInPixels = 794;
export const gridPageMinHeightInPixels = 1123;
export const gridDefaultPixelDensity = 96;
export const gridDefaultWidth = 712;
export const gridDefaultWidthInInches = 8.27;
export const gridDefaultHeight = 24;
export const gridDefaultHeightInInches = 11.69;
export const gridDefaultXAxis = 50;
export const gridDefaultYAxis = 66;
export const gridDefaultGreaterZAxis = 0;
export const gridSnapDistanceInPx = 2;
export const gridDragLinePreviewDistanceInPx = 8;

export const gridDefaultDimensionsOnDrop = {
  [GridBlockType.TEXT]: {
    width: 250,
    height: 250,
  },
  [GridBlockType.TABLE]: {
    width: 666,
    height: 158,
  },
};
export const gridDefaultDimensionsOnTablePricing = {
  width: 721,
  height: 470,
};
