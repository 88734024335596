import { useMutation } from 'react-query';
import { ApiPlanRepository } from 'services/api/plan/ApiPlanRepository';
import { UpdatePlanApiResponse } from 'services/api/plan/interfaces';
import { UpdatePlanParam } from 'services/api/plan/interfaces';

export default function useUpdatePlanMutation() {
  const reactQuery = useMutation({
    mutationFn: updatePlan,
  });

  return { updatePlan: reactQuery.mutateAsync, updatePlanPromise: reactQuery.mutate };
}

const updatePlan = async (data: UpdatePlanParam): Promise<UpdatePlanApiResponse> => {
  const planApi = new ApiPlanRepository();
  const response = await planApi.updatePlan(data);

  return {
    status: response.status,
    message: response.message,
  };
};
