import React, { ReactNode, useEffect, useState } from 'react';
import MenuItem from './menuItem';
import { mobileNavigationItems, navigationItems } from '../../utils/navigationItems';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { usePermissionsContextValue } from '../../providers/PermissionsProvider';
import { getNavigationItemsWithPermissions } from '../../services/permissions/helpers/NavigationItemsPermissions';

interface MenuBodyProps {
  onClick: () => void;
}

const Items = ({ onClick }): ReactNode[] => {
  const { isMobile } = useMediaQuery();
  const [hamburgerMenuItems, setMenuItems] = useState(navigationItems);
  const { userPermissions } = usePermissionsContextValue();

  useEffect(() => {
    const items = isMobile ? mobileNavigationItems : navigationItems;
    const itemsWithPermissions = getNavigationItemsWithPermissions(items, userPermissions);
    setMenuItems(itemsWithPermissions);
  }, [isMobile, userPermissions]);

  return hamburgerMenuItems.map(({ component, title, href, external, hidden }, index: number) => {
    if (hidden) {
      return <></>;
    }

    const Item = () => <MenuItem icon={React.createElement(component)} label={title} />;

    return external ? (
      <a href={href} key={index} target="_blank" rel="noopener noreferrer">
        <Item />
      </a>
    ) : (
      <Link to={href} key={index} onClick={onClick} target={external ? '_blank' : '_self'}>
        <Item />
      </Link>
    );
  });
};

const MenuBody: React.FC<MenuBodyProps> = (props) => {
  return <>{Items(props)}</>;
};

export default MenuBody;
