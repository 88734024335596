import * as React from 'react';
const SvgIcoSettings = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor">
      <path d="M12.8 21.8h-1.6c-.7 0-1.4-.5-1.6-1.2L9 19.2c0-.1 0-.1-.1-.2l-1.5-.9h-.2l-1.5.3c-.7.1-1.5-.2-1.8-.8l-.8-1.4c-.4-.6-.3-1.4.2-2l1-1.2s.1-.1.1-.2v-1.7c0-.1 0-.1-.1-.2l-1-1.2c-.5-.6-.5-1.4-.2-2L4 6.4c.4-.6 1.1-1 1.8-.8l1.5.3h.2L8.9 5l.1-.1.5-1.4c.2-.7.9-1.2 1.6-1.2h1.6c.7 0 1.4.5 1.6 1.2l.7 1.3c0 .1 0 .1.1.2l1.5.9h.2l1.5-.3c.7-.1 1.5.2 1.8.8l.8 1.4c.4.6.3 1.4-.2 2l-1 1.1s-.1.1-.1.2v1.7c0 .1 0 .1.1.2l1 1.1c.5.6.5 1.4.2 2l-.8 1.4c-.4.6-1.1 1-1.8.8l-1.5-.3h-.2l-1.5 1c-.1 0-.1.1-.1.1l-.5 1.4c-.3.8-1 1.3-1.7 1.3m-5.5-5.2c.3 0 .6.1.9.2l1.5.9c.4.2.6.5.8.9L11 20c0 .1.1.2.2.2h1.6c.1 0 .2-.1.2-.2l.5-1.4c.1-.4.4-.7.8-.9l1.5-.9c.4-.2.8-.3 1.2-.2l1.5.3c.1 0 .2 0 .3-.1l.8-1.4c.1-.1 0-.2 0-.3l-1-1.1c-.3-.3-.4-.7-.4-1.1v-1.7c0-.4.1-.8.4-1.1l1-1.1q.15-.15 0-.3l-.8-1.4c-.1-.1-.2-.1-.3-.1l-1.5.1c-.4.1-.8 0-1.2-.2l-1.5-.9c-.3-.2-.6-.5-.8-.9L13 3.9c0-.1-.1-.2-.2-.2h-1.6c-.1 0-.2.1-.2.2l-.5 1.4c-.2.4-.4.7-.8 1l-1.5.8c-.4.2-.8.3-1.2.2l-1.5-.2c-.1 0-.2 0-.3.1l-.7 1.4c-.1.1 0 .2 0 .3l1 1.2c.3.3.4.7.4 1.1v1.7c0 .4-.1.8-.4 1.1l-1 1.1q-.15.15 0 .3l.8 1.4c.1.1.2.1.3.1l1.4-.2c.1 0 .2-.1.3-.1" />
      <path d="M12 15.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5m0-5.5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2" />
    </g>
  </svg>
);
export default SvgIcoSettings;
