import { type Document } from 'services/documents/entities/Document';
import { moveDocumentToTrash, restoreDocumentFromTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { useDocumentsContext } from '../../tabs/DocumentsProvider';

interface UseTrashRestore {
  listType: 'Documents' | 'Trash';
  document: Document;
}

interface HandlerCallbacks {
  onSuccess?: () => void;
  onFailure?: () => void;
}

export function useTrashRestore({ listType, document }: UseTrashRestore) {
  const { setDocumentList } = useDocumentsContext();

  const optimisticallyTrashDocument = () =>
    setDocumentList((previousList) =>
      previousList.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, deleted_at: '3000-01-01' };
        }
        return doc;
      })
    );

  const optimisticallyRestoreDocument = () =>
    setDocumentList((previousList) =>
      previousList.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, deleted_at: undefined };
        }
        return doc;
      })
    );

  const handleProceed = async ({ onSuccess, onFailure }: HandlerCallbacks) => {
    try {
      if (listType === 'Documents') {
        optimisticallyTrashDocument(); //before the API call to avoid delay
        await moveDocumentToTrash(document.id);
      } else {
        optimisticallyRestoreDocument(); //before the API call to avoid delay
        await restoreDocumentFromTrash(document.id);
      }
      onSuccess?.();
    } catch (error) {
      optimisticallyRestoreDocument();
      onFailure?.();
    }
  };

  const handleUndo = async ({ onSuccess, onFailure }: HandlerCallbacks) => {
    try {
      if (listType === 'Documents') {
        optimisticallyRestoreDocument(); //before the API call to avoid delay
        await restoreDocumentFromTrash(document.id);
      } else {
        optimisticallyTrashDocument(); //before the API call to avoid delay
        await moveDocumentToTrash(document.id);
      }
      onSuccess?.();
    } catch (error) {
      optimisticallyTrashDocument();
      onFailure?.();
    }
  };

  return { handleProceed, handleUndo };
}
