import { Asset } from 'services/library/entities/Asset';
import { contentLibraryClient } from 'services/api';

interface AssetListApiResponse {
  assets: Asset[];
}

interface AssetGetApiResponse {
  asset: Asset | undefined;
}

export interface AssetApiUpdateResponse {
  name?: string;
  price?: number | null;
}

interface AssetGetApiResponse {
  asset: Asset | undefined;
}

interface CreateRequest {
  type: 'TEMPLATE';
  content_id: string;
}

export const apiContentLibraryRepository = {
  list: async () => {
    const response = await contentLibraryClient.get<AssetListApiResponse>('/assets');

    const assetList: Asset[] = response.assets;

    return assetList;
  },

  create: async (request: CreateRequest): Promise<Asset> => {
    return await contentLibraryClient.post<Asset>('/assets', request);
  },

  get: async (assetId: string): Promise<Asset | undefined> => {
    const response = await contentLibraryClient.get<AssetGetApiResponse>(`/assets/${assetId}`);
    return response.asset || undefined;
  },

  update: async ({ assetId, ...payload }) => {
    const response = await contentLibraryClient.patch<AssetApiUpdateResponse>(`/assets/${assetId}`, payload);
    return response;
  },
};
