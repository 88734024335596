import { Icon, Button, openNotification } from 'components';
import { useAccountAccessDelete } from '../../../hooks/useAccountAccessQuery';
import { useTranslation } from 'react-i18next';

type Props = {
  uuid: string;
  removeAccessCallback: () => void;
};

export const DeleteAction = ({ uuid, removeAccessCallback }: Props) => {
  const { t } = useTranslation();

  const { deleteAccessAllowance, isLoadingDeleteAccessAllowance } = useAccountAccessDelete();

  const handleDeleteClick = async () => {
    try {
      await deleteAccessAllowance(uuid);
      removeAccessCallback();
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('settings.workspaces.delete_error_msg'),
        description: t('settings.workspaces.delete_error_description'),
        placement: 'top',
      });
    }
  };
  return (
    <Button
      className={'roles__delete-button'}
      variant={'caution'}
      type={'primary'}
      icon={<Icon name={'IcoCloseX'} />}
      data-testid={'delete-user'}
      onClick={handleDeleteClick}
      disabled={isLoadingDeleteAccessAllowance}
    />
  );
};
