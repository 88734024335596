import { DocumentExpireAction } from './document-expire-action';

interface Props {
  proposalId: number;
}

export const Actions = ({ proposalId }: Props) => {
  return (
    <div data-testid="actions">
      <DocumentExpireAction proposalId={proposalId} />
    </div>
  );
};
