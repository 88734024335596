import { UndoRedoCommandInterface } from './UndoRedoCommand';
export class UndoRedoManager {
  private static undoRedoManagerInstance: UndoRedoManager;
  private readonly undoStack: UndoRedoCommandInterface[];
  private readonly stackLimit: number;
  private redoStack: UndoRedoCommandInterface[];
  private isRunningACommand: boolean;
  private constructor() {
    this.undoStack = [];
    this.redoStack = [];
    this.stackLimit = 20;
    this.isRunningACommand = false;
  }

  static getUndoRedoManager(): UndoRedoManager {
    if (!UndoRedoManager.undoRedoManagerInstance) {
      UndoRedoManager.undoRedoManagerInstance = new UndoRedoManager();
    }
    return UndoRedoManager.undoRedoManagerInstance;
  }

  private resetRedoStack(): void {
    this.redoStack = [];
  }

  pushUndoRedoCommands(command: UndoRedoCommandInterface): void {
    if (this.isRunningACommand) return;

    if (this.undoStack.length === this.stackLimit) {
      this.undoStack.shift();
    }
    this.undoStack.push(command);
    this.resetRedoStack();
  }

  isUndoStackEmpty(): boolean {
    return this.undoStack.length === 0;
  }

  isRedoStackEmpty(): boolean {
    return this.redoStack.length === 0;
  }

  async runUndoCommand(): Promise<void> {
    const commandToBeExecuted = this.undoStack.pop();
    if (!commandToBeExecuted) return;

    this.isRunningACommand = true;
    const wasCommandSuccessful = await commandToBeExecuted.undo();
    this.isRunningACommand = false;
    if (!wasCommandSuccessful) {
      await this.runUndoCommand();
      return;
    }

    this.redoStack.push(commandToBeExecuted);
  }

  async runRedoCommand(): Promise<void> {
    const commandToBeExecuted = this.redoStack.pop();
    if (!commandToBeExecuted) return;

    this.isRunningACommand = true;
    const wasCommandSuccessful = await commandToBeExecuted.redo();
    this.isRunningACommand = false;
    if (!wasCommandSuccessful) {
      await this.runRedoCommand();
      return;
    }

    this.undoStack.push(commandToBeExecuted);
  }
}
