import { Endpoints } from 'utils/endpoints';
import { apiClient } from '../../api';

interface JWTResponse {
  token: string;
}

export const getPrismaticJWT = async (): Promise<JWTResponse> => {
  return await apiClient.get(Endpoints.getPrismaticJWT);
};
