import { IconButton } from '@mui/material';

import { useBlockDeletedHandler } from '../../hooks/UseBlockDeletedHandler';
import IcoTrash from '../../../icon/icons/IcoTrash';
import { resetAllPanels } from '../../grid/reduxStore/blockStyleSettingsSlice';

import { useSectionData } from '../../Sections/useSectionContext';
import '../GridDndEditor.less';
import { useBlockStyleSettings } from '../../hooks/useBlockSettings';
import { useAppDispatch } from '../../grid/reduxStore/Store';

export interface GridDeleteComponentProp {
  blockId: string;
}
export default function GridDeleteComponent({ blockId }: GridDeleteComponentProp) {
  const gridBlockDeletedHandler = useBlockDeletedHandler();
  const dispatch = useAppDispatch();
  const { deleteBlock } = useBlockStyleSettings();
  const { blocksContent, sectionId } = useSectionData();

  const handleDeleteOk = () => {
    const blockContent = blocksContent[blockId];
    deleteBlock(blockId);
    gridBlockDeletedHandler(sectionId, blockId, blockContent).then(() => {
      dispatch(resetAllPanels());
    });
  };

  const iconButtonSX = {
    width: '28px',
    height: '28px',
    color: 'white',
  };

  return (
    <>
      <IconButton size={'small'} sx={iconButtonSX} onClick={() => handleDeleteOk()}>
        <IcoTrash className="editor__page__draggable__grid-delete-handle" />
      </IconButton>
    </>
  );
}
