import { Contact, Person, User } from 'services/entities/PersonEntity';

export type GetPersonApiResponse = {
  company_id?: number;
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_primary?: number;
};

const PersonFactory = (
  { id, email, first_name = '', last_name = '', company_id, is_primary }: GetPersonApiResponse,
  type: Person['type']
): Person => {
  return {
    id,
    email,
    firstName: first_name,
    lastName: last_name,
    companyId: company_id,
    type,
    isPrimary: is_primary,
    get fullName() {
      return [this.firstName, this.lastName].join(' ');
    },
    get uid() {
      return [this.id, this.type].join('-');
    },
  };
};

export const ContactFactory = (input: GetPersonApiResponse): Contact => {
  return PersonFactory(input, 'contact') as Contact;
};

export const UserFactory = (input: GetPersonApiResponse): User => {
  return PersonFactory(input, 'user') as User;
};
