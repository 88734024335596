import { Row, Col } from 'antd';
import React, { useRef, useState } from 'react';
import { Heading } from 'components';

import TextBlockButton from './textBlockButton';
import ImageBlockButton from './ImageBlockButton';
import ImageLibrary from '../ImageLibrary/ImageLibrary';
import { EditorRefType } from '../../../hooks/useEditorRef';

import './styles.less';
import TableBlockButton from './TableBlockButton';
import TableLibrary from '../TableLibrary';

interface EditorToolbarProps {
  editorRef: EditorRefType;
}

interface initialStateLibraryProps {
  isActive: 'image' | 'table';
}

const EditorToolbar: React.FC<EditorToolbarProps> = () => {
  const [libraryState, setLibraryState] = useState<initialStateLibraryProps | null>(null);
  const imageBlockRef = useRef<HTMLDivElement | null>(null);

  function handleActiveLibrary(name) {
    setLibraryState({ isActive: name });
  }

  return (
    <>
      <Heading level={5}>Content</Heading>
      <div className="blocks">
        <div data-testid="field_label" className="field_label">
          Blocks
        </div>

        <Row className="blocks__row" gutter={16}>
          <Col span={12} onClick={() => setLibraryState(null)}>
            <TextBlockButton title="Text" />
          </Col>
          <Col data-testid="image-block-button" ref={imageBlockRef} span={12} onClick={() => handleActiveLibrary('image')}>
            <ImageBlockButton title="Image" />
          </Col>
        </Row>
        <Row className="blocks-row" gutter={16}>
          <Col data-testid="table-block-button" ref={imageBlockRef} span={12} onClick={() => handleActiveLibrary('table')}>
            <TableBlockButton title="Table" />
          </Col>
        </Row>
      </div>
      {libraryState && (libraryState.isActive === 'image' ? <ImageLibrary /> : <TableLibrary />)}
    </>
  );
};

export default EditorToolbar;
EditorToolbar.displayName = 'EditorToolbar';
