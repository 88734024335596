const inverseSurface = '#2e3133';

export const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      fontSize: '0.8em',
      backgroundColor: inverseSurface,
      marginTop: '0px',
      '.MuiTooltip-popper[data-popper-placement*="bottom"] & ': {
        marginTop: '0px',
      },
    },
  },
};
