import * as React from 'react';
const SvgIcoApproval = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.5 16v6h16v-6c0-1.1-.9-2-2-2h-12c-1.1 0-2 .9-2 2m14 2h-12v-2h12zm-6-16c-2.76 0-5 2.24-5 5l5 7 5-7c0-2.76-2.24-5-5-5m0 9-3-4c0-1.66 1.34-3 3-3s3 1.34 3 3z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoApproval;
