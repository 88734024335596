import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { apiProspectsRepository } from 'services/repositories/implementations/ApiProspectsRepository';

export function useProspectsQuery({ offset = 0, limit = 50, search = '' }: { offset?: number; limit?: number; search?: string } = {}) {
  return useQuery({
    queryKey: QueryKeys.getProspects(offset, limit, search),
    queryFn: () => apiProspectsRepository.getProspects({ offset, limit, search }),
  });
}
