import React from 'react';
import './styles.less';
import { useTranslation } from 'react-i18next';
import { Switch } from 'components';
import { SwitchProps } from 'components/switch';

export const StatusSwitch: React.FC<SwitchProps> = ({ checked, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="mark-as-sent_container">
      <Switch checked={checked} onChange={onChange} />
      <div>{t('share.mark_as_sent')}</div>
    </div>
  );
};
