import { ReactElement } from 'react';
import './styles.less';

interface Props {
  title: string;
  subTitle: string;
  action: ReactElement;
}

export function ContactCard({ title, subTitle, action }: Props) {
  return (
    <div className="add-contact-container">
      <div className="add-contact__content">
        <div className="add-contact__avatar">{title.charAt(0)}</div>
        <div>
          <h5>{title}</h5>
          <div>{subTitle}</div>
        </div>
      </div>
      <div>{action}</div>
    </div>
  );
}
