import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Stack, Typography } from '@mui/material';

import MultiStepDialog from 'components/MultiStepDialog/MultiStepDialog';
import { MultiDialogStep } from 'components/MultiStepDialog/interface';
import useCancelPlanMutation from 'hooks/useCancelPlanMutation';

import { FormError, CancelModalProps, SubmissionError } from './interface';
import CancelSurvey from './CancelSurvey';
import OfferCard from './offerCard';
import { ApiPlanRepository } from 'services/api/plan/ApiPlanRepository';
import { CancelReasonPayload, CancelReasonEntity } from 'services/api/plan/interfaces';
import { parseUnformattedErrors } from 'services/api/utils';

const CancelModal: FunctionComponent<CancelModalProps> = ({
  isOpen,
  setModalOpen,
  accountId,
  subscription,
  promoCode,
  displayPromoSnackbar,
  promoSnackbarMessage,
}) => {
  const { t } = useTranslation();
  const [cancelReasons, setCancelReasons] = useState<CancelReasonEntity[]>();
  const [surveyFormValues, setSurveyFormValues] = useState<CancelReasonPayload>({
    id: -1,
    reasonDescription: '',
    canContact: true,
    comment: '',
  });
  const [surveyErrors, setSurveyErrors] = useState<FormError[]>([]);

  const schema = {
    id: 'number',
    canContact: 'boolean',
    reasonDescription: 'string',
    comment: 'string',
  };
  const [submissionError, setSubmissionError] = useState<SubmissionError>();

  const { cancelPlan } = useCancelPlanMutation();

  const updateSurveyField = (formKey: string, value: string | number | boolean) => {
    if (typeof value !== schema[formKey]) {
      setSurveyErrors([
        {
          fieldName: formKey,
          message: `Invalid value type for ${formKey}`,
        },
      ]);
      return;
    } else {
      setSurveyErrors([]);
    }

    setSurveyFormValues({
      ...surveyFormValues,
      [formKey]: value,
    });
  };

  const handleSaveSurvey = (curStep: number, goToNextStep: (newStep: number) => void) => {
    goToNextStep(curStep + 1);
  };

  const handleAcceptPromo = (message: string) => {
    promoSnackbarMessage(message);
    displayPromoSnackbar(true);
    setModalOpen(false);
  };

  const handleFailedPromo = (message: string) => {
    const errorStrings = parseUnformattedErrors(message);
    promoSnackbarMessage(errorStrings.join(', '));
    displayPromoSnackbar(true);
  };

  const cancelDate = subscription?.currentPeriodEndsAt?.date
    ? `on ${dayjs(subscription.currentPeriodEndsAt.date).format('MMMM D, YYYY')}`
    : 'at the end of your billing period';

  const handleConfirmCancel = useCallback(async (curStep: number, goToNextStep: (newStep: number) => void) => {
    try {
      const response = await cancelPlan({
        accountId: accountId,
        cancelReasonPayload: surveyFormValues,
      });
      if (response.state !== 'error') {
        goToNextStep(curStep + 1);
      } else {
        setSubmissionError({ status: response.state, error: response.message });
      }
    } catch (error) {
      setSubmissionError({
        status: 'failed',
        error: 'An error occured while cancelling',
      });
    }
  }, []);

  useEffect(() => {
    const apiPlan = new ApiPlanRepository();

    apiPlan.getCancelReasons().then((reasons) => {
      setCancelReasons(reasons);
      if (reasons.length > 0) {
        updateSurveyField('id', Number(reasons.at(-1)?.id || 0));
      }
    });
  }, []);

  const steps: MultiDialogStep[] = useMemo(() => {
    const newSteps: MultiDialogStep[] = [];
    newSteps.push({
      title: 'settings.plan.cancel_flow.initial_title',
      content: (
        <Typography variant="body1">
          <Trans i18nKey="settings.plan.cancel_flow.initial_content" transSupportBasicHtmlNodes />
        </Typography>
      ),
      actions: [
        { isClose: true, label: 'settings.plan.cancel_flow.keep_plan', variant: 'text' },
        { isNextStep: true, label: 'settings.plan.cancel_flow.yes_cancel', variant: 'contained' },
      ],
    });
    newSteps.push({
      title: 'settings.plan.cancel_flow.cancel_survey',
      content: <CancelSurvey updateField={updateSurveyField} formValues={surveyFormValues} errors={surveyErrors} reasons={cancelReasons} />,
      actions: [
        { isClose: true, label: 'settings.plan.cancel_flow.keep_plan', variant: 'text' },
        { onClick: handleSaveSurvey, label: 'settings.plan.cancel_flow.next', variant: 'contained', isDisabled: surveyErrors.length > 0 },
      ],
    });
    if (subscription?.uuid) {
      newSteps.push({
        title: 'settings.plan.cancel_flow.promo_modal_title',
        content: (
          <OfferCard
            accountId={accountId}
            title={t('settings.plan.cancel_flow.promo_title')}
            onSuccess={handleAcceptPromo}
            onFailure={handleFailedPromo}
            buttonLabel={t('settings.plan.cancel_flow.promo_accept_offer')}
            subscriptionUuid={subscription.uuid}
            promoCode={promoCode}
          >
            <Typography variant="body2">{t('settings.plan.cancel_flow.promo_content')}</Typography>
          </OfferCard>
        ),
        actions: [{ isNextStep: true, label: 'settings.plan.cancel_flow.no_thanks', variant: 'contained' }],
      });
    }
    newSteps.push({
      title: 'settings.plan.cancel_flow.confirm_title',
      content: (
        <Stack direction="column" gap={2}>
          {submissionError && (
            <Typography variant="body2" color="error">
              {t(`settings.plan.cancel_flow.errors.${submissionError.status}`)}
            </Typography>
          )}
          <Typography variant="body1">
            {<Trans i18nKey="settings.plan.cancel_flow.confirm_content" transSupportBasicHtmlNodes values={{ cancelDate: cancelDate }} />}
          </Typography>
        </Stack>
      ),
      actions: [
        { isClose: true, label: 'settings.plan.cancel_flow.confirm_cancel', variant: 'text' },
        { onClick: handleConfirmCancel, label: 'settings.plan.cancel_flow.confirm_button', variant: 'contained' },
      ],
    });
    newSteps.push({
      title: 'settings.plan.cancel_flow.cancel_finished_title',
      content: (
        <Typography variant="body1">
          <Trans
            i18nKey="settings.plan.cancel_flow.cancel_finished_content"
            transSupportBasicHtmlNodes
            values={{ cancelDate: cancelDate }}
          />
        </Typography>
      ),
      actions: [{ isClose: true, label: 'settings.plan.cancel_flow.cancel_finished_button', variant: 'contained' }],
    });

    return newSteps;
  }, [subscription]);

  return <MultiStepDialog isOpen={isOpen} setModalOpen={setModalOpen} steps={steps} />;
};

export default CancelModal;
