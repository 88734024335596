import { useTranslation } from 'react-i18next';
import { Configurations } from './blocks';
import { PageHeader } from 'components';

export const OptionalFeaturesPage = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader title={t('settings.optional_features.title')} paragraph={t('settings.optional_features.subtitle')} />
      <Configurations />
    </>
  );
};
