import * as React from 'react';
const SvgIcoContentCopy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5 1h-12c-1.1 0-2 .9-2 2v14h2V3h12zm3 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m0 16h-11V7h11z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoContentCopy;
