import { useNavigate } from 'react-router-dom';

import { URLS } from 'utils/urls';

export function useInsightsPanelHeaderNavigation(documentId: string) {
  const navigate = useNavigate();

  const navigateToEditor = () => {
    navigate(URLS.editDocument(documentId));
  };

  const navigateToPreview = () => {
    navigate(`${URLS.preview}/${documentId}`);
  };

  return {
    navigateToEditor,
    navigateToPreview,
  };
}
