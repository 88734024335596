import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import PaymentMethodForm from './PaymentMethodForm';
import { useContext } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';

interface PaymentMethodModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
}

const PaymentMethodModal = ({ isOpen, setIsOpen }: PaymentMethodModalProps) => {
  const { t } = useTranslation();

  const {
    accountId,
    billingData,
    isBillingFetched,
    locationData,
    isLocationFetched,
    getStatesFromCountry,
    setNotifications,
    refetchBilling,
    isBillingRefetching,
  } = useContext(PlanSummaryContext);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('settings.summary.update_billing_info')}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpen(false);
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
              padding: '5px',
            })}
          >
            <Close sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mb: 9, pb: 0 }}>
        <PaymentMethodForm
          accountId={accountId}
          billingData={billingData}
          isBillingFetched={isBillingFetched}
          locationData={locationData}
          isLocationFetched={isLocationFetched}
          getStatesFromCountry={getStatesFromCountry}
          setNotifications={setNotifications}
          refetchBilling={refetchBilling}
          isBillingRefetching={isBillingRefetching}
          onSuccess={() => {
            setIsOpen(false);
          }}
          onFailure={() => {
            // eslint-disable-next-line no-console
            console.log('anything else added post-failure');
          }}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PaymentMethodModal;
