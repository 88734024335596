import React from 'react';
import { useTranslation } from 'react-i18next';
import { SignatureSocketWriteOperationPayload } from '../../providers/SignaturesProvider';
import { useDrag } from 'react-dnd';
import { getSignatureColor, SignatureBaseButton } from '../../shared/components/SignatureBaseButton';
import { getDefaultSignature, getDefaultSignatureDimensions } from '../../signatures/constants';
import { AssignedSignee } from '../../../../services/repositories/interfaces/SignatureRepository';
import { GridBlockType } from '../../shared/gridBlockType';

interface SignatureButtonProps {
  signee?: AssignedSignee | null;
}

export const DraggableSignatureButton: React.FC<SignatureButtonProps> = ({ signee }) => {
  const { t } = useTranslation();
  const defaultSignature = getDefaultSignature();
  const item: SignatureSocketWriteOperationPayload = {
    ...defaultSignature,
  };
  if (signee) {
    item.assignedSignee = signee;
  }

  const [{ isDragging }, drag] = useDrag({
    type: GridBlockType.SIGNATURE,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item,
  });

  const defaultSignatureDimensions = getDefaultSignatureDimensions();

  return (
    <SignatureBaseButton
      ref={drag}
      style={{
        width: defaultSignatureDimensions.width,
        height: defaultSignatureDimensions.height,
        opacity: isDragging ? 0.5 : 1,
      }}
      draggable={true}
      color={getSignatureColor(signee?.email)}
      title={signee?.email ?? t('unassigned')}
    >
      Signature
    </SignatureBaseButton>
  );
};
