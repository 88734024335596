import { FunctionComponent, useMemo } from 'react';
import { Modal as BaseModal } from 'antd';
import { Props } from './interface';
import './styles.less';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Icons } from '../icon/icons';
import { Heading } from '../typography';
import { Button } from '../../components';

const { IcoCloseX } = Icons;

const Modal: FunctionComponent<Props> = ({
  isVisible,
  handleOk,
  handleCancel,
  affirmText = 'Ok',
  cancelText,
  children,
  title,
  affirmButtonVariant = 'neutral',
  className = '',
  bodyStyle,
  width,
  maskClosable = true,
  closable = true,
  escClosable = true,
}) => {
  const { isTablet, isMobile } = useMediaQuery();

  const style = useMemo((): React.CSSProperties => {
    if (isTablet) {
      return { top: 120 };
    }

    if (isMobile) {
      return { bottom: 0 };
    }

    return { top: 160 };
  }, [isTablet, isMobile]);

  return (
    <BaseModal
      maskClosable={maskClosable}
      className={`proposify-modal ${!cancelText && 'info-modal'} ${className}`}
      title={<Heading level={3}>{title}</Heading>}
      style={style}
      width={width}
      okText={affirmText}
      cancelText={cancelText}
      open={isVisible}
      onOk={handleOk}
      closeIcon={<IcoCloseX />}
      onCancel={handleCancel}
      bodyStyle={bodyStyle}
      footer={[
        <Button key="cancel" type="default" variant="neutral" onClick={handleCancel}>
          {cancelText}
        </Button>,
        <Button key="ok" type="primary" variant={affirmButtonVariant} onClick={handleOk}>
          {affirmText}
        </Button>,
      ]}
      closable={closable}
      keyboard={escClosable}
    >
      {children}
    </BaseModal>
  );
};

export default Modal;
