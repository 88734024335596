import React from 'react';
import { Props } from './interface';
import './styles.less';

const ContentCard: React.FunctionComponent<Props> = ({ content, action }) => {
  return (
    <div className="contentcard-container">
      <div>{content}</div>

      {action && <div className="action">{action}</div>}
    </div>
  );
};

export default ContentCard;
