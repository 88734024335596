import { Heading, openNotification } from 'components';
import EmptyState from 'components/emptyState';
import { PrimaryActionButton } from 'components/emptyState/components/emptyStateActionButtons';
import { Snackbar } from 'components/snackbar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAllDocuments } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import NoTrash from '../../../images/empty-state/No_Trash.svg';
import { Pagination } from '../components/pagination/Pagination';
import { usePagination } from '../components/pagination/usePagination';
import { useDocumentsContext } from '../tabs/DocumentsProvider';
import { useFilteredDocuments } from '../tabs/useFilteredDocuments';
import { EmptyTrashModal } from './EmptyTrashModal';
import { TrashList } from './TrashList';

type Props = {
  setCurrentTab: (key: string) => void;
};

export function TrashTab({ setCurrentTab }: Props) {
  const { t } = useTranslation();

  const { isLoading: isLoadingDocumentList, refreshDocumentList } = useDocumentsContext();
  const { filteredDocumentList, setFilteredDocumentList } = useFilteredDocuments({ filterTrash: true });
  const { currentPage, setCurrentPage, paginatedDocs } = usePagination({ documentList: filteredDocumentList });
  const [modalEmptyTrashVisible, setModalEmptyTrashVisible] = useState(false);

  const handleEmptyTrashAction = async () => {
    try {
      await deleteAllDocuments();
      setFilteredDocumentList([]);
      setCurrentPage(1);
      refreshDocumentList();
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.delete_error_msg'),
        description: t('pipeline.error.delete_error_description'),
        placement: 'top',
      });
    }
  };

  const snackBarActionProp = {
    text: t('pipeline.tabs.trash_document_list.empty_action'),
    onClick: () => setModalEmptyTrashVisible(true),
  };

  const sendUserBackToDocumentsTab = () => {
    setCurrentTab('documents');
  };

  return (
    <div className="documents-list-container">
      <header className="pipeline__list-header">
        <Heading level={1}>{t('pipeline.tabs.trash_document_list.title')}</Heading>
      </header>
      {filteredDocumentList.length > 0 && (
        <Pagination totalDocuments={filteredDocumentList.length} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      )}
      <Snackbar
        message={t('pipeline.tabs.trash_document_list.auto_empty_message')}
        action={filteredDocumentList.length ? snackBarActionProp : undefined}
      />
      <TrashList
        documentList={paginatedDocs}
        isLoading={isLoadingDocumentList}
        listEmpty={
          <EmptyState
            headerText={t('pipeline.empty_state.trash.header')}
            supportingText={t('pipeline.empty_state.trash.supporting_text')}
            primaryGraphic={NoTrash}
            primaryActionButton={
              <PrimaryActionButton buttonText={t('pipeline.empty_state.trash.button_text')} onClick={sendUserBackToDocumentsTab} />
            }
          />
        }
      />
      <EmptyTrashModal onConfirm={handleEmptyTrashAction} visible={modalEmptyTrashVisible} setVisible={setModalEmptyTrashVisible} />
    </div>
  );
}
