import * as React from 'react';
const SvgIcoTrash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 3v1h5v2h-1v13c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V6H4V4h5V3zM7 19h10V6H7zM9 8h2v9H9zm6 0h-2v9h2z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoTrash;
