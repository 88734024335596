import dayjs from 'dayjs';
import { useBillingInfoApiData } from 'hooks/useBillingInfoApiData';
import { useBillingHistoryApiData } from 'hooks/useBillingHistoryApiData';
import { useLocationData } from 'hooks/useLocationData';
import { usePlanApiData } from 'hooks/usePlanApiData';
import { useSubscriptionApiData } from 'hooks/useSubscriptionApiData';
import { createContext, useState, useMemo } from 'react';
import { AccountRepository } from 'services/api/account/AccountRepository';
import { BillingEntity, BillingHistoryEntity, PaymentAccountEntity, SubscriptionEntity } from 'services/api/account/interfaces';
import { ListingsRepository } from 'services/api/listings/ListingsRepository';
import { CountryListing, LocationDataEntity, StateListing } from 'services/api/listings/interfaces';
import { PlanRepository } from 'services/api/plan/PlanRepository';
import { PlanEntity } from 'services/api/plan/interfaces';
import { isLegacyPlan } from '../utils/utils';
import { usePaymentAccountApiData } from 'hooks/usePaymentAccountApiData';

interface PlanSummaryProviderProps {
  accountId: number;
  accountRepository: AccountRepository;
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  setNotifications: (notifications: string[]) => void;
  children: React.ReactNode;
}

type PlanSummaryContextProps = {
  accountId: number;
  accountRepository: AccountRepository;
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  currentPaymentMethod: PaymentMethodData | null;
  setNotifications: (notifications: string[]) => void;
  setCurrentPaymentMethod: (data: PaymentMethodData | null) => void;
  locationData: LocationDataEntity | undefined;
  isLocationFetched: boolean;
  getStatesFromCountry: (countryId: string | number, states: StateListing[], countries?: CountryListing[] | undefined) => StateListing[];
  billingHistoryData: BillingHistoryEntity | undefined;
  billingData: BillingEntity | undefined;
  isBillingFetched: boolean;
  isBillingLoading: boolean;
  isBillingRefetching: boolean;
  refetchBilling: any;
  isSubscriptionLoading: boolean;
  isSubscriptionFetched: boolean;
  subscriptionData: SubscriptionEntity | undefined;
  planData: PlanEntity | undefined;
  isPlanLoading: boolean;
  isPlanFetched: boolean;
  isCardExpired: boolean;
  isLegacyPlan: boolean;
  contactData: PaymentAccountEntity | undefined;
  isContactFetched: boolean;
  isContactLoading: boolean;
  isContactRefetching: boolean;
  refetchContact: any;
};

export const PlanSummaryContext = createContext<PlanSummaryContextProps>({} as PlanSummaryContextProps);

export const PlanSummaryProvider = ({
  accountId,
  accountRepository,
  planRepository,
  listingsRepository,
  setNotifications,
  children,
}: PlanSummaryProviderProps) => {
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethodData | null>(null);

  const {
    planData,
    isLoading: isPlanLoading,
    isFetched: isPlanFetched,
  } = usePlanApiData({ planRepository, accountId, enabled: !!accountId });

  const {
    data: subscriptionData,
    isLoading: isSubscriptionLoading,
    isFetched: isSubscriptionFetched,
  } = useSubscriptionApiData({ accountId, enabled: !!accountId });

  const {
    data: billingData,
    isLoading: isBillingLoading,
    isFetched: isBillingFetched,
    refetch: refetchBilling,
    isRefetching: isBillingRefetching,
  } = useBillingInfoApiData({
    accountRepository,
    accountId,
  });

  const {
    data: contactData,
    isLoading: isContactLoading,
    isFetched: isContactFetched,
    refetch: refetchContact,
    isRefetching: isContactRefetching,
  } = usePaymentAccountApiData({
    accountRepository,
    accountId,
  });

  const { data: billingHistoryData } = useBillingHistoryApiData({
    accountRepository,
    accountId,
  });

  const { isFetched: isLocationFetched, locationData, getStatesFromCountry } = useLocationData({ listingsRepository });

  const isCardExpired: boolean = useMemo(() => {
    if (!billingData?.expiryMonth || !billingData.expiryYear) {
      return false;
    }
    const date = new Date(billingData.expiryYear, billingData.expiryMonth, 1);
    if (dayjs().isAfter(date)) {
      return true;
    }
    return false;
  }, [billingData?.expiryMonth && billingData.expiryYear]);

  const isPlanLegacy: boolean = useMemo(() => {
    if (!planData) {
      return false;
    }
    return isLegacyPlan(planData.type || planData.name || '');
  }, [planData]);

  const contextValue: PlanSummaryContextProps = {
    accountId,
    accountRepository,
    planRepository,
    listingsRepository,
    setNotifications,
    setCurrentPaymentMethod: (data: PaymentMethodData | null) => setCurrentPaymentMethod(data),
    currentPaymentMethod,
    locationData,
    isLocationFetched,
    getStatesFromCountry,
    billingHistoryData,
    billingData,
    isBillingFetched,
    isBillingRefetching,
    refetchBilling,
    isBillingLoading,
    isSubscriptionLoading,
    isSubscriptionFetched,
    subscriptionData,
    planData,
    isPlanLoading,
    isPlanFetched,
    isCardExpired,
    isLegacyPlan: isPlanLegacy,
    isContactLoading,
    isContactFetched,
    isContactRefetching,
    contactData,
    refetchContact,
  };
  return <PlanSummaryContext.Provider value={contextValue}>{children}</PlanSummaryContext.Provider>;
};
