import { useQuery, useMutation } from 'react-query';
import { FeatureFlagRepository } from 'services/featureFlag/repositories/ApiFeatureFlagRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useGetSpecificOptionalFeature = (flag: string) => {
  return useQuery(QueryKeys.featureFlag.getSpecificOptionalFeature(flag), () => FeatureFlagRepository.getSpecificOptionalFeature(flag));
};

type enableOptionalFeatureParams = {
  flag: string;
  enabled: boolean;
};

export const useMutationEnableOptionalFeature = () => {
  return useMutation({
    mutationFn: ({ flag, enabled }: enableOptionalFeatureParams) => FeatureFlagRepository.enableOptionalFeature(flag, enabled),
  });
};
