import React from 'react';
import { Paragraph } from '../../../components';
import './blockSourceButton.less';
import IcoTable from '../../icon/icons/IcoTable';

interface TableBlockButtonProps {
  title: string;
}

const TableBlockButton: React.FunctionComponent<TableBlockButtonProps> = ({ title }) => {
  return (
    <button draggable className="block-source">
      <div className="block-source-static-icon">
        <IcoTable />
      </div>
      <Paragraph size="sm">{title}</Paragraph>
    </button>
  );
};

export default TableBlockButton;
TableBlockButton.displayName = 'TableBlockButton';
