import * as React from 'react';
const SvgIcoDocumentation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.496 19.003V4.997a2 2 0 0 1 2.001-2h13.006a2 2 0 0 1 2 2V15a2 2 0 0 1-2 2H5.497a2 2 0 1 0 0 4.002h13.006a2 2 0 0 0 2-2V14"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path d="M16.502 7.655H7.498Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M16.502 7.655H7.498" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} />
    <path d="M16.502 12H7.498Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M16.502 12H7.498" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} />
  </svg>
);
export default SvgIcoDocumentation;
