import { Helmet } from 'react-helmet';
import './styles.less';

export const GongVideo = () => {
  return (
    <>
      <Helmet>
        <script src="https://play.vidyard.com/embed/v4.js" />
      </Helmet>
      <img
        className="vidyard-player-embed"
        src="https://play.vidyard.com/iL8aWyMa7D8a5a4dVmE5DL.jpg"
        data-uuid="iL8aWyMa7D8a5a4dVmE5DL"
        data-v="4"
        data-type="inline"
        role="video"
      />
    </>
  );
};
