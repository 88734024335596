import { Box } from '@mui/material';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { Insights } from 'pages/pipeline/insights';
import { useEffect } from 'react';
import { FeatureFlags } from 'utils/featureFlags';

export function InsightsAction({
  documentId,
  visible,
  onClose,
  refreshDocumentList,
}: {
  documentId: string;
  visible: boolean;
  onClose: () => void;
  refreshDocumentList: () => void;
}) {
  const featureFlags = useFeatureFlag([FeatureFlags.documentInsights]);
  const isDocumentInsightsEnabled = featureFlags[FeatureFlags.documentInsights];

  useEffect(() => {
    const onInsightsClose = (e: InsightsClosedEvent) => {
      if (e.detail.isExpanded) {
        refreshDocumentList();
      }
      onClose();
    };

    if (visible) {
      document.addEventListener('insights:closed', onInsightsClose);
    } else {
      document.removeEventListener('insights:closed', onInsightsClose);
    }
    return () => document.removeEventListener('insights:closed', onInsightsClose);
  }, [visible]);

  if (!isDocumentInsightsEnabled) {
    return null;
  }

  return (
    <Box sx={{ visibility: visible ? 'visible' : 'hidden' }}>
      <Insights documentId={documentId} />
    </Box>
  );
}
