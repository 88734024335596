import { useAccountUsersQuery } from 'hooks/useAccountUsersQuery';

export function useSender() {
  const { data, isLoading } = useAccountUsersQuery();
  return {
    options: (data ?? []).map((user) => {
      return {
        label: user.fullName,
        value: user.id,
      };
    }),
    isLoading,
  };
}
