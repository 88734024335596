import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { PrimaryActionButton, SecondaryActionButton } from './components/emptyStateActionButtons';

interface Props {
  primaryGraphic?: string;
  headerText: string;
  supportingText?: string;
  primaryActionButton?: ReactNode;
  secondaryActionButton?: ReactNode;
  tooltip?: ReactNode;
}

const EmptyState = ({ primaryGraphic, headerText, supportingText, primaryActionButton, secondaryActionButton, tooltip }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        background: '#fff',
        borderRadius: '28px',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
        {primaryGraphic && (
          <img src={primaryGraphic} data-testid="empty-state-graphic" width={'180px'} height={'118px'} alt="Empty State Graphic" />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <Typography variant="h6" color="#00283a" data-testid="empty-state-header">
              {headerText}
            </Typography>
            {tooltip}
          </Box>
          {supportingText && (
            <Typography variant="body1" data-testid="empty-state-text" sx={{ color: 'primary.light' }}>
              {supportingText}
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" gap="16px">
        {secondaryActionButton && <Box>{secondaryActionButton}</Box>}
        {primaryActionButton && <Box>{primaryActionButton}</Box>}
      </Box>
    </Box>
  );
};

EmptyState.PrimaryActionButton = PrimaryActionButton;
EmptyState.SecondaryActionButton = SecondaryActionButton;

export default EmptyState;
