import React, { useState } from 'react';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import { TabbedNavigationProps, SubmenuProps, MenuItemProps } from './interface';
import { Link, useLocation } from 'react-router-dom';

import './styles.less';

import SectionTitle from '../sectiontitle';
import IcoArrowDown from '../../components/icon/icons/IcoArrowDown';

const TabbedNavigation: React.FC<TabbedNavigationProps> = ({ items, mode, icon }) => {
  const getSubmenu = ({ title, items, icon }: SubmenuProps) => {
    const SubmenuTitle = () => (icon != null ? <SectionTitle icon={icon}>{title}</SectionTitle> : <>{title}</>);

    return (
      <SubMenu key={title} title={<SubmenuTitle />} icon={<IcoArrowDown />} popupOffset={[0, 0]}>
        {getMenuItems(items)}
      </SubMenu>
    );
  };

  const getMenuOnlyChild = ({ title, href = '#', external = false, target = '_blank', icon = null }: MenuItemProps) => (
    <Menu.Item key={title} icon={icon} className={icon ? 'has-icon' : ''}>
      {external ? (
        <a className="tabbed-navigation-link" href={href} target={target} rel="noopener noreferrer">
          {title}
        </a>
      ) : (
        <Link className="tabbed-navigation-link" to={href}>
          {title}
        </Link>
      )}
    </Menu.Item>
  );

  const getMenuItems = (items) => items.map((item) => (item.items ? getSubmenu(item) : getMenuOnlyChild(item)));

  const hasIconClass = icon != null ? 'has-title-icon' : '';

  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (openedKeys) => {
    setOpenKeys(openedKeys);
  };

  const location = useLocation();
  const selectedItem = items.find((item) => item['href'] === location.pathname);
  const selectedKey = selectedItem ? selectedItem.title : '';

  const closeMenu = () => {
    setOpenKeys([]);
  };

  return (
    <div className={`${hasIconClass} tabbed-navigation-wrapper`}>
      <Menu
        mode={mode}
        triggerSubMenuAction={'click'}
        openKeys={openKeys}
        selectedKeys={[selectedKey]}
        onOpenChange={onOpenChange}
        onSelect={closeMenu}
      >
        {getMenuItems(items)}
      </Menu>
    </div>
  );
};

export default TabbedNavigation;
