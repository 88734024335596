export const getHalifaxDateTime = () => new Date().toLocaleString('en-US', { timeZone: 'America/Halifax' });
export const getDateBasedOnTimezone = (date: string, timeZone: string) =>
  new Date(date).toLocaleString('en-US', { timeZone: timeZone, timeZoneName: 'short' });
export const formatCreatedAtDate = (date: string) => {
  const documentCreatedAtDate = new Date(date);
  return documentCreatedAtDate.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
};
export const convertCreatedAtDateToUserTime = (date: string, userTimezone: string) => {
  const createdAtDate = new Date(date);

  const formattedLocalTime = createdAtDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', timeZone: userTimezone });
  return formattedLocalTime;
};
export const isToday = (date: string): boolean => {
  const todayDate = new Date(Date.now());
  const documentCreatedAtDate = new Date(date);
  todayDate.setHours(0, 0, 0, 0);
  documentCreatedAtDate.setHours(0, 0, 0, 0);

  return todayDate.getTime() === documentCreatedAtDate.getTime();
};
