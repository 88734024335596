import React from 'react';
import { LabelProps } from '../interfaces';
import './styles.less';

export const Label: React.FC<LabelProps> = ({ id, label, isRequired }) => {
  return (
    <label className="label" htmlFor={id}>
      {isRequired && <span className="label__required">*</span>} {label}
    </label>
  );
};
