import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { MultiStepDialogProps } from './interface';

const MultiStepDialog = ({
  initialStep = 0,
  isOpen,
  setModalOpen,
  steps,
  showCloseIcon = true,
  keepPositionOnClose = false,
  onBeforeNewStep,
  onAfterNewStep,
  onBeforeClose,
  onAfterClose,
}: MultiStepDialogProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(initialStep);

  const ModalButton = styled(Button)(() => ({
    borderRadius: '100px',
    boxShadow: 'none',
    fontWeight: 600,
    textTransform: 'initial',
  }));

  const handleNewStep = (newStep: number) => {
    let resolve = true;
    const oldStep = step;

    if (onBeforeNewStep) {
      resolve = onBeforeNewStep(oldStep, newStep);
    }
    if (resolve) {
      setStep(newStep);
      if (onAfterNewStep) {
        onAfterNewStep(oldStep, newStep);
      }
    }
  };

  const handleClose = () => {
    let resolve = true;
    const oldStep = step;
    const newStep = !keepPositionOnClose ? 0 : step;

    if (onBeforeClose) {
      resolve = onBeforeClose(step, newStep);
    }
    if (resolve) {
      setModalOpen(false);

      setTimeout(() => {
        setStep(newStep);
      }, 1000); // trigger after the closing is done, to prevent user seeing it transition

      if (onAfterClose) {
        onAfterClose(oldStep, newStep);
      }
    }
  };

  return (
    <Dialog open={isOpen} PaperProps={{ sx: { borderRadius: '24px' } }}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px 12px 24px',
        }}
      >
        {steps[step] ? t(steps[step].title) : null}
        {showCloseIcon && (
          <IconButton sx={{ ml: 'auto' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={steps[step].removePadding ? { p: 0 } : { pb: 0 }}>{steps[step]?.content || null}</DialogContent>
      {steps[step]?.actions && (
        <DialogActions sx={{ p: 3 }}>
          {steps[step].actions.map((stepAction, key) => (
            <ModalButton
              size="medium"
              disabled={stepAction.isDisabled}
              variant={stepAction.variant || 'contained'}
              key={`multi-step-dialog-${step}-action-${key}`}
              onClick={() => {
                if (stepAction.isNextStep) {
                  handleNewStep(step + 1);
                } else if (stepAction.isClose) {
                  handleClose();
                } else if (stepAction.onClick) {
                  stepAction.onClick(step, handleNewStep);
                }
              }}
            >
              {t(stepAction.label)}
            </ModalButton>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default MultiStepDialog;
