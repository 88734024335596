import * as React from 'react';
const SvgIcoClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.196 8.137a.47.47 0 0 1 0 .662l-7.063 7.064a.468.468 0 0 1-.663-.662l7.064-7.064a.47.47 0 0 1 .662 0"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M8.47 8.137a.47.47 0 0 1 .663 0l7.063 7.064a.468.468 0 0 1-.662.662L8.47 8.799a.47.47 0 0 1 0-.662"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M16.196 8.137a.47.47 0 0 1 0 .662l-7.063 7.064a.468.468 0 0 1-.663-.662l7.064-7.064a.47.47 0 0 1 .662 0"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      clipRule="evenodd"
    />
    <path
      d="M8.47 8.137a.47.47 0 0 1 .663 0l7.063 7.064a.468.468 0 0 1-.662.662L8.47 8.799a.47.47 0 0 1 0-.662"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoClose;
