/* eslint-disable react/prop-types */
// TODO: enable the rule and fix the issue

import Select from 'react-select';
import { theme } from '../../utils';

const getValue = (value: boolean) => {
  return {
    label: value ? 'true' : 'false',
    value: value ? 1 : 0,
  };
};

export const BooleanPicker = ({ keyName, onChange, value }) => {
  const options = [
    {
      label: 'true',
      value: 1,
    },
    {
      label: 'false',
      value: 0,
    },
  ];

  const handleInputChange = (value) => {
    const event = {
      target: {
        name: keyName,
        value: Boolean(value.value),
      },
    };

    onChange(event);
  };

  return <Select value={getValue(value)} options={options} theme={theme} onChange={handleInputChange} />;
};
