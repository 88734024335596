import React, { KeyboardEvent } from 'react';
import { InputNumber } from 'antd';
import './styles.less';

interface Props {
  onChange: (value: number | null) => void;
  value: number;
  onBlur: React.FocusEventHandler;
  onError?: (value: string | null) => void;
  errorClass?: string | null;
  parseValue?: (value: string | undefined) => number;
  formatter?: (value: number | undefined) => string;
}

const PriceInput = ({ onChange, errorClass, value, onBlur, formatter, parseValue }: Props) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const isNumericInput = /^-?\d*\.?\d*$/.test(event.key);
    const isAllowedSpecialKey = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Enter'].includes(event.key);

    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }

    if (
      !isNumericInput &&
      !isAllowedSpecialKey &&
      !(event.key === '.' && !value.includes('.')) &&
      !(event.key === '-' && !value.startsWith('-'))
    ) {
      event.preventDefault();
    }
  };

  return (
    <InputNumber
      data-testid="document-price"
      placeholder="0.00"
      defaultValue={0}
      className={`price-input ${errorClass}`}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={21}
      formatter={formatter}
      parser={parseValue}
      value={value}
    />
  );
};

PriceInput.displayName = 'PriceInput';

export default PriceInput;
