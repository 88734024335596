import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from 'components/sidebar';
import { Page } from 'components/page';

export const EditorLayoutRoute: React.FC = () => {
  return (
    <Page sidenav={<Sidebar mode="fixed" />} header={null}>
      <Outlet />
    </Page>
  );
};
