import { Button, Heading, Icon, openNotification } from 'components';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { usePropsalPreviewUrlQuery } from 'hooks/useProposalPreviewUrlQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Proposal } from 'services/proposal/entities/Proposal';
import { isIframe } from 'utils/isIframe';
import { CopyLinkInput } from './copy-link-input';
import { MarkSentButton } from './mark-sent-button';
import './styles.less';

type Props = {
  proposalData: Proposal;
};

export const ProposalCopyLink = ({ proposalData }: Props) => {
  const { t } = useTranslation();
  const [, setCopy] = useCopyToClipboard();
  const { data, isLoading, isError } = usePropsalPreviewUrlQuery(proposalData.id);
  const [canShowMarkAsSent, setCanShowMarkAsSent] = useState(false);

  const handleClickToCopy = async () => {
    if (!data) return;

    const success = await setCopy(data.baseUrl);
    if (success) {
      openNotification({
        title: t('share.copy_link.notification.success.title'),
        description: '',
        type: 'success',
        placement: 'bottom',
      });
    }
    setCanShowMarkAsSent(true);
  };

  return (
    <section className="copy-link">
      <div className="copy-link__info">
        <Heading level={5}>{t('share.mark_as_sent_description')}</Heading>
      </div>
      <div className="copy-link__action">
        {isIframe() ? (
          <>
            <CopyLinkInput value={data?.baseUrl} />
            {!proposalData.isSent && <MarkSentButton proposalId={proposalData.id} />}
          </>
        ) : (
          <>
            {canShowMarkAsSent && !proposalData.isSent && <MarkSentButton proposalId={proposalData.id} />}
            <Button
              type="default"
              variant="positive"
              className="copy-link__button"
              icon={<Icon name="IcoLink" title={t('share.copy_link.button')} />}
              onClick={handleClickToCopy}
              disabled={isLoading || isError}
              data-testid="copy-link-button"
            >
              {t(isLoading ? 'loading' : 'share.copy_link.button')}
            </Button>
          </>
        )}
      </div>
    </section>
  );
};
