import React from 'react';
import { ButtonPageActionProps } from './interface';
import { Button } from '..';
import './styles.less';

const ButtonPageAction: React.FC<ButtonPageActionProps> = ({ children, icon, type, onClick, variant = 'neutral', disabled = false }) => {
  return (
    <Button className="page-action" variant={variant} type={type} icon={icon} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default ButtonPageAction;
