import { SsoConfigRepository } from '../SsoConfigRepository';
import { Endpoints } from 'utils/endpoints';
import { apiClient } from '../../../api';

interface SsoSchemaApiResponse {
  properties: Record<string, unknown>;
  required: [];
}

class ApiSsoConfigRepositoryClass implements SsoConfigRepository {
  public async getSsoConfig() {
    return await apiClient.get(Endpoints.ssoConfig);
  }

  public async saveSsoConfig(payload) {
    return await apiClient.post(Endpoints.ssoConfig, payload);
  }

  public async updateSsoConfig(providerId, payload) {
    return await apiClient.put(Endpoints.updateSsoConfig(providerId), payload);
  }

  public async discoverSso(payload) {
    return await apiClient.post(Endpoints.discoverSso, payload);
  }

  public async getSsoSchema() {
    return await apiClient.get<SsoSchemaApiResponse>(Endpoints.getSsoSchema);
  }

  public async getSsoRefSchema(reference: string | undefined) {
    if (!reference) return {};
    return await apiClient.get<SsoSchemaApiResponse>(Endpoints.getSsoRefSchema(reference));
  }

  public async getRoles() {
    return await apiClient.get(Endpoints.getRole);
  }
}

export const ApiSsoConfigRepository = new ApiSsoConfigRepositoryClass();
