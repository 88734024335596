import { PlanTier } from 'services/api/plan/interfaces';

// Entities
export type BillingEntity = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  cardType: string;
  lastFour: number;
  expiryMonth: number;
  expiryYear: number;
  firstName: string;
  lastName: string;
};

export type PaymentAccountEntity = {
  email: string;
};

export type BillingHistoryEntity = {
  state: boolean;
  invoices: BillingHistoryInvoiceEntity[];
  cursor: string;
  total: number;
};

export type BillingHistoryInvoiceEntity = {
  date: string;
  plan: string;
  state: string;
  quantity: number;
  amount: string;
  total: number;
  credit: boolean;
  currency: string;
  pdf: string;
  dateObj: {
    date: string;
    timeZoneType: number;
    timeZone: string;
  };
};

export type BillingOptionPreviewChangesEntity = {
  monthlySeatPrice: number;
  newSeats: boolean;
  isCustom: boolean;
  freeSeats: number;
  paidSeats: number;
  totalSeats: number;
  monthlyTotal: number;
  termNumber: number;
  recurringTotal: number;
  chargeTotal: number;
  creditUsed: number;
  billedNow: number;
  renewalDate: string;
  hasCharge: boolean;
  showCCLink: boolean;
  showManageSeats: boolean;
  localTaxesIncluded: boolean;
  percentageOff: number;
  setupFee: number;
  discountTotal: number;
  manuallyCalculated: boolean;
  couponApplied: boolean;
  annualDiscount: number | null;
  proration: number;
};

export type BillingOptionEntity = {
  planId: number;
  planName: string;
  name: string;
  price: number;
  monthlyValue: number;
  priceBySeat: boolean;
  changePreview: BillingOptionPreviewChangesEntity | null;
  userLimit: number;
  isCurrentPlan: boolean;
};

export type BillingOptionsEntity = BillingOptionEntity[];

export type PausedAccountEntity = {
  paused: boolean;
};

export type SubscriptionEntity = {
  uuid: string;
  state: string;
  currentPeriodEndsAt: {
    date: string;
    timeZoneType: number;
    timeZone: string;
  } | null;
  netTerms: number;
  currentTermEndsAt: {
    date: string;
    timeZoneType: number;
    timeZone: string;
  } | null;
  autoRenew: boolean;
};

// API Responses
export type AccountApiResponse = {
  address_1: string;
  address_2: string;
  city: string;
  country_id: number | null;
  country_name: string | null;
  created_datetime: string;
  custom_domain: string;
  default_currency_id: number;
  name: string;
  paused: number;
  state_id: number | null;
  state_name: string | null;
  subdomain: string;
  website_url: string;
  zip_code: string;
};

export type BillingHistoryApiResponse = {
  invoices: [
    {
      date: string;
      plan: string;
      state: string;
      quantity: number;
      amount: string;
      total: number;
      credit: boolean;
      currency: string;
      pdf: string;
      date_obj: {
        date: string;
        timeZoneType: number;
        timeZone: string;
      };
    }
  ];
  nextPage: string;
  total: number;
};

export type BillingInfoApiResponse = {
  billing_address_1: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_zip: string;
  billing_country: string;
  cc_type: string;
  last_four: string;
  cc_month: string;
  cc_year: string;
  cc_first_name: string;
  cc_last_name: string;
};

export type UpdateBillingInfoApiResponse = {
  status: string;
  message: string;
};

type PlanChangeDataApiResponse = {
  monthly_seat_price: number;
  new_seats: boolean;
  is_custom: number;
  free_seats: number;
  paid_seats: number;
  total_seats: number;
  monthly_total: number;
  term_number: number;
  term_text: string;
  recurring_total: number;
  charge_total: number;
  credit_used: number;
  billed_now: number;
  renewal_date: string;
  has_charge: boolean;
  show_cc_link: boolean;
  show_manage_seats: boolean;
  local_taxes_included: boolean;
  percentage_off: number;
  setup_fee: number;
  discount: number;
  manually_calculated: boolean;
  coupon_applied: boolean;
  annual_discount: number | null | undefined;
  proration: number;
};

export type BillingOptionApiResponse = {
  id: number;
  name: string;
  code: string;
  price: number;
  plan_type_id: number | null;
  per_user: boolean;
  currency_id: number;
  user_limit: number;
  file_limit: boolean;
  team_limit: number;
  proposal_limit: number;
  has_reports: boolean;
  has_subdomain: boolean;
  has_custom_domain: boolean;
  has_custom_email: boolean;
  has_tracking: boolean;
  has_comments: boolean;
  has_signatures: boolean;
  has_unassigned_signees: boolean;
  has_integrations: boolean;
  has_salesforce: boolean;
  has_salesforce_premium: boolean;
  has_support: number;
  has_reminders: boolean;
  has_pdfs: boolean;
  has_branding: boolean;
  has_streams: boolean;
  has_starring: boolean;
  has_payments: boolean;
  has_permissions: boolean;
  has_training: boolean;
  has_document_assistance: boolean;
  has_formfields: boolean;
  pausable: boolean;
  has_custom_fields: boolean;
  has_priority_support: boolean;
  has_basic_onboarding_services: boolean;
  has_account_manager: boolean;
  has_basic_reporting: boolean;
  archive: boolean;
  billing_interval_id: number;
  custom: boolean;
  billing_interval_length: number;
  billing_interval_name: string;
  formatted_price: string;
  formatted_monthly_price: string;
  currency: string;
  currency_code: string;
  bi_label_name: string;
  bi_label_frequency: string;
  bi_label_per: string;
  bi_label_abbr: string;
  is_current_plan: boolean;
  selected: boolean;
  monthly_value: number;
  best_value: boolean;
  billing_label: string;
  savings: string;
  preview_change_data: PlanChangeDataApiResponse | null;
};

export type BillingOptionsApiResponse = BillingOptionApiResponse[];

export type CouponValidateApiResponse = {
  state: string;
  code: string;
};

export type SubscriptionApiResponse = {
  uuid: string;
  state: string;
  current_period_ends_at: {
    date: string;
    timezone: string;
    timezone_type: number;
  } | null;
  net_terms: number | null;
  current_term_ends_at: {
    date: string;
    timezone: string;
    timezone_type: number;
  } | null;
  auto_renew: boolean | null;
};

export type PaymentAccountApiResponse = {
  state: boolean;
  email: string;
};

export type UpdatePaymentAccountApiResponse = {
  state: boolean;
  message: string;
};

// Payloads and Parameters
export enum CCType {
  VISA = 'Visa',
  MASTERCARD = 'Mastercard',
  AMERICAN_EXPRESS = 'American Express',
  UNKNOWN = '',
}

export type CardUpdateProps = {
  cardholderName: string;
  type: CCType | string;
  expiryMonth: number;
  expiryYear: number;
  lastFour?: number;
  securityCode?: number;
  ccNumber?: number | string;
};

export type BillingAddress = {
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export type UpdateBillingPayload = {
  cardInfo: CardUpdateProps;
  billingAddress: BillingAddress;
};

export type UpdateBillingParam = {
  accountId: number;
  payload: UpdateBillingPayload;
};

export type GetBillingOptionsParam = {
  accountId: number;
  planName: PlanTier;
  promoCode: string;
};
