import { IconButton } from '@mui/material';
import { useEffect } from 'react';
import SvgIcoUndo from '../../icon/icons/IcoUndo';
import { UndoRedoManager } from '../UndoRedo/UndoRedoManager';

export function EditorUndoRedoToolbar() {
  const undoRedoManager = UndoRedoManager.getUndoRedoManager();

  const iconButtonSX = {
    borderRadius: '4px',
    width: '38px',
    height: '40px',
    margin: '4px 2px 4px 2px',
    padding: '0',
    transition: 'none',
    disableRipple: true,

    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#ebebeb',
      transition: 'none',
    },
  };

  const secondButtonSX = {
    ...iconButtonSX,
  };

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      const isCtrlOrMetaKeyPressed = event.ctrlKey || event.metaKey;

      if (!undoRedoManager.isRedoStackEmpty() && isCtrlOrMetaKeyPressed && event.shiftKey && event.key === 'z') {
        event.preventDefault();
        await undoRedoManager.runRedoCommand();
      } else if (!undoRedoManager.isUndoStackEmpty() && isCtrlOrMetaKeyPressed && !event.shiftKey && event.key === 'z') {
        event.preventDefault();
        await undoRedoManager.runUndoCommand();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="editor-undo-redo">
      <IconButton
        data-testid="editor-undo-button"
        size="small"
        sx={iconButtonSX}
        disabled={undoRedoManager.isUndoStackEmpty()}
        onClick={() => undoRedoManager.runUndoCommand()}
      >
        <SvgIcoUndo className="svg-icon" />
      </IconButton>
      <IconButton
        data-testid="editor-redo-button"
        size="small"
        sx={secondButtonSX}
        disabled={undoRedoManager.isRedoStackEmpty()}
        onClick={() => undoRedoManager.runRedoCommand()}
      >
        <SvgIcoUndo className="svg-icon flip-effect" />
      </IconButton>
    </div>
  );
}
