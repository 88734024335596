import { Endpoints } from 'utils/endpoints';
import { PlanFeatureRepository } from '../interfaces/PlanFeatureRepository';
import { MissingFeatureEntity } from 'services/entities/MissingFeatureEntity';
import { PlanFeatureApiResponse } from 'services/objects/http/PlanFeatureApiResponse';
import { apiClient } from 'services/api';

export class ApiPlanFeatureRepository implements PlanFeatureRepository {
  public async getDowngradeFeaturesList(planId: number): Promise<MissingFeatureEntity[]> {
    const { features } = await apiClient.get<PlanFeatureApiResponse>(Endpoints.getPlanFeatures(planId));
    let downgradeFeatures: MissingFeatureEntity[] = [];

    features.forEach((feature) => {
      const { name, active, on_downgrade_description } = feature;

      if (on_downgrade_description) {
        downgradeFeatures = [
          ...downgradeFeatures,
          {
            name,
            active,
            onDowngradeDescription: on_downgrade_description,
          },
        ];
      }
    });

    return downgradeFeatures;
  }
}
