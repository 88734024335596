import { Switch as BaseSwitch } from 'antd';

interface Props {
  checked: boolean;
  onChange: (event: any) => void;
  ariaLabel: string;
}

export const Switch = ({ checked, onChange, ariaLabel }: Props) => {
  const onInputChange = (checkedValue: boolean) => {
    onChange({
      target: {
        ariaLabel,
        value: checkedValue,
      },
    });
  };

  return <BaseSwitch checked={checked} onChange={onInputChange} />;
};
