import { useReducer, useMemo } from 'react';

export type FeaturesState = {
  isVisible: boolean;
  title: string;
  features: object;
  planType: string;
  onCancel: () => void;
  onConfirm: (plan?: string) => void;
  confirmButtonLabel: string;
};

const initialState: FeaturesState = {
  isVisible: false,
  title: '',
  features: {},
  planType: '',
  onCancel: () => {
    // do nothing
  },
  onConfirm: () => {
    // do nothing
  },
  confirmButtonLabel: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL_FEATURES_MODAL_CONTENT': {
      return {
        ...state,
        ...action.value,
      };
    }
    case 'SET_VISIBLE': {
      return {
        ...state,
        isVisible: action.value,
      };
    }
  }
};

export const useSeeAllFeaturesReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const triggerDispatch = ({
    title,
    plan,
    features,
    onConfirm,
    confirmButtonLabel,
  }: {
    title: string;
    plan: string;
    features: object;
    onConfirm: (string) => void;
    confirmButtonLabel: string;
  }) => {
    dispatch({
      type: 'SET_ALL_FEATURES_MODAL_CONTENT',
      value: {
        isVisible: true,
        title: `${title} plan features`,
        features: features,
        planType: plan,
        onCancel: () => {
          dispatch({
            type: 'SET_VISIBLE',
            value: false,
          });
        },
        onConfirm: () => onConfirm(plan),
        confirmButtonLabel: confirmButtonLabel,
      },
    });
  };

  return useMemo(
    () => ({
      state,
      triggerDispatch,
    }),
    [state, dispatch]
  );
};
