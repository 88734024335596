import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { RowTypesIdentifier, TableRowType, TableType, TotalRowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { isRowBelongsToTotal, TableRowTypes } from '../../../../../../muiTheme/dataGridUtils';

import { resetAllPanels } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { RootState, useAppDispatch } from '../../../../grid/reduxStore/Store';
import { useBlockDeletedHandler } from '../../../../hooks/UseBlockDeletedHandler';
import { columns } from '../../../../GridDndEditor/Block/Table';

import { selectContentTableRows, selectContentTableColumns, selectBlockContent } from '../../../../grid/reduxStore/editorSlice';
import { useTableManipulation } from '../../../../GridDndEditor/Block/Table/useTableManipulation';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import { defaultRowMeta } from '../../../../GridDndEditor/Block/Table/variables';
import { useBlockStyleSettings } from '../../../../hooks/useBlockSettings';
import { setActiveTableSettingsPanel, TableSettingsTypes } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { cellMenu } from '../../../../GridDndEditor/Block/Table/defaultData';

export enum TableRowAddPositions {
  BEFORE = 0,
  AFTER = +1,
}

export const useTableRowManipulation = () => {
  const dispatch = useAppDispatch();
  const [, setIsModalVisible] = useState(false);
  const { deleteBlock } = useBlockStyleSettings();
  const gridBlockDeletedHandler = useBlockDeletedHandler();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { getTableData } = useTableManipulation();
  const { selectedBlockIdByWrapper, selectedSectionId: sectionId } = useContext(SelectionContext);

  const allTextTableRows = useSelector((state: RootState) =>
    selectContentTableRows(state, sectionId || '', selectedBlockIdByWrapper as string)
  );
  const allTextTableColumns = useSelector(
    (state: RootState) => sectionId && selectContentTableColumns(state, sectionId, selectedBlockIdByWrapper as string)
  );
  const selectTableBlockContent = useSelector(
    (state: RootState) => sectionId && selectBlockContent(state, sectionId, selectedBlockIdByWrapper as string)
  );
  const allRowIds = allTextTableRows?.map((r) => r.id);

  const updatedTableState = async (blockId: string, updateRows: TableRowType[], updatedColumns: typeof columns) => {
    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableData = getTableData(blockId, sectionId) as TableType;
    const newTableData: TableType = {
      ...tableData,
      columns: updatedColumns,
      rows: updateRows,
    };

    await blockContentChangedHandler(blockId, sectionId, newTableData);
  };

  const addNewEmptyRowAtIndexPosition = (
    rows: TableRowType[],
    positionIndex: number,
    duplicatedData: TableRowType,
    isTotalFooter = false
  ) => {
    let rowData: TableRowType;
    if (isTotalFooter) {
      rowData = {
        ...duplicatedData,
        ...defaultRowMeta,
        rowType: TotalRowTypesIdentifier.DISCOUNT,
        column1: TotalRowTypesIdentifier.DISCOUNT,
        cellConfig: {
          price: {
            valueFormater: '$',
          },
        },
        optionsMenu: cellMenu,
      };
    } else {
      rowData = { ...duplicatedData, ...defaultRowMeta, rowType: TableRowTypes().BODY };
    }
    return addRowAtIndexPosition(rows, rowData, positionIndex);
  };

  const addRowAtIndexPosition = async (rows: TableRowType[], rowData: TableRowType, positionIndex: number) => {
    const newRowId = uuidv4();
    const newRowData = { ...rowData, id: newRowId };
    const newRows = [...rows];
    newRows.splice(positionIndex, 0, newRowData);
    if (!allTextTableColumns) return;

    await updatedTableState(selectedBlockIdByWrapper as string, newRows, allTextTableColumns);

    return newRowId;
  };

  const setCellFocus = (rowId: string) => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;

    if (!sectionId) throw new Error('Section id cannot be empty');

    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;
    const allColumns = tableData.columns;
    const tableCallbackDetails = toggledTableSettingsPanel.tableApi.tableCallbackDetails;
    const firstEditableColumn = allColumns.find((column) => column.editable && column.field);

    if (firstEditableColumn) {
      tableCallbackDetails.api.setRowSelectionModel([rowId]);
      dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_ROWS }));
      setToggledTableSettingsPanel({
        tableApi: {
          selectedModel: tableCallbackDetails.api.getCellParams(rowId, firstEditableColumn.field),
          tableCallbackDetails: tableCallbackDetails,
        },
      });

      tableCallbackDetails.api.startCellEditMode({
        id: rowId,
        field: firstEditableColumn.field,
      });
    } else {
      setToggledTableSettingsPanel(null);
    }
  };

  const getSelectedRowId = (): string | undefined => {
    if (!toggledTableSettingsPanel) return undefined;

    const cellSelectedModel = toggledTableSettingsPanel.tableApi.selectedModel as GridCellParams;
    return cellSelectedModel.row.id;
  };

  const handleRowAdd = async (position: TableRowAddPositions) => {
    const selectedRowId = getSelectedRowId();
    if (!selectedBlockIdByWrapper || !toggledTableSettingsPanel || !selectedRowId) return;

    const selectedRowIndex = allRowIds.indexOf(selectedRowId);
    const updatedRows = allTextTableRows;
    const rowDataDuplicated = updatedRows[selectedRowIndex] || columns[0];
    const updatedRowDataDuplicated: TableRowType = {} as TableRowType;
    Object.keys(rowDataDuplicated).forEach((key) => {
      if (key !== RowTypesIdentifier.BODY) updatedRowDataDuplicated[key] = '';
    });

    const newRowId = await addNewEmptyRowAtIndexPosition(
      updatedRows,
      selectedRowIndex + position,
      updatedRowDataDuplicated,
      isRowBelongsToTotal(rowDataDuplicated)
    );

    if (newRowId) {
      setCellFocus(newRowId);
    }
  };

  const handleRowDuplication = async () => {
    const selectedRowId = getSelectedRowId();
    if (!selectedBlockIdByWrapper || !toggledTableSettingsPanel || !selectedRowId) return;

    const selectedRowIndex = allTextTableRows.map((row) => row.id).indexOf(selectedRowId);
    const rowDataDuplicated = allTextTableRows[selectedRowIndex];
    const newRowId = await addRowAtIndexPosition(allTextTableRows, rowDataDuplicated, selectedRowIndex + TableRowAddPositions.AFTER);

    if (newRowId) {
      setCellFocus(newRowId);
    }
  };

  const handleDeleteRow = async () => {
    const selectedRowId = getSelectedRowId();
    if (!selectedBlockIdByWrapper || !toggledTableSettingsPanel || !selectedRowId) return;
    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;

    const updatedRows = tableData.rows.filter((row) => row.id !== selectedRowId);

    if (!updatedRows.length) {
      if (!sectionId || !selectTableBlockContent) return;

      deleteBlock(selectedBlockIdByWrapper);
      gridBlockDeletedHandler(sectionId, selectedBlockIdByWrapper, selectTableBlockContent).then(() => {
        setIsModalVisible(false);
        dispatch(resetAllPanels());
      });
      return;
    }

    const idIndex = allRowIds.indexOf(selectedRowId);
    const newSelectedIndex = idIndex - 1 >= 0 ? idIndex - 1 : idIndex + 1;
    const newSelectedRowId = allRowIds[newSelectedIndex];
    await updatedTableState(selectedBlockIdByWrapper, [...updatedRows], [...tableData.columns]);

    const tableCallbackDetails = toggledTableSettingsPanel.tableApi.tableCallbackDetails;
    tableCallbackDetails.api.setRowSelectionModel([newSelectedRowId]);

    setCellFocus(newSelectedRowId);
  };

  const updateRowClass = async () => {
    const selectedRowId = getSelectedRowId();
    if (!selectedBlockIdByWrapper || !toggledTableSettingsPanel) return;
    if (!sectionId) throw new Error('Section id cannot be empty');

    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;
    const updatedRows = allTextTableRows?.map((row) => {
      if (row?.id === selectedRowId) {
        return { ...row, isRowHidden: !row.isRowHidden };
      }
      return row;
    });
    await updatedTableState(selectedBlockIdByWrapper, [...updatedRows], [...tableData.columns]);
  };

  return { handleRowAdd, handleRowDuplication, handleDeleteRow, updateRowClass };
};
