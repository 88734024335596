import { Box, List, type SxProps, type Theme } from '@mui/material';
import { useRef } from 'react';
import { type Document } from 'services/documents/entities/Document';
import { highlight, Higlighter } from '../components/Highlighter';
import { DocumentListItem } from './DocumentListItem';
import { DocumentListItemV2 } from './DocumentListItemV2';

type DocumentsListProps = {
  documents: Document[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  refreshDocumentList: () => Promise<void>;
  sx: SxProps<Theme>;
};

export function DocumentsList({ documents, refreshDocumentList, setCurrentPage, sx }: DocumentsListProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const viewCreatedDocument = (documentId: string) => {
    setCurrentPage(1);
    scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    highlight(documentId);
  };

  return (
    <Box
      data-testid="documents-list"
      ref={scrollRef}
      sx={{
        ...sx,
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
      }}
    >
      <List
        sx={{
          padding: 0,
          '& > div:last-of-type li': {
            borderBottomLeftRadius: '24px',
            borderBottomRightRadius: '24px',
          },
        }}
      >
        {documents.map((document) => (
          <Higlighter key={document.id} id={document.id}>
            {document.editorVersion === 3 ? (
              <DocumentListItem document={document} refreshDocumentList={refreshDocumentList} viewCreatedDocument={viewCreatedDocument} />
            ) : (
              <DocumentListItemV2 key={document.id} document={document} />
            )}
          </Higlighter>
        ))}
      </List>
    </Box>
  );
}
