import Select from 'react-select';
import { convertToOption } from '../../utils';

type Props = {
  keyName: string;
  options: Record<string, unknown> | string[];
  onChange: (event: any) => void;
  value: string | boolean | string[];
};

export const OptionPicker = ({ keyName, options = [], onChange, value }: Props) => {
  const handleInputChange = (value) => {
    const event = {
      target: {
        name: keyName,
        value: value.value,
      },
    };
    onChange(event);
  };

  return <Select name={keyName} value={convertToOption([value])} options={convertToOption(options)} onChange={handleInputChange} />;
};
