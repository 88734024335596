import * as React from 'react';
const SvgIcoMaps = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.501.88c.345 0 .625.28.625.625v5.828a.625.625 0 1 1-1.25 0V1.505c0-.345.28-.625.625-.625M6.5 2.356c.344 0 .624.28.624.625v11.855a.625.625 0 1 1-1.25 0V2.98c0-.345.28-.625.625-.625"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="m12.226.99 3.904 1.301c.597.199.998.756.998 1.384v4.491a.625.625 0 1 1-1.25 0v-4.49c0-.091-.057-.17-.142-.199L11.83 2.176a1.05 1.05 0 0 0-.66 0L7.223 3.49a2.3 2.3 0 0 1-1.45 0L2.397 2.365a.21.21 0 0 0-.275.198v9.453a.21.21 0 0 0 .116.188L6.033 14.1c.293.147.64.147.932 0l1.756-.878a.625.625 0 0 1 .559 1.118l-1.755.878a2.29 2.29 0 0 1-2.05 0m0 0-3.796-1.897a1.46 1.46 0 0 1-.807-1.306V2.563c0-.995.975-1.699 1.92-1.384l3.377 1.126c.214.071.445.071.66 0L10.775.989h.001c.47-.156.979-.156 1.45.001M15.342 13.674a.625.625 0 0 1 .884 0l1.553 1.553a.625.625 0 0 1-.884.884l-1.553-1.553a.625.625 0 0 1 0-.884"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M14.002 10.459a1.876 1.876 0 1 0 0 3.751 1.876 1.876 0 0 0 0-3.751m0-1.25a3.126 3.126 0 1 0 0 6.252 3.126 3.126 0 0 0 0-6.252"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoMaps;
