import { AxiosResponse } from 'axios';
import { HttpCodeStatus, EXPIRED_OR_SUSPENDED_ACCOUNT_MESSAGE } from '../query/HttpCodeStatus';

export const isAccountExpiredOrSuspended = ({ data, status }: AxiosResponse) =>
  data?.message === EXPIRED_OR_SUSPENDED_ACCOUNT_MESSAGE && status === HttpCodeStatus.FORBIDDEN;

export const parseUnformattedErrors = (rawErrorMessage: string): string[] => {
  if (!rawErrorMessage) {
    return [];
  }
  const errorStrings = rawErrorMessage.split(/\r?\n/);
  const newErrors: string[] = [];
  errorStrings.map((err) => {
    let parsedErr = err.replaceAll(/(<([^>]+)>)/gi, '');
    parsedErr = parsedErr.replaceAll(/\r?\n/gi, '').trim();
    if (parsedErr !== '') {
      newErrors.push(parsedErr);
    }
  });

  return newErrors;
};
