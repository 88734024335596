import CreatableSelect from 'react-select/creatable';
import { convertToOption } from '../../utils';

type Props = {
  keyName: string;
  options: Record<string, unknown> | string[];
  onChange: (event: any) => void;
  value: string | boolean | string[];
};

export const CreatableOptionPicker = ({ keyName, options = [], onChange, value }: Props) => {
  const handleInputChange = (values) => {
    const event = {
      target: {
        name: keyName,
        value: values.map(({ value }) => value),
      },
    };
    onChange(event);
  };

  return (
    <CreatableSelect
      name={keyName}
      isMulti
      value={convertToOption(value)}
      options={convertToOption(options)}
      formatCreateLabel={() => 'Add'}
      onChange={handleInputChange}
    />
  );
};
