import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Stack, Snackbar, Alert, AlertTitle, Button } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';

import { BillingOptionEntity } from 'services/api/account/interfaces';
import { useCurrentUser } from 'providers/UserProvider';
import SettingsTheme from 'muiTheme/SettingsTheme';
import { usePlanApiData } from 'hooks/usePlanApiData';
import { useGetBillingOptions } from 'hooks/useGetBillingOptions';
import { useIsUserTrial } from 'hooks/useIsUserTrial';

import { BillingProvider } from './billingProvider';
import { BillingPageProps, Promo } from './interface';
import { getPlanTierByString, isPlanUpgrade } from 'services/api/plan/utils';

import { Loading } from 'components';
import PlanSummary from './planSummary';
import CompleteYourPurchase from './completeYourPurchase';
import PaymentMethod from './paymentMethod';

import './styles.less';

const BillingPage = ({ planRepository, accountRepository, listingsRepository }: BillingPageProps) => {
  const { planName } = useParams<{ planName: string }>();
  const [title, setTitle] = useState<string>('settings.billing.title_switch');
  // data to use when finally pushing
  const [selectedBillingOption, setBillingOption] = useState<BillingOptionEntity | null>();
  const [promo, setCurrentPromo] = useState<Promo | null>();
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [isDowngrade, setIsDowngrade] = useState<boolean>(false);

  const navigate = useNavigate();
  const userData = useCurrentUser();
  const {
    data: { accountId },
  } = userData;
  const { planData } = usePlanApiData({ planRepository, accountId, enabled: !!accountId });
  const isUserTrial = useIsUserTrial({ accountId, enabled: !!accountId });

  const {
    billingOptions,
    isFetched: isOptionsFetched,
    isError: isOptionsError,
  } = useGetBillingOptions({
    accountRepository,
    accountId,
    planName,
    promoCode: promo?.code || '',
    enabled: !!accountId,
  });

  useEffect(() => {
    if (isOptionsFetched && billingOptions) {
      if (billingOptions.length > 0) {
        const newBillingOption = billingOptions.find((opt) => !opt.isCurrentPlan);
        if (newBillingOption) {
          setBillingOption(newBillingOption);
          if (promo && !newBillingOption.changePreview?.couponApplied) {
            setApiErrors([t('settings.billing.error.unable_to_apply_coupon')]);
            setCurrentPromo(null);
          }
        } else {
          setApiErrors([t('settings.billing.error.no_billing_options')]);
        }
      } else {
        setBillingOption(null);
        setApiErrors([t('settings.billing.error.no_billing_options')]);
      }
    } else if (isOptionsError) {
      setBillingOption(null);
      setApiErrors([t('settings.billing.error.no_billing_options')]);
    }
  }, [isOptionsFetched, billingOptions, isOptionsError]);

  useEffect(() => {
    if (isUserTrial) {
      setTitle('settings.billing.title_upgrade');
      setIsDowngrade(false);
      return;
    }

    if (!selectedBillingOption || !planData) {
      setTitle('settings.billing.title');
      setIsDowngrade(false);
      return;
    }

    const curTier = getPlanTierByString(planData.name || '');
    const newTier = getPlanTierByString(selectedBillingOption.planName);
    if (curTier === newTier) {
      setTitle('settings.billing.title_same');
      setIsDowngrade(false);
      return;
    }

    if (isPlanUpgrade(newTier, curTier)) {
      setTitle('settings.billing.title_upgrade');
      setIsDowngrade(false);
    } else {
      setTitle('settings.billing.title_downgrade');
      setIsDowngrade(true);
    }
  }, [planData, selectedBillingOption, isUserTrial]);

  const { t } = useTranslation();

  if (!accountId) {
    return <Loading isLoading={true} />;
  }

  return (
    <ThemeProvider theme={SettingsTheme}>
      <BillingProvider
        accountId={accountId}
        planRepository={planRepository}
        accountRepository={accountRepository}
        listingsRepository={listingsRepository}
        currentBillingOption={selectedBillingOption}
        setApiErrors={setApiErrors}
        isBillingOptionsFetched={isOptionsFetched}
        currentPromo={promo}
        setPromo={setCurrentPromo}
        isDowngrade={isDowngrade}
      >
        <Box>
          <Button
            variant="text"
            color="primary"
            size="small"
            startIcon={<ChevronLeft />}
            onClick={() => {
              navigate(planData?.isTrialExpired ? '/trial/expired' : '/settings/plan');
            }}
          >
            {t('settings.billing.back_to_plans')}
          </Button>
          <Typography variant="h4" my={3} color="primary.main">
            {t(title, { plan_name: planName })}
          </Typography>
          <Stack direction="row" justifyContent="center" alignItems="flex-start" sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }} gap={4}>
            <Stack direction="column" justifyContent="center" sx={{ width: '100%', maxWidth: '524px' }} gap={2}>
              <PlanSummary billingOptions={billingOptions || []} handleSetBillingOption={setBillingOption} />
              <PaymentMethod />
            </Stack>
            <Stack direction="column" justifyContent="center" sx={{ width: '100%', maxWidth: '500px' }} gap={2}>
              <CompleteYourPurchase />
            </Stack>
          </Stack>
        </Box>
        {apiErrors.length > 0 && (
          <Snackbar color="error" open={apiErrors.length > 0} autoHideDuration={10000} onClose={() => setApiErrors([])}>
            <Alert severity="error" variant="standard" sx={{ width: '100%', textTransform: 'capitalize', marginBottom: 1 }}>
              <AlertTitle variant="body1" sx={{ fontWeight: 600 }}>
                {t('settings.billing.error.api_error_title')}
              </AlertTitle>
              {apiErrors.map((apiError, idx) => (
                <Typography key={`api-error-${idx}`} variant="body1">
                  {apiError.charAt(0).toUpperCase() + apiError.slice(1)}
                </Typography>
              ))}
            </Alert>
          </Snackbar>
        )}
      </BillingProvider>
    </ThemeProvider>
  );
};

export default BillingPage;
