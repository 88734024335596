import { Chip, Divider } from '@mui/material';
import { List } from 'antd';
import { Tooltip } from 'components';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { type Document } from 'services/documents/entities/Document';
import usePreviewUrl from '../../editor/usePreviewUrl';
import { BetaBadge } from '../components/beta-badge';
import { DocumentAvatar } from '../components/DocumentAvatar';
import { RestoreDocumentAction } from '../documents-tab/document-actions/RestoreDocumentAction';
import { getDocumentLink } from '../utils/getDocumentLink';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import './styles.less';

export type ListType = 'Documents' | 'Trash';

export type TrashListProps = {
  documentList: Document[];
  isLoading: boolean;
  listEmpty: ReactNode;
};

export function TrashList({ documentList, listEmpty, isLoading }: TrashListProps) {
  const [highlightItemId, setHighlightItemId] = useState('');
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const previewUrlPrefix = usePreviewUrl();
  const { formatDocumentDateTime } = useFormatDateTime();

  const handleOnListMouseEnter = (documentId: string) => {
    setHoveredItemId(documentId);

    if (!highlightItemId) {
      return;
    }
    setHighlightItemId('');
  };

  const handleOnListMouseLeave = () => {
    setHoveredItemId(null);
  };

  const formatStatusToClassName = (status: string) => status.toLowerCase().replace(' ', '-');

  return (
    <List
      className="pipeline__list"
      loading={isLoading}
      dataSource={documentList}
      locale={{ emptyText: listEmpty }}
      renderItem={(item) => {
        const isEditorv3 = item.editorVersion === 3;
        let assignedUser = '';
        if (item.assigneeUserFirstName && item.assigneeUserLastName) {
          assignedUser = item.assigneeUserFirstName + ' ' + item.assigneeUserLastName;
        }

        const assignedStandaloneContactFullName =
          item.prospectFirstName && item.prospectLastName && `${item.prospectFirstName} ${item.prospectLastName}`;

        const assignedProspect = item.companyName || assignedStandaloneContactFullName || '-';

        const tooltip = assignedUser;
        const isHovered = hoveredItemId === item.id;
        const documentLink = getDocumentLink({
          documentId: item.id,
          status: item.status,
          editorVersion: item.editorVersion,
          isLocked: item.isLocked,
          previewUrlPrefix,
        });

        return (
          <Link
            to={documentLink}
            key={item.id}
            id={item.id}
            data-testid={`pipelineItem${item.id}`}
            className={`document-version-${item.editorVersion}`}
          >
            <List.Item
              className={`${formatStatusToClassName(item.status)} ${highlightItemId === item.id ? ' item-highlight' : ''}`}
              onMouseEnter={() => handleOnListMouseEnter(item.id)}
              onMouseLeave={handleOnListMouseLeave}
              data-testid={`list-item-${item.id}`}
              id={`list-item-${item.id}`}
              onClick={(event) => event.stopPropagation()}
            >
              <List.Item.Meta
                title={
                  <div className="document-title-container">
                    {isEditorv3 ? <BetaBadge /> : null}
                    <div className="document-title" data-testid="document-title">
                      {item.title}
                    </div>
                  </div>
                }
                description={assignedProspect}
              ></List.Item.Meta>
              <div className={`document-details ${isHovered && isEditorv3 ? 'hidden' : ''}`}>
                <div className="document-created-date">{formatDocumentDateTime(item.created_at)}</div>
                <Tooltip placement={'bottom'} title={tooltip}>
                  <div className="assigned_user">
                    <DocumentAvatar document={item} />
                  </div>
                </Tooltip>
                <div className="document-status-container">
                  <Chip
                    label={item.status}
                    variant="filled"
                    className={`document-status-chip ${formatStatusToClassName(item.status)}`}
                    size="small"
                    sx={{
                      '& .MuiChip-label': {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    }}
                  />
                </div>
              </div>

              <div className={`document-actions ${isHovered ? '' : 'hidden'}`} data-testid="document-actions">
                {isEditorv3 && <RestoreDocumentAction documentToRestore={item} />}
              </div>
            </List.Item>
            <Divider className="document-divider" />
          </Link>
        );
      }}
    />
  );
}
