import { useSelector } from 'react-redux';
import { RootState } from '../grid/reduxStore/Store';
import { BlockContent } from '../grid/reduxStore/editorSlice';
import { useSectionId } from './SectionIdProvider';
import { selectBlockContent } from '../grid/reduxStore/editorSlice';

export const useBlockContent = (blockId: string): BlockContent | undefined => {
  const sectionId = useSectionId();
  return useSelector((state: RootState) => selectBlockContent(state, sectionId, blockId));
};

export const useBlockContentWithSectionId = (sectionId: string, blockId: string): BlockContent | undefined => {
  return useSelector((state: RootState) => selectBlockContent(state, sectionId, blockId));
};
