import { openNotification } from 'components';
import { useGetSpecificOptionalFeature, useMutationEnableOptionalFeature } from 'hooks/featureFlagHooks';
import { useTranslation } from 'react-i18next';
import { FeatureFlags } from 'utils/featureFlags';
import goToCinder from 'utils/goToCinder';
import './styles.less';

type Props = {
  proposalId: number;
};

export const OptOut = ({ proposalId }: Props) => {
  const { data: optionalFeature, isLoading } = useGetSpecificOptionalFeature(FeatureFlags.newShareExperience);

  if (isLoading || !optionalFeature?.canToogle) {
    return null;
  }

  return <Container proposalId={proposalId} />;
};

const Container = ({ proposalId }: Props) => {
  const { t } = useTranslation();

  const { mutateAsync: enableOptionalFeature } = useMutationEnableOptionalFeature();

  const handleOptOutClick = async () => {
    try {
      await enableOptionalFeature({ flag: FeatureFlags.newShareExperience, enabled: false });
      goToCinder(`/proposal/send/${proposalId}`);
    } catch (e) {
      openNotification({
        type: 'error',
        title: t('share.opt_out.error_title'),
        description: t('share.opt_out.error_description'),
        placement: 'top',
      });
    }
  };

  return (
    <div className="opt-out-container">
      <p className="main-message">
        <a className="main-action" data-testid="share-opt-out-button" onClick={handleOptOutClick}>
          {t('share.opt_out.message_action')}
        </a>
      </p>
      <p className="admin-text">{t('share.opt_out.message_admin')}</p>
    </div>
  );
};
