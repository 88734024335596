import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiCrmSalesforceRepository } from 'services/crm/repositories/implementations/ApiCrmSalesforceRepository';

export const useSalesforceCrm = () => {
  return useQuery(QueryKeys.salesforceCrm, ApiCrmSalesforceRepository.getSalesforceObjectTypes);
};

export const useSalesforceCrmPrefix = (prefix: string) => {
  return useQuery(QueryKeys.salesforceCrmPrefix(prefix), () => ApiCrmSalesforceRepository.getSalesforceObjectTypesPrefix(prefix), {
    enabled: false,
  });
};
