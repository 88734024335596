import * as React from 'react';
const SvgIcoAdmin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M4.73 13.07a.75.75 0 0 1 .75.75v6.36a.75.75 0 0 1-1.5 0v-6.36a.75.75 0 0 1 .75-.75M4.73 3.07a.75.75 0 0 1 .75.75v6.36a.75.75 0 0 1-1.5 0V3.82a.75.75 0 0 1 .75-.75M12 11.25a.75.75 0 0 1 .75.75v8.18a.75.75 0 0 1-1.5 0V12a.75.75 0 0 1 .75-.75M12 3.07a.75.75 0 0 1 .75.75v4.54a.75.75 0 0 1-1.5 0V3.82a.75.75 0 0 1 .75-.75M19.27 14.89a.75.75 0 0 1 .75.75v4.54a.75.75 0 0 1-1.5 0v-4.54a.75.75 0 0 1 .75-.75M19.27 3.07a.75.75 0 0 1 .75.75V12a.75.75 0 0 1-1.5 0V3.82a.75.75 0 0 1 .75-.75" />
      <path d="M1.25 13.82a.75.75 0 0 1 .75-.75h5.45a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75M8.52 8.36a.75.75 0 0 1 .75-.75h5.46a.75.75 0 0 1 0 1.5H9.27a.75.75 0 0 1-.75-.75M15.8 15.64a.75.75 0 0 1 .75-.75H22a.75.75 0 0 1 0 1.5h-5.45a.75.75 0 0 1-.75-.75" />
    </g>
  </svg>
);
export default SvgIcoAdmin;
