import { editorConfig } from './config';

export const CheckImageExtension = (file: File) => {
  const extension = file.name.split('.')?.pop();
  let extensionMatched = false;

  for (const type of editorConfig.acceptedImageTypes) {
    if (type.split('/')?.pop() === extension) {
      extensionMatched = true;
      break;
    }
  }

  return extensionMatched;
};
