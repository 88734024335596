import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { PromoFormProps } from './interface';
import { useTranslation } from 'react-i18next';
import { checkCoupon } from 'hooks/useValidateCoupon';
import { BillingContext } from './billingProvider';

const PromoForm = ({ disabled = false, setIsLoadingPromo }: PromoFormProps) => {
  const { t } = useTranslation();
  const { accountId, accountRepository, currentPromo, setPromo } = useContext(BillingContext);

  const [promoCode, setPromoCode] = useState<string>(currentPromo?.code || '');
  const [promoCodeError, setPromoCodeError] = useState<string>('');

  const handleUpdateCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value);
    setPromoCodeError('');
  };

  const handleAddPromo = async (event) => {
    event.preventDefault();
    if (!promoCode && !currentPromo) {
      setPromoCodeError(t('settings.billing.promo_code_invalid_error'));
      return;
    }
    setIsLoadingPromo(true);
    const result = await checkCoupon({ accountId, accountRepository, couponCode: promoCode });
    const { state: couponState } = result;
    if (couponState === 'redeemable') {
      setPromo(result);
    } else {
      if (couponState === 'invalid') {
        setPromoCodeError(promoCode !== '' ? t('settings.billing.promo_code_invalid_error') : '');
      } else if (couponState === 'inactive' || couponState === 'expired') {
        setPromoCodeError(t('settings.billing.promo_code_expired_error'));
      } else if (couponState === 'maxed_out') {
        setPromoCodeError(t('settings.billing.promo_code_maxed_out_error'));
      }
      setPromo(null);
    }
    setIsLoadingPromo(false);
  };

  const handleClear = (event) => {
    event.preventDefault();
    setPromoCode('');
    setPromo(null);
  };

  useEffect(() => {
    if (!currentPromo) {
      setPromoCode('');
    }
  }, [currentPromo]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        component="form"
        onSubmit={(event) => (currentPromo?.state === 'redeemable' ? handleClear(event) : handleAddPromo(event))}
        sx={{ width: '100%' }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={2}
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignSelf: 'stretch',
          }}
        >
          <TextField
            error={!!promoCodeError}
            value={promoCode}
            onChange={handleUpdateCode}
            id="promo-code"
            label={t('settings.billing.promo_code')}
            size="medium"
            sx={{
              borderColor: 'primary.main',
              flex: '1 0 0',
            }}
            disabled={disabled || currentPromo?.state === 'redeemable'}
          />
          <Box>
            <Button type="submit" variant="outlined" size="large" sx={{ borderRadius: '100px' }} disabled={disabled}>
              {t(currentPromo?.state === 'redeemable' ? 'settings.billing.clear_promo' : 'settings.billing.apply_promo')}
            </Button>
          </Box>
        </Stack>
        {promoCodeError && (
          <Typography variant="caption" color="error" px={2}>
            {promoCodeError}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PromoForm;
