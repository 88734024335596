import { useCallback, useRef } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

export type EditorRefType = FroalaEditor | null;

function useEditorRef() {
  const editorRef = useRef<EditorRefType>(null);

  const setRef = useCallback((node) => {
    editorRef.current = node;
  }, []);

  return { editorRef: editorRef.current, setRef };
}

export default useEditorRef;
