import { SsoSettingsRepository } from '../SsoSettingsRepository';
import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';

class ApiSsoSettingsRepositoryClass implements SsoSettingsRepository {
  public async getSsoSetting() {
    return await apiClient.get(Endpoints.ssoSettings);
  }

  public async saveSsoSetting(payload) {
    return await apiClient.post(Endpoints.ssoSettings, payload);
  }
}

export const ApiSsoSettingsRepository = new ApiSsoSettingsRepositoryClass();
