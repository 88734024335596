import { Grid, Box, InputAdornment } from '@mui/material';
import { SecondaryMenuItemTitle } from './SecondaryMenuTitles';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

type NumberInputBoxSingleColProps = {
  title?: string;
  descriptionText?: string;
  onChange: (val: number) => void;
  onBlur?: (val: number) => void;
  startAdornmentText?: string;
  endAdornmentText?: string;
  value?: number;
  error?: string;
  testId?: string;
};

export const NumberInputBoxSingleCol = ({
  onChange,
  onBlur,
  title = 'title',
  descriptionText,
  startAdornmentText = '',
  endAdornmentText = '',
  value = 0,
  error,
  testId,
}: NumberInputBoxSingleColProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const positiveNum = Math.abs(Number(event.target.value) || 0);
    onChange(positiveNum);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      const positiveNum = Math.abs(Number(event.target.value) || 0);
      onBlur(positiveNum);
    }
  };

  const isError = !!error;
  return (
    <Grid container direction="column" rowGap={1}>
      <SecondaryMenuItemTitle>{title}</SecondaryMenuItemTitle>
      <Grid item width={'100%'}>
        <FormControl variant="outlined" size="small" fullWidth data-testid={testId}>
          <OutlinedInput
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            error={isError}
            value={value.toString()}
            startAdornment={<InputAdornment position="start">{startAdornmentText}</InputAdornment>}
            endAdornment={<InputAdornment position="end">{endAdornmentText}</InputAdornment>}
            sx={{
              '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
            }}
          />
        </FormControl>
      </Grid>
      {descriptionText || error ? (
        <Box component="p" fontSize={12} lineHeight={1.5} letterSpacing={0.18} color={isError ? 'red' : '#353636'}>
          {isError ? error : descriptionText}
        </Box>
      ) : null}
    </Grid>
  );
};
