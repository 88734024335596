import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const primaryColor = 'rgba(0, 40, 58, 1)';
export const rotateDialogTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          borderRadius: '100px',
          textTransform: 'none',
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: 600,
        },
        contained: {
          backgroundColor: primaryColor,
          color: '#fff',
          '&:hover': {
            backgroundColor: primaryColor,
          },
        },
        text: {
          color: primaryColor,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
        paper: {
          borderRadius: '16px',
          padding: '20px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: primaryColor,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: primaryColor,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          wordBreak: 'break-word',
          color: primaryColor,
        },
      },
    },
  },
});

interface RotateDialogProps {
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
}

export const RotateConfirmationDialog = ({ open, onClose, onAgree }: RotateDialogProps) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={rotateDialogTheme}>
      <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('settings.connect_api.api_clients.rotate_secret_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>{t('settings.connect_api.api_clients.rotate_secret_confirmation_message.primary')} </strong>
            {t('settings.connect_api.api_clients.rotate_secret_confirmation_message.secondary')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onAgree} autoFocus variant="contained">
            Yes, Rotate
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

interface RotatedValue {
  name: string;
  identifier: string;
  secret: string;
}

interface PostRotateDialogProps {
  open: boolean;
  onClose: () => void;
  rotatedValue: RotatedValue | null;
}

export const PostRotateDialog = ({ open, onClose, rotatedValue }: PostRotateDialogProps) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={rotateDialogTheme}>
      <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'New Rotated Secret'}</DialogTitle>
        <DialogContent>
          {rotatedValue && (
            <Box>
              <p>
                <strong>{t('settings.connect_api.api_clients.client_secret_warning_message.primary')} </strong>
                {t('settings.connect_api.api_clients.client_secret_warning_message.secondary')}
              </p>
              <List>
                <ListItem>
                  <ListItemText primary={<strong>{t('settings.connect_api.api_clients.name')}:</strong>} secondary={rotatedValue.name} />
                </ListItem>
                <ListItem>
                  <ListItemText secondary={rotatedValue.identifier}>
                    <strong>{t('settings.connect_api.api_clients.id')}:</strong>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText secondary={rotatedValue.secret}>
                    <strong>{t('settings.connect_api.api_clients.secret')}:</strong>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
