import { transformCcRecipients, transformRecipients } from 'pages/proposals/share/blocks/form/useProposalShareFormOnSubmit';
import type { ProposalShareEmailPayload } from 'services/repositories/interfaces/ProposalRepository';
import { ShareFormFieldValues } from '../useShareForm';

export const transformShareFormFieldValuesToApiPayload = ({
  recipients,
  cc,
  bcc,
  subject,
  body,
}: ShareFormFieldValues): Omit<ProposalShareEmailPayload, 'proposalId' | 'view_document_button_text'> => {
  return {
    recipients: transformRecipients(recipients),
    cc_emails: transformCcRecipients(cc),
    bcc_emails: transformCcRecipients(bcc),
    subject,
    body: body || undefined,
  };
};
