import { components, DropdownIndicatorProps, MultiValueRemoveProps } from 'react-select';
import type { MultiValueGenericProps, OptionProps, Props } from 'react-select';
import { Icon } from 'components';
import { ContactPopover } from './popover';
import type { AdditionalMultiValueLabelData, EmailPickerOption } from './types';

export type BaseProps = Props<EmailPickerOption, true>;

interface MultiValueLabelProps extends Omit<MultiValueGenericProps<EmailPickerOption>, 'data'>, AdditionalMultiValueLabelData {
  data: EmailPickerOption;
}

const MultiValueLabel = ({ data, ...rest }: MultiValueLabelProps) => {
  return (
    <components.MultiValueLabel data={data} {...rest}>
      <ContactPopover data={data} additionalData={rest.additionalData} />
    </components.MultiValueLabel>
  );
};

const Option = (props: OptionProps<EmailPickerOption, true>) => {
  return <components.Option {...props}>{props.label}</components.Option>;
};

const MultiValueRemove = (props: MultiValueRemoveProps<any>) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="IcoCloseX" title="remove" className="multi-value-remove" />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => (
  <components.DropdownIndicator {...props}>
    <Icon name="IcoArrowDown" title="open" />
  </components.DropdownIndicator>
);
const IndicatorSeparator = () => null;

export const baseComponents = { DropdownIndicator, IndicatorSeparator, MultiValueLabel, Option, MultiValueRemove };

export const theme: BaseProps['theme'] = (basethem) => ({
  ...basethem,
  colors: {
    ...basethem.colors,
    primary25: '#d2f5fa',
    primary50: '#aaeefa',
    primary: '#004053',
  },
});

export const baseStyles = {
  container: (styles) => ({ ...styles, width: '100%' }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#d2f5fa',
    borderRadius: 24,
    paddingLeft: 4,
    paddingRight: 0,
    alignItems: 'baseline',
    color: '#353636',
    margin: '4px',
    '@media(max-width: 520px)': {
      maxWidth: 164,
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    padding: 0,
    paddingLeft: 8,
    paddingRight: 4,
    fontSize: 14,
    color: '#353636',
  }),
  control: (styles) => ({ ...styles, border: 0, borderRadius: 0, borderTopRightRadius: 8, boxShadow: 'none', minHeight: 40 }),
  menu: (styles) => ({ ...styles, zIndex: 3 }),
  multiValueRemove: () => ({
    padding: 4,
    fontSize: 14,
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24,
    flexShrink: 0,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  }),
  valueContainer: (styles) => ({ ...styles, padding: '4px 8px' }),
};
