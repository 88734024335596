import { NotificationProps } from './interface';
import Icon from '../icon';

export const getIcon = (type: NotificationProps['type']) => {
  switch (type) {
    case 'error':
      return <Icon name="IcoError" className="lighthouse" />;
    case 'info':
      return <Icon name="IcoInfo" className="lake" />;
    case 'warning':
      return <Icon name="IcoWarning" className="sunrise" />;
    case 'success':
      return <Icon name="IcoSuccess" className="seaglass" />;
  }
};
