import { Box } from '@mui/material';
import { ComponentProps } from 'react';
import { MilestoneProvider } from './MilestoneProvider';

export function Milestone({
  children,
  isTitleAlignedWithIcons,
  isLast,
  ...props
}: ComponentProps<typeof Box> & { isTitleAlignedWithIcons: boolean; isLast: boolean }) {
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      alignItems="flex-start"
      justifyContent="flex-start"
      flexBasis="5rem"
      flexShrink={0}
      flexGrow={1}
      position="relative"
      gap={2}
      {...props}
    >
      <MilestoneProvider value={{ isTitleAlignedWithIcons, isLast }}>{children}</MilestoneProvider>
    </Box>
  );
}
