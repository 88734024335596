type Value = Record<string, unknown>;
const convertObjectKeysToCamelCase = (value: Value) => {
  const response = {};

  Object.keys(value).map((key) => {
    if (key.indexOf('-') > -1 || key.indexOf('_') > -1) {
      const newKey = key.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

      response[newKey] = value[key];
    } else response[key] = value[key];
  });

  return response;
};

export const convertToCamelCase = (value: Value | Value[]) => {
  if (Array.isArray(value)) {
    return value.map((item) => {
      return convertToCamelCase(item);
    });
  }
  return convertObjectKeysToCamelCase(value);
};
