import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Switch } from 'antd';
import { Loading, openNotification } from 'components';

import { useOptionalFeaturesQuery } from '../useOptionalFeaturesQuery';
import { useOptionalFeaturesOnToggle } from '../useOptionalFeaturesOnToggle';

import './styles.less';

export const Configurations = () => {
  const { t } = useTranslation();
  const { data, status } = useOptionalFeaturesQuery();

  const dataSource = useMemo(() => data, [data]);
  const { mutate: toggleOptionalFeature, isLoading: isOptionalFeaturesToggleLoading } = useOptionalFeaturesOnToggle();

  const onInputChange = (data, value) => {
    data.enabled = value;
    toggleOptionalFeature(data, {
      onSuccess: () => {
        openNotification({
          type: 'success',
          title: t('settings.optional_features.success_label'),
          description: t('settings.optional_features.saved_successfully_label'),
          placement: 'topRight',
        });
      },
      onError: (data: any) => {
        openNotification({
          type: 'error',
          title: t('settings.optional_features.error_label'),
          description: data.reason_phrase,
          placement: 'topRight',
        });
      },
    });

    return true;
  };

  const columns = [
    {
      title: t('settings.optional_features.configurations.column_name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('settings.optional_features.configurations.column_scope'),
      dataIndex: 'scope',
      key: 'scope',
      render: (_, data) => {
        return t('settings.optional_features.configurations.' + data.scope + '_scope');
      },
    },
    {
      title: '',
      key: 'id',
      width: '200px',
      render: (_, data) => {
        return <Switch checked={data.enabled} onChange={(value) => onInputChange(data, value)} />;
      },
    },
  ];

  const isLoading = isOptionalFeaturesToggleLoading;

  switch (status) {
    case 'success':
      return (
        <div className="configurations__container">
          <Table
            className="configurations__table"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            data-testid="optional-features-table"
            rowKey={(record) => record.id}
          />
        </div>
      );
    default:
      return (
        <div className="configurations__loader">
          <Loading isLoading={isLoading} />
        </div>
      );
  }
};
