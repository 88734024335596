import { useEffect, useMemo, useState } from 'react';
import { Button, Input, Heading, Icon, Label } from 'components';
import { RichTextEditor } from 'components/rich-text-editor';
import { CreatableEmailPicker, EmailPickerOption } from './email-picker';
import { useTranslation } from 'react-i18next';
import { Proposal, ShareTemplate } from 'services/proposal/entities/Proposal';

import { usePropsalPreviewUrlQuery } from 'hooks/useProposalPreviewUrlQuery';

import { useCompanyByIdQuery } from 'hooks/useCompanyByIdQuery';
import { usePersonByIdQuery } from 'hooks/usePersonByIdQuery';
import { useAccountUsersQuery } from 'hooks/useAccountUsersQuery';

import './styles.less';
import { Actions } from '../actions';
import { convertToOption } from './email-picker/utils';
import { FormValues, useProposalOnSubmit } from './useProposalShareFormOnSubmit';
import { useProposalShareTemplateQuery } from 'hooks/useProposalShareTemplateQuery';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { Person } from 'services/entities/PersonEntity';

type Props = {
  className?: string;
  proposalData: Proposal;
};

interface ValidationResult {
  hasError: boolean;
  errors: {
    subject: boolean;
    recipients: boolean;
  };
}

const validateFormValues = (formValues: FormValues): ValidationResult => {
  const subject = (formValues.subject ?? '').length < 1;
  const recipients = (formValues.recipients ?? []).length < 1;

  return {
    hasError: subject || recipients,
    errors: { subject, recipients },
  };
};

export const ProposalShareForm = ({ className, proposalData }: Props) => {
  const { t } = useTranslation();
  const [recipients, setRecipients] = useState<EmailPickerOption[]>([]);
  const [ccRecipients, setCcRecipients] = useState<EmailPickerOption[]>([]);
  const [bccRecipients, setBccRecipients] = useState<EmailPickerOption[]>([]);
  const [viewCc, setViewCc] = useState(false);
  const [viewBcc, setViewBcc] = useState(false);
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState(proposalData.name);
  const { onSubmit, isLoading } = useProposalOnSubmit(proposalData.id);
  const [errors, setErrors] = useState<ValidationResult['errors']>({ subject: false, recipients: false });

  const { data: accountUsers } = useAccountUsersQuery();
  const { data: contact } = usePersonByIdQuery(proposalData.personId);
  const { data: company } = useCompanyByIdQuery(contact?.companyId);
  const { data: template } = useProposalShareTemplateQuery(proposalData.id);
  const { data: proposalPreviewData } = usePropsalPreviewUrlQuery(proposalData.id);

  const featureFlags = useFeatureFlag(['expire-document']);
  const isFetchingFeatureFlags = Object.keys(featureFlags).length === 0;
  const isExpireDocumentFeatureEnabled = featureFlags['expire-document'];

  const options = useMemo(() => {
    const result: Person[] = [];
    if (accountUsers) {
      result.push(...accountUsers);
    }
    if (company && company.people) {
      result.push(...company.people);
    } else if (contact) {
      result.push(contact);
    }
    return result.map((user) => convertToOption(user, 'uid'));
  }, [accountUsers, company, contact]);

  const decodeHtml = (text: string): string => {
    const inputHelper = document.createElement('textarea');
    inputHelper.innerHTML = text;
    return inputHelper.value;
  };

  const setTemplateValues = (data?: ShareTemplate) => {
    setBody(data?.finalMessage ?? '');

    if (data?.finalSubject) {
      setSubject(decodeHtml(data.finalSubject));
    }
  };

  useEffect(() => {
    setTemplateValues(template);
  }, [template]);

  useEffect(() => {
    const defaultRecepientUids = new Set<string>();
    if (contact) {
      defaultRecepientUids.add(contact.uid);
    }

    if (proposalPreviewData) {
      const signatories = [...(proposalPreviewData.users ?? []), ...(proposalPreviewData.people ?? [])]
        .filter(({ hasSignature }) => hasSignature)
        .map(({ uid }) => uid);

      signatories.forEach((signee) => defaultRecepientUids.add(signee));
    }

    const defaultRecepients = options.filter(({ value }) => defaultRecepientUids.has(value));
    setRecipients(defaultRecepients);
  }, [proposalPreviewData, company, contact, options]);

  const handleSubmit = () => {
    const viewDocumentText = t('share.email.view_document_button', { lng: proposalData.languageCode });
    const formValues = {
      recipients,
      subject,
      body,
      cc_emails: ccRecipients,
      bcc_emails: bccRecipients,
      view_document_button_text: viewDocumentText,
    };
    const { hasError, errors } = validateFormValues(formValues);
    setErrors(errors);

    if (hasError) {
      return;
    }

    onSubmit(formValues);
  };

  return (
    <section className={'form-section ' + (className ?? '')}>
      <Heading level={3}>{t('share.form.title')}</Heading>
      <div data-testid="form-fields">
        <div className={`form-field ${errors.recipients ? 'is-error' : ''}`}>
          <Label label={t('share.form.to')} data-testid="label" />
          <div className="form-field__control">
            <CreatableEmailPicker
              value={recipients}
              onChange={(values) => setRecipients([...values])}
              options={options}
              placeholder={''}
              additionalData={{ companyId: contact?.companyId }}
            />
            {[viewBcc, viewCc].includes(false) && (
              <div className="cc-containers">
                {!viewCc && (
                  <button className="cc_button" onClick={() => setViewCc(true)}>
                    {t('share.form.cc')}
                  </button>
                )}
                {!viewBcc && (
                  <button className="cc_button" onClick={() => setViewBcc(true)}>
                    {t('share.form.bcc')}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        {viewCc && (
          <div className="form-field">
            <Label label={t('share.form.cc')} data-testid="label" />
            <CreatableEmailPicker
              options={options}
              className="multiple-inputs"
              placeholder={t('share.form.cc_placeholder')}
              value={ccRecipients}
              onChange={(values) => setCcRecipients([...values])}
              isOptionDisabled={() => ccRecipients.length >= 15}
              // additionalData={{ companyId: person?.companyId }}
            />
          </div>
        )}
        {viewBcc && (
          <div className="form-field">
            <Label label={t('share.form.bcc')} data-testid="label" />
            <CreatableEmailPicker
              options={options}
              className="multiple-inputs"
              placeholder={t('share.form.cc_placeholder')}
              value={bccRecipients}
              onChange={(values) => setBccRecipients([...values])}
              isOptionDisabled={() => bccRecipients.length >= 15}
              additionalData={{ companyId: contact?.companyId }}
            />
          </div>
        )}
        <div className="form-field">
          <Label label={t('share.form.from')} data-testid="from" />
          <div className="from__container">
            <div className="from-value">{proposalData.documentLead.name}</div>
          </div>
        </div>
        <div className={`form-field ${errors.subject ? 'is-error' : ''}`}>
          <Label label={t('share.form.subject')} data-testid="label" />
          <Input
            value={subject}
            data-testid="input-subject"
            ariaLabel={t('share.form.subject')}
            placeholder={t('share.form.subject_placeholder')}
            onChange={(e) => setSubject(e.target.value)}
            maxLength={255}
          />
        </div>
        <div className="form-field">
          <RichTextEditor value={body} onChange={setBody} onTemplateChange={setTemplateValues} proposalId={proposalData.id} />
        </div>
      </div>

      <div className="form__footer">
        <div className="actionsWrapper">
          {!isFetchingFeatureFlags && isExpireDocumentFeatureEnabled && <Actions proposalId={proposalData.id} />}
        </div>
        <Button
          disabled={isLoading}
          type="primary"
          variant="positive"
          className="send-email"
          onClick={handleSubmit}
          icon={<Icon name="IcoSend" title={t('share.form.submit')} />}
        >
          {isLoading ? (
            <span data-text={t('share.form.sending')} className="send-email-text">
              {t('share.form.sending')}
            </span>
          ) : (
            t('share.form.submit')
          )}
        </Button>
      </div>
    </section>
  );
};
