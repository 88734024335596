import { Box, IconButton, Tooltip } from '@mui/material';
import IcoCloseX from 'components/icon/icons/IcoCloseX';
import IcoResizeShrink from 'components/icon/icons/IcoResizeShrink';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from '../DocumentTitle';
import { InsightsHeaderEditButton } from './InsightsHeaderEditButton';
import { InsightsHeaderPreviewButton } from './InsightsHeaderPreviewButton';

interface InsightsPanelExpandedHeaderProps {
  documentId: string;
  onClose: ({ isExpanded }: { isExpanded: boolean }) => void;
  onToggleExpand: () => void;
}

export function InsightsPanelExpandedHeader({ documentId, onClose, onToggleExpand }: InsightsPanelExpandedHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <Box flex="1 1 auto" overflow="hidden">
        <DocumentTitle title={document?.title} />
      </Box>
      <Box flex="0 0 auto" display="flex" alignItems="center" gap="0.5rem">
        <InsightsHeaderPreviewButton documentId={documentId} isExpanded />
        <InsightsHeaderEditButton documentId={documentId} isExpanded />
        <Tooltip title={t('pipeline.insights.resize_shrink_tooltip')}>
          <IconButton data-testid="insights-resize-button-shrink" onClick={onToggleExpand}>
            <IcoResizeShrink />
          </IconButton>
        </Tooltip>
        <IconButton data-testid="insights-dismiss-button" onClick={() => onClose({ isExpanded: true })}>
          <IcoCloseX />
        </IconButton>
      </Box>
    </>
  );
}
