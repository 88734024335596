import { type PropsWithChildren } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'en-CA': ['en'],
      'fr-FR': ['fr'],
      default: ['en'],
    },
    preload: ['en', 'fr', 'nl', 'pt', 'da', 'fi', 'de', 'el', 'hi', 'it', 'ru', 'es', 'sv', 'nb', 'pl'],
    debug: process.env.NODE_ENV !== 'production',
    detection: {
      order: ['cookie', 'htmlTag', 'path'],
      caches: ['cookie'],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '/files/locales/{{lng}}/translations.json',
    },
  });

export const I18nProvider = ({ children }: PropsWithChildren) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
