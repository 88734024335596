const withRootSlash = (url: string) => {
  const firstChar = url.charAt(0);

  if (firstChar !== '/') {
    return '/' + url;
  }

  return url;
};

export const constructCinderUrl = (path: string) => `${process.env.REACT_APP_LEGACY_APP_URL}${withRootSlash(path)}`;
