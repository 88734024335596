import * as React from 'react';
const SvgIcoWebStories = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.75 4.5v16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2M12.75 2.5h-9c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2m0 18h-9v-16h9zM20.75 6.5v12c.83 0 1.5-.67 1.5-1.5V8c0-.83-.67-1.5-1.5-1.5"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoWebStories;
