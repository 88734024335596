import { openNotification } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset } from 'services/library/entities/Asset';
import { apiContentLibraryRepository } from 'services/repositories/implementations/ApiContentLibraryRepository';

export function useContentLibraryListQuery() {
  const { t } = useTranslation();
  const [allAssets, setAllAssets] = useState<Asset[]>([]);
  const [visibleAssetsList, setVisibleAssetsList] = useState<Asset[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOnScroll, setIsLoadingOnScroll] = useState(false);
  const [isAssetListLoaded, setIsAssetListLoaded] = useState(false);
  const [remainingAssets, setRemainingAssets] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 30;

  const fetchContentLibraryList = async () => {
    setIsLoading(true);
    try {
      const response = await apiContentLibraryRepository.list();
      setAllAssets(response);
      setVisibleAssetsList(response.slice(0, pageSize));
      setIsAssetListLoaded(true);
      setPage(1);
      setRemainingAssets(response.length - pageSize);
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.error_title'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreAssets = () => {
    if (isLoadingOnScroll || visibleAssetsList.length >= allAssets.length) {
      return;
    }

    setIsLoadingOnScroll(true);

    // Timer used here to show the skeleton when loading more assets
    setTimeout(() => {
      const newPage = page + 1;
      const newAssets = allAssets.slice(0, newPage * pageSize);
      setVisibleAssetsList(newAssets);
      setPage(newPage);
      setIsLoadingOnScroll(false);
      setRemainingAssets(allAssets.length - newAssets.length);
    }, 1000);
  };

  useEffect(() => {
    fetchContentLibraryList();
  }, []);

  return { visibleAssetsList, isLoading, isLoadingOnScroll, isAssetListLoaded, fetchContentLibraryList, loadMoreAssets, remainingAssets };
}
