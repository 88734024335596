import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'vendor/datadog';

import { I18nProvider } from './providers/I18nProvider';
import { QueryProvider } from './providers/QueryProvider';

import App from './App';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  /* TODO: Gradually improve the error boundary to handle all the cases */
  <ErrorBoundary fallback="Oh no, Something went wrong. Please refresh the page." scope="app">
    <I18nProvider>
      <QueryProvider>
        <App />
      </QueryProvider>
    </I18nProvider>
  </ErrorBoundary>
);
