import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Typography, Stack, Divider, Link, Button, useTheme, Tooltip, IconButton, Skeleton, CircularProgress } from '@mui/material';
import { InfoOutlined, Lock, DiscountOutlined, Close } from '@mui/icons-material';
import { useTranslation, Trans } from 'react-i18next';

import useUpdatePlanMutation from 'hooks/useUpdatePlanMutation';

import { formatAsPrice, parseUnformattedApiErrors } from '../utils/utils';
import { BillingLineItem } from './interface';
import { BillingContext } from './billingProvider';
import PromoForm from './promoForm';
import { BillingCard, BillingCardActions, BillingCardContent, BillingCardHeader } from '../utils/styled';

const CompleteYourPurchase = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isLoadingPromo, setIsLoadingPromo] = useState<boolean>();

  const navigate = useNavigate();
  const { updatePlan } = useUpdatePlanMutation();
  const {
    accountId,
    currentBillingOption,
    currentPromo: promo,
    currentPaymentMethod,
    setPromo,
    isBillingOptionsFetched,
    isPaying,
    isDowngrade,
    setIsPaying,
    setApiErrors,
    setFormErrors,
  } = useContext(BillingContext);

  const handleUpdatePlan = async () => {
    if (!accountId || !currentBillingOption || isPaying) {
      return;
    }
    setIsPaying(true);
    const response = await updatePlan({
      accountId: accountId,
      payload: {
        planId: currentBillingOption?.planId,
        paidSeats: 0,
        promoCode: promo?.code || '',
      },
    });

    setIsPaying(false);

    if (response.status === 'success') {
      navigate(`/settings/plan?success=true&upgrade=${!isDowngrade}`);
    } else {
      if (!response.message) {
        setApiErrors([t('settings.billing.error.update_plan_failed')]);
        return;
      }
      const formattedErrors = parseUnformattedApiErrors(response.message);
      const newApiErrors: string[] = [];
      const newFormErrors = {};
      formattedErrors.map((error) => {
        if (error.field) {
          newFormErrors[error.field] = error.message;
        } else {
          newApiErrors.push(error.message);
        }
      });
      setFormErrors(newFormErrors);
      if (newApiErrors.length > 0) {
        setApiErrors(newApiErrors);
      }
    }
  };

  const renderLineItem = (lineItem: BillingLineItem) => {
    const { label, amount, subtitle, tooltip, highlight, discountIcon = false, handleRemoveClick = null } = lineItem;
    return (
      <Stack
        direction="row"
        color={highlight ? 'success.main' : 'text.primary'}
        justifyContent="space-between"
        alignContent="center"
        sx={{ width: '100%' }}
      >
        <Stack direction="column">
          <Stack direction="row" gap="4px" alignItems="center">
            {discountIcon && <DiscountOutlined />}
            <Typography variant="body1">{label}</Typography>
            {tooltip && (
              <Tooltip title={t(tooltip)}>
                <IconButton sx={{ padding: 0 }}>
                  <InfoOutlined sx={{ width: '20px', height: '20px', color: 'primary.light' }} />
                </IconButton>
              </Tooltip>
            )}
            {handleRemoveClick && (
              <IconButton
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}
                color="success"
                onClick={handleRemoveClick}
              >
                <Close sx={{ height: '20px', width: '20px' }} color="action" />
              </IconButton>
            )}
          </Stack>
          {subtitle && (
            <Typography variant="body1" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Stack>
        {amount && <Typography sx={{ textAlign: 'right' }}>{amount}</Typography>}
      </Stack>
    );
  };

  const renderLink = (href) => {
    return (
      <Link
        href={href}
        rel="noopener"
        target="_blank"
        color="inherit"
        variant="subtitle1"
        sx={{
          '&:link': {
            color: theme.palette.text.primary,
            fontWeight: 400,
          },
        }}
      />
    );
  };

  const renderActiveUntil = (renewalDate) => {
    const dateParts = renewalDate.split('/');
    const formattedDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
    const translationKey = isDowngrade ? 'settings.billing.current_plan_active' : 'settings.billing.new_plan_renewal';
    const dateString = dayjs(formattedDate).format('MMMM DD, YYYY');
    return (
      <Typography color="text.primary" variant="caption">
        {t(translationKey, { date: dateString })}
      </Typography>
    );
  };

  if (currentBillingOption?.isCurrentPlan || currentBillingOption?.changePreview === null) {
    return null;
  }

  return (
    <BillingCard data-testid="complete-your-purchase">
      <BillingCardHeader disableTypography title="Complete your purchase" />
      {!currentBillingOption || !isBillingOptionsFetched ? (
        <Stack direction="column" gap={2} width="100%" data-testid="skeleton-complete-purchase-loading">
          <Skeleton width="100%" height="56px" variant="rounded" />
          <Skeleton width="100%" height="24px" variant="rounded" />
          <Skeleton width="100%" height="24px" variant="rounded" />
          <Divider />
          <Skeleton width="100%" height="24px" variant="rounded" />
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Skeleton width="214px" height="24px" variant="rounded" sx={{ maxWidth: '221px' }} />
            <Skeleton width="142px" height="24px" variant="rounded" sx={{ maxWidth: '221px' }} />
          </Stack>
          <Skeleton width="100%" height="24px" variant="rounded" />
          <Skeleton width="205px" height="44px" variant="rounded" sx={{ alignSelf: 'flex-end' }} />
        </Stack>
      ) : (
        <>
          <BillingCardContent>
            <PromoForm disabled={!currentBillingOption || isPaying || isLoadingPromo} setIsLoadingPromo={setIsLoadingPromo} />
            {renderLineItem({
              label: t('settings.billing.seat_price_line_months', {
                amount: formatAsPrice(currentBillingOption.changePreview.monthlySeatPrice),
                seats: currentBillingOption.changePreview.paidSeats,
                months: currentBillingOption.changePreview.termNumber,
              }),
              amount: formatAsPrice(currentBillingOption.changePreview.recurringTotal),
            })}
            {currentBillingOption.changePreview.setupFee > 0 &&
              renderLineItem({
                label: t('settings.billing.setup_fee'),
                amount: formatAsPrice(currentBillingOption.changePreview.setupFee),
              })}

            {currentBillingOption.changePreview.discountTotal > 0 &&
              renderLineItem({
                highlight: true,
                discountIcon: true,
                label: promo?.state === 'redeemable' ? promo.code.toUpperCase() : t('settings.billing.discount_text'),
                amount: formatAsPrice(-currentBillingOption.changePreview.discountTotal),
                handleRemoveClick: promo?.state === 'redeemable' ? () => setPromo(null) : undefined,
              })}

            <Divider variant="fullWidth" sx={{ width: '100%' }} />
            {renderLineItem({
              label: t('settings.billing.subtotal'),
              amount: formatAsPrice(currentBillingOption.changePreview.chargeTotal),
            })}
            <Divider variant="fullWidth" sx={{ width: '100%' }} />
            {currentBillingOption.changePreview.creditUsed > 0 &&
              renderLineItem({
                label: t('settings.billing.credit'),
                tooltip: t('settings.billing.credit_tooltip'),
                amount: formatAsPrice(-currentBillingOption.changePreview.creditUsed),
              })}
            {currentBillingOption.changePreview.proration > 0 &&
              renderLineItem({
                label: t('settings.billing.proration'),
                tooltip: t('settings.billing.proration_tooltip'),
                amount: formatAsPrice(
                  -(currentBillingOption.changePreview.chargeTotal - (currentBillingOption.changePreview.creditUsed || 0))
                ),
              })}
            <Stack direction="row" gap={3} justifyContent="space-between" sx={{ width: '100%' }}>
              <Stack direction="column">
                <Typography color="text.primary" sx={{ fontWeight: 600 }}>
                  {t('settings.billing.total_due', { date: 'today' })}
                </Typography>
                {currentBillingOption?.changePreview?.renewalDate
                  ? renderActiveUntil(currentBillingOption.changePreview.renewalDate)
                  : null}
              </Stack>
              <Stack direction="column" textAlign="right">
                <Typography variant="h5" sx={{ textAlign: 'right' }} noWrap={true}>
                  {formatAsPrice(currentBillingOption.changePreview.billedNow)} USD
                </Typography>
                <Typography variant="caption" color="text.primary">
                  {currentBillingOption.changePreview.localTaxesIncluded
                    ? t('settings.billing.taxes_included')
                    : t('settings.billing.taxes_not_included')}
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="body2" color="text.primary">
              <Trans
                i18nKey="settings.billing.billing_disclaimer"
                components={{
                  1: renderLink('https://www.proposify.com/company/terms-of-services'),
                  2: renderLink('https://www.proposify.com/company/privacy'),
                }}
              />
            </Typography>
          </BillingCardContent>

          <BillingCardActions>
            {currentPaymentMethod ? (
              <Button
                startIcon={isPaying ? <CircularProgress size="1em" /> : <Lock />}
                size="large"
                variant="contained"
                onClick={handleUpdatePlan}
                disabled={!currentBillingOption || isPaying || isLoadingPromo}
                sx={{
                  borderRadius: '100px',
                  boxShadow: 'none',
                  textTransform: 'initial',
                  fontWeight: 600,
                }}
              >
                {t('settings.billing.pay', { amount: formatAsPrice(currentBillingOption.changePreview.billedNow) })}
              </Button>
            ) : (
              <Typography variant="body2" color="error">
                {t('settings.billing.no_payment_method')}
              </Typography>
            )}
          </BillingCardActions>
        </>
      )}
    </BillingCard>
  );
};

export default CompleteYourPurchase;
