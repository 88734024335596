import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { alpha, Box, Typography } from '@mui/material';
import BaseTab, { tabClasses } from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { styled } from '@mui/system';
import IcoArchives from 'components/icon/icons/IcoArchives';
import IcoTrash from 'components/icon/icons/IcoTrash';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CINDER_URLS } from 'utils/cinder-urls';
import { FeatureFlags } from 'utils/featureFlags';
import goToCinder from 'utils/goToCinder';
import { CreateDocument } from '../components/create-document';
import { DocumentsTab } from '../documents-tab/DocumentsTab';
import { TrashTab } from '../trash-tab/TrashTab';
import { DocumentsProvider } from './DocumentsProvider';

const tabsKeys = {
  DOCUMENTS: 'documents',
  TRASH: 'trash',
  ARCHIVES: 'archives',
} as const;

function TabPanel({ children, isOpen, index }: { children?: React.ReactNode; index: string; isOpen: boolean }) {
  return (
    <Box
      role="tabpanel"
      hidden={!isOpen}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      overflow="hidden"
      width="100%"
    >
      {isOpen && children}
    </Box>
  );
}

export function Tabs() {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>(tabsKeys.DOCUMENTS);
  const featureFlags = useFeatureFlag([FeatureFlags.editorV3, FeatureFlags.documentsV3]);
  const isEditorV3Enabled = featureFlags[FeatureFlags.editorV3];
  const isDocumentsV3Enabled = featureFlags[FeatureFlags.documentsV3];

  const handleTabChange = (_: never, newValue: string) => {
    if (newValue === tabsKeys.ARCHIVES) {
      goToCinder(CINDER_URLS.archives);
      return;
    }

    setSearchParams({});
    setCurrentTab(newValue);
  };

  return (
    <DocumentsProvider>
      <Box display="flex" flexDirection="row" flexWrap="nowrap" gap={3}>
        <MuiTabs
          TabIndicatorProps={{ style: { display: 'none' } }}
          sx={{ width: '300px', overflow: 'visible' }}
          value={currentTab}
          orientation="vertical"
          onChange={handleTabChange as never}
        >
          {currentTab === tabsKeys.DOCUMENTS && <CreateDocument />}
          <Tab
            value={tabsKeys.DOCUMENTS}
            label={
              <TabLabel variant="body1">
                <InsertDriveFileOutlinedIcon />
                {t('pipeline.tabs.document_list.title')}
              </TabLabel>
            }
          />
          {isDocumentsV3Enabled && (
            <Tab
              value={tabsKeys.ARCHIVES}
              label={
                <TabLabel variant="body1">
                  <IcoArchives width={24} height={24} />
                  {t('pipeline.tabs.archives.title')}
                </TabLabel>
              }
            />
          )}
          {isEditorV3Enabled && (
            <Tab
              value={tabsKeys.TRASH}
              label={
                <TabLabel variant="body1">
                  <IcoTrash width={24} height={24} />
                  {t('pipeline.tabs.trash_document_list.title')}
                </TabLabel>
              }
            />
          )}
        </MuiTabs>
        <TabPanel index={tabsKeys.DOCUMENTS} isOpen={currentTab === tabsKeys.DOCUMENTS}>
          <DocumentsTab />
        </TabPanel>
        {isDocumentsV3Enabled && (
          <TabPanel index={tabsKeys.ARCHIVES} isOpen={currentTab === tabsKeys.ARCHIVES}>
            <></>
          </TabPanel>
        )}
        {isEditorV3Enabled && (
          <TabPanel index={tabsKeys.TRASH} isOpen={currentTab === tabsKeys.TRASH}>
            <TrashTab setCurrentTab={setCurrentTab} />
          </TabPanel>
        )}
      </Box>
    </DocumentsProvider>
  );
}

const Tab = styled(BaseTab)`
  background-color: transparent;
  border-radius: 25px;
  border: none;
  align-items: flex-start;
  height: 32px;
  min-height: 32px;
  margin-bottom: 4px;
  color: #004053;

  &:hover {
    background-color: ${alpha('#a3eeff', 0.5)};
    cursor: pointer;
  }

  &.${tabClasses.selected} {
    background-color: #a3eeff;
  }
`;

const TabLabel = styled(Typography)`
  display: flex;
  gap: 8px;
  justify-content: center;
  font-weight: 600;
  text-transform: none;
`;
