import { Box, InputLabel } from '@mui/material';
import React from 'react';
import './SecondaryMenuTitles.less';

interface SecondaryMenuSectionTitleProps {
  hasUnderLine?: boolean;
  children: React.ReactNode;
  [x: string | number | symbol]: unknown;
}
interface SecondaryMenuItemTitleProps {
  children: React.ReactNode;
  [x: string | number | symbol]: unknown;
}

export const SecondaryMenuSectionTitle: React.FC<SecondaryMenuSectionTitleProps> = ({ hasUnderLine, children, ...otherProps }) => (
  <Box className="secondary-menu--section-title" {...otherProps}>
    {children} {hasUnderLine && <hr style={{ marginBottom: 0 }} />}
  </Box>
);

export const SecondaryMenuItemTitle: React.FC<SecondaryMenuItemTitleProps> = ({ children, ...otherProps }) => (
  <>
    <InputLabel className="secondary-menu--item-title" {...otherProps}>
      {children}
    </InputLabel>
  </>
);
