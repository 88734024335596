import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import IcoShowElement from 'components/icon/icons/IcoShowElement';
import { Fragment } from 'react';

export function OverlappableAvatars({ users }: { users: string[] }) {
  const shouldOverlap = users.length > 4;

  return (
    <Box display="flex">
      {users.slice(0, 4).map((user) => (
        <Tooltip key={user} title={user}>
          <Avatar
            sx={{
              ...(shouldOverlap && {
                marginRight: '-0.5rem',
                position: 'relative',
                '&:hover': {
                  zIndex: 2,
                },
                '&:not(:last-child)': {
                  zIndex: 1,
                },
              }),
            }}
          >
            <IcoShowElement />
          </Avatar>
        </Tooltip>
      ))}
      {users.length > 4 && (
        <Tooltip
          title={users.slice(4).map((user) => (
            <Fragment key={user}>
              {user}
              <br />
            </Fragment>
          ))}
        >
          <Avatar>
            <Typography fontWeight="bold" variant="labelSmall">
              +{users.length - 4}
            </Typography>
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
}
