import { Endpoints } from '../../../utils/endpoints';
import { apiClient } from 'services/api';
import { ListingsRepository } from './ListingsRepository';
import { LocationDataEntity, StateListing, CountryListing, LocationDataApiResponse } from 'services/api/listings/interfaces';

export class ApiListingsRepository implements ListingsRepository {
  public async getLocationData(): Promise<LocationDataEntity> {
    const { countries, states } = await apiClient.get<LocationDataApiResponse>(Endpoints.getLocationData);

    const formattedCountries = new Array<CountryListing>();
    countries.forEach((country) => {
      formattedCountries.push({
        id: Number(country.id),
        name: country.name,
        abbr: country.abbreviation,
        code: country.code,
      });
    });
    const formattedStates = new Array<StateListing>();
    states.forEach((stateProv) => {
      formattedStates.push({
        id: Number(stateProv.id),
        name: stateProv.name,
        countryId: Number(stateProv.country_id),
        abbr: stateProv.abbreviation,
      });
    });
    return {
      countries: formattedCountries,
      states: formattedStates,
    };
  }

  public getStatesFromCountry(countryId: number | string, states: StateListing[], countries?: CountryListing[]): StateListing[] {
    if (typeof countryId !== 'string') {
      return states.filter((state) => state.countryId === countryId);
    } else {
      if (countries) {
        const country = countries?.find((country) => country.code === countryId);
        if (country?.id) {
          return states.filter((state) => state.countryId === country.id);
        }
      }
      return [];
    }
  }
}
