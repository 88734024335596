import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ApiEditorRepository } from '../../../../services/repositories/implementations/ApiEditorRepository';
import { VariableEntity, VariableEntityType, VariablesGroup } from '../../../../services/entities/VariablesEntity';
import { RootState } from './Store';

export interface ApiError {
  message: string;
  code: number;
}

export interface VariablesState {
  data: VariablesGroup | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: ApiError | null;
}

const initialState: VariablesState = {
  data: null,
  status: 'idle',
  error: null,
};

const editorRepository = new ApiEditorRepository();

export const setInitialVariablesState = createAsyncThunk<VariablesGroup, string, { rejectValue: ApiError }>(
  'variables/setIniVariablesState',
  async (contentId, { rejectWithValue }) => {
    try {
      const response = await editorRepository.getVariables(contentId);
      if (!response) {
        return rejectWithValue({
          message: 'No variables found',
          code: 404,
        });
      }
      return response;
    } catch (error) {
      return rejectWithValue({
        message: 'Error fetching variables',
        code: 500,
      });
    }
  }
);

const allVariableSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {
    updateVariableValue: (
      state,
      action: PayloadAction<{ type: VariableEntityType; variableName: VariableEntity['name']; newValue: VariableEntity['value'] }>
    ) => {
      const { type, variableName, newValue } = action.payload;
      const variables = state.data?.[type];
      if (!variables) return;
      const variableIndex = variables.findIndex((element) => element.name === variableName);
      if (variableIndex !== -1) {
        variables[variableIndex].value = newValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInitialVariablesState.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setInitialVariablesState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(setInitialVariablesState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as ApiError;
      });
  },
});

export const selectAllVariables = (state: RootState) => state.allVariables.data;
export const selectVariablesStatus = (state: RootState) => state.allVariables.status;

export const { updateVariableValue } = allVariableSlice.actions;

export const allVariables = allVariableSlice.reducer;
