import { ApiPlanRepository } from '../services/api/plan/ApiPlanRepository';
import { usePlanApiData } from './usePlanApiData';

type useIsUserTrialParams = {
  accountId: number;
  enabled: boolean;
};

export function useIsUserTrial({ accountId, enabled }: useIsUserTrialParams) {
  const apiPlanRepository = new ApiPlanRepository();
  const { planData, isError } = usePlanApiData({
    planRepository: apiPlanRepository,
    accountId,
    enabled: !!enabled,
  });

  if (isError || typeof planData === 'undefined') return false;
  const { isTrial } = planData;

  return isTrial;
}
