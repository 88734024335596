import './styles.less';

function Layout({ children }: { children: React.ReactNode }) {
  return <div className="app_layout">{children}</div>;
}

function SidebarNav({ children }: { children: React.ReactNode }) {
  return <aside className="app_layout__sidebar_nav">{children}</aside>;
}

function Header({ children }: { children: React.ReactNode }) {
  return <header className="app_layout__content__header">{children}</header>;
}

function Main({ children }: { children: React.ReactNode }) {
  return <main className="app_layout__content__main">{children}</main>;
}

export function Page({ sidenav, header, children }: { sidenav: React.ReactNode; header: React.ReactNode; children: React.ReactNode }) {
  const curPath = window.location.pathname;
  const customId = curPath.replace(/\W/g, '_');
  return (
    <Layout>
      {sidenav ? <SidebarNav>{sidenav}</SidebarNav> : null}
      <div id={customId} className="app_layout__content">
        {header ? <Header>{header}</Header> : null}
        <Main>{header ? <div className="app_layout__content__main--has_header">{children}</div> : children}</Main>
      </div>
    </Layout>
  );
}
