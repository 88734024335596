import * as React from 'react';
const SvgIcoSend = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m10.48 19.062 10.988-5.832a.998.998 0 0 0 0-1.765L10.497 5.642c-.828-.44-1.756.39-1.405 1.256l2.189 5.4-2.207 5.51c-.346.867.58 1.692 1.407 1.254"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.504}
      clipRule="evenodd"
    />
    <path
      d="m11.28 12.302 10.72.05M4 15.352h1.6M3 12.352h2.6M2 9.352h3.6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgIcoSend;
