import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiClientRepository } from 'services/settings/respositories/implementations/ApiClientRepository';
import { useState } from 'react';
import { ApiClient } from '../types';

const isApiClient = (obj: any): obj is ApiClient => {
  return (
    'identifier' in obj &&
    'name' in obj &&
    Array.isArray(obj.redirectUri) &&
    typeof obj.identifier === 'string' &&
    typeof obj.name === 'string'
  );
};
export const useAPIClient = () => {
  const [apiClients, setApiClients] = useState<ApiClient[]>([]);
  const { isLoading, isError } = useQuery(QueryKeys.getAPIClients, ApiClientRepository.getAPIClients, {
    onSuccess: (data) => {
      if (Array.isArray(data) && data.every(isApiClient)) {
        setApiClients(data as ApiClient[]);
      }
    },
  });
  const addNewApiClient = (newData: ApiClient) => {
    setApiClients((prevClients) => [newData, ...prevClients]);
  };
  return { isApiClientLoading: isLoading, apiClients, addNewApiClient, isError };
};
