import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AccessAllowance } from 'services/settings/entities/Workspaces';
import {
  getAccessAllowanceListBySubdomain,
  deleteAccessAllowance,
} from 'services/settings/respositories/implementations/ApiAccessAllowanceRepository';
import { QueryKeys } from 'utils/queryKeys';

type Props = {
  subdomain: string;
  shouldLoad: boolean;
};

export const useAccountAccessQuery = ({ subdomain, shouldLoad }: Props) => {
  const queryClient = useQueryClient();

  const query = useQuery<AccessAllowance[]>(QueryKeys.accountAccess, () => getAccessAllowanceListBySubdomain(subdomain), {
    enabled: shouldLoad,
  });

  const mutation = useMutation({
    mutationFn: async (newList: AccessAllowance[]) => {
      queryClient.setQueryData(QueryKeys.accountAccess, newList);
    },
  });

  return {
    accessAllowanceList: query.data,
    setAccessAllowanceList: mutation.mutateAsync,
    isLoadingAccessAllowanceList: query.isLoading,
  };
};

export const useAccountAccessDelete = () => {
  const mutation = useMutation({
    mutationFn: (uuid: string) => deleteAccessAllowance(uuid),
  });
  return {
    deleteAccessAllowance: mutation.mutateAsync,
    isLoadingDeleteAccessAllowance: mutation.isLoading,
  };
};
