import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: '2px',
    padding: '8px',
    boxShadow: 'none',
    borderColor: state.isFocused ? '#000' : baseStyles.borderColor,
    '&:hover': {
      borderColor: '#000',
    },
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isSelected ? '#004053' : baseStyles.backgroundColor,
  }),
};

export interface OptionProp {
  value: string;
  label: string;
  name?: string;
  email?: string;
}

interface Props {
  options: OptionProp[];
  onChange: (selectedOption) => void;
  className?: string;
  disabled?: boolean;
  defaultValue?: OptionProp;
  value?: OptionProp | null;
}

export const DropdownSelect: React.FC<Props> = ({ value, options, onChange, className, disabled, defaultValue }) => {
  return (
    <>
      <Select
        data-testid="dropdown-select"
        options={options}
        isClearable
        styles={customStyles}
        onChange={onChange}
        className={className}
        isDisabled={disabled}
        defaultValue={defaultValue}
        value={value}
      />
    </>
  );
};
