import { createContext, useContext, useRef } from 'react';
import SocketClient from 'services/socket/SocketClient';
import { AuthCookieService } from '../services/cookie';
import {
  DefaultSocketConnectionErrorResponseType,
  SocketClientDefaultEvents,
  SocketConnectionErrorTypes,
  SocketConnectionEvents,
} from '../services/socket/SocketEvents';

type SocketProviderType = {
  children: React.ReactElement;
  accessToken: string;
  contentId: string;
};

type SocketContextType = {
  socketClient: SocketClient;
  connect: () => void;
};
const SocketContext = createContext<SocketContextType | undefined>(undefined);

export function useSocketClient(): SocketContextType {
  const socketContext = useContext(SocketContext);
  if (socketContext === undefined) {
    throw new Error('useSocketClient must be used within a SocketProvider');
  }
  return { ...socketContext };
}

export function SocketProvider({ children, accessToken, contentId }: SocketProviderType) {
  const editorUrl = process.env.REACT_APP_EDITOR_SERVER as string;
  const refAccessToken = useRef<string>(accessToken);
  const refSocketClient = useRef<SocketClient>(new SocketClient(editorUrl, { contentId, accessToken: accessToken }));

  const reconnectWithNewAccessToken = () => {
    const updatedAccessToken = AuthCookieService.getAccessToken();
    if (!updatedAccessToken) {
      return false;
    }

    refAccessToken.current = updatedAccessToken;

    refSocketClient.current.reconnectWithNewToken(updatedAccessToken);
    return true;
  };

  const subscribeToDefaultErrorHandlingEvents = () => {
    const socketClient = refSocketClient.current;

    socketClient.subscribeOnce(SocketClientDefaultEvents.CONNECTION_ERROR, function ({ data }: DefaultSocketConnectionErrorResponseType) {
      if (data && data.type === SocketConnectionErrorTypes.TOKEN_EXPIRED) {
        reconnectWithNewAccessToken();
      }
    });

    socketClient.subscribe(SocketConnectionEvents.TOKEN_EXPIRED, () => reconnectWithNewAccessToken());
  };

  const connect = () => {
    const socketClient = refSocketClient.current;
    subscribeToDefaultErrorHandlingEvents();
    socketClient.connect();
  };

  return <SocketContext.Provider value={{ socketClient: refSocketClient.current, connect }}>{children}</SocketContext.Provider>;
}
