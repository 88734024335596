import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import dayjs from 'dayjs';
import { BillingEntity, UpdateBillingPayload } from 'services/api/account/interfaces';

export type PaymentMethodFormFieldValues = {
  cardholderName: string;
  type: string;
  expiryDate: string;
  securityCode: string;
  ccNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export type SubmitHandler = SubmitHandlerT<PaymentMethodFormFieldValues>;

export const transformPaymentMethodFieldValuesToApiPayload = ({
  cardholderName,
  type,
  expiryDate,
  securityCode,
  ccNumber,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  country,
}: PaymentMethodFormFieldValues): UpdateBillingPayload => {
  const dateParts = expiryDate.split('/');
  const curYearStart = dayjs().format('YYYY').substring(0, 2);
  return {
    cardInfo: {
      cardholderName: cardholderName,
      type: type,
      expiryMonth: Number(dateParts[0]),
      expiryYear: Number(`${curYearStart}${dateParts[1]}`),
      securityCode: Number(securityCode),
      ccNumber: ccNumber.charAt(0) === '*' ? ccNumber : Number(ccNumber.replaceAll(' ', '')),
    },
    billingAddress: {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      country: country,
    },
  };
};

export const getDefaultValues = (billingData: BillingEntity | undefined) => {
  return {
    cardholderName: billingData ? `${billingData.firstName} ${billingData.lastName}` : '',
    type: '',
    expiryDate: billingData
      ? `${billingData.expiryMonth.toString().padStart(2, '0')}/${billingData.expiryYear.toString().substring(2).padStart(2, '0')}`
      : '',
    securityCode: '',
    ccNumber: billingData?.lastFour ? `**** **** **** ${billingData.lastFour}` : '',
    addressLine1: billingData?.address1 || '',
    addressLine2: billingData?.address2 || '',
    city: billingData?.city || '',
    state: billingData?.state || 'AZ',
    zip: billingData?.zip || '',
    country: billingData?.country || '',
  };
};

export function usePaymentMethodForm(loadedValues?: PaymentMethodFormFieldValues) {
  return useForm<PaymentMethodFormFieldValues>({
    defaultValues: {
      cardholderName: '',
      expiryDate: '',
      securityCode: '',
      ccNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    mode: 'onTouched',
    values: loadedValues || undefined,
  });
}
