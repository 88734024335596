import { alpha, Box, styled, Tooltip, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabsList from '@mui/material/Tabs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Activities } from './Activities';

const tabsKeys = {
  ACTIVITY: 'activity',
  ANALYTICS: 'analytics',
} as const;

export function InsightsShrunkContent() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>(tabsKeys.ACTIVITY);

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" gap={3} padding="0rem 1.5rem 3.375rem 1.5rem">
      <Tabs
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        value={currentTab}
        onChange={(_, newValue) => setCurrentTab(newValue)}
      >
        <Tab
          data-testid="insights-tabs-activity"
          sx={{ flex: 1 }}
          value={tabsKeys.ACTIVITY}
          label={<TabLabel variant="body1">{t('pipeline.insights.tab_activity')}</TabLabel>}
        />
        <AnalyticsDisabledTab tooltipText={t('pipeline.insights.tab_analytics_tooltip')} label={t('pipeline.insights.tab_analytics')} />
      </Tabs>
      <Box role="tabpanel" hidden={currentTab !== tabsKeys.ACTIVITY} aria-labelledby="insights-tabs-activity">
        <Activities />
      </Box>
      <Box role="tabpanel" hidden={currentTab !== tabsKeys.ANALYTICS} aria-labelledby="insights-tabs-analytics">
        <></>
      </Box>
    </Box>
  );
}

const Tabs = styled(TabsList)(({ theme }) => ({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderColor: alpha(theme.palette.secondary.main, 0.3),
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '0.2rem',
  },
  '& .MuiTabs-indicatorSpan': {
    flex: '0 0 4rem',
    backgroundColor: theme.palette.secondary.main,
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
  },
  '& .MuiTab-root': {
    maxWidth: 'none',
  },
}));

const TabLabel = styled(Typography)({
  fontWeight: 600,
  textTransform: 'none',
  fontFeatureSettings: "'liga' off, 'clig' off",
});

// workaround to make tooltip work on disabled tab, remove when tab will be clickable
function AnalyticsDisabledTab({ tooltipText, label }: { tooltipText: string; label: string }) {
  const [open, setOpen] = useState(false);
  return (
    <Tab
      data-testid="insights-tabs-analytics"
      disabled
      sx={{ flex: 1 }}
      value={tabsKeys.ANALYTICS}
      label={
        <Tooltip open={open} title={tooltipText}>
          <Box sx={{ pointerEvents: 'all' }} onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <TabLabel variant="body1">{label}</TabLabel>
          </Box>
        </Tooltip>
      }
    />
  );
}
