import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import goToCinder from 'utils/goToCinder';
import { useNavigate } from 'react-router-dom';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { Alert, Button, Skeleton, Stack, Typography } from '@mui/material';
import { getPlanTier } from 'services/api/plan/utils';
import { formatAsPrice } from '../utils/utils';
import { BillingCard, BillingCardContent } from '../utils/styled';
import BillingCardHeader, { BillingCardHeaderAction } from '../utils/BillingCardHeader';

const SubscriptionSummaryBlock = ({ title, value, extraData }: { title: string; value: string; extraData?: string | null }) => (
  <Stack direction="column" gap={0} flexGrow={{ xs: 1, sm: 0 }} width={{ xs: '40%', sm: 'auto' }}>
    <Typography variant="body2" color="text.secondary" mb={2}>
      {title}
    </Typography>
    <Typography variant="h6" color="text.primary" fontWeight="600">
      {value}
    </Typography>
    {extraData && (
      <Typography variant="body1" color="text.primary">
        {extraData}
      </Typography>
    )}
  </Stack>
);

const SubscriptionSummary = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { planData, subscriptionData, isLegacyPlan } = useContext(PlanSummaryContext);

  const headerActions: BillingCardHeaderAction[] = useMemo(() => {
    if (!subscriptionData || !planData) {
      return [];
    }

    const planTier = planData ? getPlanTier(planData) : '';
    const newActions: BillingCardHeaderAction[] = [];
    if (subscriptionData.state === 'active') {
      newActions.push({
        label: t('settings.summary.cancel_subscription'),
        onClick: () => goToCinder('/settings/cancel'),
        variant: 'text',
        color: 'primary',
        dataTestId: 'subscription-summary-cancel',
      });

      if (planTier !== 'business' && planTier !== 'employee') {
        newActions.push({
          color: 'primary',
          label: t('settings.summary.upgrade_subscription'),
          onClick: () => navigate('/settings/plan'),
          dataTestId: 'subscription-summary-upgrade',
        });
      }
    } else if (subscriptionData.state === 'cancelled') {
      newActions.push({
        label: t('settings.summary.reactivate_plan'),
        onClick: () => goToCinder('settings/enable_auto_renew'),
        variant: 'contained',
        color: 'primary',
        dataTestId: 'subscription-summary-reactivate',
      });
    }

    if (!planData.isTrial && getPlanTier(planData) !== 'free') {
      newActions.push({
        label: t('settings.summary.manage_subscription'),
        onClick: () => navigate(`/settings/billing/${planTier.charAt(0).toUpperCase() + planTier.slice(1)}`),
      });
    }
    return newActions;
  }, [subscriptionData, planData]);

  const renderSubscriptionSummary = useCallback(() => {
    if (!planData || !subscriptionData) {
      return <Skeleton variant="rounded" width="100%" height="88px" />;
    }

    if (subscriptionData.state === 'cancelled') {
      const closeDateObj = subscriptionData.currentPeriodEndsAt;
      const closeDate = closeDateObj?.date
        ? `on ${dayjs(closeDateObj.date).format('MMMM D, YYYY')}`
        : t('settings.summary.end_of_current_period');
      return (
        <Stack direction="column">
          <Typography variant="body1">{t('settings.summary.plan_cancelled', { planName: planData.name })}</Typography>
          <Typography variant="body1" color="error">
            {t('settings.summary.plan_close_date', { closeDate: closeDate })}
          </Typography>
        </Stack>
      );
    }

    return (
      <Stack direction="row" gap={{ xs: 3, sm: 9 }} flexWrap={{ xs: 'wrap', sm: 'nowrap' }} width="100%">
        <SubscriptionSummaryBlock
          title={t('settings.summary.plan')}
          value={planData.name || ''}
          extraData={
            t('settings.summary.renews', {
              billingInterval: `${planData?.billingInterval.valueOf().toLowerCase()}`,
            }) || ''
          }
        />
        {subscriptionData.autoRenew && (
          <SubscriptionSummaryBlock
            title={t('settings.summary.next_payment')}
            value={formatAsPrice(planData.estimatedRenewalTotal)}
            extraData={t('settings.summary.due', { dueDate: dayjs(subscriptionData.currentPeriodEndsAt?.date).format('MMMM D, YYYY') })}
          />
        )}
        {/* !planData.isTrial && <SubscriptionSummaryBlock title={t('settings.summary.available_credit')} value={formatAsPrice(0, true)} /> */}
        {planData.seats_taken !== null && (
          <SubscriptionSummaryBlock title={t('settings.summary.seats')} value={planData.seats_taken.toString()} />
        )}
      </Stack>
    );
  }, [planData, subscriptionData]);

  return (
    <BillingCard data-testid="subscription-summary">
      <BillingCardHeader title={t('settings.summary.your_subscription')} actions={headerActions} />
      <BillingCardContent>
        {renderSubscriptionSummary()}
        {isLegacyPlan && (
          <Alert
            severity="info"
            sx={{ width: '100%' }}
            action={
              <Button
                data-testid="subscription-summary-explore"
                variant="text"
                color="inherit"
                size="small"
                onClick={() => navigate('/settings/plan')}
              >
                {t('settings.summary.explore_plans')}
              </Button>
            }
          >
            <Typography variant="body2">{t('settings.summary.legacy_plan_upgrade')}</Typography>
          </Alert>
        )}
      </BillingCardContent>
    </BillingCard>
  );
};

export default SubscriptionSummary;
