import { Box, Paper } from '@mui/material';
import PageHeader from './blocks/page-header';
import { useAPIClient } from './blocks/useAPIClient';
import { Loading } from 'components';
import { ApiClient } from './types';
import { openNotification } from 'components';
import { ApiClientsDataGrid } from './blocks/datagrid-api-clients/datagrid-api-clients';
import { useTranslation } from 'react-i18next';

export const ConnectAPIPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { isApiClientLoading, apiClients, addNewApiClient, isError } = useAPIClient();

  if (isError) {
    openNotification({
      type: 'error',
      title: t('settings.connect_api.get_api_client_error_label'),
      description: '',
      placement: 'topRight',
    });
  }

  const handleNewData = (newData: ApiClient) => {
    addNewApiClient(newData);
  };

  if (isApiClientLoading) {
    return (
      <Box padding={2} data-testid="loading-container">
        <Loading isLoading={isApiClientLoading} />
      </Box>
    );
  }

  return (
    <Paper elevation={1} sx={{ marginLeft: '52px', borderRadius: '8px', padding: 2 }}>
      <PageHeader onNewData={handleNewData} />
      <ApiClientsDataGrid data={apiClients as ApiClient[]} />
    </Paper>
  );
};
