import { Heading, Icon, Paragraph } from 'components';
import { useTranslation } from 'react-i18next';

export const SearchEmptyResult = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="searchEmptyResult">
      <Icon name="IcoScreenSearchDesktopOutline" />
      <Heading level={3}>{t('pipeline.tabs.search.emptyResultHeading')}</Heading>
      <Paragraph size="md">{t('pipeline.tabs.search.emptyResultDescription')}</Paragraph>
    </div>
  );
};
