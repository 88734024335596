import { apiClient } from 'services/api';
import { AccessAllowance } from 'services/settings/entities/Workspaces';
import { Endpoints } from 'utils/endpoints';

type ApiReturn = {
  allowances: AccessAllowance[];
};

export async function getAccessAllowanceListBySubdomain(subdomain: string): Promise<AccessAllowance[]> {
  const data = await apiClient.get<ApiReturn>(Endpoints.getAccessAllowanceBySubdomain(subdomain));
  return data.allowances;
}

export async function deleteAccessAllowance(uuid: string): Promise<void> {
  await apiClient.delete(Endpoints.deleteAccessAllowanceBySubdomain(uuid));
}
