import React from 'react';
import { useSectionHeader } from '../../hooks/useSectionHeader';
import { SubmenuItems } from '../tabbednavigation/interface';
import { MobileSectionHeaderProps } from './interface';
import TabbedNavigation from '../tabbednavigation';
import SectionTitle from '../sectiontitle';
import './styles.less';

const MobileSectionHeader: React.FC<MobileSectionHeaderProps> = ({ title, icon, submenuItems }) => {
  const pageTitleItem: SubmenuItems = [{ title: title, items: submenuItems, icon: icon }];
  const hasSubmenuItems = submenuItems != undefined && submenuItems.length > 0;
  const { shouldShowSectionHeader, shouldShowTopBar } = useSectionHeader();

  return (
    <>
      {hasSubmenuItems ? (
        <div role="menubar">
          <TabbedNavigation mode="inline" items={pageTitleItem} icon={icon} />
        </div>
      ) : (
        shouldShowSectionHeader() && shouldShowTopBar() && <SectionTitle icon={icon}>{title}</SectionTitle>
      )}
    </>
  );
};

export default MobileSectionHeader;
