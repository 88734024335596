import { gridPageMaxWidthInPixels } from '../../shared/gridConfig';
import './ContentClipper.less';
import { useSectionData } from '../../Sections/useSectionContext';
interface ContentClipperProps {
  blockId: string;
}
export default function ContentClipper({ blockId }: ContentClipperProps) {
  const { blocksContent } = useSectionData();
  const {
    blockConfig: { x: xAxis, y: yAxis, width },
  } = blocksContent[blockId];

  const rightOverflown = width + xAxis - gridPageMaxWidthInPixels;
  const widthToHide = rightOverflown < 0 ? 0 : rightOverflown;
  const leftPosition = gridPageMaxWidthInPixels - xAxis;

  const topOverflown = yAxis;
  const heightToHideTop = topOverflown < 0 ? -topOverflown : 0;

  const leftOverflown = xAxis;
  const widthToHideLeft = leftOverflown < 0 ? -leftOverflown : 0;

  return (
    <>
      <div
        className="right-clipper"
        style={{
          left: leftPosition,
          width: widthToHide,
        }}
      />
      <div
        className="top-clipper"
        style={{
          height: heightToHideTop,
        }}
      />
      <div
        className="left-clipper"
        style={{
          width: widthToHideLeft,
        }}
      />
    </>
  );
}
