import { useParams } from 'react-router-dom';
import { ProposalCopyLink, ProposalShareForm, ProposalShareHeader, OptOut } from './blocks';
import './styles.less';
import CinderRedirect from 'components/cinderredirect';
import { useProposalByIdQuery } from 'hooks/useProposalByIdQuery';
import PageLoader from 'components/page-loader/page-loader';

export type Params = {
  proposalId: string;
};

export function ProposalSharePage() {
  const { proposalId: id } = useParams<keyof Params>() as Params;
  const proposalId = parseInt(id, 10);
  const { data: proposalData, status } = useProposalByIdQuery(proposalId);

  switch (status) {
    case 'error':
      return <CinderRedirect page="/dashboard" />;
    case 'success':
      return (
        <div className="proposal-send-page">
          <section className="proposal-send-page__container">
            <ProposalShareHeader subHeading={proposalData.clientName} />
            <ProposalShareForm proposalData={proposalData} />
            <ProposalCopyLink proposalData={proposalData} />
          </section>

          <OptOut proposalId={proposalId} />
        </div>
      );
    case 'idle':
    case 'loading':
    default:
      return <PageLoader isLoading={true} isLoaded={Boolean(proposalData)} />;
  }
}
