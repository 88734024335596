import { useAppDispatch } from '../grid/reduxStore/Store';
import { BlocksContentCollection, updateGridLayerConfig } from '../grid/reduxStore/editorSlice';
import { saveLayerWithSocket } from '../grid/reduxStore/saveHandlers';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { BlockConfig } from './models/BlockConfig.model';
import { useSectionId } from '../Sections/SectionIdProvider';

type GridBlockLayerChangeHandler = (gridBlockId: string, zIndex: number) => void;

type BlockCoordinates = {
  topX: number;
  topY: number;
  bottomX: number;
  bottomY: number;
};
const blocksOverlaps = (firstBlockCoordinates: BlockCoordinates, secondBlockCoordinates: BlockCoordinates): boolean => {
  return (
    firstBlockCoordinates.topX < secondBlockCoordinates.bottomX &&
    firstBlockCoordinates.bottomX > secondBlockCoordinates.topX &&
    firstBlockCoordinates.topY < secondBlockCoordinates.bottomY &&
    firstBlockCoordinates.bottomY > secondBlockCoordinates.topY
  );
};
const getBlockCoordinates = (blockConfig: BlockConfig): BlockCoordinates => {
  const { x: topX, y: topY, width, height } = blockConfig;

  return { topX: topX, topY: topY, bottomX: topX + width, bottomY: topY + height };
};
const isCurrentBlockInNextTargetLayer = (
  currentBlockZIndex: number,
  selectedBlockZIndex: number,
  targetZIndex: number,
  targetLayer: TargetLayer
) => {
  if (targetLayer == TargetLayer.Forward) {
    return currentBlockZIndex > selectedBlockZIndex && currentBlockZIndex < targetZIndex;
  }
  //TargetLayer.Backward
  return currentBlockZIndex < selectedBlockZIndex && currentBlockZIndex > targetZIndex;
};
enum TargetOuterLayer {
  Front = -1,
  Back = 1,
}
enum TargetLayer {
  Forward = 1,
  Backward = -1,
}
export default function useGridBlockLayer() {
  const dispatch = useAppDispatch();
  const { data: currentUser } = useCurrentUser();
  const { socketClient } = useSocketClient();
  const sectionId = useSectionId();
  const swapBlockLayers = (selectedBlockId: string, targetZIndex: number, targetBlockId: string, selectedBlockZIndex: number) => {
    gridBlockLayerChangeHandler(selectedBlockId, targetZIndex);
    gridBlockLayerChangeHandler(targetBlockId, selectedBlockZIndex);
  };
  const moveToOuterLayer = (selectedBlockZIndex: number, blockId: string, zIndexAvailable: number, targetOuterLayer: TargetOuterLayer) => {
    const isSelectedBlockInAnOuterLayer = selectedBlockZIndex == zIndexAvailable + targetOuterLayer;
    if (!isSelectedBlockInAnOuterLayer) {
      gridBlockLayerChangeHandler(blockId, zIndexAvailable);
    }
  };
  const bringGridToFront = (blockConfig: BlockConfig, nextZIndexAvailable: number) => {
    const { z: myZIndex, id: blockId } = blockConfig;
    moveToOuterLayer(myZIndex, blockId, nextZIndexAvailable, TargetOuterLayer.Front);
  };
  const sendGridToBack = (blockConfig: BlockConfig, lowerZIndexAvailable: number) => {
    const { z: myZIndex, id: blockId } = blockConfig;
    moveToOuterLayer(myZIndex, blockId, lowerZIndexAvailable, TargetOuterLayer.Back);
  };
  const moveToLayer = (blockConfig: BlockConfig, allBlocksContent: BlocksContentCollection, targetLayer: TargetLayer) => {
    const { z: selectedBlockZIndex, id: selectedBlockId } = blockConfig;
    const myBlockCoordinates = getBlockCoordinates(blockConfig);

    let targetBlockId: string | null = null;
    let targetZIndex = Infinity * targetLayer;

    for (const currentBlockId in allBlocksContent) {
      if (currentBlockId === selectedBlockId) {
        continue;
      }

      const currentBlockCoordinates = getBlockCoordinates(allBlocksContent[currentBlockId].blockConfig);
      const { z: currentBlockZIndex } = allBlocksContent[currentBlockId].blockConfig;

      if (
        isCurrentBlockInNextTargetLayer(currentBlockZIndex, selectedBlockZIndex, targetZIndex, targetLayer) &&
        blocksOverlaps(myBlockCoordinates, currentBlockCoordinates)
      ) {
        targetZIndex = currentBlockZIndex;
        targetBlockId = currentBlockId;
      }
    }

    if (targetBlockId) {
      swapBlockLayers(selectedBlockId, targetZIndex, targetBlockId, selectedBlockZIndex);
    }
  };
  const bringGridForward = (selectedBlockConfig: BlockConfig, allBlocksContent: BlocksContentCollection) => {
    moveToLayer(selectedBlockConfig, allBlocksContent, TargetLayer.Forward);
  };
  const sendGridBackward = (selectedBlockConfig: BlockConfig, allBlocksContent: BlocksContentCollection) => {
    moveToLayer(selectedBlockConfig, allBlocksContent, TargetLayer.Backward);
  };
  const gridBlockLayerChangeHandler: GridBlockLayerChangeHandler = (gridBlockId, zIndex) => {
    const payload = {
      sectionId: sectionId,
      blockId: gridBlockId,
      zIndex,
    };

    dispatch(updateGridLayerConfig(payload));
    dispatch(
      saveLayerWithSocket({
        gridBlockId,
        zIndex,
        userId: currentUser.id,
        socketClient,
      })
    );
  };

  return { bringGridToFront, bringGridForward, sendGridBackward, sendGridToBack };
}
