import { FormValidationResult, FormValues } from './interface';

const validateFormValues = (formValues: FormValues): FormValidationResult => {
  const documentName = (formValues.documentName ?? '').length < 1;
  const documentDueDate = (formValues.documentDueDate ?? '').length < 1;

  return {
    hasError: documentName || documentDueDate,
    errors: { documentName, documentDueDate },
  };
};

const utils = {
  validateFormValues,
};

export default utils;
