import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiUserIntegrationRepository } from 'services/integrations/repositories/implementations/ApiUserIntegrationRepository';
import SectionHeader from 'components/sectionheader';
import Sidebar from 'components/sidebar';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Alias from 'pages/alias';
import { Page } from 'components/page';

const apiUserIntegrationsRepository = new ApiUserIntegrationRepository();

const PageTemplateWrapper: React.FC = () => {
  const { isSmallerThanDesktop } = useMediaQuery();

  return (
    <Page
      sidenav={!isSmallerThanDesktop && <Sidebar mode="fixed" />}
      header={
        <>
          <Alias />
          {isSmallerThanDesktop && <Sidebar mode="collapsible" />}
          <SectionHeader title="Proposify" userIntegrationRepository={apiUserIntegrationsRepository} />
        </>
      }
    >
      <Outlet />
    </Page>
  );
};

export default PageTemplateWrapper;
