import React from 'react';
import './styles.less';

interface Props {
  name: string;
  thumbnail: string;
  id: number;
  selectPresetTemplate: (templateId: number) => void;
}
export const TemplateCard: React.FC<Props> = ({ name, thumbnail, id, selectPresetTemplate }) => {
  return (
    <div className="template-pill__container" onClick={() => selectPresetTemplate(id)}>
      <div className="template-pill__body">
        <img src={thumbnail} data-testid="template-card-image" />
      </div>
      <div className="template-pill__footer">{name}</div>
    </div>
  );
};
