import React from 'react';
import { Props } from './interface';
import './styles.less';
import { Col, Image, Row } from 'antd';
import { Button, Heading, Icon, Paragraph } from '../index';

const MarketplaceListCard: React.FunctionComponent<Props> = ({
  content,
  logo,
  integrationTitle,
  category,
  isPremium,
  isIntegrationConnected = false,
  isPrimary = false,
  href,
}) => {
  return (
    <Row className="marketplace-listcard__container" onClick={() => location.assign(href)}>
      <Row justify="space-between" className="marketplace-listcard__header marketplace-listcard--wrap-reverse">
        <Col>
          <Row gutter={20} className="marketplace-listcard--wrap-reverse">
            <Col>
              <Heading level={4}>{integrationTitle}</Heading>
              <span className="marketplace-listcard__header__category ant-typography proposify-typography default-font">{category}</span>
            </Col>

            {isPremium && (
              <Col>
                <span className="ant-typography proposify-typography default-font marketplace-listcard__header__premium">Premium</span>
              </Col>
            )}
          </Row>
        </Col>

        <Col className="marketplace-listcard--margin-left-auto">
          <Image src={logo} preview={false} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Paragraph size="md">{content}</Paragraph>
        </Col>

        <Col className="marketplace-listcard--margin-left-auto marketplace-listcard__button">
          {isIntegrationConnected ? (
            <Button type={isPrimary ? 'primary' : 'default'} variant="positive" icon={<Icon name="IcoIntegrations" />}>
              Manage Integration
            </Button>
          ) : (
            <Button type={isPrimary ? 'primary' : 'default'} variant="positive">
              Learn More
            </Button>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default MarketplaceListCard;
