import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Alert, Button, Stack, Typography } from '@mui/material';

import { PlanSummaryContext } from './PlanSummaryProvider';
import { useNavigate } from 'react-router-dom';

const PlanSummaryAlerts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subscriptionData, planData, isPlanFetched } = useContext(PlanSummaryContext);

  const yourPlanName = useMemo(() => (planData?.name ? `${planData.name} plan` : 'plan'), [planData?.name]);

  const planEndDate = useMemo(() => {
    if (!subscriptionData?.currentPeriodEndsAt?.date) {
      return 'soon';
    }
    const daysRemaining = Number(dayjs(subscriptionData.currentPeriodEndsAt.date).diff(dayjs(), 'day'));
    return `in ${daysRemaining} day${daysRemaining > 1 ? 's' : ''}`;
  }, [subscriptionData?.currentPeriodEndsAt]);

  return (
    <Stack data-testid="summary-page-alerts" direction="column" gap={2} my={2}>
      {isPlanFetched && subscriptionData?.state === 'cancelled' && (
        <Alert
          severity="info"
          sx={{ width: '100%' }}
          action={
            <Button variant="text" color="inherit" size="small" onClick={() => navigate(`/settings/billing/${planData?.name || ''}`)}>
              {t('settings.summary.reactivate_plan')}
            </Button>
          }
        >
          <Typography variant="body2">
            {t('settings.summary.alerts.plan_ends_at', { planName: yourPlanName, planEndDate: planEndDate })}
          </Typography>
        </Alert>
      )}
    </Stack>
  );
};

export default PlanSummaryAlerts;
