import { Button, Heading, Icon, openNotification } from 'components';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useTranslation } from 'react-i18next';
import { isIframe } from 'utils/isIframe';
import { CopyLinkInput } from './copy-link-input';
import { StatusSwitch } from './status-switch';
import './styles.less';

type Props = {
  url?: string;
  isLoading?: boolean;
  showStatusSwitch?: boolean;
  onSuccess?: () => void;
};

export const CopyLink = ({ url, isLoading = false, showStatusSwitch = false, onSuccess }: Props) => {
  const { t } = useTranslation();
  const [, setCopy] = useCopyToClipboard();

  const handleClickToCopy = async () => {
    if (!url) return;
    const success = await setCopy(url);
    if (success) {
      openNotification({
        title: t('share.copy_link.notification.success.title'),
        description: '',
        type: 'success',
        placement: 'bottom',
      });
      onSuccess?.();
    }
  };

  return (
    <section className="copy-link">
      <div className="copy-link__info">
        <Heading level={5}>{t('share.mark_as_sent_description')}</Heading>
      </div>
      <div className="copy-link__action">
        {isIframe() ? (
          <>
            <CopyLinkInput value={url} />
            {showStatusSwitch && <StatusSwitch />}
          </>
        ) : (
          <>
            {showStatusSwitch && <StatusSwitch />}
            <Button
              type="default"
              variant="positive"
              className="copy-link__button"
              icon={<Icon name="IcoLink" title={t('share.copy_link.button')} />}
              onClick={handleClickToCopy}
              disabled={isLoading || !url}
              data-testid="copy-link-button"
            >
              {t(isLoading ? 'loading' : 'share.copy_link.button')}
            </Button>
          </>
        )}
      </div>
    </section>
  );
};
