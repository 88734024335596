import * as React from 'react';
import { Props } from './interface';
import { Button as BaseButton } from 'antd';
import './styles.less';

const Button: React.FunctionComponent<Props> = ({
  size = 'middle',
  className = '',
  href,
  target,
  children,
  id,
  variant,
  type = 'primary',
  disabled = false,
  onClick,
  icon,
  htmlType,
  ...restProps
}) => {
  return (
    <BaseButton
      data-testid="buttonBase"
      {...restProps}
      id={id}
      className={`py-${variant} ${className} ${type}`}
      href={href}
      target={target}
      onClick={onClick}
      icon={icon}
      size={size}
      disabled={disabled}
      htmlType={htmlType}
    >
      {children}
    </BaseButton>
  );
};

export default Button;
