interface featureGroupProps {
  brand_customization?: planFeature[];
  content_authoring?: planFeature[];
  visibility?: planFeature[];
  process_control?: planFeature[];
  success?: planFeature[];
}

type planFeature = {
  name: string;
  basic?: boolean;
  team?: boolean;
  business?: boolean;
  isNonInclusive: boolean;
  tooltip?: string;
  basicText?: string;
  teamText?: string;
  businessText?: string;
};

const basicTopFeatures = [
  'settings.plan.basic.feature_1',
  'settings.plan.basic.feature_2',
  'settings.plan.basic.feature_3',
  'settings.plan.basic.feature_4',
  'settings.plan.basic.feature_5',
  'settings.plan.basic.feature_6',
];

const teamTopFeatures = [
  'settings.plan.team.feature_1',
  'settings.plan.team.feature_2',
  'settings.plan.team.feature_3',
  'settings.plan.team.feature_4',
  'settings.plan.team.feature_5',
];

const businessTopFeatures = [
  'settings.plan.business.feature_1',
  'settings.plan.business.feature_2',
  'settings.plan.business.feature_3',
  'settings.plan.business.feature_4',
];

const allPlanFeatures = {
  brand_customization: [
    {
      name: 'settings.plan.features.content_library',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.unlimited_templates',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.embed_images_and_videos',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.custom_domain',
      basic: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
  ],
  content_authoring: [
    {
      name: 'settings.plan.features.online_signatures',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.interactive_quoting',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.client_input_forms',
      basic: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
  ],
  visibility: [
    {
      name: 'settings.plan.features.document_sends',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
      basicText: 'settings.plan.basic.document_sends',
      teamText: 'settings.plan.team.document_sends',
      businessText: 'settings.plan.business.document_sends',
      tooltip: 'settings.plan.features.document_sends_tooltip',
    },
    {
      name: 'settings.plan.features.notifications_metrics',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.pdf_export',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.reports',
      basic: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
  ],
  process_control: [
    {
      name: 'settings.plan.features.payments',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.auto_reminders',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.custom_fields_variables',
      basic: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.standard_integrations',
      basic: false,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.roles_and_permissions',
      basic: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.approval_workflows',
      basic: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.salesforce_integration',
      basic: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.salesforce_sso',
      basic: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.workspaces',
      basic: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
  ],
  success: [
    {
      name: 'settings.plan.features.email_chat_support',
      basic: true,
      team: true,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.phone_zoom_support',
      basic: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
    {
      name: 'settings.plan.features.success_manager',
      basic: false,
      team: false,
      business: true,
      isNonInclusive: false,
    },
  ],
} as featureGroupProps;

const planChips = {
  basic: null,
  team: 'settings.plan.most_popular',
  business: null,
};

export { basicTopFeatures, teamTopFeatures, businessTopFeatures, allPlanFeatures, planChips };
