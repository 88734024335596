import { useMutation } from 'react-query';
import { ApiAccountRepository } from 'services/api/account/ApiAccountRepository';
import { UpdatePaymentAccountApiResponse, PaymentAccountEntity } from 'services/api/account/interfaces';

type UpdatePaymentAccountParam = {
  accountId: number;
  payload: PaymentAccountEntity;
};

export default function useUpdatePaymentAccountMutation() {
  const reactQuery = useMutation({
    mutationFn: updatePaymentAccount,
  });

  return { updatePaymentAccount: reactQuery.mutateAsync, updatePaymentAccountPromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const updatePaymentAccount = async (data: UpdatePaymentAccountParam): Promise<UpdatePaymentAccountApiResponse> => {
  const accountApi = new ApiAccountRepository();
  const response = await accountApi.updatePaymentAccount(data.accountId, data.payload);

  return {
    state: response.state,
    message: response.message,
  };
};
