import { useState } from 'react';
import { useQuery } from 'react-query';
import { UserIntegrationRepository } from '../services/integrations/repositories/UserIntegrationRepository';
import { QueryKeys } from 'utils/queryKeys';
import { useCurrentUser } from 'providers/UserProvider';

type UseIntegrationsApiDataParams = {
  apiUserIntegrationsRepository: UserIntegrationRepository;
};

type UseUserIntegrationApiDataResult = {
  userIntegrationData: any;
  integrationConnectorData: any;
  fetchConnectorData: any;
  fetchUserIntegrations: any;
  isLoadingUserIntegrations: any;
  isLoadingOauthConnectionData: any;
  setIntegrationName: any;
};

type UseIntegrationsApiData<P> = (params: P) => UseUserIntegrationApiDataResult;

export const useIntegrationsApiData: UseIntegrationsApiData<UseIntegrationsApiDataParams> = ({ apiUserIntegrationsRepository }) => {
  const [integrationName, setIntegrationName] = useState<string>('');
  const {
    data: { id: userId },
  } = useCurrentUser();

  const {
    data: userIntegrationData,
    refetch: fetchUserIntegrations,
    isFetching: isLoadingUserIntegrations,
  } = useQuery(QueryKeys.userIntegrations, () => apiUserIntegrationsRepository.getUserIntegrations(userId), {
    enabled: !!userId,
  });

  const {
    data: integrationConnectorData,
    refetch: fetchConnectorData,
    isFetching: isLoadingOauthConnectionData,
  } = useQuery(QueryKeys.userOauthData, () => apiUserIntegrationsRepository.getOauthConnectionData(userId, integrationName), {
    enabled: integrationName.length > 0 && !!userId,
  });

  return {
    userIntegrationData,
    integrationConnectorData,
    fetchConnectorData,
    fetchUserIntegrations,
    isLoadingUserIntegrations,
    isLoadingOauthConnectionData,
    setIntegrationName,
  };
};
