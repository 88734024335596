import React from 'react';
import { PageHeaderProps } from './interface';
import { Paragraph, Heading } from '../../components';
import './styles.less';
import { Row, Col } from 'antd';

const DesktopPageHeader: React.FC<PageHeaderProps> = ({ title, paragraph, actions, icon }) => {
  return (
    <Row>
      <Col flex={1}>
        <Row wrap={false}>
          {icon && <Col> {icon} </Col>}

          <Col flex={1} id={icon ? 'icon-heading' : ''}>
            <Heading level={1}>{title}</Heading>
            <Paragraph size="md">{paragraph}</Paragraph>
          </Col>
        </Row>
      </Col>
      {actions && (
        <Col className="action-panel" flex={1}>
          {actions}
        </Col>
      )}
    </Row>
  );
};

export default DesktopPageHeader;
