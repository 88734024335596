import WithSignatureModal from '../editor/signatures/withSignatureModal';
import { PreviewSignedSignatureBlock } from '../editor/GridDndEditor/Block/Signature';
import { SignatureStatus } from '../../services/repositories/interfaces/SignatureRepository';
import { SectionsSignaturesType } from '../editor/providers/SignaturesProvider';
import { SetSignedSignatureInSectionType } from '../../hooks/usePreviewSignatures';

const PreviewSignatures = ({
  documentId,
  sectionId,
  sectionsSignatures,
  setSignedSignatureInSection,
}: {
  documentId: string;
  sectionId: string;
  sectionsSignatures: SectionsSignaturesType;
  setSignedSignatureInSection: SetSignedSignatureInSectionType;
}) => {
  const signatures = sectionsSignatures && sectionsSignatures[sectionId] ? sectionsSignatures[sectionId] : [];
  return (
    <>
      {signatures.map(({ signatureBoxId, properties, ...rest }) => {
        return rest.status === SignatureStatus.Signed ? (
          <PreviewSignedSignatureBlock
            signee={rest.signatureEvent}
            position={properties.position}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            signedDate={rest.signatureEvent.signedDate}
          />
        ) : (
          <WithSignatureModal
            position={properties.position}
            dimensions={properties.dimensions}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            assignedSignee={rest.assignedSignee}
            documentId={documentId}
            sectionId={sectionId}
            setSignedSignatureInSection={setSignedSignatureInSection}
          />
        );
      })}
    </>
  );
};

export default PreviewSignatures;
