type TemplateApiResponse = {
  id: number;
  name: string;
  created_datetime: string;
  thumb_large: string;
};

export type GetTemplateFoldersApiResponse = {
  id: number;
  name: string;
  parent_id: number;
  child_folder_ids: number[];
  templates: TemplateApiResponse[];
};

export interface Template {
  id: number;
  name: string;
  createdDatetime: string;
  thumbLarge: string;
}

export interface TemplateFolder {
  id: number;
  name: string;
  parentId: number;
  childFolderIds: number[];
  templates: Template[];
}

const TemplateFactory = ({ id, name, created_datetime, thumb_large }: TemplateApiResponse): Template => {
  return {
    id,
    name,
    createdDatetime: created_datetime,
    thumbLarge: thumb_large,
  };
};

export const TemplateFolderFactory = (templateFolders: GetTemplateFoldersApiResponse[]): TemplateFolder[] => {
  return templateFolders.map(({ id, name, parent_id, child_folder_ids, templates }) => ({
    id,
    name,
    parentId: parent_id,
    childFolderIds: child_folder_ids,
    templates: templates.map((template) => TemplateFactory(template)),
  }));
};

export interface TemplateRepository {
  getTemplateFolders(): Promise<TemplateFolder[]>;
}
