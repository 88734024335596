import { useMemo } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { CONFIG } from 'config';
import './styles.less';
import { EditorConfig } from 'components/editor/helpers/config';

interface BodyFieldProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}
export function BodyField({ value, onChange, placeholder }: BodyFieldProps) {
  const config: EditorConfig = useMemo(() => {
    return {
      editorClass: 'custom-editor',
      toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'formatOL', 'formatUL', 'Email templates'],
      placeholderText: placeholder,
      fontFamilySelection: false,
      shortcutsEnabled: ['bold', 'italic', 'underline', 'undo', 'redo'],
      minHeight: 240,
      pasteDeniedTags: ['img', 'picture', 'video', 'source'],
      htmlAllowedTags: ['a', 'b', 'br', 'div', 'em', 'hr', 'i', 'kbd', 'label', 'li', 'ol', 'p', 'span', 'strike', 'strong', 'u', 'ul'],
      key: CONFIG.FROALA_LICENSE_KEY,
      attribution: false,
      pluginsEnabled: ['link', 'url', 'lists'],
      listAdvancedTypes: false,
      name: 'body',
    };
  }, [placeholder]);
  return <FroalaEditor tag="textarea" model={value} onModelChange={onChange} config={config} />;
}
