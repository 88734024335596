import { PlanTier } from './interfaces';

export const availableTiers: PlanTier[] = ['basic', 'team', 'business'];
export const allTiers: PlanTier[] = ['', 'free', 'basic', 'team', 'business', 'employee'];

interface PlanData {
  type: string | null;
  name: string | null;
  custom: boolean | null;
}

export const getPlanTier = (planData: PlanData): PlanTier => {
  const foundTier = planData.type ? getPlanTierByString(planData.type) : getPlanTierByString((planData.name || '').toLowerCase());
  return foundTier === '' && planData.custom ? <PlanTier>'business' : foundTier;
};

export const getPlanTierByString = (planName: string): PlanTier => {
  const foundTier = allTiers.find((tier) => <PlanTier>planName.toLowerCase() === tier);
  if (!foundTier) {
    return '';
  }
  return foundTier;
};

export const isPlanUpgrade = (planName: PlanTier, curTier: PlanTier): boolean => {
  const curPlanPosition = allTiers.indexOf(curTier);
  const newPlanPosition = allTiers.indexOf(planName);

  return newPlanPosition > curPlanPosition;
};

export const canChangeToPlan = (planName: PlanTier, curTier: PlanTier, canDowngrade = true): boolean => {
  if (curTier === planName) {
    return false;
  }
  return !isPlanUpgrade(planName, curTier) && !canDowngrade ? false : true;
};
