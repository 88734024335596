import { Grid, Stack } from '@mui/material';
import MarginSettings from './margin/MarginSettings';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { DocumentSettingsContext } from './DocumentSettingsContext';
import { SecondaryMenu, SecondaryMenuSectionTitle, SecondaryMenuItemTitle, ColorPickerSection } from '../../component';
import { ContentType, getContentType } from 'utils/getContentType';

interface DocumentDesignSettingsProps {
  setIsDesignSettingsModelOpen: (isOpen: boolean) => void;
}

export function DocumentDesignSettings({ setIsDesignSettingsModelOpen }: DocumentDesignSettingsProps) {
  const { t } = useTranslation();
  const { documentSettings, updateBackgroundColor } = useContext(DocumentSettingsContext);
  const contentType = getContentType();

  return (
    <SecondaryMenu
      menuTitle={
        contentType === ContentType.DOCUMENT
          ? t('editor.side_panel.document_design_settings')
          : t('editor.side_panel.template_design_settings')
      }
      backButtonOnClick={() => setIsDesignSettingsModelOpen(false)}
    >
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('editor.side_panel.page_layout')}</SecondaryMenuSectionTitle>
        <SecondaryMenuItemTitle>{t('editor.side_panel.margins')}</SecondaryMenuItemTitle>
        <Grid container alignItems="center" justifyContent="start">
          <MarginSettings />
        </Grid>
      </Stack>

      <Stack rowGap="16px" alignItems="center" justifyContent="space-between" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('editor.side_panel.background')}</SecondaryMenuSectionTitle>
        <ColorPickerSection currentColor={documentSettings.backgroundColor} handleColorChange={updateBackgroundColor} />
      </Stack>
    </SecondaryMenu>
  );
}
