import usePreviewUrl from 'pages/editor/usePreviewUrl';
import { useNavigate } from 'react-router-dom';
import { type Document } from 'services/documents/entities/Document';
import { getDocumentLink } from './getDocumentLink';

export function useGoToDocument({ document }: { document: Document }) {
  const navigate = useNavigate();
  const previewUrlPrefix = usePreviewUrl();

  return {
    goToDocument: () => {
      const link = getDocumentLink({
        documentId: document.id,
        status: document.status,
        editorVersion: document.editorVersion,
        isLocked: document.isLocked,
        previewUrlPrefix: previewUrlPrefix,
      });
      navigate(link, { replace: true });
    },
  };
}
