import * as React from 'react';
const SvgIcoInsightsPreview = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M19 11V7.828a2 2 0 0 0-.586-1.414l-2.828-2.828A2 2 0 0 0 14.172 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h4" />
      <path d="M19 8h-4a1 1 0 0 1-1-1V3" />
      <path
        d="M13.56 18.306a1.47 1.47 0 0 1 0-1.61c.99-1.497 2.545-2.696 4.1-2.696s3.108 1.199 4.099 2.695a1.47 1.47 0 0 1 0 1.61c-.992 1.496-2.545 2.695-4.1 2.695-1.554.001-3.108-1.198-4.098-2.694"
        clipRule="evenodd"
      />
      <path d="M17.776 17.384a.165.165 0 0 1 0 .234.165.165 0 0 1-.234 0 .165.165 0 0 1 0-.234.165.165 0 0 1 .234 0" />
    </g>
  </svg>
);
export default SvgIcoInsightsPreview;
