import { useQuery } from 'react-query';
import ApiProposalRepository from 'services/repositories/implementations/ApiProposalRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useProposalExpirationQuery = (proposalId: number) => {
  return useQuery({
    queryKey: QueryKeys.getProposalExpiration(proposalId),
    queryFn: () => ApiProposalRepository.getProposalExpiration(proposalId),
    retry: false,
  });
};
