import { Endpoints } from '../../../utils/endpoints';
import { UserPermission } from '../entities/UserPermissions';
import { UserPermissionsRepository } from '../repositories/UserPermissionsRepository';
import { convertToCamelCase } from '../../query/toCamel';
import { apiClient } from 'services/api';

export class ApiUserPermissionsRepository implements UserPermissionsRepository {
  public async getUserPermissions(): Promise<UserPermission[]> {
    const response = await apiClient.get<Record<string, unknown>>(Endpoints.getUserPermissions);
    const responseConverted = convertToCamelCase(response);
    const permissionsAllowed = Object.keys(responseConverted).filter((key) => {
      if (responseConverted[key]) return key;
    });

    const permissionsWithUnmappedValues = permissionsAllowed.map((value) => UserPermission[value as keyof typeof UserPermission]);
    const permissions = permissionsWithUnmappedValues.filter((value) => value !== undefined);

    return permissions;
  }
}
