import React, { useState } from 'react';
import { GridCallbackDetails, GridCellParams, GridColumnHeaderParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import type { GridStateColDef } from '@mui/x-data-grid/models/colDef/gridColDef';

export type ToggledTableSettingsPanelRows = {
  tableApi: {
    tableCallbackDetails: GridCallbackDetails;
    selectedModel: GridCellParams;
  };
};

type CustomGridStateColDef<R extends GridValidRowModel = GridValidRowModel, V = any, F = V> = GridStateColDef<R, V, F> & {
  columnType?: string;
};

export type CustomGridColumnHeaderParams<R extends GridValidRowModel = GridValidRowModel, V = any, F = V> = Omit<
  GridColumnHeaderParams<R, V, F>,
  'colDef'
> & {
  colDef: CustomGridStateColDef<R, V, F>;
};

export type ToggledTableSettingsPanelColumns = {
  tableApi: {
    tableCallbackDetails: GridCallbackDetails;
    selectedModel: CustomGridColumnHeaderParams;
  };
};

export type ToggledTableSettingsPanel = ToggledTableSettingsPanelRows | ToggledTableSettingsPanelColumns;

export type SidePanelContextPropsType = {
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanel | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanel | null;
};

export type SidePanelContextColumnsPropsType = {
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanelColumns | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanelColumns | null;
};

export type SidePanelContextRowsPropsType = {
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanelRows | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanelRows | null;
};

export const SidePanelProviderContext = React.createContext<SidePanelContextPropsType>({} as SidePanelContextPropsType);

interface DesignSettingsModelToggleProps {
  children: React.ReactNode;
}
export function SidePanelProvider({ children }: DesignSettingsModelToggleProps) {
  const [toggledTableSettingsPanel, setToggledTableSettingsPanel] = useState<ToggledTableSettingsPanel | null>(null);

  const contextValue: SidePanelContextPropsType = {
    toggledTableSettingsPanel: toggledTableSettingsPanel,
    setToggledTableSettingsPanel: setToggledTableSettingsPanel,
  };

  return <SidePanelProviderContext.Provider value={contextValue}>{children}</SidePanelProviderContext.Provider>;
}
