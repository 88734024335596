import { IconButton, Tooltip } from '@mui/material';
import Info from '../../icon/icons/IcoInfo';
import { useTranslation } from 'react-i18next';

interface Props {
  tooltipText: string;
}

export function EmptyStateTooltip({ tooltipText }: Props) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(tooltipText)} placement="right">
      <IconButton data-testid="info-icon" sx={{ color: '#00283A8F', padding: 0 }}>
        <Info width={'20px'} height={'20px'} />
      </IconButton>
    </Tooltip>
  );
}
