import { Button, Icon, openNotification, Tooltip } from 'components';
import { openToast } from 'components/toast';
import { useTranslation } from 'react-i18next';
import { type Document } from 'services/documents/entities/Document';
import { useTrashRestore } from './useTrashRestore';

interface RestoreDocumentActionProps {
  documentToRestore: Document;
}

export function RestoreDocumentAction({ documentToRestore }: RestoreDocumentActionProps) {
  const { t } = useTranslation();
  const { handleProceed, handleUndo } = useTrashRestore({
    listType: 'Trash',
    document: documentToRestore,
  });

  const onSuccess = async () => {
    openToast({
      message: t('pipeline.tabs.trash_document_list.action_message'),
      onUndo: async () => handleUndo({}),
    });
  };

  const onFailure = () => {
    openNotification({
      type: 'error',
      title: t('pipeline.error.restore_error_msg'),
      description: t('pipeline.error.error_description'),
      placement: 'top',
    });
  };

  return (
    <Tooltip placement={'bottom'} title={t('pipeline.tabs.trash_document_list.action_tooltip')} className="pipeline-documents-tooltip">
      <Button
        aria-label="Restore"
        icon={<Icon name="IcoRestoreDocument" />}
        variant="neutral"
        type="default"
        className="restore-button"
        onClick={(event) => {
          event.preventDefault();
          handleProceed({ onSuccess, onFailure });
        }}
        data-testid={`trash-area-${documentToRestore.title}`}
      ></Button>
    </Tooltip>
  );
}
