import * as React from 'react';
const SvgIcoInsertPhoto = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.5 5v14h-14V5zm0-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-4.86 8.86-3 3.87-2.14-2.59-3 3.86h12z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoInsertPhoto;
