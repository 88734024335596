import React, { useState, useMemo } from 'react';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { TemplateList } from './template-list';
import { TemplateFolder } from 'services/templates/entities/TemplateRepository';
import './styles.less';
import { useTemplateFolderQuery } from '../../hooks/useTemplate';

interface Props {
  setStage: (stage: string) => void;
  selectPresetTemplate: (id: number) => void;
}

const defaultTemplateFolder = {
  id: 0,
  name: '',
  parentId: 0,
  templates: [],
  childFolderIds: [],
};

export const Templates: React.FC<Props> = ({ setStage, selectPresetTemplate }) => {
  const { t } = useTranslation();
  const [currentTemplateData, setCurrentTemplateData] = useState<TemplateFolder>(defaultTemplateFolder);
  const { data: templateFolders } = useTemplateFolderQuery();

  const rootTemplate = useMemo(() => {
    if (!templateFolders || templateFolders.length < 1) return;

    // set root templates in state
    const rootTemplate = templateFolders.find((data) => data.name == 'ProposifyRootFolder');
    if (rootTemplate) setCurrentTemplateData(rootTemplate);
    return rootTemplate;
  }, [templateFolders]);

  const navigateToFolder = (folderData: TemplateFolder) => {
    setCurrentTemplateData(folderData);
  };

  return (
    <div className="template__container">
      <div className="template__heading">
        <p
          className="template-pointer"
          onClick={() => {
            setStage('template');
            setCurrentTemplateData(rootTemplate ?? defaultTemplateFolder);
          }}
        >
          {t('crm.template.home_label')}
        </p>

        <p className="template-pointer" onClick={() => setStage('create')}>
          {t('crm.template.start_from_scratch_label')}
        </p>
      </div>

      <Divider className="template__divider" />

      <TemplateList
        currentTemplateData={currentTemplateData}
        navigateToFolder={navigateToFolder}
        selectPresetTemplate={selectPresetTemplate}
      />
    </div>
  );
};
