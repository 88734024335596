import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import './styles.less';
import { WorkspaceSwitchableAccount } from 'services/settings/entities/Workspaces';
import { useWorkspaceAccountUsage } from '../hooks/useWorkspaceAccountUsage';
import goToCinder from 'utils/goToCinder';
import { CINDER_URLS } from 'utils/cinder-urls';

type Props = {
  account: WorkspaceSwitchableAccount;
};

export const WorkspaceAccount = ({ account }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { setWorkspaceAccountUsage } = useWorkspaceAccountUsage();

  const switchAccountClick = async (subdomain: string) => {
    await setWorkspaceAccountUsage(subdomain);
    goToCinder(CINDER_URLS.dashboard);
  };

  return (
    <div className="account__container" data-testid="workspaces-account">
      <div>
        <h5>
          {account.name}
          <AccountIdentifiers isDefault={account.isDefault} isCurrent={account.isCurrent} />
        </h5>
        <p>{account.subdomain}</p>
      </div>
      <div>
        <Button variant={'emphasis'} disabled={account.isCurrent} type={'primary'} onClick={() => switchAccountClick(account.subdomain)}>
          {t('settings.workspaces_switcher.open_label')}
        </Button>
      </div>
    </div>
  );
};

const AccountIdentifiers = ({ isDefault, isCurrent }: { isDefault: boolean; isCurrent: boolean }) => {
  const { t } = useTranslation();

  if (isDefault) {
    return <div> - {t('settings.workspaces_switcher.account_default')}</div>;
  }

  if (isCurrent) {
    return <div> - {t('settings.workspaces_switcher.account_current')}</div>;
  }

  return null;
};
