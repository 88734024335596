import GongLogo from './gong-logo.png';
import SalesforceLogo from './salesforce-logo.png';
import HubspotLogo from './hubspot-logo.png';
import PipedriveLogo from './pipedrive-logo.png';
import HarvestLogo from './harvest-logo.png';
import ZapierLogo from './zapier-logo.png';
import StripeLogo from './stripe-logo.png';
import Freshbooks3Logo from './freshbooks2-logo.png';
import QuickbooksLogo from './quickbooks-logo.png';
import ZohoLogo from './zoho-logo.png';
import Basecamp3Logo from './basecamp3-logo.png';
import DriftLogo from './drift-logo.png';
import IntercomLogo from './intercom-logo.png';
import OlarkLogo from './olark-logo.png';

export const Logos = {
  gong: GongLogo,
  salesforce: SalesforceLogo,
  hubspot: HubspotLogo,
  pipedrive: PipedriveLogo,
  harvest: HarvestLogo,
  zapier: ZapierLogo,
  stripe: StripeLogo,
  freshbooks2: Freshbooks3Logo,
  quickbooks: QuickbooksLogo,
  zoho: ZohoLogo,
  basecamp3: Basecamp3Logo,
  drift: DriftLogo,
  intercom: IntercomLogo,
  olark: OlarkLogo,
};
