import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/typography';
import { loadSignatureOptions, SignatureSelect } from 'components/editor/signatures/signature-select';
import type { SignatureSelectOption } from 'components/editor/signatures/signature-select';
import { useCurrentUser } from 'providers/UserProvider';

import './styles.less';
import { DraggableSignatureButton } from './DraggableSignatureButton';

export default function Signatures() {
  const { t } = useTranslation();
  const {
    data: { accountId },
  } = useCurrentUser();
  const [selectedSignee, setSelectedSignee] = useState<SignatureSelectOption | null | undefined>(null);

  return (
    <>
      <Heading level={5}>Signatures</Heading>
      <div className="signature__container">
        <div>
          <div className="field_label">{t('editor.signature.for')}</div>
          <SignatureSelect
            value={selectedSignee}
            onChange={(newValue) => setSelectedSignee(newValue)}
            loadOptions={loadSignatureOptions(accountId)}
            placeholder={t('editor.signature.search_placeholder')}
          />
        </div>
        <DraggableSignatureButton signee={selectedSignee} />
      </div>
    </>
  );
}
