import { DocumentSaveStatus } from 'components/editor/shared/models/DocumentSaveStatus';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../grid/reduxStore/Store';

export default function SaveStatusText() {
  const { t } = useTranslation();
  const currentSaveStatus = useSelector((state: RootState) => state.saveStatus.currentSaveStatus);

  return <>{t(`document.save_statuses.${DocumentSaveStatus[currentSaveStatus.status]?.toLowerCase()}`)}</>;
}
