import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AccountCollapse } from './blocks/account-collapse';

type Props = {
  accounts: any[];
};

export const LinkedAccounts = ({ accounts }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [openedAccount, setOpenedAccount] = useState<null | string>(null);
  const handleOpenAccount = (key: string | null) => {
    setOpenedAccount(key);
  };

  return (
    <>
      {accounts.length < 1 && <div className="workspaces-linked-accounts__no-data">{t('settings.workspaces_switcher.no_data_label')}</div>}
      {accounts.map((account) => (
        <AccountCollapse accountData={account} openedAccount={openedAccount} handleOpenAccount={handleOpenAccount} key={account.id} />
      ))}
    </>
  );
};
