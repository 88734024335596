import { useMutation, useQueryClient } from 'react-query';
import { Endpoints } from '../utils/endpoints';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';

export function useDocumentImageUploadMutation() {
  const queryClient = useQueryClient();

  const queryMutation = useMutation({
    mutationFn: apiDocumentRepository.uploadDocumentLibraryImages,
    onSuccess: async (_, payload) => await queryClient.invalidateQueries(Endpoints.documentUploadImage(payload.documentId)),
  });

  return { uploadImage: queryMutation.mutate };
}
