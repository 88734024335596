import { useCurrentUser } from 'providers/UserProvider';
import { convertCreatedAtDateToUserTime, formatCreatedAtDate, isToday } from 'utils/dateHandlers';

export function useFormatDateTime() {
  const { data: currentUserInfo } = useCurrentUser();

  const formatDocumentDateTime = (createdAtDate: string) => {
    const userTimezone = currentUserInfo.timezone || 'UTC';

    if (isToday(createdAtDate)) {
      return convertCreatedAtDateToUserTime(createdAtDate, userTimezone);
    } else {
      return formatCreatedAtDate(createdAtDate);
    }
  };

  return { formatDocumentDateTime };
}
