import { Heading } from '../typography';
import React from 'react';
import { SectionTitleProps } from './interface';
import './styles.less';

const SectionTitle: React.FC<SectionTitleProps> = ({ icon, children }) => {
  return (
    <div className="proposify-section-title-wrapper">
      {icon}
      <Heading level={4}>{children}</Heading>
    </div>
  );
};

export default SectionTitle;
