import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../grid/reduxStore/Store';

import { GridBlockType } from '../shared/gridBlockType';
import { SelectionContext } from '../GridDndEditor/SelectedBlockInfoProvider';
import { selectBlockContent, BlockContent } from '../grid/reduxStore/editorSlice';
export function EditorFroalaToolbar() {
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const blockId = selectedBlockIdByWrapper as string;
  const blocksContent = useSelector(
    (state: RootState) => selectedSectionId && selectBlockContent(state, selectedSectionId, blockId)
  ) as BlockContent;

  const isSelectedBlockText = blocksContent?.type == GridBlockType.TEXT;

  const shouldShowToolbar = isSelectedBlockText ? 'active' : 'hidden';

  return <div id="editor-froala-toolbar" style={{ width: '100%' }} className={`editor-froala-toolbar ${shouldShowToolbar}`} />;
}
