import * as React from 'react';
import { Props } from './interface';
import Button from '../index';
import './styles.less';

const SimpleButton: React.FunctionComponent<Props> = ({ id, icon, disabled, onClick }) => {
  const variant = 'neutral';
  const type = 'default';
  const className = 'simple-icon';

  return <Button id={id} variant={variant} type={type} icon={icon} className={className} onClick={onClick} disabled={disabled}></Button>;
};

export default SimpleButton;
