import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Loading, Button, openNotification } from 'components';
import { QueryKeys } from 'utils/queryKeys';
import { useSalesforceCrm } from '../hooks/useSalesforceCrm';
import { useSalesforceObjectsOnSubmit, useSalesforceObjects } from '../hooks/useSalesforceObjectsOnSubmit';
import { baseStyles, theme } from '../../../../proposals/share/blocks/form/email-picker/shared';
import { salesforceObjectEntity } from '../../interface';

import './index.less';

interface DataType {
  key: React.Key;
  name: string;
}

const convertToDataSource = (data: any[] | unknown): any[] => {
  if (!data || !Array.isArray(data) || data.length < 1) return [];

  return data.map((item) => ({
    id: item?.name,
    label: item?.label,
    value: item?.label,
    labelPlural: item?.labelPlural,
    name: item?.name,
  }));
};

const convertToSalesforceObjectsPayload = (data: any[]): salesforceObjectEntity[] => {
  if (!data || !Array.isArray(data) || data.length < 1) return [];

  return data.map((item) => ({
    label: item.label,
    labelPlural: item.labelPlural,
    name: item.name,
  }));
};

export const SalesforceObjects = () => {
  const { t } = useTranslation();
  const [selectedFields, setSelectedFields] = useState<DataType[]>([]);

  const queryClient = useQueryClient();
  const { data: salesforceCrmData, status } = useSalesforceCrm();
  const { data: salesforceObjectsSettingsData, isLoading: isLoadingSalesforceSettings } = useSalesforceObjects();
  const { mutate: saveSalesforceObjects, isLoading: isLoadingSaveSalesforceSettings } = useSalesforceObjectsOnSubmit();

  const isLoading = useMemo(() => {
    return isLoadingSalesforceSettings || isLoadingSaveSalesforceSettings;
  }, [isLoadingSalesforceSettings, isLoadingSaveSalesforceSettings]);
  const crmDataSource = useMemo(() => {
    return convertToDataSource(salesforceCrmData);
  }, [salesforceCrmData]);
  useMemo(() => {
    if (!salesforceObjectsSettingsData) return [];

    setSelectedFields([...convertToDataSource(salesforceObjectsSettingsData?.subscriptions)]);
  }, [salesforceObjectsSettingsData]);

  const handleClick = () => {
    if (selectedFields.length < 1) return;

    const payload = {
      subscriptions: convertToSalesforceObjectsPayload(selectedFields),
    };
    saveSalesforceObjects(payload, {
      onSuccess: () => {
        openNotification({
          type: 'success',
          title: t('crm.salesforce.success_label'),
          description: t('crm.salesforce.saved_description'),
          placement: 'topRight',
        });
        queryClient.invalidateQueries(QueryKeys.salesforceObjects);
      },
      onError: (error: any) => {
        openNotification({
          type: 'error',
          title: t('crm.salesforce.error_label'),
          description: error?.reason_phrase,
          placement: 'topRight',
        });
      },
    });
  };

  switch (status) {
    case 'success':
      return (
        <div className="salesforce-objects__container" data-testid={'salesforce-object'}>
          {isLoading && (
            <div className="salesforce-objects__loader">
              <Loading isLoading />
            </div>
          )}

          <Select
            isMulti
            styles={baseStyles}
            theme={theme}
            options={crmDataSource}
            placeholder={t('crm.salesforce.search_placeholder')}
            onChange={(values) => setSelectedFields([...values])}
            value={selectedFields}
          />
          <Button className={'salesforce-objects__button'} variant={'emphasis'} type={'primary'} onClick={handleClick} disabled={isLoading}>
            {t('crm.salesforce.save_label')}
          </Button>
        </div>
      );
    default:
      return (
        <div className="salesforce-objects__loader">
          <Loading isLoading />
        </div>
      );
  }
};
