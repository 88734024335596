import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import './style.less';
import TableButton from '../toolbar/TableButton';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';

export default function TableLibrary() {
  const { t } = useTranslation();
  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.spPricingTable]);
  const isPricingTableEnabled = evaluatedFeatureFlags[FeatureFlags.spPricingTable];

  return (
    <div className="table__library__container">
      <div className="table__container_title">{t('document.table.title_tables')}</div>
      <div className="table__content__section">
        <Row className="blocks-row" gutter={8}>
          <Col data-testid="table-block-button" span={12}>
            <TableButton testId="text-table-block-button" title={t('document.table.text_table')} />
          </Col>
          {isPricingTableEnabled && (
            <Col data-testid="table-block-button" span={12}>
              <TableButton testId="pricing-table-block-button" isPricingTable title={t('document.table.pricing_table')} />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

TableLibrary.displayName = 'TableLibrary';
