import Axios from 'axios';
import qs from 'qs';
import jwt_decode from 'jwt-decode';
import { AuthTokenApiResponse } from './interfaces';
import { ConfigRequestType } from '../query/interfaces';
import { getHalifaxDateTime } from 'utils/dateHandlers';
import { Endpoints } from 'utils/endpoints';
import { logoutUser } from './handleLogout';
import * as storedTokensFromCookie from './handleTokenCookie';
import { AuthCookieService } from '../cookie';

const defaultAxiosClient: ConfigRequestType = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  crossDomain: true,
};

export const generateNewAccessToken = async ({ refreshToken }: { refreshToken: string }): Promise<AuthTokenApiResponse> => {
  const axios = Axios.create(defaultAxiosClient);

  const response = await axios.post<AuthTokenApiResponse>(
    Endpoints.refreshToken,
    qs.stringify({
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
      refresh_token: decodeURIComponent(refreshToken),
      grant_type: 'refresh_token',
    })
  );
  return response.data;
};

export const isTokenExpired = (token: string) => {
  const decodedJwt = jwt_decode<{ exp: number }>(token);

  if (!decodedJwt) {
    return true;
  }

  const expiryMiliseconds = decodedJwt.exp * 1000;
  const now = Date.now();

  return expiryMiliseconds < now;
};

const ONE_MS = 1;
const ONE_SECOND_IN_MS = ONE_MS * 1000;
const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;
const ONE_WEEK_IN_MS = ONE_DAY_IN_MS * 7;

export async function retryTokenAuthorization(): Promise<void> {
  const { refreshToken, accessToken } = storedTokensFromCookie;

  if (!refreshToken || !accessToken) {
    logoutUser();
    return;
  }

  if (isTokenExpired(accessToken)) {
    try {
      const { access_token, expires_in, refresh_token } = await generateNewAccessToken({ refreshToken });
      const now = new Date(getHalifaxDateTime()).getTime();
      const expiresIn = now + expires_in * 1000;
      const oneWeekFromNow = now + ONE_WEEK_IN_MS;

      AuthCookieService.setAccessToken(access_token, expiresIn);
      AuthCookieService.setRefreshToken(encodeURIComponent(refresh_token), oneWeekFromNow);
    } catch (e) {
      logoutUser();
    }
  }
}
