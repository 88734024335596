import { useContext } from 'react';
import './MarginSettings.less';
import { DocumentSettingsContext } from '../DocumentSettingsContext';
import {
  gridDefaultHeightInInches,
  gridDefaultPixelDensity,
  gridDefaultWidthInInches,
  gridPageMaxWidthInPixels,
  gridPageMinHeightInPixels,
} from '../../../../shared/gridConfig';
import { MarginSide } from '../models/MarginSide.enum';
import { FormattedInput } from './FormattedInput';

const MarginSettings = () => {
  const { documentSettings, updateMargins } = useContext(DocumentSettingsContext);
  const { top, left, right, bottom } = documentSettings.margin;
  const marginPreviewWidthInPixels = 110;
  const marginPreviewHeightInPixels = 140;
  const marginDecimalRoundingFactor = 1;

  const topMarginValue = (top / gridDefaultPixelDensity).toFixed(marginDecimalRoundingFactor);
  const leftMarginValue = (left / gridDefaultPixelDensity).toFixed(marginDecimalRoundingFactor);
  const rightMarginValue = (right / gridDefaultPixelDensity).toFixed(marginDecimalRoundingFactor);
  const bottomMarginValue = (bottom / gridDefaultPixelDensity).toFixed(marginDecimalRoundingFactor);

  const calculateMarginLineStyle = (marginValue, gridValue, previewSize, direction) => {
    switch (direction) {
      case 'top':
        return { top: `${(marginValue / gridValue) * previewSize}px` };
      case 'right':
        return { right: `${(marginValue / gridValue) * previewSize}px` };
      case 'bottom':
        return { bottom: `${(marginValue / gridValue) * previewSize}px` };
      case 'left':
        return { left: `${(marginValue / gridValue) * previewSize}px` };
      default:
        return {};
    }
  };

  const handleMarginChange = (value: string | number, position: MarginSide) => {
    updateMargins(value, position);
  };

  return (
    <div className="page-preview-container">
      <FormattedInput
        id="top-margin"
        variant="standard"
        value={topMarginValue}
        onChange={handleMarginChange}
        className="page-preview-container__top-margin number-input_hide-arrows"
        min={0}
        max={gridDefaultHeightInInches}
        position={MarginSide.TOP}
      />

      <div className="margin-preview-box">
        <FormattedInput
          id="left-margin"
          className="page-preview-container__left-margin mui-override number-input_hide-arrows"
          variant="standard"
          value={leftMarginValue}
          onChange={handleMarginChange}
          min={0}
          max={gridDefaultWidthInInches}
          position={MarginSide.LEFT}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: gridDefaultWidthInInches }}
        />

        <div className="margin-preview">
          <div
            className="margin-line top"
            style={calculateMarginLineStyle(top, gridPageMinHeightInPixels, marginPreviewHeightInPixels, 'top')}
          ></div>
          <div
            className="margin-line right"
            style={calculateMarginLineStyle(right, gridPageMaxWidthInPixels, marginPreviewWidthInPixels, 'right')}
          ></div>
          <div
            className="margin-line bottom"
            style={calculateMarginLineStyle(bottom, gridPageMinHeightInPixels, marginPreviewHeightInPixels, 'bottom')}
          ></div>
          <div
            className="margin-line left"
            style={calculateMarginLineStyle(left, gridPageMaxWidthInPixels, marginPreviewWidthInPixels, 'left')}
          ></div>
          <div className="page-preview" />
        </div>

        <FormattedInput
          id="right-margin"
          className="page-preview-container__right-margin mui-override number-input_hide-arrows"
          variant="standard"
          value={rightMarginValue}
          onChange={handleMarginChange}
          min={0}
          max={gridDefaultWidthInInches}
          position={MarginSide.RIGHT}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: gridDefaultWidthInInches }}
        />
      </div>

      <FormattedInput
        id="bottom-margin"
        className="page-preview-container__bottom-margin number-input_hide-arrows"
        variant="standard"
        value={bottomMarginValue}
        onChange={handleMarginChange}
        min={0}
        max={gridDefaultHeightInInches}
        position={MarginSide.BOTTOM}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: gridDefaultHeightInInches }}
      />
    </div>
  );
};

export default MarginSettings;
