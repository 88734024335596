// Automatically generated by @svgr, do not edit directly! See icon/icon-index-template
import '../styles.less';
import { IconListType } from '../interface';
import { default as PromotionRocket } from './PromotionRocket';
import { default as IcoAddCircleOutline } from './IcoAddCircleOutline';
import { default as IcoAddShape } from './IcoAddShape';
import { default as IcoAdd } from './IcoAdd';
import { default as IcoAdmin } from './IcoAdmin';
import { default as IcoApproval } from './IcoApproval';
import { default as IcoApprove } from './IcoApprove';
import { default as IcoArchives } from './IcoArchives';
import { default as IcoArrowDown } from './IcoArrowDown';
import { default as IcoArrowRight } from './IcoArrowRight';
import { default as IcoAutomation } from './IcoAutomation';
import { default as IcoBracketAddPlus } from './IcoBracketAddPlus';
import { default as IcoBubble } from './IcoBubble';
import { default as IcoCheckmark } from './IcoCheckmark';
import { default as IcoCircleCheckSolid } from './IcoCircleCheckSolid';
import { default as IcoCircleCloseX } from './IcoCircleCloseX';
import { default as IcoCirclePlusSolid } from './IcoCirclePlusSolid';
import { default as IcoClients } from './IcoClients';
import { default as IcoCloseX } from './IcoCloseX';
import { default as IcoClose } from './IcoClose';
import { default as IcoCloudUpload } from './IcoCloudUpload';
import { default as IcoCoffeeCup } from './IcoCoffeeCup';
import { default as IcoConnect } from './IcoConnect';
import { default as IcoContentCopy } from './IcoContentCopy';
import { default as IcoContent } from './IcoContent';
import { default as IcoDash } from './IcoDash';
import { default as IcoDashboard } from './IcoDashboard';
import { default as IcoDelete } from './IcoDelete';
import { default as IcoDisconnect } from './IcoDisconnect';
import { default as IcoDocumentation } from './IcoDocumentation';
import { default as IcoDocuments } from './IcoDocuments';
import { default as IcoDollarSignSolid } from './IcoDollarSignSolid';
import { default as IcoDoubleArrowLeft } from './IcoDoubleArrowLeft';
import { default as IcoDrag } from './IcoDrag';
import { default as IcoDuplicate } from './IcoDuplicate';
import { default as IcoEdit } from './IcoEdit';
import { default as IcoError } from './IcoError';
import { default as IcoFolder2 } from './IcoFolder2';
import { default as IcoFolder } from './IcoFolder';
import { default as IcoGridResize } from './IcoGridResize';
import { default as IcoHamburguerMenu } from './IcoHamburguerMenu';
import { default as IcoHelp } from './IcoHelp';
import { default as IcoHideElement } from './IcoHideElement';
import { default as IcoImagePlus } from './IcoImagePlus';
import { default as IcoImage } from './IcoImage';
import { default as IcoInfo } from './IcoInfo';
import { default as IcoInsertPhoto } from './IcoInsertPhoto';
import { default as IcoInsightsPreview } from './IcoInsightsPreview';
import { default as IcoInsights } from './IcoInsights';
import { default as IcoIntegrations } from './IcoIntegrations';
import { default as IcoInterests } from './IcoInterests';
import { default as IcoLink } from './IcoLink';
import { default as IcoLockOpen } from './IcoLockOpen';
import { default as IcoLock } from './IcoLock';
import { default as IcoLost } from './IcoLost';
import { default as IcoMaps } from './IcoMaps';
import { default as IcoMetrics } from './IcoMetrics';
import { default as IcoOrderedList } from './IcoOrderedList';
import { default as IcoPaintBucket } from './IcoPaintBucket';
import { default as IcoPencil } from './IcoPencil';
import { default as IcoPictureAsPdf } from './IcoPictureAsPdf';
import { default as IcoPlus } from './IcoPlus';
import { default as IcoPointer } from './IcoPointer';
import { default as IcoPreview } from './IcoPreview';
import { default as IcoPricingTable } from './IcoPricingTable';
import { default as IcoProposifyLogo } from './IcoProposifyLogo';
import { default as IcoResizeExpand } from './IcoResizeExpand';
import { default as IcoResizeShrink } from './IcoResizeShrink';
import { default as IcoRestoreDocument } from './IcoRestoreDocument';
import { default as IcoScreenSearchDesktopOutline } from './IcoScreenSearchDesktopOutline';
import { default as IcoSearch } from './IcoSearch';
import { default as IcoSearch24Px } from './IcoSearch24Px';
import { default as IcoSectionLibrary } from './IcoSectionLibrary';
import { default as IcoSend } from './IcoSend';
import { default as IcoSent } from './IcoSent';
import { default as IcoSettingsLibrary } from './IcoSettingsLibrary';
import { default as IcoSettings } from './IcoSettings';
import { default as IcoShowElement } from './IcoShowElement';
import { default as IcoSignaturePen } from './IcoSignaturePen';
import { default as IcoSignature } from './IcoSignature';
import { default as IcoSingleUserAddPlus } from './IcoSingleUserAddPlus';
import { default as IcoSuccess } from './IcoSuccess';
import { default as IcoTableColumnAddAfter } from './IcoTableColumnAddAfter';
import { default as IcoTableColumnAddBefore } from './IcoTableColumnAddBefore';
import { default as IcoTableRowPlusAfter } from './IcoTableRowPlusAfter';
import { default as IcoTableRowPlusBefore } from './IcoTableRowPlusBefore';
import { default as IcoTable } from './IcoTable';
import { default as IcoTemplates } from './IcoTemplates';
import { default as IcoTextFields } from './IcoTextFields';
import { default as IcoTextbox } from './IcoTextbox';
import { default as IcoTrash } from './IcoTrash';
import { default as IcoTune } from './IcoTune';
import { default as IcoUndo } from './IcoUndo';
import { default as IcoUser } from './IcoUser';
import { default as IcoVariables } from './IcoVariables';
import { default as IcoWarning } from './IcoWarning';
import { default as IcoWebStories } from './IcoWebStories';
import { default as IcoWon } from './IcoWon';
import { default as IcoWorkspace } from './IcoWorkspace';

const Icons: any = {
  PromotionRocket,
  IcoAddCircleOutline,
  IcoAddShape,
  IcoAdd,
  IcoAdmin,
  IcoApproval,
  IcoApprove,
  IcoArchives,
  IcoArrowDown,
  IcoArrowRight,
  IcoAutomation,
  IcoBracketAddPlus,
  IcoBubble,
  IcoCheckmark,
  IcoCircleCheckSolid,
  IcoCircleCloseX,
  IcoCirclePlusSolid,
  IcoClients,
  IcoCloseX,
  IcoClose,
  IcoCloudUpload,
  IcoCoffeeCup,
  IcoConnect,
  IcoContentCopy,
  IcoContent,
  IcoDash,
  IcoDashboard,
  IcoDelete,
  IcoDisconnect,
  IcoDocumentation,
  IcoDocuments,
  IcoDollarSignSolid,
  IcoDoubleArrowLeft,
  IcoDrag,
  IcoDuplicate,
  IcoEdit,
  IcoError,
  IcoFolder2,
  IcoFolder,
  IcoGridResize,
  IcoHamburguerMenu,
  IcoHelp,
  IcoHideElement,
  IcoImagePlus,
  IcoImage,
  IcoInfo,
  IcoInsertPhoto,
  IcoInsightsPreview,
  IcoInsights,
  IcoIntegrations,
  IcoInterests,
  IcoLink,
  IcoLockOpen,
  IcoLock,
  IcoLost,
  IcoMaps,
  IcoMetrics,
  IcoOrderedList,
  IcoPaintBucket,
  IcoPencil,
  IcoPictureAsPdf,
  IcoPlus,
  IcoPointer,
  IcoPreview,
  IcoPricingTable,
  IcoProposifyLogo,
  IcoResizeExpand,
  IcoResizeShrink,
  IcoRestoreDocument,
  IcoScreenSearchDesktopOutline,
  IcoSearch,
  IcoSearch24Px,
  IcoSectionLibrary,
  IcoSend,
  IcoSent,
  IcoSettingsLibrary,
  IcoSettings,
  IcoShowElement,
  IcoSignaturePen,
  IcoSignature,
  IcoSingleUserAddPlus,
  IcoSuccess,
  IcoTableColumnAddAfter,
  IcoTableColumnAddBefore,
  IcoTableRowPlusAfter,
  IcoTableRowPlusBefore,
  IcoTable,
  IcoTemplates,
  IcoTextFields,
  IcoTextbox,
  IcoTrash,
  IcoTune,
  IcoUndo,
  IcoUser,
  IcoVariables,
  IcoWarning,
  IcoWebStories,
  IcoWon,
  IcoWorkspace,
};

const IconList: IconListType = [
  'PromotionRocket',
  'IcoAddCircleOutline',
  'IcoAddShape',
  'IcoAdd',
  'IcoAdmin',
  'IcoApproval',
  'IcoApprove',
  'IcoArchives',
  'IcoArrowDown',
  'IcoArrowRight',
  'IcoAutomation',
  'IcoBracketAddPlus',
  'IcoBubble',
  'IcoCheckmark',
  'IcoCircleCheckSolid',
  'IcoCircleCloseX',
  'IcoCirclePlusSolid',
  'IcoClients',
  'IcoCloseX',
  'IcoClose',
  'IcoCloudUpload',
  'IcoCoffeeCup',
  'IcoConnect',
  'IcoContentCopy',
  'IcoContent',
  'IcoDash',
  'IcoDashboard',
  'IcoDelete',
  'IcoDisconnect',
  'IcoDocumentation',
  'IcoDocuments',
  'IcoDollarSignSolid',
  'IcoDoubleArrowLeft',
  'IcoDrag',
  'IcoDuplicate',
  'IcoEdit',
  'IcoError',
  'IcoFolder2',
  'IcoFolder',
  'IcoGridResize',
  'IcoHamburguerMenu',
  'IcoHelp',
  'IcoHideElement',
  'IcoImagePlus',
  'IcoImage',
  'IcoInfo',
  'IcoInsertPhoto',
  'IcoInsightsPreview',
  'IcoInsights',
  'IcoIntegrations',
  'IcoInterests',
  'IcoLink',
  'IcoLockOpen',
  'IcoLock',
  'IcoLost',
  'IcoMaps',
  'IcoMetrics',
  'IcoOrderedList',
  'IcoPaintBucket',
  'IcoPencil',
  'IcoPictureAsPdf',
  'IcoPlus',
  'IcoPointer',
  'IcoPreview',
  'IcoPricingTable',
  'IcoProposifyLogo',
  'IcoResizeExpand',
  'IcoResizeShrink',
  'IcoRestoreDocument',
  'IcoScreenSearchDesktopOutline',
  'IcoSearch',
  'IcoSearch24Px',
  'IcoSectionLibrary',
  'IcoSend',
  'IcoSent',
  'IcoSettingsLibrary',
  'IcoSettings',
  'IcoShowElement',
  'IcoSignaturePen',
  'IcoSignature',
  'IcoSingleUserAddPlus',
  'IcoSuccess',
  'IcoTableColumnAddAfter',
  'IcoTableColumnAddBefore',
  'IcoTableRowPlusAfter',
  'IcoTableRowPlusBefore',
  'IcoTable',
  'IcoTemplates',
  'IcoTextFields',
  'IcoTextbox',
  'IcoTrash',
  'IcoTune',
  'IcoUndo',
  'IcoUser',
  'IcoVariables',
  'IcoWarning',
  'IcoWebStories',
  'IcoWon',
  'IcoWorkspace',
];

type IconNamesType =
  | 'PromotionRocket'
  | 'IcoAddCircleOutline'
  | 'IcoAddShape'
  | 'IcoAdd'
  | 'IcoAdmin'
  | 'IcoApproval'
  | 'IcoApprove'
  | 'IcoArchives'
  | 'IcoArrowDown'
  | 'IcoArrowRight'
  | 'IcoAutomation'
  | 'IcoBracketAddPlus'
  | 'IcoBubble'
  | 'IcoCheckmark'
  | 'IcoCircleCheckSolid'
  | 'IcoCircleCloseX'
  | 'IcoCirclePlusSolid'
  | 'IcoClients'
  | 'IcoCloseX'
  | 'IcoClose'
  | 'IcoCloudUpload'
  | 'IcoCoffeeCup'
  | 'IcoConnect'
  | 'IcoContentCopy'
  | 'IcoContent'
  | 'IcoDash'
  | 'IcoDashboard'
  | 'IcoDelete'
  | 'IcoDisconnect'
  | 'IcoDocumentation'
  | 'IcoDocuments'
  | 'IcoDollarSignSolid'
  | 'IcoDoubleArrowLeft'
  | 'IcoDrag'
  | 'IcoDuplicate'
  | 'IcoEdit'
  | 'IcoError'
  | 'IcoFolder2'
  | 'IcoFolder'
  | 'IcoGridResize'
  | 'IcoHamburguerMenu'
  | 'IcoHelp'
  | 'IcoHideElement'
  | 'IcoImagePlus'
  | 'IcoImage'
  | 'IcoInfo'
  | 'IcoInsertPhoto'
  | 'IcoInsightsPreview'
  | 'IcoInsights'
  | 'IcoIntegrations'
  | 'IcoInterests'
  | 'IcoLink'
  | 'IcoLockOpen'
  | 'IcoLock'
  | 'IcoLost'
  | 'IcoMaps'
  | 'IcoMetrics'
  | 'IcoOrderedList'
  | 'IcoPaintBucket'
  | 'IcoPencil'
  | 'IcoPictureAsPdf'
  | 'IcoPlus'
  | 'IcoPointer'
  | 'IcoPreview'
  | 'IcoPricingTable'
  | 'IcoProposifyLogo'
  | 'IcoResizeExpand'
  | 'IcoResizeShrink'
  | 'IcoRestoreDocument'
  | 'IcoScreenSearchDesktopOutline'
  | 'IcoSearch'
  | 'IcoSearch24Px'
  | 'IcoSectionLibrary'
  | 'IcoSend'
  | 'IcoSent'
  | 'IcoSettingsLibrary'
  | 'IcoSettings'
  | 'IcoShowElement'
  | 'IcoSignaturePen'
  | 'IcoSignature'
  | 'IcoSingleUserAddPlus'
  | 'IcoSuccess'
  | 'IcoTableColumnAddAfter'
  | 'IcoTableColumnAddBefore'
  | 'IcoTableRowPlusAfter'
  | 'IcoTableRowPlusBefore'
  | 'IcoTable'
  | 'IcoTemplates'
  | 'IcoTextFields'
  | 'IcoTextbox'
  | 'IcoTrash'
  | 'IcoTune'
  | 'IcoUndo'
  | 'IcoUser'
  | 'IcoVariables'
  | 'IcoWarning'
  | 'IcoWebStories'
  | 'IcoWon'
  | 'IcoWorkspace';

export { Icons, IconList };
export type { IconNamesType };
