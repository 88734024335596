import { SignatureBox } from 'services/repositories/interfaces/SignatureRepository';

export const getSignaturesMaxHeight = (signatures?: SignatureBox[]) => {
  let max = 0;
  if (!signatures) {
    return 0;
  }

  signatures.forEach((signature) => {
    const { properties } = signature;
    const { position, dimensions } = properties;
    const signatureBottom = position.y + parseInt(String(dimensions.height) || '0');
    if (signatureBottom > max) {
      max = signatureBottom;
    }
  });

  return max;
};
