import { EditorContentWrapper } from 'pages/editor/editorContentWrapper';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiContentLibraryRepository } from '../../services/repositories/implementations/ApiContentLibraryRepository';
import PageLoader from '../../components/page-loader/page-loader';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import { CINDER_URLS } from 'utils/cinder-urls';
import { Asset } from 'services/library/entities/Asset';

const TemplateEditorPage = () => {
  const { assetId } = useParams<{ assetId: string }>();

  if (!assetId) {
    throw new Error('Asset id missing');
  }

  const {
    data: asset,
    isFetching,
    isFetched,
    status: documentLoadingStatus,
  } = useQuery({
    queryKey: `editor_asset_index_get_by_id_${assetId}_call`,
    queryFn: () => apiContentLibraryRepository.get(assetId),
    enabled: !!assetId,
  });

  if (documentLoadingStatus === 'error') {
    return <NavigateToCinder path={CINDER_URLS.error} />;
  }

  return (
    <PageLoader isLoading={isFetching} isLoaded={!isFetching && isFetched}>
      <TemplateEditor asset={asset} />;
    </PageLoader>
  );
};

const TemplateEditor = ({ asset }: { asset?: Asset }) => {
  if (!asset) {
    throw new Error('Asset not found');
  }

  return <EditorContentWrapper id={asset.id} contentId={asset.content_id} isDocumentLocked={null} />;
};

export default TemplateEditorPage;
