import { Box, ListItem, ListItemText, Typography } from '@mui/material';

import { useState } from 'react';
import { type Document } from 'services/documents/entities/Document';
import { DocumentAvatar } from '../components/DocumentAvatar';
import { DocumentStatusChip } from '../components/DocumentStatusChip';
import { getAssignedProspect } from '../utils/getAssignedProspect';
import { statusColors } from '../utils/statusColors';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import { useGoToDocument } from '../utils/useGoToDocument';

interface DocumentListItemV2Props {
  document: Document;
}

export function DocumentListItemV2({ document }: DocumentListItemV2Props) {
  const { formatDocumentDateTime } = useFormatDateTime();
  const { goToDocument } = useGoToDocument({ document });
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ListItem
      key={document.id}
      tabIndex={0}
      data-testid={`list-item-${document.id}`}
      divider
      sx={{
        gap: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        '&:focus': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        },
        borderLeft: `4px solid ${statusColors[document.status]}`,
      }}
      onClick={goToDocument}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          goToDocument();
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ListItemText
        sx={{ flex: '1 1 150px' }}
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              fontWeight={600}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              data-testid="document-title"
            >
              {document.title}
            </Typography>
          </Box>
        }
        secondary={
          <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {getAssignedProspect(document)}
          </Typography>
        }
      />
      <Box sx={{ flex: '0 1 100px' }}>
        <Typography variant="labelSmall" textAlign="right" whiteSpace="nowrap" data-testid="document-created-date">
          {formatDocumentDateTime(document.created_at)}
        </Typography>
      </Box>

      <Box sx={{ flex: '0 1 50px' }}>{!isHovered && <DocumentAvatar document={document} />}</Box>

      <Box sx={{ flex: '0 1 200px', display: 'flex' }}>
        <DocumentStatusChip status={document.status} />
      </Box>
    </ListItem>
  );
}
