import { styled, Card, CardContent, CardHeader, CardActions } from '@mui/material';

export const BillingCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  position: 'relative',
  gap: theme.spacing(3),
  borderRadius: theme.spacing(3),
  boxShadow: 'none',
  alignSelf: 'stretch',
  width: '100%',
}));

export const BillingCardHeader = styled(CardHeader)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(0, 0, 3, 0),
  fontSize: '20px',
  color: theme.palette.text.primary,
}));

export const BillingCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  borderRadius: '4px',
  padding: 0,
  columnGap: theme.spacing(2),
  '&:last-child': {
    paddingBottom: 0,
  },
}));

export const BillingCardActions = styled(CardActions)(({ theme }) => ({
  justifyContent: 'flex-end',
  padding: theme.spacing(3, 0, 0),
}));
