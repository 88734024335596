import * as React from 'react';
const SvgIcoSignaturePen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 19.8c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l13-13c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-13 13c-.2.1-.4.2-.6.2" fill="currentColor" />
    <path
      d="M9.8 16.7H4c-.4 0-.8-.3-.8-.8v-5.8c0-1 .4-2 1.1-2.7L10 1.9c2.2-2.2 5.8-2.2 8.1 0 1.1 1.1 1.7 2.5 1.7 4s-.6 3-1.7 4l-5.7 5.7c-.7.7-1.7 1.1-2.6 1.1m-5.1-1.5h5c.6 0 1.2-.2 1.6-.7L17 8.8q1.2-1.2 1.2-3c0-1.1-.4-2.2-1.2-3-1.6-1.6-4.3-1.6-5.9 0L5.4 8.5c-.4.4-.7 1-.7 1.6zM14.2 20.4c-1.3 0-2.7-.1-3.9-.3-2.9-.5-4.9-.5-5.9-.1-.4.2-.8 0-1-.4s0-.8.4-1c1.3-.6 3.4-.6 6.8-.1 2.5.4 5.3.4 6.1 0 .4-.2.8 0 1 .4s0 .8-.4 1c-.8.4-1.9.5-3.1.5"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoSignaturePen;
