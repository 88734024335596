import { Chip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { Divider, Space } from 'antd';
import { useGetSpecificOptionalFeature, useMutationEnableOptionalFeature } from 'hooks/featureFlagHooks';
import { useEffect, useState } from 'react';
import { FeatureFlags } from 'utils/featureFlags';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { openNotification } from 'components/notification';

export const FeaturesToggle: React.FC = () => {
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const [showFlags, setShowFlags] = useState(false);
  const { data: documentsV3OptionalFeature } = useGetSpecificOptionalFeature(FeatureFlags.documentsV3);
  const { mutateAsync: controlOptionalFeature } = useMutationEnableOptionalFeature();

  useEffect(() => {
    if (!documentsV3OptionalFeature) {
      return;
    }

    if (documentsV3OptionalFeature.canToogle) {
      setShowFlags(true);
    }
  }, [documentsV3OptionalFeature]);

  const handleFeatureFlagClick = async () => {
    try {
      await controlOptionalFeature({
        flag: FeatureFlags.documentsV3,
        enabled: !documentsV3OptionalFeature?.enabled,
      });
    } catch (error) {
      openNotification({
        type: 'error',
        title: translation('api_default_error_msg'),
        description: translation('api_default_error_description'),
        placement: 'top',
      });
      return;
    }

    navigate('/pipeline');
    window.location.reload();
  };

  return (
    <>
      {showFlags ? (
        <>
          <Divider style={{ margin: 0 }} />
          <Space style={{ padding: 12 }}>
            <div className="features-toggle">
              <p>{translation('admin.feature_flags.toggle.title')}</p>
              <Chip
                className={`feature-flag ${documentsV3OptionalFeature?.enabled ? 'flag-enabled' : ''}`}
                variant={documentsV3OptionalFeature?.enabled ? 'filled' : 'outlined'}
                clickable
                avatar={documentsV3OptionalFeature?.enabled ? <DoneIcon /> : undefined}
                label={translation('admin.feature_flags.toggle.documents_v3')}
                onClick={handleFeatureFlagClick}
              />
            </div>
          </Space>
        </>
      ) : null}
    </>
  );
};
