import { useMutation } from 'react-query';
import { ApiPlanRepository } from '../services/api/plan/ApiPlanRepository';

export const usePromoCodeMutation = () => {
  return useMutation({
    mutationFn: (payload: { accountId: number; promoCode: string; subscriptionUuid: string }) => {
      const item = new ApiPlanRepository();
      return item.applyPromoCode(payload.accountId, payload.promoCode, payload.subscriptionUuid);
    },
  });
};
