import React from 'react';
import { Paragraph } from '../../../components';
import './blockSourceButton.less';
import IcoImage from '../../icon/icons/IcoImage';

interface ImageBlockButtonProps {
  title: string;
}

const ImageBlockButton: React.FunctionComponent<ImageBlockButtonProps> = ({ title }) => {
  return (
    <button draggable className="block-source">
      <div className="block-source-static-icon">
        <IcoImage />
      </div>
      <Paragraph size="sm">{title}</Paragraph>
    </button>
  );
};

export default ImageBlockButton;
ImageBlockButton.displayName = 'ImageBlockButton';
