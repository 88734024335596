import { Tooltip, Typography } from '@mui/material';

import { useEffect, useRef, useState } from 'react';

export function DocumentTitle({ title }: { title?: string }) {
  const titleRef = useRef<HTMLDivElement>(null);
  const [isTextEllipsed, setIsTextEllipsed] = useState(false);

  useEffect(() => {
    if (titleRef.current) {
      setIsTextEllipsed(titleRef.current.clientWidth < titleRef.current.scrollWidth);
    }
  }, [title]);

  return (
    <Tooltip title={isTextEllipsed ? title : undefined}>
      <Typography ref={titleRef} data-testid="insights-header-title" variant="h5" fontWeight={600} noWrap>
        {title}
      </Typography>
    </Tooltip>
  );
}
