import * as React from 'react';
const SvgIcoPreview = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 19.8H3C1.5 19.8.2 18.6.2 17V3C.2 1.5 1.4.2 3 .2h7.2c.7 0 1.4.3 1.9.8l2.8 2.8c.5.5.8 1.2.8 1.9V9c0 .4-.3.8-.8.8s-.8-.3-.8-.8V5.8c0-.3-.1-.7-.4-.9l-2.8-2.8c-.2-.2-.6-.4-.9-.4H3c-.7.1-1.2.6-1.2 1.3v14c0 .7.6 1.2 1.2 1.2h4c.4 0 .8.3.8.8s-.4.8-.8.8"
      fill="currentColor"
    />
    <path
      d="M15 6.8h-4C10 6.8 9.2 6 9.2 5V1c0-.4.3-.8.8-.8s.8.3.8.8v4c0 .1.1.2.2.2h4c.4 0 .8.3.8.8s-.4.8-.8.8M13.7 19.8c-2 0-3.8-1.6-4.7-3-.5-.7-.5-1.7 0-2.4 1-1.5 2.7-3 4.7-3s3.8 1.6 4.7 3c.5.7.5 1.7 0 2.4-1 1.4-2.7 3-4.7 3m-3.5-3.9c.9 1.4 2.3 2.4 3.5 2.4s2.5-.9 3.5-2.4c.2-.2.2-.5 0-.8-1-1.4-2.3-2.4-3.5-2.4s-2.5.9-3.5 2.4c-.2.2-.2.6 0 .8"
      fill="currentColor"
    />
    <path d="M13.7 16.4c-.2 0-.5-.1-.6-.3q-.3-.3-.3-.6c0-.2.1-.5.3-.6.4-.4.9-.4 1.3 0 .3.4.3.9 0 1.3-.3.1-.5.2-.7.2" fill="currentColor" />
  </svg>
);
export default SvgIcoPreview;
