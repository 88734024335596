import * as React from 'react';
const SvgIcoFolder2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M2.25 6A2.75 2.75 0 0 1 5 3.25h4.471c.58 0 1.12.287 1.446.765l1.405 2.065c.046.068.124.11.207.11H19a2.75 2.75 0 0 1 2.75 2.75V18A2.75 2.75 0 0 1 19 20.75H5A2.75 2.75 0 0 1 2.25 18zM5 4.75c-.69 0-1.25.56-1.25 1.25v12c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V8.94c0-.69-.56-1.25-1.25-1.25h-6.471a1.75 1.75 0 0 1-1.447-.767L9.677 4.86a.25.25 0 0 0-.206-.109z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoFolder2;
