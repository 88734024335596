import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';
import { OptionalFeature } from '../entities/OptionalFeature';

export type EnableOptionalFeatureApiReturn = {
  id: string;
  scope: string;
  enabled: boolean;
};

export type GetOptionalFeatureApiReturn = {
  id: string;
  name?: string;
  description?: string;
  scope: string;
  can_toggle: boolean;
  enabled: boolean;
};

export class ApiFeatureFlagRepository {
  public async enableOptionalFeature(flag: string, enabled: boolean): Promise<void> {
    const body = {
      enabled,
    };
    await apiClient.patch<EnableOptionalFeatureApiReturn>(Endpoints.featureFlag.specificOptionalFeature(flag), body);
  }

  public async getSpecificOptionalFeature(flag: string): Promise<OptionalFeature> {
    const body = [flag];
    const apiData = await apiClient.post<GetOptionalFeatureApiReturn[]>(Endpoints.featureFlag.optionalFeatures, body);
    const optionalFeature = apiData[0];

    return {
      id: optionalFeature.id,
      enabled: optionalFeature.enabled,
      canToogle: optionalFeature.can_toggle,
    };
  }

  public async getOptionalFeatures(): Promise<Array<OptionalFeature>> {
    const apiData = await apiClient.get<GetOptionalFeatureApiReturn[]>(Endpoints.featureFlag.optionalFeatures);
    return apiData
      .filter((optionalFeature) => optionalFeature.can_toggle)
      .map((optionalFeature) => ({
        id: optionalFeature.id,
        name: optionalFeature.name,
        scope: optionalFeature.scope,
        enabled: optionalFeature.enabled,
        canToogle: optionalFeature.can_toggle,
      }));
  }
}

export const FeatureFlagRepository = new ApiFeatureFlagRepository();
