import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';

export function useDocumentImageLibrary(documentId: string, enabled = true) {
  const { data, isError, isFetched, refetch } = useQuery(
    QueryKeys.documentImageLibraryImages(documentId),
    () => apiDocumentRepository.getDocumentLibraryImages(documentId),
    {
      enabled,
    }
  );
  const result = !data ? [] : data;
  return {
    isError,
    isFetched,
    refetch,
    result,
  };
}
