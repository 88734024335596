import { useQueries } from 'react-query';
import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';
import { useCurrentUser } from 'providers/UserProvider';

const useFeatureFlag = (flags: string[]) => {
  const { data } = useCurrentUser();
  const userId = data.id;
  let ab = {};

  const queryResults = useQueries(
    flags.map((flag) => {
      return {
        queryKey: [flag, userId],
        queryFn: () => apiClient.get<Record<string, boolean>>(Endpoints.getUserFeatureFlagContext(userId, flag)),
        enabled: !!userId,
      };
    })
  );

  queryResults.forEach(({ data }) => (ab = { ...ab, ...data }));

  return ab;
};

export default useFeatureFlag;
