import { linkedAccount, LinkedAccountsRepository } from '../LinkedAccountsRepository';
import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';

class ApiLinkedAccountsRepositoryClass implements LinkedAccountsRepository {
  public async getLinkedAccounts(accountId: number): Promise<linkedAccount[]> {
    return await apiClient.get(Endpoints.getLinkedAccounts(accountId));
  }
}

export const ApiLinkedAccountsRepository = new ApiLinkedAccountsRepositoryClass();
