import { useTranslation } from 'react-i18next';
import { openNotification } from 'components';
import goToCinder from 'utils/goToCinder';
import { useShareEmailMutation } from 'hooks/useShareEmailMutation';

import type { ProposalShareEmailPayload } from 'services/repositories/interfaces/ProposalRepository';
import type { EmailPickerOption } from './email-picker';
import { isIframe } from 'utils/isIframe';

type RecipientsPayload = ProposalShareEmailPayload['recipients'];
type CcPayload = ProposalShareEmailPayload['cc_emails'];

export const transformRecipients = (selected: EmailPickerOption[]): RecipientsPayload => {
  const initial: RecipientsPayload = { contact_ids: [], user_ids: [] };
  return selected.reduce((payload, { value, id, type }) => {
    if (id == null) {
      payload.raw_emails = [...(payload.raw_emails ?? []), value];
    } else {
      if (type === 'contact') {
        payload.contact_ids.push(id);
      }
      if (type === 'user') {
        payload.user_ids.push(id);
      }
    }

    return payload;
  }, initial);
};

export const transformCcRecipients = (selected: EmailPickerOption[]): CcPayload => selected.map(({ email }) => email);

export type FormValues = {
  recipients: EmailPickerOption[];
  subject: string;
  body?: string;
  cc_emails: EmailPickerOption[];
  bcc_emails: EmailPickerOption[];
  view_document_button_text: string;
};

type OnSubmitFn = (formValues: FormValues) => void;

interface UseProposalOnSubmitResult {
  onSubmit: OnSubmitFn;
  isLoading: boolean;
}

export const useProposalOnSubmit = (proposalId: number): UseProposalOnSubmitResult => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useShareEmailMutation();

  const getShareEmailErrorMessage = (message: string): string => {
    if (
      message &&
      message.includes('Email send failed: You tried to send to recipient(s) that have been marked as inactive. Found inactive addresses')
    ) {
      return t('share.form.notification.error.invalid_emails');
    }

    return '';
  };

  const onSubmit: OnSubmitFn = ({ recipients, subject, body, cc_emails, bcc_emails, view_document_button_text }) => {
    mutate(
      {
        subject,
        body: body || undefined,
        recipients: transformRecipients(recipients),
        proposalId,
        cc_emails: transformCcRecipients(cc_emails),
        bcc_emails: transformCcRecipients(bcc_emails),
        view_document_button_text,
      },
      {
        onSuccess: () => {
          openNotification({
            type: 'success',
            title: t('share.form.notification.success.title'),
            description: '',
          });
          if (isIframe()) {
            window.parent.postMessage(JSON.stringify({ action: 'DONE' }), '*');
          } else {
            goToCinder(`/proposal/snapshot/${proposalId}`);
          }
        },
        onError: (err: any) => {
          const data: any = err.response?.data;
          openNotification({
            type: 'error',
            title: t('share.form.notification.error.title'),
            description: getShareEmailErrorMessage(data?.message),
          });
        },
      }
    );
  };

  return {
    onSubmit,
    isLoading,
  };
};
