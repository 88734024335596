export const automationsPhrases = {
  'emptyState.integrationMarketplaceTitle': 'Workflow Automations',
  'emptyState.integrationMarketplaceText--customer':
    'The workflow automations displays workflows that have been enabled or available for configuration.',
  dynamicPhrase: {
    Marketplace: 'Automations',
  },
};

export const workflowPhrases = {
  'integrations__filterBar.title': 'Workflow',
  'integrations__filterBar.addButton': 'Add workflow',
  'input.filterSearchPlaceholder': { _: 'Search workflows' },
};
