import { useQuery } from 'react-query';
import { fetchActivitiesForDocument } from './api';

const REFRESH_INTERVAL_IN_MS = 60 * 1000;

export function useQueryActivities({ documentId }: { documentId: string }) {
  const { data, isLoading } = useQuery(['activities', documentId], fetchActivitiesForDocument(documentId), {
    refetchInterval: REFRESH_INTERVAL_IN_MS,
  });

  const result = !data ? [] : data;
  return {
    result,
    isLoading,
  };
}
