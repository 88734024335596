import { ColumnTypeIdentifier, RowTypesIdentifier, TableColumnType, TableRowType } from '../../../grid/reduxStore/table.types';

export const roundDecimalValue = (inputValue: string | number, allowedDecimalPlaces: number): string => {
  const numericValue = parseFloat(inputValue as string);
  const decimalPart = (numericValue.toString().split('.')[1] || '').length;

  if (decimalPart > allowedDecimalPlaces) {
    return numericValue.toFixed(allowedDecimalPlaces);
  }
  return numericValue.toString();
};

export function isPricingNumberCell(row: TableRowType, colDef: TableColumnType): boolean {
  if (row.rowType !== RowTypesIdentifier.BODY) return false;
  return [ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL].includes(colDef.columnType);
}
