import React from 'react';
import { PageHeaderProps } from './interface';
import MobilePageHeader from './mobilePageHeader';
import DesktopPageHeader from './desktopPageHeader';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import './styles.less';
import '../../styles/layout.less';

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { isSmallerThanDesktop } = useMediaQuery();

  return <div className="page-info">{isSmallerThanDesktop ? <MobilePageHeader {...props} /> : <DesktopPageHeader {...props} />}</div>;
};

export default PageHeader;
