import { useTranslation } from 'react-i18next';
import { Heading, Icon } from 'components';
import { isIframe } from 'utils/isIframe';
import { useNavigate } from 'react-router-dom';

export const DocumentCreateHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (isIframe()) {
      window.parent.postMessage(JSON.stringify({ action: 'EXIT' }), '*');
    }
    navigate(-1);
  };

  return (
    <header className="document-create-page__header-section">
      <div>
        <Heading level={4}>{t('crm.document_create.create_label')}</Heading>
      </div>
      <div>
        <button type="button" className="close-button" onClick={handleBackButton}>
          <Icon name="IcoCloseX" title={t('close')} />
        </button>
      </div>
    </header>
  );
};
