import React from 'react';
import { Icons } from './icons';
import { Props } from './interface';

const Icon: React.FC<Props> = ({ name, className = '', title, ...props }) => {
  const SpecificIcon: any = Icons[name];
  const testId = props['data-testid'];

  return (
    <span className={`py-react-icon ${className}`} title={title}>
      <SpecificIcon data-testid={testId} aria-labelledby="title" />
    </span>
  );
};

export default Icon;
