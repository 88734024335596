import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeatureTable from './featureTable';
import { seeAllFeaturesState } from './interface';

interface SeeAllFeaturesModalProps {
  state: seeAllFeaturesState;
}

const SeeAllFeaturesModal: React.FC<SeeAllFeaturesModalProps> = ({ state }) => {
  const { t } = useTranslation('translation');
  const { isVisible, title, features, planType, onCancel, onConfirm, confirmButtonLabel } = state;

  const StyledButton = styled(Button)(() => ({
    borderRadius: '100px',
    boxShadow: 'none',
    textTransform: 'none',
  }));

  return (
    <Dialog
      open={isVisible}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FeatureTable features={features} planType={planType} />
      </DialogContent>

      <DialogActions sx={{ p: 3, flexWrap: 'wrap' }}>
        <StyledButton size="medium" variant="text" color="primary" onClick={onCancel}>
          {t('settings.plan.button_cancel_label')}
        </StyledButton>
        <StyledButton autoFocus onClick={onConfirm} size="medium" variant="contained" color="primary">
          {t(confirmButtonLabel)}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default SeeAllFeaturesModal;
