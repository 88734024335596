import { useArchiveProposalMutation } from 'hooks/useArchiveProposalMutation';
import { useDeleteExpirationProposalMutation } from 'hooks/useDeleteExpirationProposalMutation';
import { useProposalExpirationQuery } from 'hooks/useGetProposalExpirationQuery';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { useInputNumber } from './useNumberInputOnChangeHandler';

interface UseProposalExpiryResult {
  days: null | number;
  onSubmit: () => void;
  onChange: (value: string | number) => void;
  isLoading: boolean;
  expiryDate: null | Date;
  isApplied: boolean;
  isSubmitting: boolean;
}

interface UseProposalExpiryOptions {
  onSuccess: () => void;
}

type UseProposalExpiry = (proposalId: number, options: UseProposalExpiryOptions) => UseProposalExpiryResult;

export const addDays = (date: Date, days: number) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days, 23, 59, 59);
};
export const toDateTimeString = (inputDate: Date) => {
  const year = inputDate.getFullYear();
  const month = inputDate.getMonth() + 1;
  const date = inputDate.getDate();

  return `${year}-${month}-${date} 23:59:59`;
};

export const calculateDaysBetweenDates = (fromDate: Date, toDate: Date) => {
  const diffInTime = toDate.getTime() - fromDate.getTime();
  const days = diffInTime / (1000 * 3600 * 24);
  return Math.round(days);
};

export const calculateDaysFrom = (datetime: string, against = new Date()) => {
  const [date] = datetime.split(' ');
  const year = against.getFullYear();
  const month = against.getMonth() + 1;
  const day = against.getDate();
  const againstDate = new Date(`${year}-${month}-${day}`);
  const expiryDate = new Date(date);
  return calculateDaysBetweenDates(againstDate, expiryDate);
};

export const useProposalExpiry: UseProposalExpiry = (proposalId, { onSuccess }) => {
  const [expiresInDays, setExpiresInDays] = useInputNumber(1);
  const { data, isLoading } = useProposalExpirationQuery(proposalId);
  const { mutate: archiveProposal, isLoading: isSubmitting } = useArchiveProposalMutation();
  const { mutate: clearExpiration } = useDeleteExpirationProposalMutation(proposalId);
  const expiryDate = expiresInDays != null ? addDays(new Date(), expiresInDays) : null;
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data && data.archivesAt) {
      setExpiresInDays(calculateDaysFrom(data.archivesAt));
    }
  }, [data]);

  const onSubmit = () => {
    const key = QueryKeys.getProposalExpiration(proposalId);
    if (expiryDate) {
      archiveProposal(
        { proposalId, archivesAt: toDateTimeString(expiryDate) },
        {
          onSuccess: (response) => {
            queryClient.setQueryData(key, () => response);
            queryClient.invalidateQueries();
            onSuccess();
          },
        }
      );
    } else {
      clearExpiration(undefined, {
        onSuccess: () => {
          queryClient.setQueryData(key, () => undefined);
          queryClient.invalidateQueries(key);
          onSuccess();
        },
      });
    }
  };

  const onChange: UseProposalExpiryResult['onChange'] = (value) => {
    const MINIMUM = 0;
    const parsed = parseInt(String(value || 0), 10);
    if (isNaN(parsed) || parsed < MINIMUM) {
      return;
    }

    setExpiresInDays(value);
  };

  return {
    days: expiresInDays,
    expiryDate,
    onChange,
    isLoading: isLoading,
    isSubmitting,
    onSubmit,
    isApplied: Boolean(data?.archivesAt),
  };
};
