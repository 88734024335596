import { Endpoints } from 'utils/endpoints';
import { apiClient } from '../../api';

interface PrismaticCredentials {
  client_id: string;
  client_secret: string;
}

export const getPrismaticCredentials = async (): Promise<PrismaticCredentials> => {
  return await apiClient.get(Endpoints.getPrismaticCredentials);
};
