import React, { MouseEvent, TouchEvent, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Popover from '@radix-ui/react-popover';

import { SignaturesContext } from '../../../providers/SignaturesProvider';
import { getDefaultSignatureDimensions } from '../../../signatures/constants';
import { Dimensions, Position } from '../../../shared/interfaces';
import { getSignatureColor, SignatureBaseButton } from '../../../shared/components/SignatureBaseButton';
import { SignatureDetail } from '../../../signatures/signature-detail';
import { AssignedSignee } from 'services/repositories/interfaces/SignatureRepository';
import { BlockConfig } from '../../models/BlockConfig.model';
import { useInViewport } from '../../useInViewport';
import { availableResizeHandle } from '../../consts';
import { useDraggableBlockManipulations } from '../../DraggableGridBlock/useDraggableBlockManipulations';
import PlaceHolder from '../Placeholder';
import './Signature.less';
import { useSectionId } from '../../../Sections/SectionIdProvider';
import { GridBlockType } from '../../../shared/gridBlockType';
import { DraggableCore, DraggableEventHandler } from 'react-draggable';
import { Resizable, ResizeCallback } from 're-resizable';
import { gridPixelSize } from '../../../shared/gridConfig';
import { BlockDimensionType, BlockPositionType } from '../../DraggableGridBlock/types';
import { toggleSelectNoneClass } from 'components/editor/helpers/toggleSelectNoneClass';

export interface UnSignedSignatureProps {
  signatureId: string;
  assignedSignee?: AssignedSignee | null;
  handleClick?: () => void;
  position: Position;
  dimensions: Dimensions;
  bounds: string;
}

const SignatureBlock: React.FunctionComponent<UnSignedSignatureProps> = ({
  signatureId,
  assignedSignee = null,
  position,
  dimensions,
  handleClick,
  bounds,
}) => {
  const { t } = useTranslation();
  const { handleSignaturePropertyUpdate, handleSignatureRemoval } = useContext(SignaturesContext);
  const signee = assignedSignee;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { scrollToPosition, ref: scrollElRef } = useInViewport();
  const positionOnInteractionStart = useRef<BlockPositionType>({ xAxisPx: 0, yAxisPx: 0 });
  const dimensionOnInteractionStart = useRef<BlockDimensionType>({ heightPx: 0, widthPx: 0 });
  const { handleDrag, handleDragStart, handleOnResizing, resetManipulatedBlockForGuidelines } = useDraggableBlockManipulations();
  const sectionId = useSectionId();
  const elementToBound = document.querySelector(bounds) as HTMLElement;

  const handleSignatureDragStop: DraggableEventHandler = () => {
    resetManipulatedBlockForGuidelines();
    setIsDragging(false);
    handleSignaturePropertyUpdate(sectionId, {
      signatureBoxId: signatureId,
      properties: {
        position,
        dimensions,
      },
    });
  };

  const handleOnSignatureResizingStop = () => {
    toggleSelectNoneClass(false);
    resetManipulatedBlockForGuidelines();
    handleSignaturePropertyUpdate(sectionId, {
      signatureBoxId: signatureId,
      properties: {
        position,
        dimensions,
      },
    });
  };

  const renderResizeHandle = Object.keys(availableResizeHandle).reduce((resizeHandles, key) => {
    resizeHandles[key] = true;
    return resizeHandles;
  }, {});

  const handleOnSignatureDrag: DraggableEventHandler = (e, data) => {
    const { x, y } = position;
    const { height, width } = dimensions;

    scrollToPosition(y);

    const blockConfig = {
      height,
      width,
      id: signatureId,
      x,
      y,
    } as BlockConfig;

    handleDrag(e as MouseEvent, data, blockConfig, GridBlockType.SIGNATURE, elementToBound);
  };

  const handleOnSignatureResizing: ResizeCallback = (_event, direction, _refToElement, delta) => {
    const { width, height } = dimensions;
    const { x, y } = position;
    const blockConfig = {
      height,
      width,
      id: signatureId,
      x,
      y,
    } as BlockConfig;

    handleOnResizing(
      direction,
      delta,
      positionOnInteractionStart.current,
      dimensionOnInteractionStart.current,
      blockConfig,
      GridBlockType.SIGNATURE
    );
  };

  const handleOnSignatureResizingStart = (event: MouseEvent | TouchEvent): void => {
    positionOnInteractionStart.current = { xAxisPx: position.x, yAxisPx: position.y };
    dimensionOnInteractionStart.current = {
      heightPx: dimensions.height,
      widthPx: dimensions.width,
    };
    event.stopPropagation();
    toggleSelectNoneClass(true);
  };

  const customStyle = useMemo<React.CSSProperties>(
    () => ({
      position: 'absolute',
      top: 0,
      left: 0,
      transform: `translate(${position.x}px, ${position.y}px)`,
    }),
    [position.x, position.y]
  );

  const currentBlockForPlaceholder = {
    ...dimensions,
    x: positionOnInteractionStart.current.xAxisPx,
    y: positionOnInteractionStart.current.yAxisPx,
  };

  const minDimensions = getDefaultSignatureDimensions();

  return (
    <>
      {isDragging && <PlaceHolder trackedBlocks={currentBlockForPlaceholder} />}
      <DraggableCore
        onStart={() => {
          setIsDragging(true);
          positionOnInteractionStart.current = { xAxisPx: position.x, yAxisPx: position.y };
          handleDragStart();
        }}
        onDrag={handleOnSignatureDrag}
        onStop={handleSignatureDragStop}
        onMouseDown={handleClick}
        grid={[gridPixelSize, gridPixelSize]}
      >
        <Resizable
          className={`signature_${signatureId} unsigned_signature`}
          data-testid={`unsigned_signature_draggable_${signatureId}`}
          size={dimensions}
          bounds={elementToBound}
          boundsByDirection={true}
          minWidth={minDimensions.width}
          style={customStyle}
          minHeight={minDimensions.height}
          grid={[gridPixelSize, gridPixelSize]}
          handleComponent={availableResizeHandle}
          enable={renderResizeHandle}
          onResizeStart={(event) => handleOnSignatureResizingStart(event)}
          onResize={handleOnSignatureResizing}
          onResizeStop={handleOnSignatureResizingStop}
          lockAspectRatio
        >
          <div ref={scrollElRef} />
          <Popover.Root>
            <Popover.Trigger asChild>
              <SignatureBaseButton color={getSignatureColor(signee?.email)} data-signature-id={signatureId}>
                {t('signature')}
              </SignatureBaseButton>
            </Popover.Trigger>
            <Popover.Content side="top" className="signature__tooltip" sideOffset={5}>
              <SignatureDetail
                title={signee ? `${signee.firstName} ${signee.lastName}` : t('unassigned')}
                label={signee ? `${signee.firstName} ${signee.lastName?.[0]}.` : t('unassigned')}
                onDelete={() => {
                  handleSignatureRemoval({ sectionId, signatureId });
                }}
              />
            </Popover.Content>
          </Popover.Root>
        </Resizable>
      </DraggableCore>
    </>
  );
};

export default SignatureBlock;
