import * as React from 'react';
import { IMaskInput } from 'react-imask';

export interface MaskedInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  id: string;
}

export const CCInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={'XXXX XXXX XXXX ####'}
      definitions={{
        '#': /[0-9]/,
        X: /[0-9,*]/,
      }}
      unmask
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.id, value } })}
      overwrite
    />
  );
});

export const ExpiryDateInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={'##/##'}
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.id, value } })}
      overwrite
    />
  );
});

export const SecurityCodeInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={'####'}
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.id, value } })}
      overwrite
    />
  );
});
