import { useMutation } from 'react-query';
import { ApiAccountRepository } from 'services/api/account/ApiAccountRepository';
import { CardUpdateProps, BillingAddress, UpdateBillingInfoApiResponse } from 'services/api/account/interfaces';

type UpdateBillingPayload = {
  cardInfo: CardUpdateProps;
  billingAddress: BillingAddress;
};

type UpdateBillingParam = {
  accountId: number;
  payload: UpdateBillingPayload;
};

export default function useUpdateBillingInfoMutation() {
  const reactQuery = useMutation({
    mutationFn: updateBillingInfo,
  });

  return { updateBillingInfo: reactQuery.mutateAsync, updateBillingInfoPromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const updateBillingInfo = async (data: UpdateBillingParam): Promise<UpdateBillingInfoApiResponse> => {
  const accountApi = new ApiAccountRepository();
  const response = await accountApi.updateBillingInfo(data);

  return {
    status: response.status,
    message: response.message,
  };
};
