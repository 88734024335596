import { useState, useEffect } from 'react';
import { RequestInfo } from 'node-fetch';
import { generateNewAccessToken, isTokenExpired } from '../services/authorization/handleAuthorization';
import { refreshToken } from 'services/authorization/handleTokenCookie';
import { AuthCookieService } from '../services/cookie';

interface RequestProps {
  path?: RequestInfo;
  options?: any;
}

type Token = string | null;

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export function useApiDeprecated<T>({ path, options }: RequestProps) {
  const [data, setData] = useState<T | any>();
  const [loading, setLoading] = useState(false);
  const stringifiedPath = JSON.stringify(path);

  const fetchOptions = (token: Token, newOptions: any = null) => {
    options = newOptions ?? options;
    return {
      crossDomain: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      ...options,
    };
  };

  useEffect(() => {
    if (!stringifiedPath) return;
    let isFetchCancelled = false;
    fetchApi(isFetchCancelled);

    return () => {
      isFetchCancelled = true;
    };
  }, [stringifiedPath]);

  let url = `${REACT_APP_API_ENDPOINT}${path}`;

  const fetchApi = async (isFetchCancelled: boolean, newPath = '', newOptions: any = null) => {
    setLoading(true);
    let token = AuthCookieService.getAccessToken();

    if (!token) {
      return;
    } else if (isTokenExpired(token) && refreshToken) {
      await generateNewAccessToken({ refreshToken });
      token = AuthCookieService.getAccessToken();
    }

    const options = newOptions ? fetchOptions(token, newOptions) : fetchOptions(token);
    url = newPath.length > 0 ? REACT_APP_API_ENDPOINT + newPath : url;

    return await fetch(url, options)
      .then(async (response) => {
        let body;
        try {
          body = await response.json();
        } catch (err) {
          body = {};
        }

        if (response.status === 200) {
          if (!isFetchCancelled) {
            return { status: response.status, body };
          }
        } else {
          console.error(`Error ${response.status}: ${response.statusText.toLowerCase()}`); // eslint-disable-line no-console

          if (response.status === 201) return { status: response.status, body };

          return { status: response.status, body };
        }
      })
      .then((data) => {
        setData(data);
        return data;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error, url);
      })
      .finally(() => {
        setLoading(false);
        return data;
      }); // eslint-disable-line no-console
  };

  return { data, fetchApi, loading };
}
