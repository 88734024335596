import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Button, openNotification } from 'components';
import { useSsoSchemaQuery } from '../../sso/blocks/useSsoSchemaQuery';
import { useSsoConfigQuery } from '../../sso/blocks/useSsoConfigQuery';
import { useAutoDiscoveryFormOnSubmit } from '../../sso/blocks/useAutoDiscoveryFormOnSubmit';
import { useUpdateProviderOnSubmit } from '../../sso-provider-update/blocks';
import { useSsoConfigOnSubmit } from '../../sso/blocks/useSsoConfigOnSubmit';
import { AutoDiscovery } from '../../sso/blocks/form/auto-discovery/auto-discovery';
import { RecursiveForm } from '../../sso-create/recursive-form';
import { URLS } from 'utils/urls';

const getDiscoveryData = (data: object, referencedProperty?: string | undefined): object => {
  let result = {};
  for (const key in data) {
    if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
      result = { ...result, ...getDiscoveryData(data[key], key) };
    } else {
      if (referencedProperty) result[`${referencedProperty}.${key}`] = data[key];
      else result[key] = data[key];
    }
  }
  return result;
};

const expandObject = (data: object): object => {
  const result = {};

  for (const property in data) {
    const splitProperty = property.split('.');

    if (splitProperty.length == 1) result[splitProperty[0]] = data[splitProperty[0]];

    if (splitProperty.length == 2) {
      if (!result[splitProperty[0]]) result[splitProperty[0]] = {};

      result[splitProperty[0]][splitProperty[1]] = data[`${splitProperty[0]}.${splitProperty[1]}`];
    }
  }

  delete result['id'];
  delete result['created_datetime'];
  delete result['updated_datetime'];
  return result;
};

const getProviderData = (data: object, nestedKey: null | string = null): object => {
  return Object.keys(data).reduce((providerData, key) => {
    if (typeof data[key] == 'object' && !Array.isArray(data[key])) {
      providerData = { ...providerData, ...getProviderData(data[key], key) };
    } else providerData[nestedKey ? `${nestedKey}.${key}` : key] = data[key];
    return providerData;
  }, {});
  return {};
};

export const OpenIdProvider = () => {
  const { providerId } = useParams();
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    identity_provider_identifier: '',
  });

  const { data, status } = useSsoSchemaQuery();
  const { data: configData, refetch } = useSsoConfigQuery();
  const { mutate: submitAutoDiscovery, isLoading: isDiscoveryLoading } = useAutoDiscoveryFormOnSubmit();
  const { mutate: saveSsoConfig, isLoading: isSavingConfig } = useSsoConfigOnSubmit();
  const { mutate: updateSsoConfig, isLoading: isUpdatingConfig } = useUpdateProviderOnSubmit();
  const navigate = useNavigate();

  const isFormValid = useMemo(() => {
    if (formValues) {
      if ('identity_provider_identifier' in formValues && formValues.identity_provider_identifier.trim() == '') return false;
    }
    return true;
  }, [formValues]);

  const isLoading = useMemo(() => {
    return isDiscoveryLoading || isSavingConfig || isUpdatingConfig;
  }, [isDiscoveryLoading, isSavingConfig, isUpdatingConfig]);

  const provider = useMemo(() => {
    if (providerId && configData && Array.isArray(configData)) return configData.find((data) => data.id == providerId);
  }, [providerId, configData]);

  useEffect(() => {
    if (provider) {
      setFormValues((state) => ({ ...state, ...getProviderData(provider) }));
    }
  }, [provider]);

  const handleInputChange = (event) => {
    const { name, value, nestedProperty } = event.target;
    if (nestedProperty) {
      setFormValues((state) => ({ ...state, [nestedProperty]: { ...state[nestedProperty], [name]: value } }));
      return;
    }

    setFormValues((state) => ({ ...state, [name]: value }));
  };

  const handleAutoDiscovery = () => {
    if (!Object.hasOwn(formValues, 'discovery_url') || formValues['discovery_url'] == '') return;

    submitAutoDiscovery(
      {
        discovery_url: formValues['discovery_url'],
      },
      {
        onSuccess: (data) => {
          const result = getDiscoveryData(data);
          setFormValues((prevState) => ({ ...prevState, ...result }));
          openNotification({
            type: 'success',
            title: t('settings.sso.success_label'),
            description: t('settings.sso.discovery_description_label'),
            placement: 'topRight',
          });
        },
        onError: (data: any) => {
          openNotification({
            type: 'error',
            title: t('settings.sso.auto_discovery_error_title'),
            description: data.reason_phrase,
            placement: 'topRight',
          });
        },
      }
    );
  };

  const handleSaveConfigurations = () => {
    const payload = expandObject(formValues);
    if (isFormValid) {
      if (providerId) {
        updateSsoConfig(
          {
            providerId,
            payload,
          },
          {
            onSuccess: () => {
              openNotification({
                type: 'success',
                title: t('settings.sso.success_label'),
                description: t('settings.sso_config_update.update_success_description'),
                placement: 'topRight',
              });
              refetch();
              navigate(URLS.ssoConfiguration);
            },
            onError: (err: any) => {
              const data: any = err.response?.data;
              openNotification({
                type: 'error',
                title: t('settings.sso.error_label'),
                description: data.reason_phrase,
                placement: 'topRight',
              });
            },
          }
        );
      } else {
        saveSsoConfig(payload, {
          onSuccess: () => {
            refetch();
            navigate(URLS.ssoConfiguration);
          },
          onError: (data: any) => {
            openNotification({
              type: 'error',
              title: t('settings.sso.error_label'),
              description: data.reason_phrase,
              placement: 'topRight',
            });
          },
        });
      }
    } else {
      openNotification({
        type: 'error',
        title: t('settings.sso.error_label'),
        description: t('settings.sso.configurations.identity_provider_error'),
        placement: 'topRight',
      });
    }
  };

  switch (status) {
    case 'success':
      return (
        <>
          {isLoading && (
            <div className="sso-create__loader">
              <Loading isLoading />
            </div>
          )}

          <PageHeader
            title={providerId ? t('settings.sso_config_update.title') : t('settings.sso_create.title')}
            paragraph={providerId ? t('settings.sso_config_update.subtitle') : t('settings.sso_create.subtitle')}
          />
          <section className="sso-form__container">
            <AutoDiscovery
              autoDiscoveryValue={formValues['discovery_url'] ?? ''}
              handleInputChange={handleInputChange}
              onSubmit={handleAutoDiscovery}
            />

            <Divider />

            <RecursiveForm
              properties={data?.properties}
              formValues={formValues}
              setFormValues={setFormValues}
              handleInputChange={handleInputChange}
            />

            <Button disabled={false} type="primary" variant="positive" onClick={handleSaveConfigurations}>
              {providerId ? <>Update</> : <>Submit</>}
            </Button>
          </section>
        </>
      );
    default:
      return (
        <div className="sso-create__loader">
          <Loading isLoading />
        </div>
      );
  }
};
