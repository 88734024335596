import { Dimensions, Position } from '../shared/interfaces';
import {
  SignatureStatus,
  SignatureType,
  UnAssignedUnSignedSignatureBox,
} from '../../../services/repositories/interfaces/SignatureRepository';

const defaultSignatureDimensions: Dimensions = {
  width: 116,
  height: 56,
};

const defaultSignaturePosition: Position = {
  x: 500,
  y: 300,
};

export const getDefaultSignatureDimensions = (): Dimensions => {
  return { ...defaultSignatureDimensions };
};

export const getDefaultSignature = (): UnAssignedUnSignedSignatureBox => {
  return {
    signatureBoxId: '',
    type: SignatureType.Signature,
    status: SignatureStatus.UnSigned,
    properties: {
      position: { ...defaultSignaturePosition },
      dimensions: { ...defaultSignatureDimensions },
    },
    signatureEvent: null,
    assignedSignee: null,
  };
};
