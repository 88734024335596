import { useTranslation } from 'react-i18next';
import useCreateDocumentMutation from 'hooks/useCreateDocumentMutation';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { URLS } from 'utils/urls';

export default function NewDocumentPage() {
  const { t } = useTranslation();
  const { createDocumentPromise } = useCreateDocumentMutation();
  const navigate = useNavigate();

  useEffect(() => {
    createDocumentPromise(
      {
        title: t('editor.default_document_title'),
      },
      {
        onSuccess: (response) => {
          navigate(URLS.editDocument(response.documentId), { replace: true });
        },
      }
    );
  }, []);

  return null;
}
