import { Avatar, Tooltip, Typography } from '@mui/material';
import { assignAvatarColor } from 'components/avatar';
import { useCurrentUser } from 'providers/UserProvider';
import { type Document } from 'services/documents/entities/Document';

const avatarColorsMap = {
  'color-primary70': '#4fb5eb',
  'color-primary80': '#7fd0ff',
  'color-primary90': '#c5e7ff',
  'color-primary95': '#e4f3ff',
  'color-secondary70': '#13bcd5',
  'color-secondary80': '#49d8f1',
  'color-secondary90': '#a3eeff',
  'color-secondary95': '#d5f7ff',
};

export const DocumentAvatar = ({ document }: { document: Document }) => {
  const { data: currentUserInfo } = useCurrentUser();

  const assignedUserFullName = `${document.assigneeUserFirstName || ''} ${document.assigneeUserLastName || ''}`.trim();
  const currentUserAvatar = assignedUserFullName === currentUserInfo.fullName ? currentUserInfo.avatar : null;
  const validUserProfileImage = !currentUserAvatar?.includes('img/blank.gif') ? currentUserAvatar : null;

  const initials = `${document.assigneeUserFirstName?.[0] || ''}${document.assigneeUserLastName?.[0] || ''}`.toUpperCase();

  if (!validUserProfileImage && !initials) {
    return null;
  }

  return (
    <Tooltip title={assignedUserFullName}>
      <Avatar
        variant="large"
        data-testid={`document-avatar-${document.id}`}
        sx={{ bgcolor: avatarColorsMap[assignAvatarColor(initials)] }}
        alt="Avatar Profile Image"
        src={validUserProfileImage || undefined}
      >
        <Typography variant="labelSmall">{initials}</Typography>
      </Avatar>
    </Tooltip>
  );
};
