import { Switch as HeadlessSwitch } from '@headlessui/react';
import './styles.less';

export interface SwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export function Switch({ checked, onChange }: SwitchProps) {
  return (
    <HeadlessSwitch checked={checked} onChange={onChange} className={'switch'}>
      {(enabled) => <span aria-hidden="true" className={`knob ${enabled ? 'active' : ''}`} />}
    </HeadlessSwitch>
  );
}
