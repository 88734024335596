import { useMutation } from 'react-query';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';
import { ApiEditorRepository } from 'services/repositories/implementations/ApiEditorRepository';

type CreateDocumentParam = {
  title?: string;
  price?: number;
  copyFromContentId?: string;
};

export default function useCreateDocumentMutation() {
  const reactQuery = useMutation({
    mutationFn: createDocumentFlow,
  });

  return { createDocument: reactQuery.mutateAsync, createDocumentPromise: reactQuery.mutate };
}

const createDocumentFlow = async (payload: CreateDocumentParam | undefined) => {
  const editorRepository = new ApiEditorRepository();
  const content = await editorRepository.createContent('DOCUMENT');
  const document = await apiDocumentRepository.createDocument({ contentId: content.id, title: payload?.title, price: payload?.price });

  if (payload?.copyFromContentId) {
    await editorRepository.copyContent({ fromContentId: payload?.copyFromContentId, toContentId: content.id });
  }

  return {
    documentId: document.documentId,
    contentId: content.id,
  };
};
