import React from 'react';
import { useLocation } from 'react-router-dom';
import goToCinder from 'utils/goToCinder';

type Props = {
  page?: string;
};

const CinderRedirect: React.FC<Props> = ({ page }) => {
  const { pathname, search, hash } = useLocation();

  if (page) {
    goToCinder(page, { replace: true });
    return null;
  }

  const path = `${pathname}${search}${hash}`;
  goToCinder(path, { replace: true });

  return null;
};

export default CinderRedirect;
