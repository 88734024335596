import * as React from 'react';
const SvgIcoSettingsLibrary = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.25 5A2.756 2.756 0 0 1 6 2.25h13c.47 0 .92.16 1.255.495s.495.785.495 1.255v8.8a.75.75 0 0 1-1.5 0V4c0-.13-.04-.18-.055-.195-.016-.015-.065-.055-.195-.055H6c-.686 0-1.25.564-1.25 1.25v12a.75.75 0 0 1-1.5 0z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M8 2.25a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-1.5 0V3A.75.75 0 0 1 8 2.25"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M3.25 17A2.756 2.756 0 0 1 6 14.25h7.3a.75.75 0 0 1 0 1.5H6c-.686 0-1.25.564-1.25 1.25s.564 1.25 1.25 1.25h7.3a.75.75 0 0 1 0 1.5H6A2.756 2.756 0 0 1 3.25 17M18.748 14.25a.75.75 0 0 1 .75.75v1.042a.75.75 0 0 1-1.5 0V15a.75.75 0 0 1 .75-.75M18.748 19.43a.75.75 0 0 1 .75.75v1.042a.75.75 0 0 1-1.5 0V20.18a.75.75 0 0 1 .75-.75M22.09 16.184a.75.75 0 0 1-.28 1.023l-.902.514a.75.75 0 1 1-.742-1.304l.902-.514a.75.75 0 0 1 1.023.28M17.592 18.776a.75.75 0 0 1-.273 1.025l-.886.514a.75.75 0 0 1-.752-1.298l.886-.514a.75.75 0 0 1 1.025.273M19.885 18.782a.75.75 0 0 1 1.023-.28l.902.513a.75.75 0 1 1-.742 1.303l-.902-.514a.75.75 0 0 1-.28-1.022M15.408 16.18a.75.75 0 0 1 1.025-.273l.886.514a.75.75 0 1 1-.752 1.298l-.886-.514a.75.75 0 0 1-.273-1.025"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M18.748 16.786a1.325 1.325 0 1 0 0 2.65 1.325 1.325 0 0 0 0-2.65m0-1.5a2.825 2.825 0 1 0 0 5.65 2.825 2.825 0 0 0 0-5.65"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoSettingsLibrary;
