import { FenixThemeProvider } from 'providers/FenixThemeProvider';
import React from 'react';
import { Tabs } from './tabs/Tabs';

const DocumentsPage: React.FC = () => {
  return (
    <FenixThemeProvider>
      <Tabs />
    </FenixThemeProvider>
  );
};

export default DocumentsPage;
