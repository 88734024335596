import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { styled, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import goToCinder from 'utils/goToCinder';

export interface ConfirmationDialogRawProps {
  open: boolean;
  onClose: (value?: string) => void;
}

const TooManyUsersModal = ({ onClose, open }: ConfirmationDialogRawProps) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  const handleManageUsers = () => {
    goToCinder('/user');
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: '100px',
    boxShadow: 'none',
  }));

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px 12px 24px',
        }}
      >
        {t('settings.plan.too_many_users')}
        <IconButton sx={{ ml: 'auto' }} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 0, mb: 0 }}>
        <strong>The Basic plan has a maximum of 2 users.</strong> If you would like to continue switching to the Basic plan, please remove
        some users from your workspace.
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <StyledButton size="medium" variant="text" color="primary" onClick={handleManageUsers}>
          {t('settings.plan.manage_users')}
        </StyledButton>
        <StyledButton autoFocus onClick={handleCancel} size="medium" variant="contained" color="primary">
          {t('settings.plan.keep_current_plan')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default TooManyUsersModal;
