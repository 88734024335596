import { CCType } from 'services/api/account/interfaces';
import AEIcon from '../../../images/icons/icon-adornment-ae.png';
import MCIcon from '../../../images/icons/icon-adornment-mc.png';
import VisaIcon from '../../../images/icons/icon-adornment-visa.png';

export const getCCTypeFromCCNumber = (numString: string): CCType => {
  switch (numString[0]) {
    case '2':
    case '5':
      return CCType.MASTERCARD;
    case '3':
      return CCType.AMERICAN_EXPRESS;
    case '4':
      return CCType.VISA;
  }
  return CCType.UNKNOWN;
};

export const getCCTypeFromString = (name: string): CCType => {
  switch (name.toLowerCase()) {
    case 'mastercard':
    case 'mc':
      return CCType.MASTERCARD;
    case 'american express':
    case 'amex':
    case 'americanexpress':
      return CCType.AMERICAN_EXPRESS;
    case 'visa':
      return CCType.VISA;
  }
  return CCType.UNKNOWN;
};

export const getIconFromCCType = (ccType: CCType | string): string => {
  switch (ccType) {
    case CCType.MASTERCARD || CCType.MASTERCARD.toString():
      return MCIcon;
    case CCType.AMERICAN_EXPRESS || CCType.AMERICAN_EXPRESS.toString():
      return AEIcon;
    case CCType.VISA || CCType.VISA.toString():
      return VisaIcon;
    default:
      return VisaIcon;
  }
};
