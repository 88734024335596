import { Paragraph } from 'components/typography';
import './styles.less';

type actionProps = {
  text: string;
  onClick: () => void;
};

type Props = {
  message: string;
  action?: actionProps;
};

export const Snackbar = ({ message, action }: Props) => {
  return (
    <div className="custom-snackbar">
      <div className="snackbar-title">
        <Paragraph size="sm">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Paragraph>
      </div>
      {action && (
        <div className="snackbar-actions">
          <button className="snackbar-btn" onClick={action.onClick}>
            {action.text}
          </button>
        </div>
      )}
    </div>
  );
};
