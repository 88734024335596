import React from 'react';
import { Button } from 'antd';
import Icon from '../icon';
import './styles.less';
import { HamburgerButtonProps } from './interfaces';

const HamburgerButton: React.FC<HamburgerButtonProps> = ({ onClick }) => {
  return (
    <Button
      shape="circle"
      onClick={onClick}
      icon={<Icon name="IcoHamburguerMenu" />}
      className="proposify-hamburger-menu"
      data-testid="hamburger-menu"
      role="button"
    />
  );
};

export default HamburgerButton;
