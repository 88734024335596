import { Paper } from '@mui/material';
import prismatic, { BooleanOperator, TermOperator, PrismaticMessageEvent, getMessageIframe } from '@prismatic-io/embedded';
import { useEffect, useCallback } from 'react';
import usePrismaticAuth from './usePrismaticAuth';
import { Container, styled, ThemeProvider } from '@mui/material';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';
import integrationsTheme from './theme';
import { workflowPhrases } from './phrases';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { getPrismaticCredentials } from 'services/integrations/repositories/GetPrismaticCredentialsRepository';
import { PrismaticData, PrismaticCredentials } from './types';

const embeddedDivId = 'embedded-designer-div';
const EmbeddedDesignerWrapper = styled(Container)(() => ({
  height: '80vh',
  width: '100%',
}));

export const PrismaticPage = (): JSX.Element => {
  const { authenticated, userinfo } = usePrismaticAuth();
  const { allowEmbeddedDesigner } = userinfo?.authenticatedUser.customer || { allowEmbeddedDesigner: false };
  const featureFlags = useFeatureFlag([FeatureFlags.prismatic]);
  const isPrismaticEnabled = featureFlags[FeatureFlags.prismatic];
  const queryClient = useQueryClient();

  const {
    data: prismaticCredentials,
    isLoading,
    error,
  } = useQuery<PrismaticCredentials>(QueryKeys.getPrismaticCredentials, getPrismaticCredentials);

  useEffect(() => {
    if (authenticated && allowEmbeddedDesigner) {
      prismatic.showIntegrations({
        selector: `#${embeddedDivId}`,
        theme: 'LIGHT',
        filters: {
          components: {
            filterQuery: [BooleanOperator.and, [TermOperator.notEqual, 'key', 'ftp'], [TermOperator.notEqual, 'key', 'sftp']],
          },
        },
        translation: {
          phrases: workflowPhrases,
        },
      });
    }
  }, [authenticated, allowEmbeddedDesigner]);

  const messageListener = useCallback(
    (message: MessageEvent) => {
      const { event, data } = message.data;

      if (event === PrismaticMessageEvent.INSTANCE_CONFIGURATION_LOADED && !data.readOnly) {
        const iframe = getMessageIframe(message);
        const prismaticData = data as PrismaticData;

        const prismaticConfigVars = Object.entries(prismaticData.configVars).filter(([configVarKey]) =>
          configVarKey.includes('Proposify Connect')
        );

        if (prismaticConfigVars.length === 0) return;
        if (isLoading || error || !prismaticCredentials) return;
        const { client_id: clientId, client_secret: clientSecret } = prismaticCredentials;

        prismaticConfigVars.forEach(([configVarKey]) => {
          prismatic.setConfigVars({
            iframe,
            configVars: {
              [configVarKey]: {
                inputs: {
                  clientId: { value: clientId },
                  clientSecret: { value: clientSecret },
                },
              },
            },
          });
        });
        queryClient.invalidateQueries(QueryKeys.getPrismaticCredentials);
      }
    },
    [isLoading, error, prismaticCredentials]
  );

  useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [messageListener]);

  return (
    isPrismaticEnabled && (
      <ThemeProvider theme={integrationsTheme}>
        <Paper data-testid="my-embedded-builder-div" elevation={0}>
          <EmbeddedDesignerWrapper id={embeddedDivId} maxWidth={false} disableGutters />
        </Paper>
      </ThemeProvider>
    )
  );
};
