import { Endpoints } from 'utils/endpoints';
import { UserIntegration, IntegrationConnectorData } from '../../entities/UserIntegration';
import { UserIntegrationRepository } from '../UserIntegrationRepository';
import { apiClient } from 'services/api';

export type ApiReturn = {
  integrationId: number;
  integrationName: string;
  isActiveUserConnection: boolean;
};

export class ApiUserIntegrationRepository implements UserIntegrationRepository {
  public async getUserIntegrations(userId: number): Promise<UserIntegration[]> {
    const data = await apiClient.get<ApiReturn[]>(Endpoints.getUserIntegrations(userId));
    return data.map(
      ({ integrationId, integrationName, isActiveUserConnection }) =>
        <UserIntegration>{
          integrationId,
          integrationName,
          isUserConnected: isActiveUserConnection,
        }
    );
  }

  public async getOauthConnectionData(userId: number, integrationName: string): Promise<IntegrationConnectorData[]> {
    return await apiClient.get(Endpoints.getIntegrationOauthData(userId, integrationName));
  }
}
