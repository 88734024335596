import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';

export const useGetBillingOptions = ({ accountRepository, accountId, planName, promoCode, enabled }) => {
  const { data, isLoading, isError, isFetched, refetch, error } = useQuery({
    queryKey: QueryKeys.getBillingOptions(accountId, planName, promoCode),
    queryFn: () => accountRepository.getBillingOptions({ accountId, planName, promoCode }),
    enabled: !!enabled,
    retry: 3,
  });

  return {
    billingOptions: data,
    isLoading,
    isError,
    isFetched,
    refetch,
    error,
  };
};
