import { useEffect, useRef, useState } from 'react';
import { ColorSet, ReactColorPicker } from 'react-color-picker-tool';
import './ColorPicker.less';
import { Fab, Popover } from '@mui/material';

export type RGBA = {
  r: number;
  g: number;
  b: number;
  a: number;
};

interface ColorPickerProps {
  handleColorChange: (color: string) => void;
  currentColor?: string;
  customRef?: React.RefObject<HTMLElement>; // A ref for the container where the color picker will "stick" to
}

interface ColorPopupProps {
  onClose: (color: RGBA) => void;
  open: boolean;
  buttonRef?: React.RefObject<HTMLElement>;
  currentColor: RGBA;
}

const defaultRgbaColor = { r: 255, g: 255, b: 255, a: 1 };

export default function ColorPicker({ handleColorChange, customRef, currentColor }: ColorPickerProps) {
  const [open, setOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState<RGBA>(defaultRgbaColor);
  const fabRef = useRef(null);

  useEffect(() => {
    if (currentColor) {
      setSelectedColor(colorFromString(currentColor));
    }
  }, [currentColor]);

  const convertToHexa = (color: RGBA) => {
    return rgbaToHexa(color.r, color.g, color.b, color.a);
  };

  const colorFromString = (color: string): RGBA => {
    if (!color) {
      return defaultRgbaColor;
    }

    const [r, g, b, a] = hexaToRgba(color)
      .replace('rgba(', '')
      .replace(')', '')
      .split(',')
      .map((value) => parseFloat(value));
    return { r, g, b, a };
  };

  function rgbaToHexa(red, green, blue, alpha) {
    // Convert each decimal component to hexadecimal
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');
    const alphaHex = Math.round(alpha * 255)
      .toString(16)
      .padStart(2, '0'); // Convert alpha to 0-255 range and then to hexadecimal

    return '#' + redHex + greenHex + blueHex + alphaHex;
  }

  function hexaToRgba(hex) {
    // Remove the '#' if present
    hex = hex.replace(/^#/, '');

    const redHex = hex.substring(0, 2);
    const greenHex = hex.substring(2, 4);
    const blueHex = hex.substring(4, 6);
    const alphaHex = hex.substring(6, 8);

    // Convert hexadecimal components to decimal
    const red = parseInt(redHex, 16);
    const green = parseInt(greenHex, 16);
    const blue = parseInt(blueHex, 16);
    const alpha = parseInt(alphaHex, 16) / 255; // Convert alpha to 0-1 range
    const roundedAlpha = Math.round(alpha * 100) / 100;

    return `rgba(${red}, ${green}, ${blue}, ${roundedAlpha})`;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (currentColor: RGBA) => {
    setOpen(false);
    setSelectedColor(currentColor);
    handleColorChange(convertToHexa(currentColor));
  };

  const backgroundColor = convertToHexa(selectedColor);
  const convertedColor = currentColor ? colorFromString(currentColor) : defaultRgbaColor;

  return (
    <div className="editor-color-picker">
      <Fab className="color-button" ref={fabRef} style={{ backgroundColor: backgroundColor }} onClick={handleClickOpen}></Fab>
      <ColorPopup open={open} onClose={handleClose} buttonRef={customRef || fabRef} currentColor={convertedColor} />
    </div>
  );
}

function ColorPopup({ onClose, open, buttonRef, currentColor }: ColorPopupProps) {
  const [color, setColor] = useState<RGBA>(defaultRgbaColor);
  const defaultWidthInPx = 250;

  useEffect(() => {
    if (currentColor) {
      setColor(currentColor);
    }
  }, [currentColor]);

  const handleClose = () => {
    onClose(color);
  };

  const colorChangeHandler = (color: ColorSet) => {
    setColor({
      ...color.rgba,
      a: Math.round(color.rgba.a * 100) / 100,
    });
  };

  return (
    <Popover
      disableScrollLock={true}
      onClose={handleClose}
      open={open}
      anchorEl={buttonRef?.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="color-picker-container">
        <ReactColorPicker color={color} width={defaultWidthInPx} onChange={(color) => colorChangeHandler(color)} />
      </div>
    </Popover>
  );
}
