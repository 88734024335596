import { useState, useEffect } from 'react';

export const TABLET = 998;
export const MOBILE = 719;
export const DESKTOP = 999;

export const useMediaQuery = () => {
  const [width, setWidth] = useState(0);

  const hasWindow: boolean = typeof window !== 'undefined';

  const isDesktop = width > TABLET;
  const isMobile = width <= MOBILE;
  const isTablet = width <= TABLET && width > MOBILE;
  const isGreatherThanMobile = width > MOBILE;
  const isSmallerThanDesktop = width < DESKTOP;

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        const media = window.innerWidth;
        setWidth(media);
      };

      if (width === 0) {
        handleResize();
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return {
    isDesktop,
    isMobile,
    isTablet,
    isGreatherThanMobile,
    isSmallerThanDesktop,
  };
};
