import { ReactNode, useState } from 'react';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'components';
import './styles.less';

type Props = {
  children: ReactNode;
};

export const Collapse = ({ children }: Props) => {
  const { t } = useTranslation();
  const [collapseStatus, setCollapseStatus] = useState(false);
  const handleCollapseClick = () => setCollapseStatus(!collapseStatus);

  return (
    <div className="collapse__container" data-testid="collapse">
      <div className="collapse__divider" onClick={handleCollapseClick}>
        <Divider>
          <Paragraph size="md">{t('settings.sso.advanced_configuration')}</Paragraph>
        </Divider>
      </div>

      <div className={`${collapseStatus ? 'collapse-open' : 'collapse-close'}`}>{children}</div>

      <div className={`${collapseStatus ? 'collapse__icon-open' : 'collapse__icon-close'}`} onClick={handleCollapseClick} />
    </div>
  );
};
