import SettingsTheme from 'muiTheme/SettingsTheme';
import BillingHeader from '../plan/billingHeader';
import { Box, Snackbar, Stack, ThemeProvider } from '@mui/material';
import PageLoader from 'components/page-loader/page-loader';
import './styles.less';
import { useState } from 'react';
import { ListingsRepository } from 'services/api/listings/ListingsRepository';
import { AccountRepository } from 'services/api/account/AccountRepository';
import { useCurrentUser } from 'providers/UserProvider';
import { PlanSummaryProvider } from './PlanSummaryProvider';
import { PlanRepository } from 'services/api/plan/PlanRepository';
import PaymentMethodSummary from './PaymentMethodSummary';
import InvoiceHistory from './InvoiceHistory';
import SubscriptionSummary from './SubscriptionSummary';
import PlanSummaryAlerts from './PlanSummaryAlerts';
import BillingContact from './BillingContact';

interface PlanSummaryPageProps {
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  accountRepository: AccountRepository;
}

const PlanSummaryPage = ({ planRepository, listingsRepository, accountRepository }: PlanSummaryPageProps) => {
  const [notifications, setNotifications] = useState<string[]>([]);

  const {
    data: { accountId },
  } = useCurrentUser();

  return (
    <PageLoader isLoading={false} isLoaded={true}>
      <ThemeProvider theme={SettingsTheme}>
        <PlanSummaryProvider
          accountId={accountId}
          accountRepository={accountRepository}
          planRepository={planRepository}
          listingsRepository={listingsRepository}
          setNotifications={setNotifications}
        >
          <Box>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              message={notifications.length > 0 ? notifications[0] : ''}
              autoHideDuration={6000}
              onClose={() => setNotifications(notifications.length > 1 ? [...notifications.slice(1)] : [])}
              open={notifications.length > 0}
              data-testid="summary-page-snackbar-notifications"
            />
            <Box>
              <BillingHeader>
                <PlanSummaryAlerts />
              </BillingHeader>
            </Box>
            <Stack data-testid="summary-page-components" direction="column" sx={{ my: 3 }} gap={3}>
              <SubscriptionSummary />
              <PaymentMethodSummary />
              <BillingContact />
              <InvoiceHistory />
            </Stack>
          </Box>
        </PlanSummaryProvider>
      </ThemeProvider>
    </PageLoader>
  );
};

export default PlanSummaryPage;
