import { useProspectsQuery } from 'hooks/useProspectsQuery';
import { useEffect, useState } from 'react';
import type { ProspectType } from 'services/repositories/interfaces/ProspectRepository';
import { apiProspectsRepository } from 'services/repositories/implementations/ApiProspectsRepository';

export interface ProspectOption {
  label: string;
  value: string;
  id: number;
  type: ProspectType;
}

const defaultPaginationValues = { offset: 0, limit: 50 };

export function useProspect() {
  const [options, setOptions] = useState<ProspectOption[] | undefined>(undefined);
  const [pagination, setPagination] = useState(defaultPaginationValues);
  const prospectsQueryResult = useProspectsQuery({
    offset: pagination.offset,
    limit: pagination.limit,
    search: '',
  });

  useEffect(() => {
    const opts: ProspectOption[] | undefined = prospectsQueryResult.data?.map((prospect) => {
      return {
        label: prospect.name,
        value: prospect.uid,
        id: prospect.id,
        type: prospect.type,
      };
    });

    if (opts == undefined) {
      return;
    }

    setOptions((prevOptions) => {
      if (prevOptions != undefined) {
        return prevOptions.concat(opts);
      }
      return opts;
    });
  }, [prospectsQueryResult.isFetched == true]);

  const onMenuScrollToBottom = async () => {
    if (!prospectsQueryResult.isLoading) {
      const newOffset = pagination.offset + pagination.limit;
      setPagination({ offset: newOffset, limit: pagination.limit });
    }
  };

  const loadOptions = (inputValue: string, callback) => {
    apiProspectsRepository
      .getProspects({ offset: defaultPaginationValues.offset, limit: defaultPaginationValues.limit, search: inputValue })
      .then((response) => {
        callback(
          response.map((prospect) => {
            return {
              label: prospect.name,
              value: prospect.uid,
              id: prospect.id,
              type: prospect.type,
            };
          })
        );
      })
      .catch(() => callback([]));
  };

  return {
    onMenuScrollToBottom,
    isLoading: prospectsQueryResult.isLoading,
    options: options,
    loadOptions,
  };
}
