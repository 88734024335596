import { DropdownSelect, OptionProp } from 'pages/crm/document-create/blocks/form/dropdown-select';
import './styles.less';

type Props = {
  rolesOptions: OptionProp[];
};

export const RolesColumn = ({ rolesOptions }: Props) => {
  const defaultAdminRole = rolesOptions[0];
  return (
    <div className="roles__container">
      <DropdownSelect
        className={'roles__dropdown'}
        options={rolesOptions}
        onChange={() => {
          // do nothing
        }}
        disabled={true}
        defaultValue={defaultAdminRole}
      />
    </div>
  );
};
