import { PreviewFooter } from './footer';
import { PreviewHeader } from './header';
import './preview.styles.less';
import { PreviewMainGrid } from './mainGrid';

interface Props {
  header: React.ReactNode;
  main: React.ReactNode;
  footer: React.ReactNode;
  banner: React.ReactNode;
}

export const PreviewGrid = ({ header, main, footer, banner }: Props) => {
  return (
    <div className="preview">
      <PreviewHeader>{header}</PreviewHeader>
      {banner}
      <PreviewMainGrid>{main}</PreviewMainGrid>
      <PreviewFooter>{footer}</PreviewFooter>
    </div>
  );
};
