import { createContext, useContext, ReactNode } from 'react';

const SectionIdContext = createContext<string | null>(null);

export const SectionIdProvider = ({ sectionId, children }: { sectionId: string; children: ReactNode }) => {
  return <SectionIdContext.Provider value={sectionId}>{children}</SectionIdContext.Provider>;
};

export const useSectionId = () => {
  const context = useContext(SectionIdContext);
  if (context === null) {
    throw new Error('useSectionId must be used within a SectionIdProvider');
  }
  return context;
};
