import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Typography, Button, Stack, InputAdornment, Alert, Tooltip, AlertTitle } from '@mui/material';
import { PaymentFormLineItem, PaymentMethodFormProps } from './interface';
import { useTranslation } from 'react-i18next';
import AEIcon from '../../../images/icons/icon-adornment-ae.png';
import MCIcon from '../../../images/icons/icon-adornment-mc.png';
import VisaIcon from '../../../images/icons/icon-adornment-visa.png';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { StateListing } from 'services/api/listings/interfaces';
import { CCInput, ExpiryDateInput, SecurityCodeInput } from './MaskedInputs';
import useUpdateBillingInfoMutation from 'hooks/useUpdateBillingInfoMutation';
import { getCCTypeFromCCNumber } from './utils';
import { BillingContext } from './billingProvider';
import PaymentFormLine from './paymentFormLine';
import { ErrorOutline } from '@mui/icons-material';

const PaymentMethodForm = ({
  initialData,
  setIsEditing,
  disabled = false,
  locationData,
  getStatesFromCountry,
  updatePaymentMethod,
}: PaymentMethodFormProps) => {
  const { t } = useTranslation();

  let expiryString = '';
  if (initialData.cardInfo.expiryMonth) {
    const paddedMonth = initialData.cardInfo.expiryMonth.toString().padStart(2, '0');
    const paddedYear = initialData.cardInfo.expiryYear.toString().padStart(2, '0');
    expiryString = `${paddedMonth}/${paddedYear}`;
  }

  const [ccNumber, setCCNumber] = useState<string>(
    initialData.cardInfo.lastFour ? `**** **** **** ${initialData.cardInfo.lastFour?.toString()}` : ''
  );
  const [expiry, setExpiry] = useState<string>(expiryString);
  const [securityCode, setSecurityCode] = useState<string>('');
  const [cardholderName, setCardholderName] = useState<string>(initialData.cardInfo.cardholderName || '');
  const [address1, setAddress1] = useState<string>(initialData.billingAddress.addressLine1 || '');
  const [address2, setAddress2] = useState<string>(initialData.billingAddress.addressLine2 || '');
  const [city, setCity] = useState<string>(initialData.billingAddress.city || '');
  // the backend uses state, but we use province just to avoid name confusion on the form
  const [province, setProvince] = useState<string>(initialData.billingAddress.state || '');
  const [zip, setZip] = useState<string>(initialData.billingAddress.zip || '');
  const [country, setCountry] = useState<string>(initialData.billingAddress.country || '');
  const [validProvinces, setValidProvinces] = useState<StateListing[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const { accountId, formErrors, setFormErrors } = useContext(BillingContext);

  const { updateBillingInfo, isLoading } = useUpdateBillingInfoMutation();

  const handleFieldError = (fieldId: string, error?: string) => {
    if (error) {
      const newErrors = { ...formErrors, [fieldId]: error };
      setFormErrors(newErrors);
    } else {
      const newErrors = { ...formErrors };
      delete newErrors[fieldId];
      setFormErrors(newErrors);
    }
  };

  const validateField = (field: PaymentFormLineItem | { id: string; required: boolean }, value: string) => {
    if (field.required && value.trim() === '') {
      handleFieldError(field.id, t('settings.billing.required'));
    } else {
      handleFieldError(field.id, '');
    }
    if (field.id === 'expiry') {
      const expiryParts = value.split('/');
      if (expiryParts.length !== 2) {
        handleFieldError(field.id, t('settings.billing.payment_method_form.invalid_expiry'));
      } else {
        const validationDate = new Date(Number(`20${expiryParts[1]}`), Number(expiryParts[0]), 1);
        if (dayjs().isAfter(validationDate)) {
          handleFieldError(field.id, t('settings.billing.payment_method_form.invalid_expiry'));
        }
      }
    }
  };

  const handleUpdatePaymentMethod = async (event) => {
    event.preventDefault();
    const requiredFields = [
      { field: 'card_number', value: ccNumber },
      { field: 'expiry', value: expiry },
      { field: 'security_code', value: securityCode },
      { field: 'cardholder_name', value: cardholderName },
      { field: 'address1', value: address1 },
      { field: 'city', value: city },
      { field: 'postal_code', value: zip },
      { field: 'country', value: country },
    ];

    let valid = true;
    const newErrors = {};
    requiredFields.map((item) => {
      if (item.value.trim() === '') {
        valid = false;
        newErrors[item.field] = t('settings.billing.required');
      }
    });
    if (expiry) {
      const expiryParts = expiry.split('/');
      if (expiryParts.length !== 2) {
        valid = false;
        newErrors['expiry'] = t('settings.billing.payment_method_form.invalid_expiry');
      } else {
        const validationDate = new Date(Number(`20${expiryParts[1]}`), Number(expiryParts[0]), 1);
        if (dayjs().isAfter(validationDate)) {
          valid = false;
          newErrors['expiry'] = t('settings.billing.payment_method_form.invalid_expiry');
        }
      }
    }
    if (!valid) {
      setFormErrors(newErrors);
      return;
    } else {
      setFormErrors({});
    }
    const curYearStart = dayjs().format('YYYY').substring(0, 2);
    const expiryPieces = expiry.split('/');
    const updateCCInfo = ccNumber.indexOf('*') === -1;
    const payload = {
      billingAddress: {
        addressLine1: address1,
        addressLine2: address2 || null,
        city: city,
        state: province,
        zip: zip,
        country: country,
      },
      cardInfo: {
        cardholderName: cardholderName,
        type: updateCCInfo ? getCCTypeFromCCNumber(ccNumber) : '',
        expiryMonth: Number(expiryPieces[0]),
        expiryYear: Number(`${curYearStart}${expiryPieces[1]}`),
        securityCode: Number(securityCode),
        ccNumber: updateCCInfo ? Number(ccNumber.replaceAll(' ', '')) : undefined,
      },
    };

    const response = await updateBillingInfo({ accountId: accountId, payload });
    if (response['status'] === 'success') {
      setErrors([]);
      updatePaymentMethod(payload);
    } else {
      const errorStrings = response['message'].split(/\r?\n/);
      const newErrors: string[] = [];
      const newFormErrors = {};
      errorStrings.map((err) => {
        let parsedErr = err.replaceAll(/(<([^>]+)>)/gi, '');
        parsedErr = parsedErr.replaceAll(/\r?\n/gi, '').trim();
        if (parsedErr !== '') {
          if (err.toLowerCase().indexOf('credit card number') > -1) {
            newFormErrors['card_number'] = t('settings.billing.payment_method_form.invalid_credit_card');
          } else if (err.toLowerCase().indexOf('verification value') > -1) {
            newFormErrors['security_code'] = t('settings.billing.payment_method_form.invalid_security_code');
          } else {
            newErrors.push(parsedErr);
          }
        }
      });
      setFormErrors(newFormErrors);
      setErrors(newErrors);
    }
  };

  const updateValidProvinces = (countryId: number, updateProvince = true) => {
    const provStates = getStatesFromCountry(countryId, locationData.states || []);
    if (provStates) {
      setValidProvinces(provStates);
      if (updateProvince) {
        setProvince(provStates.length > 0 ? provStates[0].abbr : '');
      }
    } else {
      setValidProvinces([]);
      if (updateProvince) {
        setProvince('');
      }
    }
  };

  useEffect(() => {
    const defaultCountry = locationData.countries.find((c) => c.code === initialData.billingAddress.country);
    if (defaultCountry) {
      updateValidProvinces(defaultCountry.id, false);
    }
  }, []);

  return (
    <Stack direction="column" noValidate component="form" onSubmit={handleUpdatePaymentMethod} sx={{ width: '100%', gap: 3 }}>
      {errors && errors.length > 0 && (
        <Stack direction="column" gap={2}>
          <Alert icon={<ErrorOutline />} variant="standard" severity="error">
            <AlertTitle variant="body1" sx={{ fontWeight: 600 }}>
              {t('settings.billing.payment_method_form.unable_to_save')}
            </AlertTitle>
            {errors.map((error, key) => (
              <Typography variant="body2" key={`error-${key}`}>
                {error.trim().charAt(0).toUpperCase() + error.trim().slice(1)}
              </Typography>
            ))}
          </Alert>
        </Stack>
      )}
      <PaymentFormLine
        validation={validateField}
        formItems={[
          {
            id: 'card_number',
            adornments: (
              <Stack direction="row">
                <InputAdornment position="end">
                  <img src={VisaIcon} />
                </InputAdornment>
                <InputAdornment position="end">
                  <img src={MCIcon} />
                </InputAdornment>
                <InputAdornment position="end">
                  <img src={AEIcon} />
                </InputAdornment>
              </Stack>
            ),
            onChange: (value) => {
              setCCNumber(value);
            },
            value: ccNumber,
            maskInputComponent: CCInput,
            error: formErrors['card_number'],
            disabled: isLoading,
          },
        ]}
      />
      <PaymentFormLine
        validation={(field, value) => {
          const expiryPieces = expiry.split('/');
          if (expiryPieces.length < 2) {
            return;
          }
          validateField(field, value);
        }}
        formItems={[
          {
            id: 'expiry',
            onChange: (expiry) => {
              if (expiry !== expiryString && ccNumber.indexOf('*') > -1) {
                setCCNumber('');
              }
              setExpiry(expiry);
            },
            value: expiry,
            maskInputComponent: ExpiryDateInput,
            error: formErrors['expiry'],
            shrinkLabel: true,
            disabled: isLoading,
          },
          {
            id: 'security_code',
            onChange: setSecurityCode,
            value: securityCode,
            maskInputComponent: SecurityCodeInput,
            required: true,
            error: formErrors['security_code'],
            shrinkLabel: true,
            disabled: isLoading,
            adornments: (
              <Tooltip title={t('settings.billing.payment_method_form.security_code_tooltip')}>
                <InfoOutlined />
              </Tooltip>
            ),
          },
        ]}
      />

      <PaymentFormLine
        validation={validateField}
        formItems={[
          {
            id: 'cardholder_name',
            onChange: setCardholderName,
            value: cardholderName,
            error: formErrors['cardholder_name'],
            disabled: isLoading,
          },
        ]}
      />

      <PaymentFormLine
        validation={validateField}
        formItems={[
          {
            id: 'address1',
            onChange: setAddress1,
            value: address1,
            error: formErrors['address1'],
            shrinkLabel: true,
            disabled: isLoading,
          },
        ]}
      />

      <PaymentFormLine
        validation={validateField}
        formItems={[
          {
            id: 'address2',
            onChange: setAddress2,
            value: address2,
            required: false,
            error: formErrors['address2'],
            shrinkLabel: true,
            disabled: isLoading,
          },
        ]}
      />

      <PaymentFormLine
        validation={validateField}
        formItems={[
          {
            id: 'city',
            onChange: setCity,
            value: city,
            error: formErrors['city'],
            disabled: isLoading,
          },
        ]}
      />

      <PaymentFormLine
        validation={validateField}
        formItems={[
          {
            id: 'country',
            onChange: (newCountryCode: string) => {
              const newCountry = locationData.countries.find((c) => c.code === newCountryCode);
              if (newCountry) {
                setCountry(newCountryCode);
                updateValidProvinces(Number(newCountry.id));
              }
            },
            value: country,
            options:
              locationData.countries.map((c) => {
                return {
                  id: c.code,
                  name: c.name,
                };
              }) || [],
            error: formErrors['country'],
            shrinkLabel: true,
            disabled: isLoading,
          },
        ]}
      />

      <PaymentFormLine
        validation={validateField}
        formItems={[
          {
            id: 'province',
            onChange: setProvince,
            value: province,
            options: validProvinces.map((p) => {
              return {
                id: p.abbr,
                name: p.name,
              };
            }),
            disabled: validProvinces.length === 0 || isLoading,
            required: validProvinces.length > 0,
            hidden: validProvinces.length === 0,
            error: formErrors['province'],
          },
          {
            id: 'postal_code',
            onChange: setZip,
            value: zip,
            error: formErrors['postal_code'],
            disabled: isLoading,
          },
        ]}
      />

      <Stack direction="row" sx={{ justifyContent: 'flex-end', alignItems: 'center' }} gap="12px">
        <Button variant="text" size="medium" color="primary" disabled={isLoading} onClick={() => setIsEditing(false)}>
          {t('settings.billing.payment_method_form.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="medium"
          color="primary"
          disabled={disabled || Object.keys(formErrors).length > 0 || isLoading}
        >
          {isLoading ? t('settings.billing.payment_method_form.saving') : t('settings.billing.payment_method_form.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default PaymentMethodForm;
