import { useMemo } from 'react';
import { selectContentTable } from '../../../grid/reduxStore/editorSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../grid/reduxStore/Store';
import { useApplyCustomRulesToColumns } from './useApplyCustomRulesToColumns';

export function useApplyRulesToColumn(sectionId: string, blockId: string) {
  const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || undefined;

  const pricingDecimalPlaces = useMemo(() => tableData?.metadata?.pricingDecimalPlaces, [tableData]);
  const { applyCustomRulesToColumns } = useApplyCustomRulesToColumns(sectionId, blockId, pricingDecimalPlaces);

  return { applyCustomRulesToColumns };
}
