import * as React from 'react';
const SvgIcoSearch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M15.184 7.369a5.526 5.526 0 1 0-7.815 7.815 5.526 5.526 0 0 0 7.815-7.815m1.06-1.061a7.026 7.026 0 1 0-9.936 9.936 7.026 7.026 0 0 0 9.936-9.936" />
      <path d="M15.18 15.18a.75.75 0 0 1 1.06 0l3.29 3.29a.75.75 0 1 1-1.06 1.06l-3.29-3.29a.75.75 0 0 1 0-1.06" />
    </g>
  </svg>
);
export default SvgIcoSearch;
