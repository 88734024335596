import { Box, keyframes } from '@mui/material';
import { useEffect, useState } from 'react';

const subscribers = new Set<(id: string | null) => void>();

const ANIMATION_DURATION_IN_MS = 3000;

export function highlight(id: string) {
  subscribers.forEach((callback) => callback(id));

  setTimeout(() => {
    subscribers.forEach((callback) => callback(null));
  }, ANIMATION_DURATION_IN_MS * 2); // Ensure the animation is complete before resetting
}

const glow = keyframes`
  0% {
    background-color: #F0F8FA;
    box-shadow: inset 0 0 0 2px #00B8D1;
    border-radius: 8px;
  }
  100% {
    box-shadow: inset 0 0 0 0px transparent;
  }
`;

const childAnimation = keyframes`
  0% {
    background-color: transparent;
    border-left: 4px solid transparent;
  }
  100% {
    /* Empty to revert to original */
  }
`;

export function Higlighter({ children, id }: { children: React.ReactNode; id: string }) {
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    const callback = (highlightedDocId: string | null) => {
      setIsHighlighted(highlightedDocId === id);
    };
    subscribers.add(callback);
    return () => {
      subscribers.delete(callback);
    };
  }, [id]);

  return (
    <Box
      sx={{
        transition: 'all 0.3s ease',
        ...(isHighlighted && {
          animation: `${glow} ${ANIMATION_DURATION_IN_MS}ms ease-in-out`,
          '& > *': {
            animation: `${childAnimation} ${ANIMATION_DURATION_IN_MS}ms ease-in-out`,
          },
        }),
      }}
    >
      {children}
    </Box>
  );
}
