import './GrayBlock.less';

export type PlaceholderProps = {
  position: {
    x: number;
    y: number;
  };
  dimension: {
    width: number;
    height: number;
  };
};

export default function GrayBlock({ position, dimension }: PlaceholderProps) {
  return (
    <div
      data-testid="placeholder"
      className="drag-placeholder"
      style={{
        height: dimension.height,
        width: dimension.width,
        left: position.x,
        top: position.y,
      }}
    ></div>
  );
}
