import { Tooltip as BaseTooltip } from 'antd';
import { Props } from './interface';
import React from 'react';

export const Tooltip: React.FunctionComponent<Props> = ({ title, placement = 'bottom', className, children }: Props) => {
  return (
    <BaseTooltip title={title} placement={placement} overlayClassName={className}>
      {children}
    </BaseTooltip>
  );
};
