import { apiClient } from 'services/api';
import { ApiArchiveProposalPayload } from 'services/objects/http/ArchiveProposalPayload';
import { ProposalPreviewUrlApiResponse } from 'services/objects/http/ProposalPreviewUrlResponse';
import { Endpoints } from 'utils/endpoints';
import { ContactFactory, UserFactory } from '../factories/PersonFactory';
import { ProposalRepository } from '../interfaces/ProposalRepository';

const getFirstAndLastName = (displayName = '') => {
  const [first_name, last_name] = displayName.split(' ');
  return {
    first_name,
    last_name,
  };
};

interface GetProposalExpirationApiResponse {
  archives_at: string;
}
const ApiProposalRepository: ProposalRepository = {
  async getPreviewUrl(proposalId) {
    const { base_url, people, users } = await apiClient.get<ProposalPreviewUrlApiResponse>(Endpoints.getProposalPreviewUrl(proposalId));

    return {
      baseUrl: base_url,
      users: users.map(({ has_signature, email, display_name, id }) => ({
        ...UserFactory({ email, id, ...getFirstAndLastName(display_name) }),
        hasSignature: has_signature,
      })),
      people: people.map(({ id, email, display_name, has_signature }) => ({
        ...ContactFactory({ email, id, ...getFirstAndLastName(display_name) }),
        hasSignature: has_signature,
      })),
    };
  },
  async shareEmail({ proposalId, ...payload }) {
    return apiClient.post<void>(Endpoints.getProposalShareUrl(proposalId), payload);
  },
  async markProposalAsSent(proposalId: number) {
    return apiClient.put<void>(Endpoints.markProposalAsSent(proposalId));
  },
  async markProposalAsDraft(proposalId: number) {
    return apiClient.put<void>(Endpoints.markProposalAsDraft(proposalId));
  },
  async archiveProposal({ proposalId, archivesAt }) {
    const payload: ApiArchiveProposalPayload = {
      archives_at: archivesAt,
    };
    return apiClient.post(Endpoints.archiveProposal(proposalId), payload);
  },
  async getProposalExpiration(proposalId) {
    const { archives_at } = await apiClient.get<GetProposalExpirationApiResponse>(Endpoints.archiveProposal(proposalId));
    return {
      archivesAt: archives_at,
    };
  },
  async deleteProposalExpiration(proposalId) {
    return apiClient.delete(Endpoints.archiveProposal(proposalId));
  },
};

export default ApiProposalRepository;
