import { useNavigate } from 'react-router-dom';
import SimpleButton from '../button/simplebutton';
import Icon from '../icon';

const WorkspaceButton = () => {
  const navigate = useNavigate();

  return <SimpleButton icon={<Icon name="IcoWorkspace" title="Workspace" />} onClick={() => navigate('/workspaces/switcher')} />;
};

export default WorkspaceButton;
