import { Outlet } from 'react-router-dom';
import { ApiUserRepository } from 'services/repositories/implementations/ApiUserRepository';
import { ApiUserPermissionsRepository } from 'services/permissions/implementations/ApiUserPermissionsRepository';
import { RefreshAuthTokenInBackground } from 'services/backgroundrefreshtoken/refresh-auth-token-in-background';
import UserProvider, { useCurrentUser } from 'providers/UserProvider';
import PermissionsProvider from 'providers/PermissionsProvider';
import { setUserData } from '../utils/DataTrackerHandler';

const userRepository = new ApiUserRepository();
const userPermissionsRepository = new ApiUserPermissionsRepository();

const DataTrackerSetUser = (): null => {
  const {
    data: { id, accountId },
  } = useCurrentUser();
  setUserData({ id: id, account_id: accountId });
  return null;
};

export function AuthenticatedLayoutRoute() {
  return (
    <UserProvider userRepository={userRepository}>
      <DataTrackerSetUser />
      <PermissionsProvider repository={userPermissionsRepository}>
        <RefreshAuthTokenInBackground />
        <Outlet />
      </PermissionsProvider>
    </UserProvider>
  );
}
