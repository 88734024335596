import { DocumentCreateHeader, DocumentForm } from './blocks';
import './styles.less';

export const DocumentCreatePage = (): JSX.Element => {
  return (
    <div className="document-create-page">
      <section className="document-create-page__container">
        <DocumentCreateHeader />
        <DocumentForm />
      </section>
    </div>
  );
};
