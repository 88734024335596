import { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';

const EditorConfigContext = createContext<EditorConfigContextProps>({} as EditorConfigContextProps);

export interface EditorConfigContextProps {
  isConfigLoaded: boolean;
  setIsConfigLoaded: Dispatch<SetStateAction<boolean>>;
  isSignatureLoaded: boolean;
  setIsSignatureLoaded: Dispatch<SetStateAction<boolean>>;
}

export const EditorConfigProvider = ({ children }: { children: ReactNode }) => {
  const [isConfigLoaded, setIsConfigLoaded] = useState<boolean>(false);
  const [isSignatureLoaded, setIsSignatureLoaded] = useState<boolean>(false);

  return (
    <EditorConfigContext.Provider value={{ isConfigLoaded, setIsConfigLoaded, isSignatureLoaded, setIsSignatureLoaded }}>
      {children}
    </EditorConfigContext.Provider>
  );
};

export const useEditorConfig = () => {
  const context = useContext(EditorConfigContext);
  if (context === null) {
    throw new Error('useEditorConfig must be used within a EditorConfigProvider');
  }
  return context;
};
