import { apiClient } from 'services/api';
import { AccessAllowance, WorkspaceSwitchableAccount } from 'services/settings/entities/Workspaces';
import { Endpoints } from 'utils/endpoints';

export async function getWorkspaceAccounts(): Promise<WorkspaceSwitchableAccount[]> {
  return await apiClient.get<WorkspaceSwitchableAccount[]>(Endpoints.getWorkspaceMyAccounts);
}

export async function postAccessUsage(subdomain: string): Promise<void> {
  await apiClient.post(Endpoints.postWorkspaceAccessUsage, {
    targetAccountSubdomain: subdomain,
  });
}

export async function postAccessAllowances(userData): Promise<AccessAllowance> {
  return await apiClient.post(Endpoints.postWorkspaceAccessAllowances, userData);
}
