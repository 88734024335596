// import { Checkbox } from 'antd';
import { Button, Heading, Icon, Modal } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.less';
import { useProposalExpiry } from './useProposalExpiry';

const displayDate = (date: Date) => date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });

interface Props {
  proposalId: number;
}
export const DocumentExpireAction = ({ proposalId }: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const closeModal = () => setIsVisible(false);
  const openModal = () => setIsVisible(true);
  // const [shouldWarn, setShouldWarn] = useState(false);
  const { days, expiryDate, isLoading, isSubmitting, isApplied, onChange, onSubmit } = useProposalExpiry(proposalId, {
    onSuccess: closeModal,
  });

  return (
    <>
      <Button
        icon={<Icon name={isApplied ? 'IcoCheckmark' : 'IcoPlus'} />}
        variant={isApplied ? 'positive' : 'neutral'}
        type="text"
        onClick={openModal}
        disabled={isLoading}
      >
        {t('share.actions.expire_document.auto_expire')}
      </Button>
      <Modal
        isVisible={isVisible}
        handleOk={onSubmit}
        handleCancel={closeModal}
        cancelText={t('cancel')}
        affirmText={isSubmitting ? t('applying') : t('apply')}
      >
        <Heading level={4}>{t('share.actions.expire_document.title')}</Heading>
        <div className="action-modal__content">
          <div className="action-modal__text">{t('share.actions.expire_document.description')}</div>
          <div>
            <div className="action-modal__text action__subHeading">{t('share.actions.expire_document.expiration_period')}</div>
            <div className="expire-in">
              <div className="action-modal__text">{t('share.actions.expire_document.expire_document_in')}</div>
              <div className="input-days__wrapper">
                <input
                  min={0}
                  step={1}
                  max={999}
                  type={'number'}
                  value={days ?? ''}
                  className="input-days__input"
                  onChange={({ target: { value } }) => onChange(value)}
                />
                days
              </div>
            </div>
          </div>
          {expiryDate ? (
            <>
              <div className="action-modal__text">
                {t('share.actions.expire_document.expire_in')}
                {displayDate(expiryDate)}
              </div>
              {/* Note: Commenting the code till Expiry Warning email be picked up in the next sprint */}
              {/* <div>
                <div className="action-modal__text action__subHeading">{t('share.actions.expire_document.warning_email')}</div>
                <div className="action__warning">
                  <Checkbox checked={shouldWarn} onChange={(e) => setShouldWarn(e.target.checked)} />
                  <div className="action-modal__text">{t('share.actions.expire_document.warn_signers')}</div>
                </div>
              </div> */}
            </>
          ) : (
            <div className="action-modal__text action-modal__text--remove">{t('share.actions.expire_document.remove')}</div>
          )}
        </div>
      </Modal>
    </>
  );
};
