import { apiClient } from 'services/api';
import { PersonApiResponse } from 'services/objects/http/PersonApiResponse';
import { Endpoints } from 'utils/endpoints';
import { ContactFactory, GetPersonApiResponse } from '../factories/PersonFactory';
import { PersonRepository } from '../interfaces/PersonRepository';

interface CreatePersonApiPayload {
  company_id?: number | null;
  first_name: string;
  last_name: string;
  email: string;
}

export const ApiPersonRepository: PersonRepository = {
  createPerson: async ({ email, firstName, lastName, companyId }) => {
    const payload: CreatePersonApiPayload = {
      email,
      first_name: firstName,
      last_name: lastName,
      company_id: companyId,
    };

    const response = await apiClient.post<PersonApiResponse>(Endpoints.createPerson, payload);

    return ContactFactory(response);
  },

  getPersons: async () => {
    const response = await apiClient.get<GetPersonApiResponse[]>(Endpoints.getPersons);
    return response.map((data) => ContactFactory(data));
  },
};
