import { Skeleton } from '@mui/material';
import { Milestone, MilestoneContent, MilestoneDate, MilestoneInfo, Timeline } from './timeline';

export function ActivitiesSkeleton({ numberOfItems }: { numberOfItems: number }) {
  return (
    <Timeline data-testid="insights-activities-skeleton">
      {new Array(numberOfItems).fill(null).map((_, index) => (
        <Milestone key={index} isTitleAlignedWithIcons={true} isLast={index === numberOfItems - 1}>
          <MilestoneDate>
            <Skeleton variant="rectangular" width={100} height={20} />
          </MilestoneDate>
          <MilestoneContent>
            <Skeleton variant="circular" width={30} height={30} />
            <MilestoneInfo>
              <Skeleton variant="rectangular" width={375} height={20} />
              <Skeleton variant="rectangular" width={330} height={40} />
            </MilestoneInfo>
          </MilestoneContent>
        </Milestone>
      ))}
    </Timeline>
  );
}
