import * as React from 'react';
import { Props } from './interface';
import { Link } from 'react-router-dom';

const Anchor: React.FunctionComponent<Props> = ({ href, children, title, className = '' }) => {
  return (
    <Link title={title} to={href} className={className}>
      {children}
    </Link>
  );
};

export default Anchor;
