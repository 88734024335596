import { useCompanyByIdQuery } from 'hooks/useCompanyByIdQuery';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/select';
import { ContactOption, DocumentSettingsFormInstance, DocumentSettingsFormValues } from './types';
import { ProspectType } from 'services/repositories/interfaces/ProspectRepository';

function ContactFormItem({ companyId }: { companyId: number }) {
  const { t } = useTranslation();
  const { data, isLoading } = useCompanyByIdQuery(companyId);
  return (
    <Form.Item name="contact" preserve={false} label={t('document.form.contact')}>
      <Select<ContactOption>
        isClearable
        placeholder={t('document.form.contact_placeholder')}
        isLoading={isLoading}
        options={data?.people.map((contact) => ({ label: contact.fullName, value: contact.id }))}
      />
    </Form.Item>
  );
}

export function renderContactFormItem({ getFieldValue }: { getFieldValue: DocumentSettingsFormInstance['getFieldValue'] }) {
  const value: DocumentSettingsFormValues['prospect'] = getFieldValue('prospect');

  return value?.type === ProspectType.Company ? <ContactFormItem companyId={value.id} /> : null;
}
