import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button, Label, openNotification, Loading } from 'components';
import { Switch } from './switch';
import { theme } from '../utils';
import { useSsoConfigQuery } from '../useSsoConfigQuery';
import { useSsoSettingsQuery } from '../useSsoSettingsQuery';
import { useSsoSettingsOnSubmit } from '../useSsoSettingsOnSubmit';
import { useSsoRolesQuery } from '../useSsoRolesQuery';

import './style.less';

const providers = [
  {
    data: {
      type: '',
    },
    label: 'No Single Sign-On',
    value: 'No Single Sign-On',
  },
  {
    data: {
      type: 'salesforce',
    },
    label: 'Salesforce',
    value: 'Salesforce',
  },
];

const defaultRoles = [
  {
    label: 'Admin',
    value: 0,
  },
];

interface settingsPayloadProps {
  user_provisionning: {
    default_role: number;
    enabled: boolean;
  };
  single_logout: boolean;
  force_sso_login: boolean;
  provider: object;
}

export const Settings = () => {
  const { t } = useTranslation();
  const { data: ssoConfigData, isLoading: isSsoConfigLoading } = useSsoConfigQuery();
  const { data: ssoSettingsData, refetch, isLoading: isSsoSettingsLoading } = useSsoSettingsQuery();
  const { data: roles, isLoading: isSsoRolesLoading } = useSsoRolesQuery();
  const { mutate: saveSsoSettings, isLoading: isSsoSettingsSubmitLoading } = useSsoSettingsOnSubmit();
  const [settingsPayload, setSettingsPayload] = useState<settingsPayloadProps>({
    user_provisionning: {
      default_role: 0,
      enabled: false,
    },
    single_logout: false,
    force_sso_login: false,
    provider: {},
  });

  const handleSaveSettings = () => {
    saveSsoSettings(settingsPayload, {
      onSuccess: () => {
        refetch();
        openNotification({
          type: 'success',
          title: t('settings.sso.success_label'),
          description: t('settings.sso.saved_successfully_label'),
          placement: 'topRight',
        });
      },
      onError: (data: any) => {
        openNotification({
          type: 'error',
          title: t('settings.sso.error_label'),
          description: data.reason_phrase,
          placement: 'topRight',
        });
      },
    });
  };

  const onInputChange = (event) => {
    const { ariaLabel, value } = event.target;
    setSettingsPayload((state) => ({ ...state, [ariaLabel]: value }));
  };

  const providersOptions = useMemo(() => {
    const options: any[] = [...providers];

    if (ssoConfigData && Array.isArray(ssoConfigData))
      ssoConfigData.forEach((option) => {
        options.push({
          label: option.identity_provider_identifier,
          value: option.id,
          data: {
            type: 'oidc',
            id: option.id,
          },
        });
      });

    return options;
  }, [ssoConfigData]);

  const rolesOptions = useMemo(() => {
    const options: any[] = [...defaultRoles];

    if (roles && Array.isArray(roles)) {
      roles.forEach((role) => {
        options.push({
          label: role.name,
          value: role.id,
        });
      });
    }
    return options;
  }, [roles]);

  const getRoleValue = (value: number) => {
    return rolesOptions.find((role) => role.value == value);
  };

  const capitalizeFirstLetter = (string: string | unknown): string | unknown => {
    if (typeof string == 'string') return string.charAt(0).toUpperCase() + string.slice(1);
    return string;
  };

  const getProviderValue = (value: object) => {
    if ('type' in value) {
      if ('id' in value && value['type'] == 'oidc') {
        let provider;
        if (ssoConfigData && Array.isArray(ssoConfigData)) {
          provider = ssoConfigData?.find((data) => data.id == value.id);
        }

        return {
          label: provider?.identity_provider_identifier,
          value: value.id,
          data: {
            type: 'oidc',
            id: value.id,
          },
        };
      } else if (value.type == '') {
        return {
          label: 'No Single Sign-On',
          value: 'No Single Sign-On',
        };
      } else {
        return {
          label: capitalizeFirstLetter(value.type),
          value: value.type,
        };
      }
    }
    return {};
  };

  const isLoading = isSsoConfigLoading || isSsoSettingsLoading || isSsoRolesLoading || isSsoSettingsSubmitLoading;

  useEffect(() => {
    if (!ssoSettingsData) return;
    setSettingsPayload((state) => ({ ...state, ...ssoSettingsData }));
  }, [ssoSettingsData]);

  return (
    <div className="settings__container">
      {isLoading && (
        <div className="sso-create__loader">
          <Loading isLoading={isLoading} />
        </div>
      )}

      <div className="settings__form" data-testid="sso-preferred-settings">
        <div className="settings__form__item">
          <Label label={t('settings.sso.settings.provider_label')} />
          <div className="settings__form__item-input">
            <Select
              options={providersOptions}
              theme={theme}
              value={getProviderValue(settingsPayload['provider'])}
              onChange={(value: any) => {
                setSettingsPayload((state) => ({ ...state, ['provider']: value.data }));
              }}
            />
          </div>
        </div>

        <div className="settings__form__item">
          <Label label={t('settings.sso.settings.single_logout_label')} />
          <div className="settings__form__item-input">
            <Switch checked={settingsPayload['single_logout']} onChange={onInputChange} ariaLabel="single_logout" />
          </div>
        </div>

        <div className="settings__form__item">
          <Label label={t('settings.sso.settings.force_sso_login_label')} />
          <div className="settings__form__item-input">
            <Switch checked={settingsPayload['force_sso_login']} onChange={onInputChange} ariaLabel="force_sso_login" />
          </div>
        </div>

        <div className="settings__form__nested">
          <Label label={t('settings.sso.settings.user_provisioning_label')} />

          <div className="settings__form__nested__form">
            <div className="settings__form__nested__form__item">
              <Label label={t('settings.sso.settings.default_role_label')} />

              <div className="settings__form__nested__form__item-input">
                <Select
                  options={rolesOptions}
                  theme={theme}
                  value={getRoleValue(settingsPayload['user_provisionning']['default_role'])}
                  onChange={(value: any) => {
                    setSettingsPayload((state) => ({
                      ...state,
                      user_provisionning: {
                        ...state.user_provisionning,
                        default_role: value.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>

            <div className="settings__form__nested__form__item">
              <Label label={t('settings.sso.settings.enabled_label')} />

              <div className="settings__form__nested__form__item-input">
                <Switch
                  checked={settingsPayload.user_provisionning.enabled}
                  onChange={(event) => {
                    const { ariaLabel, value } = event.target;
                    setSettingsPayload((state) => ({
                      ...state,
                      user_provisionning: {
                        ...state.user_provisionning,
                        [ariaLabel]: value,
                      },
                    }));
                  }}
                  ariaLabel="enabled"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="settings__save_button">
          <Button type="primary" variant="positive" onClick={handleSaveSettings} disabled={isLoading}>
            {t('settings.sso.configurations.save_settings_button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
