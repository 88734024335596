import { openNotification } from 'components/notification';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from 'services/documents/entities/Document';
import { useDocuments } from './useDocuments';

type DocumentsContext = {
  isRefreshing: boolean;
  isLoading: boolean;
  documentList: Document[];
  refreshDocumentList: () => Promise<void>;
  setDocumentList: Dispatch<SetStateAction<Document[]>>;
};

const DocumentsContext = createContext<DocumentsContext | null>(null);

export const DocumentsProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  const { documentList, isLoading, isRefreshing, refreshDocumentList, setDocumentList } = useDocuments({
    onFetchError: () => {
      openNotification({
        type: 'error',
        title: t('pipeline.error.error_title'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    },
  });

  return (
    <DocumentsContext.Provider
      value={{
        isRefreshing,
        isLoading,
        documentList,
        refreshDocumentList,
        setDocumentList,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export const useDocumentsContext = () => {
  const context = useContext(DocumentsContext);
  if (!context) {
    throw new Error('useDocuments must be used within a DocumentsProvider');
  }
  return context;
};
