import { NavigationItemsProps } from 'utils/navigationItems';
import {
  templatePageAllowedUserPermissions,
  settingsPageAllowedUserPermissions,
  libraryPageAllowedUserPermissions,
  integrationsPageAllowedUserPermissions,
  isPageAllowed,
} from './PagesPermission';
import { UserPermission } from '../entities/UserPermissions';

export const getNavigationItemsWithPermissions = (
  items: NavigationItemsProps[],
  userPermissions: UserPermission[]
): NavigationItemsProps[] => {
  const itemsWithPermissions = items.map((item) => {
    let pagePermissions: UserPermission[] | null = null;

    if (item.href.endsWith('/template/saved')) {
      pagePermissions = templatePageAllowedUserPermissions;
    }

    if (item.href.endsWith('/library/section')) {
      pagePermissions = libraryPageAllowedUserPermissions;
    }

    if (item.href.endsWith('/integrations')) {
      pagePermissions = integrationsPageAllowedUserPermissions;
    }

    if (item.href.endsWith('/settings')) {
      pagePermissions = settingsPageAllowedUserPermissions;
    }

    if (pagePermissions) {
      item.hidden = !isPageAllowed({ userPermissions, allowedPermissions: pagePermissions });
    } else {
      item.hidden = item.hidden ?? false;
    }

    return item;
  });

  return itemsWithPermissions;
};
