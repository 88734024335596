import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import type { EmailPickerOption } from 'pages/proposals/share/blocks/form/email-picker';

export interface ShareFormFieldValues {
  recipients: EmailPickerOption[];
  cc: EmailPickerOption[];
  bcc: EmailPickerOption[];
  subject: string;
  body?: string;
}

export type SubmitHandler = SubmitHandlerT<ShareFormFieldValues>;

export function useShareForm() {
  return useForm<ShareFormFieldValues>({
    defaultValues: {
      recipients: [],
      cc: [],
      bcc: [],
      subject: '',
      body: undefined,
    },
  });
}
