import { useMutation } from 'react-query';
import { ApiPlanRepository } from 'services/api/plan/ApiPlanRepository';
import { CancelPlanPayload } from 'services/api/plan/interfaces';

export default function useCancelPlanMutation() {
  const query = useMutation({
    mutationFn: cancelPlanMutation,
  });

  return { cancelPlan: query.mutateAsync, cancelPlanPromise: query.mutate };
}

const cancelPlanMutation = async (payload: CancelPlanPayload) => {
  const apiPlan = new ApiPlanRepository();
  return await apiPlan.cancelPlan(payload);
};
