import * as React from 'react';
const SvgIcoUndo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.53 4.47a.75.75 0 0 1 0 1.06L6.06 8l2.47 2.47a.75.75 0 1 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06l3-3a.75.75 0 0 1 1.06 0"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M4.25 8A.75.75 0 0 1 5 7.25h9c3.665 0 6.75 2.738 6.75 6.25s-3.085 6.25-6.75 6.25H6a.75.75 0 0 1 0-1.5h8c2.963 0 5.25-2.186 5.25-4.75S16.963 8.75 14 8.75H5A.75.75 0 0 1 4.25 8"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoUndo;
