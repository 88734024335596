import * as React from 'react';
const SvgIcoPictureAsPdf = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.5 2h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 14h-12V4h12zM4.5 6h-2v14c0 1.1.9 2 2 2h14v-2h-14zm12 6V9c0-.55-.45-1-1-1h-2v5h2c.55 0 1-.45 1-1m-2-3h1v3h-1zm4 2h1v-1h-1V9h1V8h-2v5h1zm-8 0h1c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1h-2v5h1zm0-2h1v1h-1z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoPictureAsPdf;
