import * as React from 'react';
import { Props } from './interface';
import { Icon, Paragraph } from '../../components';
import './styles.less';

const Highlight: React.FunctionComponent<Props> = (props) => {
  const { icon, text, iconColorClassName } = props;
  return (
    <div className="highlight-container">
      <Paragraph data-testid="highlight-paragraph" size="sm">
        <Icon data-testid="highlight-icon" name={icon} className={iconColorClassName} />
        {text}
      </Paragraph>
    </div>
  );
};

export default Highlight;
