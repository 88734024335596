import * as React from 'react';
const SvgIcoPaintBucket = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.15 3.298a.75.75 0 0 1 .53.22l6.736 6.736a.75.75 0 0 1 0 1.06l-5.634 5.634a2.87 2.87 0 0 1-4.054 0l-3.742-3.742a2.867 2.867 0 0 1 0-4.054L8.62 3.518a.75.75 0 0 1 .53-.22m0 1.81-5.104 5.104a1.367 1.367 0 0 0 0 1.934l3.742 3.742c.533.533 1.4.533 1.934 0l5.103-5.104zM19.156 13.075c.11.044.207.111.283.196l.004.004.02.022.073.082a15.503 15.503 0 0 1 1.018 1.287c.278.394.568.85.791 1.315.217.45.405.978.405 1.497a2.874 2.874 0 0 1-2.867 2.868 2.874 2.874 0 0 1-2.867-2.867c0-.519.188-1.047.405-1.497a9.4 9.4 0 0 1 .79-1.316 16 16 0 0 1 1.019-1.287l.073-.082.02-.022.006-.006.002-.002v-.001l.552.508-.552-.508a.75.75 0 0 1 .825-.19m-.273 1.861c.14.176.293.378.446.595.25.356.49.737.664 1.1.18.375.257.662.257.847 0 .751-.617 1.368-1.367 1.368s-1.367-.617-1.367-1.367c0-.185.076-.472.257-.848.174-.362.413-.744.664-1.1.153-.217.306-.419.446-.595"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M7.535 2.405a.75.75 0 0 1 1.06.01l1.09 1.11a.75.75 0 1 1-1.07 1.05l-1.09-1.11a.75.75 0 0 1 .01-1.06M2.18 10.78a.75.75 0 0 1 .75-.75h12.96a.75.75 0 0 1 0 1.5H2.93a.75.75 0 0 1-.75-.75M2.67 21.44a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H3.42a.75.75 0 0 1-.75-.75"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoPaintBucket;
