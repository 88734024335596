import React from 'react';
import { Props } from './interface';
import './styles.less';
import { useGetPreviewByDocId } from '../../../pages/editor/useGetPreviewByDocId';

const Banner: React.FC<Props> = ({ children, documentId }) => {
  const { data: documentData } = useGetPreviewByDocId(documentId);
  return documentData?.isLocked ? <div className="preview__banner">{children}</div> : null;
};

export default Banner;
