import { GridValidRowModel } from '@mui/x-data-grid-pro';
import {
  ColumnTypeIdentifier,
  RowTypesIdentifier,
  TableColumnType,
  TotalRowTypesIdentifier,
} from '../../../../grid/reduxStore/table.types';

const DEFAULT_PRECISION = 5;
function segregatedColumns(columns: TableColumnType[]) {
  return columns.reduce((acc, column) => {
    const { columnType } = column;

    if (!acc[columnType]) {
      acc[columnType] = [];
    }

    acc[columnType].push(column);
    return acc;
  }, {} as Record<ColumnTypeIdentifier, TableColumnType[]>);
}

function calculateSubTotalsPerRow(
  segregatedCols: Record<ColumnTypeIdentifier, TableColumnType[]>,
  rows: GridValidRowModel[],
  precision = DEFAULT_PRECISION
): GridValidRowModel[] {
  const priceColumn = segregatedCols[ColumnTypeIdentifier.PRICE]?.[0];
  const subtotalColumn = segregatedCols[ColumnTypeIdentifier.SUBTOTAL]?.[0];
  const multiplierColumns = segregatedCols[ColumnTypeIdentifier.MULTIPLIER] || [];

  if (!priceColumn || !subtotalColumn) {
    return rows;
  }

  return rows.map((row) => {
    const priceValueStr = row[priceColumn.field];
    const rowTypeIsBody = row.rowType === RowTypesIdentifier.BODY;

    if (!rowTypeIsBody || !priceValueStr) return row;

    let priceValue = parseFloat(priceValueStr);
    if (isNaN(priceValue)) return row;

    multiplierColumns.forEach((multiplierCol) => {
      const multiplierValueStr = row[multiplierCol.field];
      const multiplierValue = parseFloat(multiplierValueStr) || 1;
      priceValue *= multiplierValue;
    });

    const roundedSubtotal = Number(priceValue.toFixed(precision));

    return {
      ...row,
      [subtotalColumn.field]: roundedSubtotal,
    };
  });
}

function calculateSubtotalFooterRow(
  rows: GridValidRowModel[],
  subtotalColumn: TableColumnType,
  precision = DEFAULT_PRECISION
): GridValidRowModel[] {
  const subtotal = rows.reduce((acc, row) => {
    if (row.rowType === RowTypesIdentifier.BODY) {
      const rowSubtotal = parseFloat(row[subtotalColumn.field]) || 0;
      acc += rowSubtotal;
    }
    return acc;
  }, 0);

  const roundedSubtotal = Number(subtotal.toFixed(precision));

  const subtotalRow = rows.find((row) => row.rowType === TotalRowTypesIdentifier.SUBTOTAL);

  if (subtotalRow) {
    const updatedSubtotalRow = { ...subtotalRow, [subtotalColumn.field]: roundedSubtotal };

    return rows.map((row) => (row === subtotalRow ? updatedSubtotalRow : row));
  }

  return rows;
}

export function calculateTotal(rows: GridValidRowModel[], columns: TableColumnType[]): GridValidRowModel[] {
  const segregatedCols = segregatedColumns(columns);
  const subtotalColumn = segregatedCols[ColumnTypeIdentifier.SUBTOTAL]?.[0];

  const calculatedSubtotalRows = calculateSubTotalsPerRow(segregatedCols, rows);

  if (!subtotalColumn) {
    return calculatedSubtotalRows;
  }
  return calculateSubtotalFooterRow(calculatedSubtotalRows, segregatedCols[ColumnTypeIdentifier.SUBTOTAL]?.[0]);
}
