import { useState } from 'react';

type UseInputNumber = (defaultValue: number) => [number | null, (value: string | number) => void];

export const useInputNumber: UseInputNumber = (defaultValue: number) => {
  const [state, setState] = useState<number | null>(defaultValue);

  const handleOnChange = (v: string | number) => {
    const value = String(v).toString();
    if (!value.trim().length) {
      setState(null);
    } else {
      const parsed = parseInt(value, 10);
      if (Number.isInteger(parsed)) {
        setState(parsed);
      }
    }
  };
  return [state, handleOnChange];
};
