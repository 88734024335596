import { useTranslation } from 'react-i18next';
import './styles.less';
import { Typography, Box, Dialog, IconButton, Skeleton } from '@mui/material';
import CloseX from '../../../../components/icon/icons/IcoCloseX';
import { AssetList } from 'pages/library/components/asset-list';
import { StartFromScratchButton } from '../start-from-scratch-button';
import { useContentLibraryListQuery } from 'hooks/useContentLibraryListQuery';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/urls';
import { Asset } from 'services/library/entities/Asset';
import { openNotification } from 'components/notification';
import useCreateDocumentMutation from 'hooks/useCreateDocumentMutation';
import EmptyState from 'components/emptyState';
import { SecondaryActionButton } from 'components/emptyState/components/emptyStateActionButtons';
import { EmptyStateTooltip } from 'components/emptyState/components/emptyStateTooltip';
import NoAssets from '../../../../images/empty-state/No_Assets.svg';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export function CreateDocumentModal(props: Props) {
  if (!props.open) {
    return <></>;
  }

  return CreateDocumentModalComponent(props);
}

function CreateDocumentModalComponent({ open, handleClose }: Props) {
  const { visibleAssetsList, isLoading } = useContentLibraryListQuery();
  const { createDocument } = useCreateDocumentMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAssetClickCreateDocumentHandler = async (asset: Asset) => {
    let documentId: string;
    try {
      const createdDocument = await createDocument({ title: asset.name, price: asset.price, copyFromContentId: asset.content_id });
      documentId = createdDocument.documentId;
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.error_title'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
      return;
    }
    navigate(`${URLS.document}/${documentId}`);
  };

  return (
    <Dialog
      className="create-document-modal"
      data-testid="create-document-modal"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: '28px' } }}
      maxWidth="md"
      fullWidth
    >
      <Box className="modal-container" display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {!isLoading && visibleAssetsList.length > 0 ? (
              <Typography className="create-document-title">{t('pipeline.select_template_modal.select_template')}</Typography>
            ) : null}
          </Box>
          <Box>
            <IconButton
              className="create-document-close-button"
              data-testid="create-document-close-button"
              onClick={handleClose}
              disableRipple
            >
              <CloseX />
            </IconButton>
          </Box>
        </Box>
        {!isLoading ? (
          visibleAssetsList.length === 0 ? (
            <Box width="75%" margin="auto">
              <EmptyState
                headerText={t('pipeline.select_template_modal.empty_state.header')}
                primaryGraphic={NoAssets}
                supportingText={t('pipeline.select_template_modal.empty_state.supporting_text')}
                primaryActionButton={<StartFromScratchButton />}
                secondaryActionButton={
                  <SecondaryActionButton
                    buttonText={t('pipeline.select_template_modal.empty_state.secondary_button_text')}
                    onClick={() => {
                      navigate(URLS.library);
                    }}
                  />
                }
                tooltip={<EmptyStateTooltip tooltipText={t('pipeline.select_template_modal.empty_state.tooltip_text')} />}
              />
            </Box>
          ) : (
            <>
              <Box>
                <Box display="flex" justifyContent="flex-end">
                  <Typography className="create-document-modal-sort-by-caption">{t('pipeline.select_template_modal.sort_by')}</Typography>
                </Box>
                <Box className="create-document-asset-list">
                  <AssetList onAssetClick={onAssetClickCreateDocumentHandler} />
                </Box>
              </Box>
              <Box>
                <StartFromScratchButton />
              </Box>
            </>
          )
        ) : (
          <>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rectangular" height={60} />
          </>
        )}
      </Box>
    </Dialog>
  );
}
