export const getSanitizedDataForTableFooter = (row, columns, value?: string) => {
  let convertedRowToFooterData = {};
  const indexForInitialColumns = 1;
  const indexForAddedColumns = 2;
  const isInitialStateRow = row.field === '';
  const firstColumnAfterId = isInitialStateRow ? indexForAddedColumns : indexForInitialColumns;

  if (isInitialStateRow) {
    convertedRowToFooterData = { ...row };
    delete convertedRowToFooterData['editable'];
    delete convertedRowToFooterData['width'];
    delete convertedRowToFooterData['headerName'];
    delete convertedRowToFooterData['field'];
    columns.slice(firstColumnAfterId).forEach((column) => {
      convertedRowToFooterData[column.field] = '';
    });
  } else {
    Object.keys(row).forEach((key) => {
      if (key !== 'id' && key !== 'rowType' && key !== '__reorder__') {
        convertedRowToFooterData[key] = value ? value : row[columns[firstColumnAfterId].field];
      } else {
        convertedRowToFooterData[key] = row[key];
      }
    });
  }
  return convertedRowToFooterData;
};
