import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  TextField,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClient as apiWrapper } from 'services/api';
import { Endpoints } from 'utils/endpoints';
import { ApiClient, ApiClientWithSecret } from '../../types';

const primaryColor = 'rgba(0, 40, 58, 1)';
export const createApiClientDialogTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          borderRadius: '100px',
          textTransform: 'none',
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: 600,
        },
        contained: {
          backgroundColor: primaryColor,
          color: '#fff',
          '&:hover': {
            backgroundColor: primaryColor,
          },
        },
        text: {
          color: primaryColor,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
        paper: {
          borderRadius: '16px',
          padding: '20px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: primaryColor,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          wordBreak: 'break-word',
          color: primaryColor,
        },
      },
    },
  },
});

interface PageHeaderProps {
  onNewData: (newData: ApiClient) => void;
}

export const CreateApiClient = ({ onNewData }: PageHeaderProps) => {
  const { t } = useTranslation();
  const [isCreateClientFormOpen, setIsCreateClientFormOpen] = useState(false);
  const [isCreateClientResponseOpen, setIsCreateClientResponseOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    redirectUri: '',
  });
  const [createdApiClient, setCreatedApiClient] = useState<ApiClientWithSecret | null>(null);
  const [errors, setErrors] = useState({
    name: false,
    redirectUri: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const nameError = formData.name.trim() === '';
    const redirectUriError = formData.redirectUri.split('\n').every((uri) => uri.trim() === '');

    if (nameError || redirectUriError) {
      setErrors({
        name: nameError,
        redirectUri: redirectUriError,
      });
      return;
    }

    const updatedFormData = {
      name: formData.name,
      redirectUri: formData.redirectUri.split('\n'),
    };
    const response = await apiWrapper.post<ApiClientWithSecret>(Endpoints.createAPIClient, updatedFormData);
    setCreatedApiClient(response as ApiClientWithSecret);
    setFormData({
      name: '',
      redirectUri: '',
    });

    const newApiClient = {
      name: response.name,
      redirectUri: response.redirectUri,
      identifier: response.identifier,
    };
    onNewData(newApiClient);
    setIsCreateClientFormOpen(false);
    setIsCreateClientResponseOpen(true);
  };

  return (
    <Box>
      <Button
        variant="contained"
        sx={{
          fontFamily: 'inherit',
          borderRadius: '100px',
          textTransform: 'none',
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: 600,
          backgroundColor: primaryColor,
          color: '#fff',
          '&:hover': {
            backgroundColor: primaryColor,
          },
        }}
        onClick={() => {
          setIsCreateClientFormOpen(true);
        }}
      >
        {t('settings.connect_api.api_clients.create_button')}
      </Button>
      <ThemeProvider theme={createApiClientDialogTheme}>
        <Dialog open={isCreateClientFormOpen}>
          <DialogTitle id="alert-dialog-title">{t('settings.connect_api.api_clients.create_title')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              error={errors.name}
              helperText={errors.name ? t('settings.connect_api.api_clients.name_required_message') : ''}
            />
            <TextField
              margin="dense"
              name="redirectUri"
              label="Redirect URI"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={formData.redirectUri}
              onChange={handleChange}
              error={errors.redirectUri}
              helperText={errors.redirectUri ? t('redirect_uri_required_message') : ''}
            />
            {t('settings.connect_api.api_clients.multiUriInputHint')}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsCreateClientFormOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={isCreateClientResponseOpen}>
          <DialogTitle id="alert-dialog-title">{'API Client Details'}</DialogTitle>
          <DialogContent>
            {createdApiClient && (
              <Box>
                <p>
                  <strong>{t('settings.connect_api.api_clients.client_secret_warning_message.primary')} </strong>
                  {t('settings.connect_api.api_clients.client_secret_warning_message.secondary')}
                </p>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={<strong>{t('settings.connect_api.api_clients.name')}:</strong>}
                      secondary={createdApiClient.name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText secondary={createdApiClient.identifier}>
                      <strong>{t('settings.connect_api.api_clients.id')}:</strong>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText secondary={createdApiClient.secret}>
                      <strong>{t('settings.connect_api.api_clients.secret')}:</strong>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsCreateClientResponseOpen(false)} autoFocus variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Box>
  );
};
