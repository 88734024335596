import { Box } from '@mui/material';
import { ComponentProps } from 'react';

export function MilestoneDate({ children, ...props }: ComponentProps<typeof Box>) {
  return (
    <Box display="flex" flexDirection="column" paddingTop="0.5rem" flexBasis="6rem" flexShrink={0} flexGrow={0} {...props}>
      {children}
    </Box>
  );
}
