import './App.less';
import './styles/layout.less';

import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routing/AppRoutes';
import { initDataTracker } from './utils/DataTrackerHandler';

const Zendesk = React.lazy(() => import('./services/zendesk'));

const App = (): JSX.Element => {
  initDataTracker();
  return (
    <BrowserRouter>
      <AppRoutes />
      <Suspense fallback={null}>
        <Zendesk />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
