import { TablePagination as MuiTablePagination, type SxProps, type Theme } from '@mui/material';
import './styles.less';
import { NUMBER_OF_DOCUMENTS_PER_PAGE } from './usePagination';

type Props = {
  totalDocuments: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
};

export function Pagination({ currentPage, totalDocuments, setCurrentPage, sx }: Props & { sx?: SxProps<Theme> }) {
  return (
    <MuiTablePagination
      className="documents-list-pagination"
      component="div"
      count={totalDocuments}
      page={!totalDocuments || totalDocuments <= 0 ? 0 : currentPage - 1}
      onPageChange={(_, newCurrentPage) => setCurrentPage(newCurrentPage + 1)}
      rowsPerPage={NUMBER_OF_DOCUMENTS_PER_PAGE}
      rowsPerPageOptions={[]}
      data-testid="pagination-toolbar"
      sx={sx}
    />
  );
}
