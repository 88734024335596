import * as React from 'react';
import { Props } from './interface';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useApiDeprecated } from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../../components';
import { useIntegrationsApiData } from '../../hooks/useIntegrationsApiData';
import { useCurrentUser } from 'providers/UserProvider';

const LoadingIcon = <LoadingOutlined className="loader" spin />;

const Oauth2: React.FC<Props> = ({ apiUserIntegrationsRepository }) => {
  const { t } = useTranslation();
  const { integrationName } = useParams();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const {
    data: { id: userId },
  } = useCurrentUser();
  const { fetchUserIntegrations, isLoadingUserIntegrations } = useIntegrationsApiData({ apiUserIntegrationsRepository });
  const navigate = useNavigate();

  const authCode = searchParams.get('code');
  const securityState = searchParams.get('state');
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  const { fetchApi } = useApiDeprecated({});

  const displayError = () => {
    openNotification({
      type: 'error',
      title: t('integrations.error_title'),
      description: t('integrations.try_again_error_message'),
    });
  };

  useEffect(() => {
    if (!userId) return;

    if (userId && authCode && securityState) {
      (async () => {
        const response = await fetchApi(false, `/user/${userId}/integrations/${integrationName}/connections`, {
          method: 'POST',
          body: JSON.stringify({
            authCode: authCode,
            securityState: securityState,
          }),
        });

        if (response && response.status === 201) {
          await fetchUserIntegrations();
        } else displayError();
        navigate(`/integrations/${integrationName}`);
      })();
    } else {
      displayError();
      navigate(`/integrations/${integrationName}`);
    }
    setIsLoading(false);
  }, [userId, authCode, securityState]);

  useEffect(() => {
    if (document.readyState === 'complete') {
      if (error || errorDescription) {
        displayError();
        navigate(`/integrations/${integrationName}`);
      }
    }
  }, [error, errorDescription]);

  return <Spin indicator={LoadingIcon} spinning={isLoadingUserIntegrations || isLoading} />;
};

export default Oauth2;
