import { FileCopyOutlined as FileCopyIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Document } from 'services/documents/entities/Document';
import { useDuplicateDocument } from './useDuplicateDocument';

type DuplicateDocumentActionProps = {
  documentToDuplicate: Document;
  viewCreatedDocument: (createdDocumentId: string) => void;
};

export function DuplicateDocumentAction({ documentToDuplicate, viewCreatedDocument }: DuplicateDocumentActionProps) {
  const { t } = useTranslation();
  const { duplicateDocument } = useDuplicateDocument({ documentToDuplicate, viewCreatedDocument });

  return (
    <Tooltip title={t('pipeline.tabs.document_list.more_actions.duplicate_document_tooltip')}>
      <IconButton onClick={duplicateDocument} data-testid="duplicate-button">
        <FileCopyIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
}
