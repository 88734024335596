import { Endpoints } from 'utils/endpoints';
import { UserRepository } from '../interfaces/UserRepository';
import { UserEntity } from 'services/entities/UserEntity';
import { convertToCamelCase } from '../../query/toCamel';
import { apiClient } from 'services/api';

export class ApiUserRepository implements UserRepository {
  public async getUserMe(): Promise<UserEntity> {
    const data = await apiClient.get<UserEntity>(Endpoints.getCurrentUser);
    const result: UserEntity = convertToCamelCase(data);
    const fullName = [result.firstName, result.lastName].filter(Boolean).join(' ');
    result.displayName = fullName;
    result.fullName = fullName;
    return result;
  }
}
