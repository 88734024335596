import type { GetDocumentEntity } from 'services/repositories/interfaces/DocumentRepository';
import { ProspectType } from 'services/repositories/interfaces/ProspectRepository';
import type { ContactOption, DocumentSettingsFormValues } from './types';
import type { ProspectOption } from './useProspect';

export function constructInitialValues(document: GetDocumentEntity): DocumentSettingsFormValues {
  let prospect: ProspectOption | null = null;
  let contact: ContactOption | null = null;

  if (document.prospect) {
    const { id, name, type, uid } = document.prospect;
    prospect = {
      label: name,
      value: uid,
      id,
      type,
    };

    if (document.prospect.type === ProspectType.Company && document.prospect.contact) {
      contact = {
        label: document.prospect.contact.name,
        value: document.prospect.contact.id,
      };
    }
  }

  const initialValues: DocumentSettingsFormValues = {
    prospect,
    contact,
    assignedUser: null,
  };

  if (document.assignedUser) {
    return {
      ...initialValues,
      assignedUser: {
        label: document.assignedUser.name,
        value: document.assignedUser.id,
      },
    };
  }

  return initialValues;
}
