import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { styled, CardHeader, Stack, Typography, Button, SxProps, Theme, Tooltip, IconButton } from '@mui/material';

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(0, 0, 3, 0),
  fontSize: '20px',
  color: theme.palette.text.primary,
}));

export type BillingCardHeaderAction = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary';
  sx?: SxProps<Theme>;
  dataTestId?: string;
};

interface BillingCardHeaderProps {
  title: string;
  subheader?: string;
  actions?: BillingCardHeaderAction[];
  tooltip?: string;
  addSidePadding?: boolean;
}

const defaultButtonStyles = { padding: '4px, 10px', borderRadius: '100px' };

const BillingCardHeader = ({ title, subheader = '', actions = [], tooltip = '', addSidePadding }: BillingCardHeaderProps) => (
  <StyledCardHeader
    sx={{ px: addSidePadding ? 3 : undefined }}
    disableTypography
    title={
      <Stack direction="row" gap={3} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack direction="row" gap="4px">
          <Typography variant="h5" color="text.primary">
            {title}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip} arrow>
              <IconButton sx={{ padding: '5px' }}>
                <InfoOutlined sx={{ width: '20px', height: '20px', color: 'primary.light' }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        {actions?.length > 0 && (
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" gap={1}>
            {actions.map((action, idx) => (
              <Button
                key={`billing-card-header-action-${idx}`}
                disabled={!!action.disabled}
                onClick={action.onClick}
                variant={action.variant || 'contained'}
                size={action.size || 'small'}
                color={action.color || 'secondary'}
                sx={action.sx ? { ...defaultButtonStyles, ...action.sx } : defaultButtonStyles}
                data-testid={action.dataTestId}
              >
                {action.label}
              </Button>
            ))}
          </Stack>
        )}
      </Stack>
    }
    subheader={
      subheader && (
        <Typography variant="body2" color="text.secondary">
          {subheader}
        </Typography>
      )
    }
  />
);

export default BillingCardHeader;
