const lightPrimaryColor = '#00658C';

export const MuiSwitch = {
  styleOverrides: {
    root: {
      '.MuiSwitch-switchBase': {
        '&.Mui-checked': {
          color: lightPrimaryColor,
          '+.MuiSwitch-track': {
            backgroundColor: lightPrimaryColor,
          },
        },
      },
    },
  },
};
