import { IconButton, Grid, Box } from '@mui/material';
import SvgIcoArrowRight from '../../../icon/icons/IcoArrowRight';
import './SecondaryMenuTrigger.less';

export type SecondaryMenuTriggerProps = {
  menuTriggerTitle: string;
  menTriggerDesc: string;
  handleClick: () => void;
};

export default function SecondaryMenuTrigger({ menuTriggerTitle, menTriggerDesc, handleClick }: SecondaryMenuTriggerProps) {
  return (
    <Grid container className="secondary-menu-trigger--block" onClick={handleClick} alignItems="center">
      <Grid item>
        <div className="secondary-menu-trigger--title">{menuTriggerTitle}</div>
        <div className="secondary-menu-trigger--desc">{menTriggerDesc}</div>
      </Grid>
      <Box>
        <IconButton size="small">
          <SvgIcoArrowRight className="chevron-icon" />
        </IconButton>
      </Box>
    </Grid>
  );
}
