import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SaveStatus } from '../../shared/models/SaveStatus';
import { DocumentSaveStatus } from '../../shared/models/DocumentSaveStatus';
import { AcknowledgmentResponseStatus, ErrorCode } from 'services/socket/SocketEvents';

export interface SaveStatusState {
  currentSaveStatus: SaveStatus;
}

const initialState: SaveStatusState = {
  currentSaveStatus: { status: DocumentSaveStatus.NOT_SAVED, errCode: '' },
};

const saveStatusSlice = createSlice({
  name: 'saveStatus',
  initialState,
  reducers: {
    setSaveStatus(state, action: PayloadAction<{ status: DocumentSaveStatus; errCode?: string }>) {
      state.currentSaveStatus = { status: action.payload.status, errCode: action.payload.errCode || '' };
    },
    setSaveStatusBasedOnApiResponse(state, action: PayloadAction<{ status: AcknowledgmentResponseStatus; errorCode?: ErrorCode }>) {
      if (action.payload.status === AcknowledgmentResponseStatus.OK) {
        state.currentSaveStatus = { status: DocumentSaveStatus.SAVED, errCode: '' };
      } else if (action.payload.status === AcknowledgmentResponseStatus.FAILURE) {
        state.currentSaveStatus = { status: DocumentSaveStatus.NOT_SAVED, errCode: action.payload.errorCode };
      }
    },
  },
});

export const { setSaveStatus, setSaveStatusBasedOnApiResponse } = saveStatusSlice.actions;

export const saveStatus = saveStatusSlice.reducer;
