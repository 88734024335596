import { CopyLink } from 'components/copy-link';
import { openNotification } from 'components/notification';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import { useShareDocumentMutation } from 'hooks/useShareDocumentMutation';
import { useCurrentUser } from 'providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { ShareForm } from './share-form';
import { SubmitHandler } from './share-form/useShareForm';
import { transformShareFormFieldValuesToApiPayload } from './share-form/utils/transformer';

import './styles.less';
import usePreviewUrl from '../../../pages/editor/usePreviewUrl';

interface DocumentShareProps {
  documentId: string;
  onSuccess: () => void;
}

export function DocumentShare({ documentId, onSuccess }: DocumentShareProps) {
  const { mutateAsync } = useShareDocumentMutation();
  const { data } = useGetDocumentByIdQuery(documentId);
  const {
    data: { accountId },
  } = useCurrentUser();
  const { t } = useTranslation();

  const previewUrl = usePreviewUrl() + documentId;
  const onSubmit: SubmitHandler = async (data) => {
    try {
      await mutateAsync(
        { id: documentId, ...transformShareFormFieldValuesToApiPayload(data) },
        {
          onSuccess: () => {
            openNotification({
              title: t('document.share.form.success'),
              description: '',
              type: 'success',
            });
            onSuccess();
          },
        }
      );
    } catch (e) {
      openNotification({
        title: t('share.form.notification.error.title'),
        description: '',
        type: 'error',
      });
    }
  };
  return (
    <div className="share__container">
      <ShareForm onSubmit={onSubmit} companyId={data?.company?.id} accountId={accountId} documentId={documentId} />
      <CopyLink url={window.location.hostname + previewUrl} />
    </div>
  );
}
