import { useEffect } from 'react';
import { Label, Input } from 'components';
import { CreatableOptionPicker } from '../creatable-option-picker';
import { OptionPicker } from '../option-picker';
import { BooleanPicker } from '../boolean-picker';

type Props = {
  property: string;
  nestedProperty?: string | null;
  propertyValues?: {
    description?: string;
    items?: {
      enum?: string[];
    };
    enum?: string[];
    default?: any;
  };
  $ref?: string;
  type: any;
  formValues: object | undefined;
  handleInputChange: (event: any) => void;
  setFormValues: any;
  referenceProperty?: string;
};

const getLabel = (label: string): string => {
  return label.split('_').join(' ');
};

const getKeyName = (label: string, referenceProperty: string | undefined): string => {
  if (referenceProperty) {
    return `${referenceProperty}.${label}`;
  } else return label;
};

export const FormItem = ({
  property,
  type,
  formValues,
  handleInputChange,
  propertyValues,
  nestedProperty,
  setFormValues,
  referenceProperty,
}: Props) => {
  useEffect(() => {
    const keyName = referenceProperty ? `${referenceProperty}.${property}` : property;

    if (formValues && !(keyName in formValues)) {
      setFormValues((state) => ({
        ...state,
        [keyName]: propertyValues?.default ?? (type === 'boolean' ? false : ['array', 'allOf', 'anyOf', 'oneOf'].includes(type) ? [] : ''),
      }));
    }
  }, []);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    if (nestedProperty) {
      handleInputChange({
        target: {
          name,
          value,
          nestedProperty,
        },
      });
      return;
    }
    handleInputChange({
      target: {
        name,
        value,
      },
    });
  };

  const keyName = getKeyName(property, referenceProperty);
  const selectedValue = formValues && Object.hasOwn(formValues, keyName) ? formValues[keyName] : propertyValues?.default;
  switch (type) {
    case 'boolean':
      return (
        <div className="sso-form__client__item">
          <Label label={getLabel(property)} />
          <BooleanPicker keyName={keyName} onChange={handleInputChange} value={selectedValue ?? false} />
        </div>
      );
    case 'array':
    case 'allOf':
    case 'oneOf':
    case 'anyOf':
      return (
        <div className="sso-form__client__item">
          <Label label={getLabel(property)} />
          <CreatableOptionPicker
            keyName={keyName}
            options={propertyValues?.items?.enum ?? []}
            onChange={onInputChange}
            value={selectedValue ?? []}
          />
        </div>
      );
    default:
      if (propertyValues?.enum) {
        return (
          <div className="sso-form__client__item">
            <Label label={getLabel(property)} />
            <OptionPicker keyName={keyName} options={propertyValues?.enum ?? []} onChange={onInputChange} value={selectedValue} />
          </div>
        );
      }

      return (
        <div className="sso-form__client__item">
          <Label label={getLabel(property)} />
          <Input value={formValues ? formValues[keyName] : ''} ariaLabel={keyName} name={keyName} onChange={onInputChange} />
        </div>
      );
  }
};
