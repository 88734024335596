import { Collapse } from '../sso/blocks/collapse';
import { FormItem } from '../sso/blocks/form/form-item';
import { useSsoRefSchemaQuery } from '../sso/blocks/useSsoSchemaQuery';

type Props = {
  properties?: object;
  reference?: string | undefined;
  referenceProperty?: string;
  formValues?: object;
  propertyValues?: any;
  setFormValues: any;
  handleInputChange: (event: any) => void;
};

export const RecursiveForm = ({
  properties,
  reference = undefined,
  formValues,
  setFormValues,
  propertyValues,
  referenceProperty,
  handleInputChange,
}: Props) => {
  const { data, status } = useSsoRefSchemaQuery(reference);

  if (reference) {
    switch (status) {
      case 'success':
        return (
          <Collapse>
            {Object.keys(data?.properties).map((property) => {
              if (Object.hasOwn(data?.properties[property], 'readOnly')) return null;

              if (property === 'discovery_url') return null;

              if (Object.hasOwn(data?.properties[property], '$ref') || propertyValues?.type == 'object')
                return (
                  <RecursiveForm
                    reference={data?.properties[property]['$ref']}
                    propertyValues={data?.properties[property]}
                    setFormValues={setFormValues}
                    handleInputChange={handleInputChange}
                    formValues={formValues}
                    key={property}
                  />
                );

              return (
                <FormItem
                  key={property}
                  property={property}
                  type={data?.properties[property].type ?? Object.keys(data?.properties[property])[0]}
                  propertyValues={data?.properties[property]}
                  handleInputChange={handleInputChange}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  referenceProperty={referenceProperty}
                />
              );
            })}
          </Collapse>
        );
      default:
        return <></>;
    }
  } else if (properties) {
    return (
      <>
        {Object.keys(properties).map((property) => {
          if (Object.hasOwn(properties[property], 'readOnly')) return null;

          if (property === 'discovery_url') return null;

          if (Object.hasOwn(properties[property], '$ref'))
            return (
              <RecursiveForm
                reference={properties[property]['$ref']}
                setFormValues={setFormValues}
                propertyValues={properties[property]}
                referenceProperty={property}
                handleInputChange={handleInputChange}
                formValues={formValues}
                key={property}
              />
            );

          return (
            <FormItem
              key={property}
              property={property}
              propertyValues={properties[property]}
              type={properties[property].type ?? Object.keys(data?.properties[property])[0]}
              handleInputChange={handleInputChange}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          );
        })}
      </>
    );
  } else return <></>;
};
