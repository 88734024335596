import { useTranslation } from 'react-i18next';
import { PageHeader, openNotification } from 'components';
import { WorkspacesSwitcherBody } from './blocks/workspaces-switcher-body';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const WorkspacesSwitcher = (): JSX.Element => {
  const { t } = useTranslation();
  const [currentQueryParam] = useSearchParams();
  const isAccessRevoked = currentQueryParam.get('error');

  const handleAccountAccessRevoked = () => {
    if (isAccessRevoked === 'true') {
      openNotification({
        title: t('settings.workspaces_switcher.access_revoked_error_title'),
        description: t('settings.workspaces_switcher.access_revoked_error_description'),
        type: 'error',
        placement: 'top',
      });
    }
  };

  useEffect(() => {
    handleAccountAccessRevoked();
  }, []);

  return (
    <>
      <PageHeader title={t('settings.workspaces_switcher.title')} paragraph={t('settings.workspaces_switcher.subtitle')} />
      <WorkspacesSwitcherBody />
    </>
  );
};
