import * as React from 'react';
const SvgIcoVariables = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.572 8.196a.72.72 0 0 1 .494.889l-1.753 6.136a.719.719 0 1 1-1.382-.395l1.753-6.136a.72.72 0 0 1 .888-.494M9.157 8.818c.28.28.28.735 0 1.016L7.913 11.08l1.244 1.245A.719.719 0 1 1 8.14 13.34l-1.752-1.752a.72.72 0 0 1 0-1.017L8.14 8.818a.72.72 0 0 1 1.017 0M13.838 10.571a.72.72 0 0 1 1.017 0l1.753 1.752a.72.72 0 0 1 0 1.017l-1.753 1.753a.719.719 0 0 1-1.016-1.016l1.245-1.245-1.245-1.244a.72.72 0 0 1 0-1.017"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M11.498 4.39a7.565 7.565 0 1 0 0 15.13 7.565 7.565 0 0 0 0-15.13m-9.002 7.566a9.002 9.002 0 1 1 18.004 0 9.002 9.002 0 0 1-18.004 0"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoVariables;
