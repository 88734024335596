import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Table, Divider } from 'antd';

import { Button, Loading, Heading } from 'components';
import { Settings } from '../settings';

import { useSsoConfigQuery } from '../useSsoConfigQuery';

import './styles.less';

const convertToDataSource = (data: any[] | unknown): any[] => {
  if (!data || !Array.isArray(data) || data.length < 1) return [];

  return data.map((item) => ({
    key: item?.id,
    name: item?.identity_provider_identifier,
  }));
};

export const Configurations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, status } = useSsoConfigQuery();

  const dataSource = useMemo(() => {
    return convertToDataSource(data);
  }, [data]);

  const columns = [
    {
      title: t('settings.sso.configurations.column_name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '200px',
      render: (_, data) => {
        return (
          <div className="actions">
            <Button
              type="default"
              variant="neutral"
              className="configurations__edit_button"
              onClick={() => navigate(`/settings/sso/configuration/${data.key}`)}
            >
              {t('settings.sso.configurations.edit_button')}
            </Button>
          </div>
        );
      },
    },
  ];

  switch (status) {
    case 'success':
      return (
        <div className="configurations__container">
          <Settings />

          <Divider />
          <div className="configurations__header">
            <Heading level={2}>{t('settings.sso.configurations.oicp')}</Heading>
            <Button
              type="primary"
              variant="positive"
              className="configurations__add_button"
              onClick={() => navigate('/settings/sso/configuration')}
            >
              {t('settings.sso.configurations.add_button')}
            </Button>
          </div>

          <Table
            className="configurations__table"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            data-testid="sso-providers-table"
          />
        </div>
      );
    default:
      return (
        <div className="configurations__loader">
          <Loading isLoading />
        </div>
      );
  }
};
