import * as React from 'react';
const SvgIcoPointer = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="m8.173 4.992-.002.014-.007 11.156 2.484-1.555a.72.72 0 0 1 1.027.286l2.083 4.116a.033.033 0 0 0 .044.015l2.245-1.136a.033.033 0 0 0 .015-.044l-2.083-4.116a.72.72 0 0 1 .378-.997l2.738-1.087L8.223 4.98q-.01-.007-.012-.007h-.003a.04.04 0 0 0-.019.004.04.04 0 0 0-.015.012zm.918-1.166c-.973-.732-2.364-.037-2.364 1.18l-.008 12.46a.722.722 0 0 0 1.105.612l2.92-1.827 1.726 3.41a1.477 1.477 0 0 0 1.984.651l2.245-1.136a1.477 1.477 0 0 0 .651-1.984l-1.726-3.41 3.2-1.27a.722.722 0 0 0 .168-1.25z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoPointer;
