import { useState, useEffect, useMemo, useContext } from 'react';
import { Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon';
import { IconNamesType } from 'components/icon/icons';
import EditorToolbar from '../toolbar/toolbar';
import { Signatures } from './Signatures';
import { Variables } from './Variables';
import './styles.less';
import { EditorRefType } from '../../../hooks/useEditorRef';
import { SidePanelModels, SidePanelProviderContext } from './content';
import { resetAllPanels, setActiveTableSettingsPanel } from '../grid/reduxStore/blockStyleSettingsSlice';
import { ContentType, getContentType } from 'utils/getContentType';
import { RenderEditorSidePanelSettings } from './RenderEditorSidePanelSettings';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../grid/reduxStore/Store';

interface Props {
  editorRef: EditorRefType;
  documentId: string;
}

export function EditorSidePanel({ editorRef, documentId }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState('document_tab');
  const { toggledDesignSettingModelType, activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const { setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const isEditorToolbarDisplayed = !activeTableSettingsPanel && !toggledDesignSettingModelType;

  const handleTabChange = (key: string) => {
    if (key != 'content_tab') {
      dispatch(setActiveTableSettingsPanel({ type: null }));
      setToggledTableSettingsPanel(null);
    }
    setActiveTab(key);
  };

  useEffect(() => {
    if (toggledDesignSettingModelType || activeTableSettingsPanel) {
      setActiveTab('content_tab');
    }
  }, [toggledDesignSettingModelType, activeTableSettingsPanel]);

  const contentType = getContentType();

  const navItems: { key: string; label: string; icon: IconNamesType; children?: React.ReactNode }[] = [
    {
      key: 'document_tab',
      label: contentType === ContentType.DOCUMENT ? t('editor.nav_tab.document') : t('editor.nav_tab.template'),
      icon: 'IcoSettingsLibrary',
      children: <RenderEditorSidePanelSettings documentId={documentId} activeTab={activeTab} />,
    },
    {
      key: 'content_tab',
      label: t('editor.nav_tab.content'),
      icon: 'IcoAddShape',
      children: (
        <>
          <SidePanelModels activeTab={activeTab} />
          {isEditorToolbarDisplayed && <EditorToolbar editorRef={editorRef} />}
        </>
      ),
    },
    {
      key: 'signatures_tab',
      label: t('editor.nav_tab.signatures'),
      icon: 'IcoSignaturePen',
      children: <Signatures />,
    },
    {
      key: 'variables_tab',
      label: t('editor.nav_tab.variables'),
      icon: 'IcoVariables',
      children: <Variables />,
    },
  ];

  const navItemMemo = useMemo(
    () =>
      navItems.map(({ key, label, icon, children }) => ({
        label: <NavItem id={key} label={label} icon={icon} />,
        key: key,
        children: children,
      })),
    [isEditorToolbarDisplayed, activeTab, navItems]
  );

  return (
    <Tabs
      className={'editor-sidepanel-nav'}
      tabPosition="left"
      defaultActiveKey="content"
      activeKey={activeTab}
      onChange={handleTabChange}
      items={navItemMemo}
    />
  );
}

function NavItem({ id, label, icon }: { id: string; label: string; icon: IconNamesType }) {
  const [isOpenTab, setIsOpenTab] = useState(false);
  const dispatch = useAppDispatch();

  const handleMouseEnter = () => {
    setIsOpenTab(true);
  };

  const handleOnClick = () => {
    dispatch(resetAllPanels());
  };

  const handleMouseLeave = () => {
    setIsOpenTab(false);
  };

  useEffect(() => {
    const hideTooltip = () => {
      setIsOpenTab(false);
    };
    window.addEventListener('scroll', hideTooltip);
    return () => {
      window.removeEventListener('scroll', hideTooltip);
    };
  }, []);

  return (
    <Tooltip placement="left" title={label} open={isOpenTab}>
      <span
        id={id}
        onClick={handleOnClick}
        className="editor-sidepanel-nav__icon-wrapper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon name={icon} />
      </span>
    </Tooltip>
  );
}
