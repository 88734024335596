import React from 'react';
import { Props } from './interface';
import './styles.less';
import { Image } from 'antd';

const FeatureLogo: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="featurelogo-container" role="img">
      <Image {...props} className="logo" preview={false} />
    </div>
  );
};

export default FeatureLogo;
