import { ContentType, getContentType } from 'utils/getContentType';
import { DocumentSettings } from './document-settings';
import { TemplateSettings } from './template-settings/TemplateSettings';

interface Props {
  documentId: string;
  activeTab: string;
}

export function RenderEditorSidePanelSettings({ documentId, activeTab }: Props) {
  const contentType = getContentType();

  if (contentType === ContentType.DOCUMENT) {
    return <DocumentSettings documentId={documentId} activeTab={activeTab} />;
  } else {
    return <TemplateSettings activeTab={activeTab} />;
  }
}
