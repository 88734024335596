import { Box, Card, Typography } from '@mui/material';
import { Activities } from './Activities';

export function InsightsExpandedContent() {
  return (
    <Box padding="1.5rem 3.5rem" display="grid" gap="1.5rem" gridTemplateColumns="1fr 1fr" bgcolor="background.default">
      <Card elevation={0} sx={{ padding: '1.5rem' }}>
        <Typography variant="h4" fontWeight="bold" paddingBottom="1.5rem">
          Activity
        </Typography>
        <Activities />
      </Card>
    </Box>
  );
}
