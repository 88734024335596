import { createTheme } from '@mui/material/styles';

const integrationsTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          marginLeft: '52px',
          borderRadius: '8px',
          padding: '16px',
        },
      },
    },
  },
});

export default integrationsTheme;
