import { Box, IconButton, Tooltip } from '@mui/material';
import IcoCloseX from 'components/icon/icons/IcoCloseX';
import IcoResizeExpand from 'components/icon/icons/IcoResizeExpand';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from '../DocumentTitle';
import { InsightsHeaderEditButton } from './InsightsHeaderEditButton';
import { InsightsHeaderPreviewButton } from './InsightsHeaderPreviewButton';

interface InsightsPanelShrunkHeaderProps {
  documentId: string;
  onClose: ({ isExpanded }: { isExpanded: boolean }) => void;
  onToggleExpand: () => void;
}

export function InsightsPanelShrunkHeader({ documentId, onClose, onToggleExpand }: InsightsPanelShrunkHeaderProps) {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box flex="1 1 auto" overflow="hidden">
        <DocumentTitle title={document?.title} />
      </Box>
      <Box flex="0 0 auto" display="flex" alignItems="center" gap="0.75rem">
        <InsightsHeaderPreviewButton documentId={documentId} />
        <InsightsHeaderEditButton documentId={documentId} />
        <Tooltip title={t('pipeline.insights.resize_expand_tooltip')}>
          <IconButton data-testid="insights-resize-button-expand" onClick={onToggleExpand} sx={{ padding: '0.3125rem' }}>
            <IcoResizeExpand />
          </IconButton>
        </Tooltip>
        <IconButton data-testid="insights-dismiss-button" onClick={() => onClose({ isExpanded: false })} sx={{ padding: '0.3125rem' }}>
          <IcoCloseX />
        </IconButton>
      </Box>
    </Box>
  );
}
