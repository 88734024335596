import * as React from 'react';
const SvgIcoSent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <g fill="currentColor">
      <path d="m10.85 4.626 10.97 5.823c1.24.66 1.243 2.434 0 3.093l-10.986 5.832c-1.435.76-3.068-.67-2.458-2.197l2.095-5.23-2.076-5.119c-.619-1.527 1.018-2.965 2.455-2.202M9.789 6.264l2.189 5.4a.75.75 0 0 1 0 .562l-2.206 5.509a.252.252 0 0 0 .357.31l10.987-5.831a.247.247 0 0 0 0-.437L10.144 5.954a.251.251 0 0 0-.356.31" />
      <path d="M10.53 11.947a.75.75 0 0 1 .754-.747l10.72.05a.75.75 0 0 1-.008 1.5l-10.72-.05a.75.75 0 0 1-.746-.753M3.25 15a.75.75 0 0 1 .75-.75h1.6a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75m-1-3a.75.75 0 0 1 .75-.75h2.6a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75m-1-3A.75.75 0 0 1 2 8.25h3.6a.75.75 0 1 1 0 1.5H2A.75.75 0 0 1 1.25 9" />
    </g>
  </svg>
);
export default SvgIcoSent;
