export enum ContentType {
  DOCUMENT,
  TEMPLATE,
}

export const getContentType = (): ContentType => {
  if (window.location.href.includes('template')) {
    return ContentType.TEMPLATE;
  } else {
    return ContentType.DOCUMENT;
  }
};
