import { UserIntegrationRepository } from '../../services/integrations/repositories/UserIntegrationRepository';

export interface Props {
  apiUserIntegrationsRepository: UserIntegrationRepository;
}

export interface MarketplacePageProps {
  apiUserIntegrationsRepository: UserIntegrationRepository;
}

export enum IntegrationType {
  CRM = 'crm',
  AI = 'ai',
  INTEGRATION_PLATFORM = 'integration_platform',
  PAYMENT = 'payment',
  PRODUCTIVITY = 'productivity',
  CHAT = 'chat',
}

export enum IntegrationCardSize {
  FULL = 24,
  HALF = 12,
  QUARTER = 8,
}

export interface IntegrationMarketplaceCard {
  identifier: string;
  typeKey: string;
  marketplaceSize: number;
  isPremium: boolean;
  external: boolean;
}

export const Integrations: { [key: string]: IntegrationMarketplaceCard } = {
  GONG: {
    identifier: 'gong',
    typeKey: IntegrationType.AI,
    marketplaceSize: IntegrationCardSize.FULL,
    isPremium: false,
    external: false,
  },
  SALESFORCE: {
    identifier: 'salesforce',
    typeKey: IntegrationType.CRM,
    marketplaceSize: IntegrationCardSize.HALF,
    isPremium: true,
    external: true,
  },
  HUBSPOT: {
    identifier: 'hubspot',
    typeKey: IntegrationType.CRM,
    marketplaceSize: IntegrationCardSize.HALF,
    isPremium: false,
    external: true,
  },
  PIPEDRIVE: {
    identifier: 'pipedrive',
    typeKey: IntegrationType.CRM,
    marketplaceSize: IntegrationCardSize.HALF,
    isPremium: false,
    external: true,
  },
  HARVEST: {
    identifier: 'harvest',
    typeKey: IntegrationType.CRM,
    marketplaceSize: IntegrationCardSize.HALF,
    isPremium: false,
    external: true,
  },
  ZAPIER: {
    identifier: 'zapier',
    typeKey: IntegrationType.INTEGRATION_PLATFORM,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  STRIPE: {
    identifier: 'stripe',
    typeKey: IntegrationType.PAYMENT,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  FRESHBOOKS: {
    identifier: 'freshbooks2',
    typeKey: IntegrationType.PAYMENT,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  QUICKBOOKS: {
    identifier: 'quickbooks',
    typeKey: IntegrationType.PAYMENT,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  ZOHO: {
    identifier: 'zoho',
    typeKey: IntegrationType.CRM,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  BASECAMP: {
    identifier: 'basecamp3',
    typeKey: IntegrationType.PRODUCTIVITY,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  DRIFT: {
    identifier: 'drift',
    typeKey: IntegrationType.CHAT,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  INTERCOM: {
    identifier: 'intercom',
    typeKey: IntegrationType.CHAT,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
  OLARK: {
    identifier: 'olark',
    typeKey: IntegrationType.CHAT,
    marketplaceSize: IntegrationCardSize.QUARTER,
    isPremium: false,
    external: true,
  },
};
