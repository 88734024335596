import React from 'react';
import { useCurrentUser } from 'providers/UserProvider';
import './styles.less';

const AliasHeader: React.FC = () => {
  const {
    data: { isAliased },
  } = useCurrentUser();

  if (!isAliased) {
    return null;
  }

  return (
    <div className="alias" data-testid="alias-container">
      <span>You are impersonating a customer</span>
    </div>
  );
};

export default AliasHeader;
