import * as React from 'react';
const SvgIcoInsights = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <g fill="currentColor">
      <path d="M21 8.5c-1.45 0-2.26 1.44-1.93 2.51l-3.55 3.56c-.3-.09-.74-.09-1.04 0l-2.55-2.55c.34-1.07-.47-2.52-1.93-2.52-1.45 0-2.27 1.44-1.93 2.52l-4.56 4.55C2.44 16.24 1 17.05 1 18.5c0 1.1.9 2 2 2 1.45 0 2.26-1.44 1.93-2.51l4.55-4.56c.3.09.74.09 1.04 0l2.55 2.55c-.34 1.07.47 2.52 1.93 2.52 1.45 0 2.27-1.44 1.93-2.52l3.56-3.55c1.07.33 2.51-.48 2.51-1.93 0-1.1-.9-2-2-2" />
      <path d="m15 9.5.94-2.07L18 6.5l-2.06-.93L15 3.5l-.92 2.07L12 6.5l2.08.93zM3.5 11.5l.5-2L6 9l-2-.5-.5-2-.5 2L1 9l2 .5z" />
    </g>
  </svg>
);
export default SvgIcoInsights;
