import { Grid } from '@mui/material';
import { SecondaryMenu, SecondaryMenuSectionTitle, SecondaryMenuItemTitle } from '../../../component';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Stack } from '@mui/material';
import { useContext, useRef, useEffect } from 'react';
import { setIsAdvancedSpacingModelOpen, setActiveTableSettingsPanel } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { useBlockStyleSettings } from '../../../../hooks/useBlockSettings';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../grid/reduxStore/Store';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
const PaddingInput = ({
  label,
  name,
  value,
  onChange,
}: {
  label: string;
  name: string;
  value: number | undefined;
  onChange: (e) => void;
}) => {
  return (
    <Grid item xs={6}>
      <TextField
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        variant="outlined"
        fullWidth
        sx={{
          // mb: 1,
          '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'reset', // Hide the up and down arrows
            margin: 0,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  );
};

export function AdvancedSpacing() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { changePadding } = useBlockStyleSettings();
  const { selectedBlockIdByIcon } = useContext(SelectionContext);
  const blockId = selectedBlockIdByIcon as string;
  const { toggledDesignSettingModelType } = useSelector((state: RootState) => state.blockStyleSettings);
  const {
    paddingTop = 0,
    paddingBottom = 0,
    paddingLeft = 0,
    paddingRight = 0,
  } = useSelector((state: RootState) => state.blockStyleSettings.blockStyle[blockId]) || {};
  const typeBeforeAdvancedSpacingTrigged = useRef(toggledDesignSettingModelType);

  const handlePaddingChange = (e) => {
    const { name, value } = e.target;

    changePadding(blockId, {
      paddingTop,
      paddingBottom,
      paddingRight,
      paddingLeft,
      [name]: value !== '' ? parseInt(value) : 0,
    });
  };

  const onBackButtonOnClick = () => {
    dispatch(setIsAdvancedSpacingModelOpen(false));
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const onCloseButtonOnClick = () => {
    dispatch(setIsAdvancedSpacingModelOpen(false));
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  useEffect(() => {
    if (toggledDesignSettingModelType) {
      typeBeforeAdvancedSpacingTrigged.current = toggledDesignSettingModelType;
    }
  }, [toggledDesignSettingModelType]);

  return (
    <SecondaryMenu
      menuTitle={t('document.grid.text.advanced_spacing.title')}
      backButtonOnClick={onBackButtonOnClick}
      closeButtonOnClick={onCloseButtonOnClick}
      testId={'advanced-spacing-component'}
    >
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.text.advanced_spacing.spacing')}</SecondaryMenuSectionTitle>
        <div>
          <SecondaryMenuItemTitle>{t('document.grid.text.advanced_spacing.padding')}</SecondaryMenuItemTitle>
          <Box sx={{ justifyContent: 'space-between', marginTop: '16px' }}>
            <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
              <PaddingInput label="Top" name="paddingTop" value={paddingTop} onChange={handlePaddingChange} />

              <PaddingInput label="Bottom" name="paddingBottom" value={paddingBottom} onChange={handlePaddingChange} />

              <PaddingInput label="Left" name="paddingLeft" value={paddingLeft} onChange={handlePaddingChange} />

              <PaddingInput label="Right" name="paddingRight" value={paddingRight} onChange={handlePaddingChange} />
            </Grid>
          </Box>
        </div>
      </Stack>
    </SecondaryMenu>
  );
}
