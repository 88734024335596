import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Paragraph, Heading } from 'components/typography';

import { Position } from '../../../shared/interfaces';
import { SignatureEvent } from 'services/repositories/interfaces/SignatureRepository';
import { gridPageMaxWidthInPixels } from '../../../shared/gridConfig';

import './Signature.less';

interface SignedSignatureProps {
  signee: SignatureEvent;
  signatureId: string;
  position: Position;
  signedDate: string;
  isSelected?: boolean;
}

const PreviewSignedSignatureBlock: React.FunctionComponent<SignedSignatureProps> = ({ signee, signatureId, position, signedDate }) => {
  const [adjustedPosition, setAdjustedPosition] = useState(position);

  const { t } = useTranslation();
  const { firstName, lastName, ipAddress } = signee;
  const fullName = firstName + ' ' + lastName;
  const formattedSignedDate = new Date(signedDate || '').toLocaleString('en-US', { timeZoneName: 'short' });
  const tooltip = `Signed on ${formattedSignedDate} by ${fullName} @ ${ipAddress}`;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef?.current) {
      const offsetInitialSourceRight = position.x + containerRef?.current.clientWidth;
      const isSignaturePlacedOnEdge = offsetInitialSourceRight > gridPageMaxWidthInPixels;
      const adjustedX = isSignaturePlacedOnEdge ? gridPageMaxWidthInPixels - containerRef?.current.clientWidth : position.x;
      const newPosition = { ...position, x: adjustedX };
      setAdjustedPosition(newPosition);
    }
  }, [signedDate]);

  const customStylePositioning: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: `translate(${adjustedPosition.x}px, ${adjustedPosition.y}px)`,
  };

  return (
    <div className="signed_signature" style={{ ...customStylePositioning }}>
      <Tooltip title={tooltip} placement="bottom">
        <div ref={containerRef} className="signature signed-signature" data-signature-id={signatureId}>
          <span className="signature-icon-container"></span>
          <div className="signature-signee-container">
            <Paragraph size="xs">{t('editor.signature.signed_by')}</Paragraph>
            <Heading level={1}>{fullName}</Heading>
            <Paragraph size="xs">{formattedSignedDate}</Paragraph>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default PreviewSignedSignatureBlock;
