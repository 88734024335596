import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DatePicker } from 'antd';
import { useCurrentUser } from 'providers/UserProvider';
import { Button, Input, Label, Loading, openNotification } from 'components';
import { DropdownSelect, OptionProp } from '../dropdown-select';
import { useDocumentFormOnSubmit } from '../../hooks/useDocumentFormOnSubmit';
import { usePersonsQuery } from '../../hooks/usePersonsQuery';
import utils from '../../utils';
import { isIframe } from 'utils/isIframe';
import goToCinder from 'utils/goToCinder';
import { URLS } from 'utils/urls';

interface Props {
  selectedTemplate: null | number;
}
const convertToOption = (data): OptionProp => {
  return {
    value: data.id,
    label: `${data.fullName}`,
  };
};

export const DocumentCreate: React.FC<Props> = ({ selectedTemplate }) => {
  const { t } = useTranslation();
  const {
    data: { id: userId },
  } = useCurrentUser();
  const [formData, setFormData] = useState<{
    documentName: string;
    documentDueDate: string;
    personId: null | string;
    integration: null | string;
    integrationDealId: null | string;
  }>({
    documentName: '',
    documentDueDate: '',
    personId: null,
    integration: null,
    integrationDealId: null,
  });
  const [errors, setErrors] = useState({ documentName: false, documentDueDate: false });
  const { mutate: createDocument, isLoading } = useDocumentFormOnSubmit();
  const { data: clients } = usePersonsQuery();
  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const name = queryParams.get('name');
    if (!name) return;

    const integration = queryParams.get('integration');
    const objectType = queryParams.get('ObjectAPIName') ?? queryParams.get('objectType');
    const integrationDealId = integration != 'salesforce' || objectType == 'Opportunity' ? queryParams.get('objectId') : null;

    setFormData({ ...formData, documentName: name, integration, integrationDealId });
  }, []);

  const options = useMemo(() => {
    if (!clients) {
      return [];
    }

    return clients.map((client) => convertToOption(client));
  }, [clients]);

  const handleSubmit = () => {
    const { hasError, errors } = utils.validateFormValues(formData);
    setErrors(errors);

    if (hasError) {
      return;
    }

    const payload = {
      name: formData.documentName,
      user_id: userId,
      due_date: formData.documentDueDate,
      person_id: formData.personId,
      integration: formData.integration,
      integration_deal_id: formData.integration ? formData.integrationDealId : null,
    };

    if (selectedTemplate) {
      payload['preset_id'] = selectedTemplate;
    }

    createDocument(payload, {
      onSuccess: (proposal: any) => {
        openNotification({
          type: 'success',
          title: t('crm.document_create.success_label'),
          description: t('crm.document_create.created_description'),
          placement: 'topRight',
        });
        if (isIframe()) {
          window.parent.postMessage(JSON.stringify({ action: 'DONE', proposal }), '*');
        }
        goToCinder(URLS.cinderEditDocument(proposal.id));
      },
      onError: (error: any) => {
        openNotification({
          type: 'error',
          title: t('crm.document_create.error_label'),
          description: error?.message,
          placement: 'topRight',
        });
      },
    });
  };

  return (
    <section className="document-create-form">
      {isLoading && (
        <div className="document-create-form__loader">
          <Loading isLoading />
        </div>
      )}

      <div className="document-create-form__form-field">
        <Label label={t('crm.document_create.name_label')} isRequired />
        <div className={errors.documentName ? 'error' : ''}>
          <Input
            value={formData.documentName}
            ariaLabel={t('crm.document_create.name_label')}
            placeholder={t('crm.document_create.name_placeholder')}
            onChange={(e) =>
              setFormData({
                ...formData,
                documentName: e.target.value,
              })
            }
            maxLength={255}
          />
        </div>
      </div>

      <div className="document-create-form__form-field">
        <Label label={t('crm.document_create.due_date_label')} isRequired />
        <div className={errors.documentDueDate ? 'error' : ''}>
          <DatePicker
            className="document-create-form__date-picker"
            allowClear={false}
            onChange={(date) => {
              if (!date) return;

              setFormData({
                ...formData,
                documentDueDate: date?.format('YYYY-MM-DD'),
              });
            }}
            data-testid="date-picker"
          />
        </div>
      </div>

      <div className="document-create-form__form-field">
        <Label label={t('crm.document_create.client_label')} />
        <DropdownSelect
          options={options}
          onChange={(selectedOption) => setFormData({ ...formData, personId: selectedOption?.value ?? null })}
        />
      </div>
      <Button type="primary" variant="positive" className="document-create-form__create-button" onClick={handleSubmit} disabled={isLoading}>
        {t('create')}
      </Button>
    </section>
  );
};
