import { usePermissionsContextValue } from 'providers/PermissionsProvider';
import { integrationsPageAllowedUserPermissions, isPageAllowed } from 'services/permissions/helpers/PagesPermission';
import ProtectedRoutes from './ProtectedRoutes';

export const IntegrationsLayoutRoute = () => {
  const { userPermissions } = usePermissionsContextValue();

  const isPermissionLoading = userPermissions.length == 0;
  const isIntegrationPagesAllowed = isPageAllowed({ userPermissions, allowedPermissions: integrationsPageAllowedUserPermissions });
  return <ProtectedRoutes isLoading={isPermissionLoading} condition={isIntegrationPagesAllowed} />;
};
