import React, { useCallback, useState } from 'react';

export function useInViewport(): { ref: React.RefCallback<HTMLElement>; scrollToPosition } {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);

  const setRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setRefElement(node);
    }
  }, []);

  const checkElementIsVisibleInViewport = (top, bottom) => {
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    const pageTopScrollPosition = 140;
    return (top > pageTopScrollPosition || bottom > 0) && top < vHeight;
  };

  const scrollToPosition = (y: number) => {
    if (refElement) {
      const { top, bottom } = refElement.getBoundingClientRect();
      const isInViewport = checkElementIsVisibleInViewport(top, bottom);
      if (!isInViewport) {
        window.scroll({ top: y, behavior: 'smooth' });
      }
    }
  };

  return { ref: setRef, scrollToPosition };
}
