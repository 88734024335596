import * as React from 'react';
const SvgIcoDrag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle fill="currentColor" cx={1.5} cy={1.5} r={1.5} />
    <circle fill="currentColor" cx={7.5} cy={1.5} r={1.5} />
    <circle fill="currentColor" cx={7.5} cy={7.5} r={1.5} />
    <circle fill="currentColor" cx={1.5} cy={7.5} r={1.5} />
    <circle fill="currentColor" cx={1.5} cy={13.5} r={1.5} />
    <circle fill="currentColor" cx={7.5} cy={13.5} r={1.5} />
  </svg>
);
export default SvgIcoDrag;
