import { Button, Input } from 'antd';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import './SearchBar.less';

type SearchBarProps = {
  currentSearchTerm: string;
  handleSearch: (value: string) => void;
};

export function SearchBar({ currentSearchTerm, handleSearch }: SearchBarProps) {
  const { t } = useTranslation();

  return (
    <div className="documents-list-search-bar-area">
      <Input.Search
        defaultValue={currentSearchTerm}
        addonBefore={<Button className="documents-list-search-button" icon={<Icon name="IcoSearch24Px" />} />}
        placeholder={t('pipeline.tabs.search.inputPlaceholder')}
        className="documents-list-search-bar"
        bordered={false}
        allowClear={{ clearIcon: <Button icon={<Icon name="IcoCloseX" />} /> }}
        onBlur={(event) => handleSearch(event.target.value)}
        onSearch={(event) => handleSearch(event)}
        data-testid="pipelineSearchInput"
      ></Input.Search>
    </div>
  );
}
