import { accessToken } from 'services/authorization/handleTokenCookie';
import DocumentBuilder from 'components/editor/DocumentBuilder/DocumentBuilder';
import { SignaturesProvider } from 'components/editor/providers/SignaturesProvider';
import { Provider } from 'react-redux';
import { rootStore } from '../../components/editor/grid/reduxStore/Store';
import { SocketProvider } from '../../providers/SocketContext';
import { SelectedBlockInfoProvider } from '../../components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { EditorContentProvider } from 'providers/EditorContentContext';
import { DocumentLockedModalProvider } from 'components/editor/modals/DocumentLockedModalProvider';
import { EditorConfigProvider } from '../../components/editor/GridDndEditor/Block/Text/EditorConfigs';
interface Props {
  id: string;
  contentId: string;
  isDocumentLocked: boolean | null;
}

export function EditorContentWrapper({ id, contentId, isDocumentLocked }: Props) {
  return (
    <SocketProvider accessToken={accessToken as string} contentId={contentId}>
      <Provider store={rootStore}>
        <DocumentLockedModalProvider documentId={id} isDocumentLocked={isDocumentLocked}>
          <SignaturesProvider documentId={contentId}>
            <SelectedBlockInfoProvider>
              <EditorConfigProvider>
                <EditorContentProvider values={{ contentId: contentId }}>
                  <DocumentBuilder documentId={id} contentId={contentId} />
                </EditorContentProvider>
              </EditorConfigProvider>
            </SelectedBlockInfoProvider>
          </SignaturesProvider>
        </DocumentLockedModalProvider>
      </Provider>
    </SocketProvider>
  );
}
