export const CINDER_URLS = Object.freeze({
  editClient(clientId: number) {
    return `/client/person/edit/${clientId}`;
  },
  dashboard: '/dashboard',
  settings: '/settings',
  userEdit(userId: number) {
    return `/user/edit/${userId}`;
  },
  resourceGuides: '/resource/guides',
  logout: '/logout',
  library: '/library/section',
  templateSaved: '/template/saved',
  metrics: '/metrics',
  user: '/user',
  client: '/client',
  error: '/error',
  viewSnapshot: function (documentId: string) {
    return `/proposal/snapshot/${documentId}`;
  },
  createDocument: '/proposal/templates',
  archives: '/archive',
});
