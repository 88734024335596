import * as React from 'react';
const SvgIcoWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor">
      <path d="M12 8.63a.75.75 0 0 1 .75.75v3.74a.75.75 0 0 1-1.5 0V9.38a.75.75 0 0 1 .75-.75" fillRule="evenodd" clipRule="evenodd" />
      <path d="M13 16.4a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
      <path
        d="M13.601 4.47c-.701-1.23-2.5-1.23-3.202 0L2.813 17.764c-.686 1.203.198 2.688 1.601 2.688h15.172c1.403 0 2.287-1.485 1.6-2.688zm-4.574-.758c1.302-2.283 4.644-2.283 5.947 0l7.585 13.296C23.834 19.242 22.192 22 19.586 22H4.414c-2.606 0-4.248-2.758-2.973-4.992z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default SvgIcoWarning;
