import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spin } from 'antd';
import { SEO, MarketplaceListCard } from 'components';
import { Logos } from 'images/integrations';
import { Integrations, IntegrationCardSize, MarketplacePageProps } from './interface';
import { getIntegrationRedirect } from 'services/integrations/helpers/IntegrationRedirect';
import './styles.less';
import { useIntegrationsApiData } from '../../hooks/useIntegrationsApiData';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingIcon = <LoadingOutlined className="loader" spin />;

const IntegrationsPage: React.FC<MarketplacePageProps> = ({ apiUserIntegrationsRepository }) => {
  const { t } = useTranslation();
  const { userIntegrationData, isLoadingUserIntegrations } = useIntegrationsApiData({ apiUserIntegrationsRepository });

  function renderMarketplaceCards() {
    return Object.keys(Integrations).map((integrationKey) => {
      const integration = Integrations[integrationKey];
      const identifier = integration.identifier;
      const pageToRedirect = getIntegrationRedirect(integration);

      const integrationStatus = userIntegrationData?.find((integrationData) => integrationData.integrationName === identifier);

      const isSizeFull = integration.marketplaceSize === IntegrationCardSize.FULL;
      const sizeAllCardsHalfExceptFull = !isSizeFull ? IntegrationCardSize.HALF : IntegrationCardSize.FULL;

      return (
        <Col
          data-testid="integration-item"
          key={identifier}
          lg={{ span: integration.marketplaceSize }}
          md={{ span: sizeAllCardsHalfExceptFull }}
          sm={{ span: IntegrationCardSize.FULL }}
        >
          <MarketplaceListCard
            integrationTitle={t(`integrations.${identifier}.name`)}
            category={t(`integrations.types.${integration.typeKey}`)}
            content={t(`integrations.${identifier}.description`)}
            logo={Logos[identifier]}
            href={pageToRedirect}
            isPremium={integration.isPremium}
            isIntegrationConnected={!!integrationStatus?.isUserConnected}
          />
        </Col>
      );
    });
  }

  return (
    <div className="integrations-marketplace-page">
      <Spin indicator={LoadingIcon} spinning={isLoadingUserIntegrations} />
      <Row className="page-header-container">
        <Col flex={1}>
          <SEO title={`${t('integrations.integration_title')} | ${t('integrations.marketplace.title')}`} />
        </Col>
      </Row>
      <Row data-testid="integrations" className="cards-container" gutter={24}>
        {renderMarketplaceCards()}
      </Row>
    </div>
  );
};

export default IntegrationsPage;
