import * as React from 'react';
const SvgPromotionRocket = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <g fill="none">
      <path d="M0 0h24v24H0Z" />
      <path
        d="m12.94 11.06-7.07 7.07M6.633 12.999l-3.463-.818a.5.5 0 0 1-.239-.84l2.754-2.754a1 1 0 0 1 .699-.293l3.105-.027"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="m19.879 8.975.808-3.888a1.5 1.5 0 0 0-1.774-1.774l-3.888.808a6 6 0 0 0-3.021 1.632l-3.19 3.189a8 8 0 0 0-2.259 4.498l-.011.073a3.48 3.48 0 0 0 .982 2.961v0a3.48 3.48 0 0 0 2.961.981l.073-.011a8 8 0 0 0 4.498-2.259l3.189-3.189a6 6 0 0 0 1.632-3.021"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M13.4 4.714a22.4 22.4 0 0 1 5.886 5.886M11 17.367l.818 3.463a.5.5 0 0 0 .84.239l2.754-2.754a1 1 0 0 0 .293-.699l.027-3.105"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
export default SvgPromotionRocket;
