import * as React from 'react';
import { Props } from './interface';
import { Input as BaseInput, InputRef } from 'antd';
import './styles.less';

const Input = React.forwardRef<InputRef, Props>(({ placeholder, onChange, ariaLabel, id, maxLength, value, name, readOnly }, ref) => {
  return (
    <BaseInput
      ref={ref}
      value={value}
      className="proposify-input"
      placeholder={placeholder}
      onChange={onChange}
      aria-label={ariaLabel}
      id={id}
      maxLength={maxLength}
      name={name}
      readOnly={readOnly}
    />
  );
});

Input.displayName = 'Input';

export default Input;
