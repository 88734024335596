import { useMutation, useQuery } from 'react-query';
import { ApiCrmSalesforceRepository } from 'services/crm/repositories/implementations/ApiCrmSalesforceRepository';
import { QueryKeys } from 'utils/queryKeys';
import { salesforceObjectSettings } from '../../interface';

export const useSalesforceObjectsOnSubmit = () => {
  return useMutation<void, unknown, salesforceObjectSettings>({
    mutationFn: (payload: salesforceObjectSettings) => ApiCrmSalesforceRepository.saveSalesforceObjectSettings(payload),
  });
};

export const useSalesforceObjects = () => {
  return useQuery(QueryKeys.salesforceObjects, ApiCrmSalesforceRepository.getSalesforceObjectSettings);
};
