import { useEffect, useState } from 'react';
import { useSignaturesByDocumentId } from './useSignaturesByDocumentIdQuery';
import { SectionsSignaturesType } from '../components/editor/providers/SignaturesProvider';
import { SignatureBox } from '../services/repositories/interfaces/SignatureRepository';

export type SetSignedSignatureInSectionType = (signedSignature: SignatureBox, sectionId: string) => void;

export const usePreviewSignatures = (documentId: string) => {
  const [sectionsSignatures, setSectionsSignatures] = useState<SectionsSignaturesType>({});

  const { data } = useSignaturesByDocumentId(documentId);

  const setSignedSignatureInSection: SetSignedSignatureInSectionType = (signedSignature, sectionId) => {
    setSectionsSignatures((prevSectionSignatures) => {
      return {
        ...prevSectionSignatures,
        [sectionId]: [
          ...prevSectionSignatures[sectionId].map((signature) => {
            return signature.signatureBoxId === signedSignature.signatureBoxId ? signedSignature : signature;
          }),
        ],
      };
    });
  };

  useEffect(() => {
    if (!data) return;

    setSectionsSignatures(data);
  }, [data, documentId]);

  return { sectionsSignatures, setSignedSignatureInSection };
};
