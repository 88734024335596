import { Box, useTheme } from '@mui/material';
import '../../../../styles/tokens.less';
interface StyledSecondaryMenuWrapperProps {
  isSecondaryMenuActive: boolean;
  children: React.ReactNode;
  testId?: string;
}

export const SecondaryMenuWrapper: React.FC<StyledSecondaryMenuWrapperProps> = ({
  isSecondaryMenuActive,
  children,
  testId,
}: StyledSecondaryMenuWrapperProps) => {
  const theme = useTheme();
  return (
    <>
      {isSecondaryMenuActive && (
        <Box
          sx={{
            transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
            opacity: isSecondaryMenuActive ? 1 : 0,
            transform: `translateX(${isSecondaryMenuActive ? '0' : '-100%'})`,
            position: 'absolute',
            background: 'white',
            zIndex: theme.zIndex.drawer,
            left: theme.spacing(2),
            right: theme.spacing(2),
            height: '100%',
          }}
          data-testid={testId}
        >
          {children}
        </Box>
      )}
    </>
  );
};
