import { apiClient } from 'services/api';
import { TemplateRepository, GetTemplateFoldersApiResponse, TemplateFolderFactory } from '../entities/TemplateRepository';
import { Endpoints } from 'utils/endpoints';

class ApiProposalTemplateRepositoryClass implements TemplateRepository {
  public async getTemplateFolders() {
    const data = await apiClient.get<GetTemplateFoldersApiResponse[]>(Endpoints.getTemplateFolders);
    return TemplateFolderFactory(data);
  }
}

export const ApiProposalTemplateRepository = new ApiProposalTemplateRepositoryClass();
