import { Endpoints } from 'utils/endpoints';
import { apiClient } from 'services/api';
import { CrmDocumentRepository, createDocumentPayload } from '../CrmDocumentRepository';

class ApiCrmDocumentRepositoryClass implements CrmDocumentRepository {
  public async createDocument(payload: createDocumentPayload) {
    return await apiClient.post<void>(Endpoints.createDocument, payload);
  }
}

export const ApiCrmDocumentRepository = new ApiCrmDocumentRepositoryClass();
