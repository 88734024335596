import { createContext, ReactNode, useContext, useMemo } from 'react';
import { UseQueryResult } from 'react-query';

import PageLoader from 'components/page-loader/page-loader';
import { useCurrentUserQuery } from 'hooks/useCurrentUserQuery';
import { UserEntity } from 'services/entities/UserEntity';
import { UserRepository } from 'services/repositories/interfaces/UserRepository';

type UserContextResult = Pick<
  UseQueryResult<UserEntity, unknown>,
  'data' | 'error' | 'isError' | 'isIdle' | 'isLoading' | 'isLoadingError' | 'isRefetchError' | 'isSuccess' | 'status'
>;

const UserContextPageLoading = ({ children }: { children: ReactNode }) => {
  const { isLoading, data } = useUserContext();
  return (
    <PageLoader isLoading={isLoading} isLoaded={Boolean(data)}>
      {children}
    </PageLoader>
  );
};

type UserContextValue = UserContextResult | null;
const UserContext = createContext<UserContextValue>(null);
UserContext.displayName = 'UserContext';

interface Props {
  children: ReactNode;
  userRepository: UserRepository;
}

export const UserContextProvider = ({ children, userRepository }: Props) => {
  const queryResullt = useCurrentUserQuery(userRepository, { enabled: true });

  const value: UserContextResult = useMemo(() => {
    return queryResullt;
  }, [queryResullt]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const UserProvider = (props: Props) => {
  return (
    <UserContextProvider {...props}>
      <UserContextPageLoading>{props.children}</UserContextPageLoading>
    </UserContextProvider>
  );
};

export const useUserContext = () => {
  const value = useContext(UserContext);
  if (!value) {
    throw Error('useUserContext should be used inside  UserProvider');
  }

  return value;
};

export const useCurrentUser = () => {
  const { data } = useUserContext();
  if (!data) {
    throw Error('user is missing. You need to load data in UserContext provider before using useCurrentUser hook.');
  }

  return { data };
};

export default UserProvider;
