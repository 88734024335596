import { Box, List, ListItem, ListItemText, Skeleton, type SxProps, type Theme } from '@mui/material';

export function DocumentListSkeleton({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <List data-testid="document-list-skeleton" sx={sx}>
      {Array.from({ length: 10 }).map((_, index) => (
        <ListItem
          key={index}
          divider
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 2,
            height: '72px',
          }}
        >
          <ListItemText
            sx={{ flex: '1 1 200px' }}
            primary={
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Skeleton variant="text" width="80%" height={20} />
              </Box>
            }
            secondary={<Skeleton variant="text" width="60%" height={16} />}
          />

          <Box sx={{ flex: '0 1 100px' }}>
            <Skeleton variant="text" width="40%" height={20} />
          </Box>

          <Box sx={{ flex: '0 1 50px' }}>
            <Skeleton variant="circular" width={40} height={40} />
          </Box>

          <Box sx={{ flex: '0 1 150px', display: 'flex', justifyContent: 'flex-end' }}>
            <Skeleton sx={{ borderRadius: 2 }} variant="rectangular" width={60} height={24} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
}
