import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { BillingHistoryEntity } from 'services/api/account/interfaces';
import { AccountRepository } from 'services/api/account/AccountRepository';

type UseBillingHistoryApiDataParams = {
  accountRepository: AccountRepository;
  accountId: number;
  pagination?: {
    numPerPage: number;
    cursor?: string;
  };
};

type UseBillingHistoryApiDataResult = {
  data?: BillingHistoryEntity;
  refetch;
  isLoading: boolean;
  isFetched: boolean;
};

type UseBillingHistoryApiData<P> = (params: P) => UseBillingHistoryApiDataResult;

export const useBillingHistoryApiData: UseBillingHistoryApiData<UseBillingHistoryApiDataParams> = ({
  accountRepository,
  accountId,
  pagination,
}) => {
  const { data, refetch, isLoading, isFetched } = useQuery({
    queryKey: QueryKeys.getBillingHistory(accountId, pagination?.cursor || '', pagination?.numPerPage || 6),
    queryFn: () => accountRepository.getBillingHistory(accountId, pagination?.cursor || '', pagination?.numPerPage || 6),
  });

  return {
    data,
    isLoading,
    isFetched,
    refetch,
  };
};
