import { Col, Row } from 'antd';
import { Button, Icon } from 'components';
import { DocumentShareModal } from '../../editor/document-share-modal';
import { DocumentShare } from '../../editor/document-share';
import { Header } from 'components/editor/document-share-modal/header';
import { useTranslation } from 'react-i18next';
import { UserContextProvider, useUserContext } from 'providers/UserProvider';
import { ApiUserRepository } from 'services/repositories/implementations/ApiUserRepository';
import { useState } from 'react';

interface MenuProps {
  documentId: string;
  documentTitle: string;
  documentProspectName: string;
}

const RightSidePreviewMenu = ({ documentId, documentTitle, documentProspectName }: MenuProps) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const closeShareModal = () => setIsVisible(false);
  const openShareModal = () => setIsVisible(true);
  const documentTitleWithProspectName = [documentTitle, documentProspectName].filter(Boolean).join(' - ');

  const { data: user } = useUserContext();

  return user ? (
    <Row>
      <Col>
        <Button
          icon={<Icon name="IcoSend" title={t('share_document')} />}
          variant="positive"
          type="primary"
          className="send-button"
          onClick={openShareModal}
        >
          {t('share_document')}
        </Button>
        <DocumentShareModal
          open={isVisible}
          onCancel={closeShareModal}
          title={<Header title={t('share.title')} description={documentTitleWithProspectName} />}
          destroyOnClose
        >
          <DocumentShare documentId={documentId} onSuccess={closeShareModal} />
        </DocumentShareModal>
      </Col>
    </Row>
  ) : null;
};

export function PreviewActionMenu({ documentId, documentTitle, documentProspectName }: MenuProps) {
  return (
    <UserContextProvider userRepository={new ApiUserRepository()}>
      <RightSidePreviewMenu documentId={documentId} documentTitle={documentTitle} documentProspectName={documentProspectName} />
    </UserContextProvider>
  );
}
