import { Box, styled } from '@mui/material';
import { ComponentProps } from 'react';
import { useMilestoneContext } from './MilestoneProvider';

const VerticalLine = styled('div')(({ theme }) => ({
  borderLeft: `0.05rem solid ${theme.palette.text.secondary}`,
  opacity: 0.3,
  position: 'absolute',
  top: '2.5rem',
  left: '8.125rem',
  height: 'calc(100% - 2rem)',
}));

export function MilestoneLine({ ...props }: ComponentProps<'div'>) {
  const { isLast, isTitleAlignedWithIcons } = useMilestoneContext();

  if (isLast && isTitleAlignedWithIcons) return null;

  return <VerticalLine {...props} />;
}

export function MilestoneContent({ children, ...props }: ComponentProps<typeof Box>) {
  const { isTitleAlignedWithIcons } = useMilestoneContext();

  return (
    <Box display="flex" flexDirection={isTitleAlignedWithIcons ? 'row' : 'column'} gap={1} {...props}>
      <MilestoneLine data-testid="milestone-line" />
      {children}
    </Box>
  );
}
