import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { MaskedInputProps } from '../billing/MaskedInputs';
import { SelectOptions } from '../billing/interface';

interface FormFieldProps<T extends FieldValues> {
  id: string;
  label: string;
  placeholder?: string;
  shrinkLabel?: boolean;
  adornments?: React.ReactNode;
  options?: SelectOptions[] | undefined;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  maskInputComponent?: React.ForwardRefExoticComponent<MaskedInputProps & React.RefAttributes<HTMLInputElement>>;
  control: Control<T>;
  rules?: Omit<RegisterOptions<T, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  helperText?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
}

function FormField<T extends FieldValues>(props: FormFieldProps<T>) {
  const {
    id,
    label,
    placeholder = '',
    shrinkLabel = false,
    adornments = null,
    options,
    maskInputComponent,
    disabled = false,
    control,
    rules,
    helperText = '',
    onFocus,
  } = props;

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <FormControl key={`form-control-${id}`} error={!!error} sx={{ flex: '1 0 0', overflow: 'visible' }}>
          {options ? (
            <>
              {options.length > 0 && (
                <InputLabel id={`form-field-label-${id}`} error={!!error} shrink={shrinkLabel} sx={{ background: '#fff' }}>
                  {label}
                </InputLabel>
              )}
              <Select
                id={id}
                label={label}
                disabled={disabled}
                error={!!error}
                sx={{
                  display: options.length > 0 ? 'inherit' : 'none',
                }}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder || undefined}
                value={value}
                onFocus={onFocus}
              >
                {options.map((option) => (
                  <MenuItem key={`form-field-option-${id}-${option.id}`} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <TextField
              id={id}
              label={label}
              disabled={disabled}
              error={!!error}
              size="medium"
              InputLabelProps={{
                shrink: shrinkLabel,
              }}
              InputProps={{
                endAdornment: adornments || undefined,
                inputComponent: (maskInputComponent as any) || undefined,
                error: !!error,
              }}
              sx={{
                flex: '1 0 0',
              }}
              onChange={onChange}
              onBlur={onBlur}
              helperText={error?.message || helperText || undefined}
              FormHelperTextProps={{
                error: !!error,
              }}
              placeholder={placeholder || undefined}
              value={value}
              onFocus={onFocus}
            />
          )}
        </FormControl>
      )}
    />
  );
}

export default FormField;
