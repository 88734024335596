import { ApiUserRepository } from 'services/repositories/implementations/ApiUserRepository';
import UserProvider, { useCurrentUser } from 'providers/UserProvider';
import { setUserData } from '../utils/DataTrackerHandler';
import { SettingsPlanLayoutRoute } from './SettingsPlanLayoutRoute';

const userRepository = new ApiUserRepository();

const DataTrackerSetUser = (): null => {
  const {
    data: { id, accountId },
  } = useCurrentUser();
  setUserData({ id: id, account_id: accountId });
  return null;
};

export function TrialExpiredLayoutRoute() {
  return (
    <UserProvider userRepository={userRepository}>
      <DataTrackerSetUser />
      <div id="trial-expired">
        <SettingsPlanLayoutRoute />
      </div>
    </UserProvider>
  );
}
