import React from 'react';
import { Props } from './interface';
import Anchor from '../anchor';
import './styles.less';

const Logo: React.FunctionComponent<Props> = ({ href, title, className }) => {
  const backgroundClass = className !== undefined ? className : '';

  return <Anchor href={href} title={title} className={`${backgroundClass} proposify-logo`} />;
};

export default Logo;
