import * as React from 'react';
const SvgIcoApprove = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <g fill="currentColor">
      <path d="M8.3 9.75a.75.75 0 0 1 .75.75v9.503a.75.75 0 0 1-1.5 0V10.5a.75.75 0 0 1 .75-.75" />
      <path d="m11.334 4.905-2.337 5.872a.75.75 0 0 1-.697.472H5.498a.75.75 0 0 0-.75.751v6.503c0 .414.335.75.75.75h10.878a1.75 1.75 0 0 0 1.699-1.326l1.125-4.502a1.75 1.75 0 0 0-1.698-2.176h-3a.75.75 0 0 1-.75-.75V7.111c0-.937-.581-1.777-1.458-2.107l-.64-.241a.25.25 0 0 0-.32.142M9.94 4.35a1.75 1.75 0 0 1 2.244-.99l.64.24a3.75 3.75 0 0 1 2.427 3.51v2.64h2.25a3.25 3.25 0 0 1 3.154 4.039L19.53 18.29a3.25 3.25 0 0 1-3.154 2.462H5.498a2.25 2.25 0 0 1-2.25-2.25V12a2.25 2.25 0 0 1 2.25-2.25H7.79z" />
    </g>
  </svg>
);
export default SvgIcoApprove;
