// using type any because the import for rudderstack is being done in index.html
declare const window: {
  rudderanalytics: any;
} & Window;

export const trackEvents = (eventName: string, value: object) => {
  if (window.rudderanalytics) {
    window.rudderanalytics.track(eventName, value);
  }
};
