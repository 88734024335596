import { FunctionComponent } from 'react';
import { LoadingProps } from './interface';
import loadingAnimation from './animations/loading.json';
import doneAnimation from './animations/done.json';
import './styles.less';
import Lottie from 'lottie-react';

const Loading: FunctionComponent<LoadingProps> = ({ isLoading }) => {
  const animation = isLoading ? loadingAnimation : doneAnimation;
  const animationState = isLoading ? 'loading' : 'done';
  return (
    <div className="loading-container" data-testid={`animation-${animationState}`}>
      <Lottie animationData={animation} loop={true} className={`${animationState} animation`} />
    </div>
  );
};

export default Loading;
