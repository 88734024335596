import * as React from 'react';
const SvgIcoImagePlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.33 2.325h20.35v20.35H2.33z" fill="currentColor" />
    <circle fill="currentColor" cx={22.68} cy={22.676} r={8} />
    <path
      d="M14.663 22.659c0 .933.174 1.826.467 2.666H3.997A2.667 2.667 0 0 1 1.33 22.66V3.991c0-1.467 1.2-2.667 2.667-2.667h18.666a2.667 2.667 0 0 1 2.667 2.667v11.133a8 8 0 0 0-2.667-.466V3.992H3.997v18.667zm1.28-8.947-3.666 4.72-2.614-3.147-3.666 4.707h9.133a8.05 8.05 0 0 1 2.733-3.72zm8.054 7.613v-4H21.33v4h-4v2.667h4v4h2.667v-4h4v-2.667z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoImagePlus;
