import React from 'react';
import { useTheme, Tabs, Tab } from '@mui/material';
import { LinkTabsProps, LinkTabProps } from './interface';

const LinkTabs: React.FC<LinkTabsProps> = ({ items, selected = -1, ariaLabel = '' }: LinkTabsProps) => {
  const theme = useTheme();

  const LinkTab = (item: LinkTabProps) => (
    <Tab
      component="a"
      value={item.id}
      label={item.title}
      href={item.href}
      target={item?.target || '_self'}
      sx={{
        textTransform: 'initial',
        opacity: 1,
        '&:-webkit-any-link': {
          color: item.selected ? theme.palette.primary.main : theme.palette.text.secondary,
          textDecoration: 'none',
          padding: '9px 16px',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      }}
    />
  );

  return (
    <Tabs
      value={selected}
      aria-label={ariaLabel}
      TabIndicatorProps={{
        sx: {
          top: theme.spacing(5),
        },
      }}
    >
      {items.map((item) => (
        <LinkTab key={`link-tab-${item.id}`} {...item} />
      ))}
    </Tabs>
  );
};

export default LinkTabs;
