import { SecondaryMenu } from './index';
import { Grid, IconButton, Select, MenuItem, Box, FormHelperText, FormControl, SelectChangeEvent, InputLabel } from '@mui/material';
import { ReactNode } from 'react';
interface TableSettingsProps {
  iconButtons: {
    icon: ReactNode;
    fn?: (val?: any) => void;
    arialLabel: string;
    disabled?: boolean;
  }[];
  handleBackButtonClick: () => void;
  handleSelectChange: (event: SelectChangeEvent) => void;
  helpText: string;
  panelTitle: string;
  selectId: string;
  selectOptions: { [type: string]: string };
  selectValue: string;
  menuTitle: string;
  testId: string;
  checkDisabled?: (option: string) => boolean;
  children?: ReactNode;
  disabled?: boolean;
}

const TableSettings = ({
  iconButtons,
  handleBackButtonClick,
  handleSelectChange,
  helpText,
  menuTitle,
  panelTitle,
  selectId,
  selectOptions,
  selectValue,
  testId,
  checkDisabled,
  children,
  disabled,
}: TableSettingsProps) => {
  return (
    <SecondaryMenu testId={testId} menuTitle={menuTitle} backButtonOnClick={handleBackButtonClick}>
      <Box display="flex" justifyContent="space-between" width="100%" mx={2}>
        {iconButtons.map(({ icon, fn, arialLabel, disabled = false }) => (
          <IconButton
            disabled={disabled}
            key={arialLabel}
            aria-label={arialLabel}
            size="medium"
            color="primary"
            className="py-icon-button-blue"
            onClick={fn}
            data-testid={`table-button-${arialLabel}`}
          >
            {icon}
          </IconButton>
        ))}
      </Box>
      <Grid container direction="column" rowGap={1}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-helper-label">{panelTitle}</InputLabel>
          <Select
            disabled={disabled}
            labelId="demo-simple-select-helper-label"
            data-testid={`${testId}-select`}
            id={selectId}
            label={panelTitle}
            value={selectValue}
            onChange={handleSelectChange}
            size="small"
            MenuProps={{ disableScrollLock: true }}
          >
            {Object.values(selectOptions).map((option) => {
              return (
                <MenuItem
                  data-testid={`${testId}-option${option}`}
                  disabled={checkDisabled ? checkDisabled(option) : false}
                  key={option}
                  value={option}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormHelperText>{helpText}</FormHelperText>
      </Grid>
      {children}
    </SecondaryMenu>
  );
};

export default TableSettings;
