import * as Popover from '@radix-ui/react-popover';
import { AdditionalMultiValueLabelData, EmailPickerOption } from '../types';
import { ContactCard } from './contact-card';
import { ContactAction } from './contact-card/contact-action';

interface Props extends AdditionalMultiValueLabelData {
  data: EmailPickerOption;
}

export const ContactPopover = ({ data, additionalData }: Props) => {
  const { label, email, id } = data;
  const renderContent = () => (
    <>
      {label ? <b>{label}</b> : null}
      {email && label !== email ? <span style={{ paddingLeft: 8 }}>{email}</span> : null}
    </>
  );
  return (
    <Popover.Root>
      <Popover.Trigger className="email-picker-popover__trigger">{renderContent()}</Popover.Trigger>
      <Popover.Anchor className="email-picker-popover__anchor" />
      <Popover.Portal>
        <Popover.Content
          className="email-picker-popover__panel"
          onFocusOutside={(e) => {
            e.preventDefault();
          }}
        >
          <ContactCard
            subTitle={email}
            title={label}
            action={
              <ContactAction companyId={additionalData?.companyId} email={email} onSuccess={additionalData?.onCreate} contactId={id} />
            }
          />
          <Popover.Arrow className="email-picker-popover__panel--arrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
