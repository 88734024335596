import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { AccountRepository } from 'services/api/account/AccountRepository';
import { BillingEntity } from 'services/api/account/interfaces';

type UseBillingInfoApiDataParams = {
  accountRepository: AccountRepository;
  accountId: number;
};

type UseBillingInfoApiDataResult = {
  data?: BillingEntity;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  isRefetching: boolean;
  refetch;
};

type UseBillingInfoApiData<P> = (params: P) => UseBillingInfoApiDataResult;

export const useBillingInfoApiData: UseBillingInfoApiData<UseBillingInfoApiDataParams> = ({ accountRepository, accountId }) => {
  const { data, isLoading, isError, isFetched, refetch, isRefetching } = useQuery({
    queryKey: QueryKeys.getBillingInfo(accountId),
    queryFn: () => accountRepository.getBillingInfo(accountId),
  });

  return {
    data,
    isLoading,
    isError,
    isFetched,
    isRefetching,
    refetch,
  };
};
