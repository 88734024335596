import { useCallback, useContext, useState } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { Card, CardHeader, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCCTypeFromString, getIconFromCCType } from '../billing/utils';
import PaymentMethodModal from './PaymentMethodModal';
import { BillingCard, BillingCardContent } from '../utils/styled';
import BillingCardHeader from '../utils/BillingCardHeader';

const PaymentMethodSummary = () => {
  const { t } = useTranslation();
  const { isBillingLoading, billingData, isBillingRefetching } = useContext(PlanSummaryContext);
  const [isPaymentMethodOpen, setIsPaymentMethodOpen] = useState<boolean>(false);

  const getIcon = useCallback(() => {
    const cardName = billingData?.cardType || '';
    return getIconFromCCType(getCCTypeFromString(cardName));
  }, [billingData?.cardType]);

  const renderSummary = useCallback(() => {
    if (isBillingLoading || isBillingRefetching) {
      return <Skeleton width="100%" height="25px" variant="rounded" />;
    }

    if (!billingData || !billingData.lastFour) {
      return <Typography variant="body1">{t('settings.billing.no_card_on_file')}</Typography>;
    }

    return (
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <img src={getIcon()} style={{ height: '25px' }} />
        <Typography variant="body1" sx={{ ml: 1, mr: '61px' }}>
          {t('settings.billing.card_type', {
            last4Digits: billingData.lastFour,
          })}
        </Typography>
        <Typography variant="body1">
          {t('settings.billing.expires', {
            expiryDate: `${billingData.expiryMonth.toString().padStart(2, '0')}/${billingData.expiryYear}`,
            interpolation: { escapeValue: false },
          })}
        </Typography>
      </Stack>
    );
  }, [isBillingLoading, isBillingRefetching, billingData]);

  return (
    <>
      <BillingCard data-testid="payment-method-summary">
        <BillingCardHeader
          title={t('settings.summary.billing_info')}
          actions={[
            {
              label: t('settings.summary.update_billing_info'),
              onClick: () => setIsPaymentMethodOpen(true),
              dataTestId: 'payment-method-modal-open',
            },
          ]}
        />
        <BillingCardContent>
          <Card variant="outlined" sx={{ p: 0, width: '100%', border: 'none', '& .MuiCardHeader-avatar': { marginRight: 1 } }}>
            <CardHeader disableTypography sx={{ p: 0 }} title={renderSummary()} />
          </Card>
        </BillingCardContent>
      </BillingCard>
      {isPaymentMethodOpen && <PaymentMethodModal isOpen={isPaymentMethodOpen} setIsOpen={setIsPaymentMethodOpen} />}
    </>
  );
};

export default PaymentMethodSummary;
