import * as React from 'react';
const SvgIcoProposifyLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.985.35C5.514.35.25 5.569.25 11.982c0 4.337 2.407 8.127 5.967 10.126V11.97c0-3.153 2.588-5.717 5.768-5.717s5.767 2.564 5.767 5.717-2.588 5.716-5.767 5.716c-.555 0-1.093-.08-1.6-.226v-2.543a3.37 3.37 0 0 0 1.588.396c1.854 0 3.362-1.495 3.362-3.332 0-1.838-1.508-3.334-3.362-3.334-1.853 0-3.362 1.495-3.362 3.334v11.14c1.069.32 2.202.492 3.374.492 6.471 0 11.735-5.22 11.735-11.632C23.72 5.568 18.455.35 11.985.35m-.012 10.057c.877 0 1.589.707 1.589 1.575s-.713 1.574-1.589 1.574a1.584 1.584 0 0 1-1.588-1.574c0-.868.713-1.575 1.588-1.575m.011 11.449a10 10 0 0 1-1.6-.129v-2.453a7.6 7.6 0 0 0 1.6.171c4.159 0 7.542-3.354 7.542-7.475 0-4.122-3.383-7.476-7.541-7.476S4.443 7.85 4.443 11.971v6.455a9.78 9.78 0 0 1-2.42-6.444c0-5.445 4.469-9.874 9.962-9.874s9.96 4.43 9.96 9.874-4.468 9.874-9.96 9.874"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoProposifyLogo;
