import { default as ResizeIcon } from '../../icon/icons/IcoGridResize';
const generateReSizeHandle = (testId) => (
  <div data-testid={testId} className="editor__page__draggable__grid-resize-handle-icon">
    <ResizeIcon />
  </div>
);

export const availableResizeHandle = {
  bottomRight: generateReSizeHandle('grid_block_resize_handle_test_bottomRight'),
  bottomLeft: generateReSizeHandle('grid_block_resize_handle_test_bottomLeft'),
  topLeft: generateReSizeHandle('grid_block_resize_handle_test_topLeft'),
  topRight: generateReSizeHandle('grid_block_resize_handle_test_topRight'),
};
