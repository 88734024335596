import { createContext, useContext } from 'react';

type MilestoneContext = {
  isTitleAlignedWithIcons: boolean;
  isLast: boolean;
};

const MilestoneContext = createContext<MilestoneContext>({
  isTitleAlignedWithIcons: false,
  isLast: false,
});

export function MilestoneProvider({ children, value }: { children: React.ReactNode; value: MilestoneContext }) {
  return <MilestoneContext.Provider value={value}>{children}</MilestoneContext.Provider>;
}

export const useMilestoneContext = () => useContext(MilestoneContext);
