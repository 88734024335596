import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Props } from './interface';
import { Integrations as IntegrationsList } from '../interface';
import { OauthConnectionData, IntegrationData } from '../../../providers/interfaces';
import { useApiDeprecated } from '../../../hooks/useApi';
import {
  FeatureLogo,
  PageHeader,
  ButtonPageAction,
  Icon,
  Paragraph,
  ContentCard,
  Highlight,
  Heading,
  SEO,
  openNotification,
} from '../../../components';
import { GongVideo } from './blocks';
import './styles.less';
import { useIntegrationsApiData } from '../../../hooks/useIntegrationsApiData';
import { useCurrentUser } from 'providers/UserProvider';

const LoadingIcon = <LoadingOutlined className="loader" spin />;

const GongPage: React.FC<Props> = ({ apiUserIntegrationsRepository }) => {
  const { t } = useTranslation();

  const {
    data: { id: userId },
  } = useCurrentUser();
  const { fetchApi } = useApiDeprecated({});
  const {
    userIntegrationData,
    fetchUserIntegrations,
    integrationConnectorData,
    setIntegrationName,
    isLoadingOauthConnectionData,
    isLoadingUserIntegrations,
  } = useIntegrationsApiData({ apiUserIntegrationsRepository });

  const [gongData, setGongData] = useState<IntegrationData>({
    integrationId: 0,
    integrationName: '',
    isUserConnected: false,
  });
  const [gongOauthConnectionData, setGongOauthConnectionData] = useState<OauthConnectionData>({
    integrationName: '',
    authType: '',
    connectorLink: '',
  });
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!userId) return;
    setIntegrationName(IntegrationsList.GONG.identifier);
  }, [userId]);

  useEffect(() => {
    if (!userIntegrationData) return;

    const gongConnection = userIntegrationData.find((instance) => instance.integrationName === IntegrationsList.GONG.identifier);
    if (gongConnection) {
      setGongData(gongConnection);
    }
    setPageLoaded(true);
  }, [userIntegrationData]);

  useEffect(() => {
    if (!integrationConnectorData || integrationConnectorData.length < 1) return;

    const gongAuthConnection = integrationConnectorData.find((instance) => instance.integrationName === IntegrationsList.GONG.identifier);
    if (gongAuthConnection) setGongOauthConnectionData(gongAuthConnection);
  }, [integrationConnectorData]);

  const handleConnectGong = () => {
    window.open(gongOauthConnectionData.connectorLink, '_self');
  };

  const handleDisconnectGong = async () => {
    if (userId) {
      setIsLoading(true);
      const response = await fetchApi(false, `/user/${userId}/integrations/gong/connections`, {
        method: 'DELETE',
      });

      if (response && response.status === 200) {
        fetchUserIntegrations();
      } else {
        openNotification({
          type: 'error',
          title: t('integrations.error_title'),
          description: t('integrations.try_again_error_message'),
        });
      }
      setIsLoading(false);
    }
  };

  return pageLoaded ? (
    <>
      <Spin indicator={LoadingIcon} spinning={isLoadingOauthConnectionData || isLoadingUserIntegrations || isLoading} />
      <Row className="page-header-container">
        <Col flex={1}>
          <SEO title={`${t('integrations.integration_title')} | ${t('integrations.gong.name')}`} />
          <PageHeader
            title={t('integrations.gong.name')}
            paragraph={t('integrations.gong.description')}
            actions={[
              <ButtonPageAction
                key={1}
                type="default"
                icon={<Icon name="IcoDocumentation" />}
                onClick={() => location.assign(t('integrations.gong.documentation_href'))}
              >
                {t('integrations.gong.view_documentation_button_text')}
              </ButtonPageAction>,
              <ButtonPageAction
                key={2}
                type="primary"
                icon={gongData.isUserConnected ? <Icon name="IcoDisconnect" /> : <Icon name="IcoConnect" />}
                variant={gongData.isUserConnected ? 'negative' : 'positive'}
                onClick={gongData.isUserConnected ? handleDisconnectGong : handleConnectGong}
                disabled={
                  gongOauthConnectionData.connectorLink.length < 1 || isLoadingOauthConnectionData || isLoadingUserIntegrations || isLoading
                    ? true
                    : false
                }
              >
                {gongData.isUserConnected ? t('integrations.gong.disconnect_button_text') : t('integrations.gong.connect_button_text')}
              </ButtonPageAction>,
            ]}
            icon={<FeatureLogo src={t('integrations.gong.feature_logo_src')} alt={t('integrations.gong.feature_logo_alt_text')} />}
          />
        </Col>
      </Row>

      {gongData.isUserConnected ? (
        <>
          <Row className="content-card">
            <Col flex={1}>
              <ContentCard
                content={
                  <>
                    <Highlight icon="IcoBubble" iconColorClassName="purple" text={t('integrations.gong.highlight_sent_document_text')} />
                    <Highlight
                      icon="IcoBubble"
                      iconColorClassName="coral-red"
                      text={t('integrations.gong.highlight_viewed_document_text')}
                    />
                  </>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col flex={1}>
              <Heading level={3}>{t('integrations.gong.more_information_text')}</Heading>
              <ul className="gong__ul">
                <li className="gong__ul__li">
                  <a href={t('integrations.gong.documentation_href')}>{t('integrations.gong.connecting_with_gong_text')}</a>{' '}
                </li>
              </ul>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="disconnected-grid-container">
          <div>
            <Heading level={2}>{t('integrations.gong.seal_your_deals_text')}</Heading>

            <Paragraph size="md">{t('integrations.gong.seal_your_deals_paragraph_text')}</Paragraph>
          </div>

          <div>
            <GongVideo />

            <div className="video-title">
              <Paragraph size="md">{t('integrations.gong.gong_wistia_video_title')}</Paragraph>
            </div>
          </div>

          <div>
            <ContentCard
              content={
                <>
                  <Heading level={4}>{t('integrations.gong.deal_intelligence_text')}</Heading>

                  <Heading level={5}>{t('integrations.gong.gain_visibility_text')}</Heading>
                  <Paragraph size="md">{t('integrations.gong.gain_visibility_paragraph_text')}</Paragraph>

                  <Heading level={5}>{t('integrations.gong.generate_actionable_text')}</Heading>
                  <Paragraph size="md">{t('integrations.gong.generate_actionable_paragraph_text')}</Paragraph>

                  <Heading level={5}>{t('integrations.gong.gain_confidence_text')}</Heading>
                  <Paragraph size="md">{t('integrations.gong.gain_confidence_paragraph_text')}</Paragraph>
                </>
              }
            />
          </div>

          <div>
            <Heading level={4}>{t('integrations.gong.ready_to_connect_text')}</Heading>
            <ul className="gong__ul">
              <li className="gong__ul__li">
                <a href={t('integrations.gong.documentation_href')}>{t('integrations.gong.connecting_with_gong_text')}</a>{' '}
              </li>
            </ul>
          </div>
        </Row>
      )}
    </>
  ) : (
    <Spin indicator={LoadingIcon} spinning={!pageLoaded} />
  );
};

export default GongPage;
