import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { AccountRepository } from 'services/api/account/AccountRepository';
import { PaymentAccountEntity } from 'services/api/account/interfaces';

type UsePaymentAccountApiDataParams = {
  accountRepository: AccountRepository;
  accountId: number;
};

type UsePaymentAccountApiDataResult = {
  data?: PaymentAccountEntity;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  isRefetching: boolean;
  refetch;
};

type UsePaymentAccountApiData<P> = (params: P) => UsePaymentAccountApiDataResult;

export const usePaymentAccountApiData: UsePaymentAccountApiData<UsePaymentAccountApiDataParams> = ({ accountRepository, accountId }) => {
  const { data, isLoading, isError, isFetched, refetch, isRefetching } = useQuery({
    queryKey: QueryKeys.getPaymentAccount(accountId),
    queryFn: () => accountRepository.getPaymentAccount(accountId),
  });

  return {
    data,
    isLoading,
    isError,
    isFetched,
    isRefetching,
    refetch,
  };
};
