import { Input } from 'components';

const noOperation = () => {
  return;
};

interface Props {
  value?: string;
}

export const CopyLinkInput = ({ value }: Props) => {
  return <Input value={value} ariaLabel="copy link input" onChange={noOperation} />;
};
