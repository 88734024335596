import { useCallback, useContext, useState } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { Card, CardHeader, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BillingContactModal from './BillingContactModal';
import { BillingCard, BillingCardContent } from '../utils/styled';
import BillingCardHeader from '../utils/BillingCardHeader';

const BillingContact = () => {
  const { t } = useTranslation();
  const { isContactLoading, contactData, isContactRefetching } = useContext(PlanSummaryContext);
  const [isBillingContactOpen, setIsBillingContactOpen] = useState<boolean>(false);

  const renderSummary = useCallback(() => {
    if (isContactLoading || isContactRefetching) {
      return <Skeleton width="100%" height="25px" variant="rounded" />;
    }

    if (!contactData || !contactData.email) {
      return <Typography variant="body1">{t('settings.summary.billing_contact.error_loading_email')}</Typography>;
    }

    return (
      <Typography variant="body1" sx={{ ml: 0, mr: '61px' }}>
        {contactData.email}
      </Typography>
    );
  }, [isContactLoading, isContactRefetching, contactData?.email]);

  return (
    <>
      <BillingCard data-testid="billing-contact">
        <BillingCardHeader
          title={t('settings.summary.billing_contact.title')}
          actions={[
            {
              label: t('settings.summary.billing_contact.change_billing_contact'),
              onClick: () => setIsBillingContactOpen(true),
              dataTestId: 'billing-contact-modal-open',
            },
          ]}
        />
        <BillingCardContent>
          <Card variant="outlined" sx={{ p: 0, width: '100%', border: 'none', '& .MuiCardHeader-avatar': { marginRight: 1 } }}>
            <CardHeader disableTypography sx={{ p: 0 }} title={renderSummary()} />
          </Card>
        </BillingCardContent>
      </BillingCard>
      <BillingContactModal isOpen={isBillingContactOpen} setIsOpen={setIsBillingContactOpen} />
    </>
  );
};

export default BillingContact;
