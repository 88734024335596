import { ReactNode, useMemo, useState } from 'react';
import { Table, TableColumnType } from 'antd';
import { OptionProp } from 'pages/crm/document-create/blocks/form/dropdown-select';
import { AddUser } from '../add-user';
import { RolesColumn } from '../roles-column';
import { Name } from '../name';
import { useRolesQuery } from 'hooks/useRolesQuery';
import { useAccountUsersQuery } from 'hooks/useAccountUsersQuery';
import { useAccountAccessQuery } from '../../../hooks/useAccountAccessQuery';
import './styles.less';
import { AccessAllowance } from 'services/settings/entities/Workspaces';
import { DeleteAction } from '../delete-action';

export type Option = {
  label: string;
  value: string | number;
};

interface Props {
  children?: ReactNode;
  accountData: any;
  openedAccount: null | string;
  handleOpenAccount: (key: null | string) => void;
}

const defaultRoles: OptionProp[] = [
  {
    label: 'Admin',
    value: '0',
  },
];

export const AccountCollapse = ({ accountData, openedAccount, handleOpenAccount }: Props): JSX.Element => {
  const [selectedOpenAccount, setSelectedOpenAccount] = useState<string>('');

  const { data: roles } = useRolesQuery();
  const { data: users } = useAccountUsersQuery();
  const { accessAllowanceList, setAccessAllowanceList, isLoadingAccessAllowanceList } = useAccountAccessQuery({
    subdomain: accountData.accountSubdomain,
    shouldLoad: accountData.id == openedAccount,
  });

  const rolesOptions: OptionProp[] = useMemo(() => {
    const options: OptionProp[] = [...defaultRoles];

    if (roles && Array.isArray(roles)) {
      roles.forEach((role) => {
        options.push({
          label: role.name,
          value: role.id,
        });
      });
    }
    return options;
  }, [roles]);

  const usersOptions = useMemo(() => {
    const options: any[] = [];
    if (users && Array.isArray(users)) {
      users.forEach((user) => {
        options.push({
          label: `${user.fullName} <${user.email}>`,
          email: user.email,
          name: user.fullName,
          value: user.id,
        });
      });
    }
    return options;
  }, [users]);

  const onOpenAccount = () => {
    const key = accountData.id == openedAccount ? null : accountData.id;
    handleOpenAccount(key);

    setSelectedOpenAccount(accountData.accountSubdomain === openedAccount ? null : accountData.accountSubdomain);
  };

  const handleAddNewUser = (newUserData: any) => {
    setAccessAllowanceList([...(accessAllowanceList ?? []), newUserData]);
  };

  const handleDeleteUser = async (data: AccessAllowance) => {
    await setAccessAllowanceList(accessAllowanceList?.filter((obj) => obj.uuid !== data.uuid) ?? []);
  };

  const columns: TableColumnType<AccessAllowance>[] = [
    {
      title: 'Name',
      key: 'name',
      render: (_, data: AccessAllowance) => {
        return <Name name={data.userName} />;
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'userEmail',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: () => {
        return <RolesColumn rolesOptions={rolesOptions} />;
      },
    },
    {
      title: '',
      key: 'deleteAction',
      width: 50,
      render: (_, data: AccessAllowance) => {
        return <DeleteAction uuid={data.uuid} removeAccessCallback={() => handleDeleteUser(data)} />;
      },
    },
  ];

  return (
    <div className="linked-accounts-collapse__container">
      <div onClick={onOpenAccount}>
        <h5>{accountData.accountName}</h5>
        <p>{accountData.accountSubdomain}</p>
      </div>
      <div className={`${openedAccount == accountData.id ? 'linked-accounts-collapse-open' : 'linked-accounts-collapse-close'}`}>
        <AddUser
          rolesOptions={rolesOptions}
          usersOptions={usersOptions}
          selectedOpenAccount={selectedOpenAccount}
          onAddNewUser={handleAddNewUser}
          accessAllowanceList={accessAllowanceList}
        />

        <Table
          className="linked-accounts-collapse__table"
          dataSource={accessAllowanceList}
          columns={columns}
          pagination={false}
          loading={isLoadingAccessAllowanceList}
        />
      </div>
    </div>
  );
};
