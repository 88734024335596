import React from 'react';
import { Props } from './interface';
import { Helmet } from 'react-helmet';

export const SEO: React.FunctionComponent<Props> = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

export default SEO;
