import { Box, CircularProgress, Paper } from '@mui/material';
import { Snackbar } from 'components/snackbar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentListSkeleton } from '../components/DocumentListSkeleton';
import { DocumentsListEmptyState } from '../components/documents-list-empty-state';
import { Pagination } from '../components/pagination/Pagination';
import { usePagination } from '../components/pagination/usePagination';
import { SearchBar } from '../components/search-bar/SearchBar';
import { SearchEmptyResult } from '../components/SearchEmptyResult';
import { StatusFilterBar } from '../components/status-filters-bar/StatusFilterBar';
import { useDocumentsContext } from '../tabs/DocumentsProvider';
import { useFilteredDocuments } from '../tabs/useFilteredDocuments';
import { useUrlSearch } from '../utils/useUrlSearch';
import './antd-global-overrides.css';
import { DocumentsList } from './DocumentsList';

export function DocumentsTab() {
  const { t } = useTranslation();

  const { currentSearchTerm, currentStatusFilter, currentPageFilter, handleQueryParams, clearQueryParams } = useUrlSearch();

  const [selectedItemStatus, setSelectedItemStatus] = useState(currentStatusFilter ? currentStatusFilter.split(',') : ['All']);

  const { isLoading: isLoadingDocumentList, isRefreshing: isRefreshingDocumentList, refreshDocumentList } = useDocumentsContext();
  const { filteredDocumentList } = useFilteredDocuments({
    filterTerm: currentSearchTerm,
    filterTrash: false,
    filterStatus: currentStatusFilter,
  });

  const handlePageFilter = (value: string) => {
    if (value === currentPageFilter) {
      return;
    }
    handleQueryParams({ filterPage: value });
  };

  const { currentPage, setCurrentPage, paginatedDocs } = usePagination({ documentList: filteredDocumentList, currentPageFilter });

  useEffect(() => {
    handlePageFilter(currentPage.toString());
  }, [currentPage]);

  const handleSearch = (value: string) => {
    if (value === currentSearchTerm) {
      return;
    }

    setCurrentPage(1);
    handleQueryParams({ filterTerm: value, filterPage: '1' });
  };

  const handleStatusFilter = (value: string) => {
    if (value === currentStatusFilter) {
      return;
    }
    setCurrentPage(1);
    handleQueryParams({ filterStatus: value, filterPage: '1' });
  };

  const handleStatusButtonOnChangeClick = (event, selectedStatus: string[]) => {
    const clickedStatus = event.target.value;

    if (clickedStatus === 'All') {
      setSelectedItemStatus(() => {
        handleStatusFilter('');
        return ['All'];
      });
    } else if (selectedItemStatus.length === 1 && currentStatusFilter === clickedStatus) {
      setSelectedItemStatus(['All']);
      handleStatusFilter('');
    } else {
      const filterSelectedStatus = selectedStatus.filter((status) => status !== 'All');
      setSelectedItemStatus(filterSelectedStatus);
      handleStatusFilter(filterSelectedStatus.join(','));
    }
  };

  const clearFilters = () => {
    clearQueryParams();
    setSelectedItemStatus(['All']);
  };

  return (
    <Box>
      <SearchBar currentSearchTerm={currentSearchTerm} handleSearch={handleSearch} />
      {currentSearchTerm && (
        <Box marginY={2}>
          <Snackbar message={t('pipeline.tabs.search.searchResults', { count: filteredDocumentList.length, term: currentSearchTerm })} />
        </Box>
      )}

      <Paper elevation={0} sx={{ borderCollapse: 'collapse', borderRadius: '24px', opacity: isRefreshingDocumentList ? 0.5 : 1 }}>
        {!isLoadingDocumentList && currentSearchTerm && filteredDocumentList.length === 0 && (
          <Box padding={6} marginTop={2}>
            <SearchEmptyResult />
          </Box>
        )}

        <Box height="52px">
          {filteredDocumentList.length > 0 && paginatedDocs.length > 0 && (
            <Pagination
              sx={{ opacity: isRefreshingDocumentList ? 0 : 1 }}
              totalDocuments={filteredDocumentList.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Box>

        {isLoadingDocumentList && !isRefreshingDocumentList && (
          <DocumentListSkeleton sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 270px)' }} />
        )}

        {!isLoadingDocumentList && !currentSearchTerm && filteredDocumentList.length === 0 && (
          <Box paddingY={6}>
            <DocumentsListEmptyState selectedItemStatus={selectedItemStatus} clearStatusFilters={clearFilters} />
          </Box>
        )}

        {(!isLoadingDocumentList || isRefreshingDocumentList) && filteredDocumentList.length > 0 && (
          <Box position="relative">
            <DocumentsList
              sx={{ overflowY: 'auto', maxHeight: currentSearchTerm ? 'calc(100vh - 330px)' : 'calc(100vh - 270px)' }}
              documents={paginatedDocs}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              refreshDocumentList={refreshDocumentList}
            />
            {isRefreshingDocumentList && (
              <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }} zIndex={1}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}
      </Paper>

      <StatusFilterBar selectedItemStatus={selectedItemStatus} handleStatusButtonOnChangeClick={handleStatusButtonOnChangeClick} />
    </Box>
  );
}
