import React, { Suspense, useRef, ReactNode, useState, useEffect } from 'react';
import { gridPageMinHeightInPixels } from '../shared/gridConfig';

interface LazyLoadComponentProps {
  children: ReactNode;
  fallback?: ReactNode;
  rootMargin?: string;
  minHeight?: string;
}

const intersectionObserverRootMargin = '100px';

const LazyLoadComponent: React.FC<LazyLoadComponentProps> = ({
  children,
  fallback = <div>Loading...</div>,
  rootMargin = intersectionObserverRootMargin,
  minHeight = `${gridPageMinHeightInPixels}px`,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFn = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  const options = {
    root: null,
    rootMargin: rootMargin,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFn, options);
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [options, ref]);

  return (
    <div ref={ref} style={{ minHeight }}>
      {isVisible ? <Suspense fallback={fallback}>{children}</Suspense> : fallback}
    </div>
  );
};

export default LazyLoadComponent;
