import { useEffect, useRef } from 'react';
import jwt_decode from 'jwt-decode';
import { generateNewAccessToken, isTokenExpired } from '../authorization/handleAuthorization';
import { getHalifaxDateTime } from '../../utils/dateHandlers';
import { logoutUser } from '../authorization/handleLogout';
import { AuthCookieService } from '../cookie';

export const refreshTokenFlow = async () => {
  try {
    const accessToken = AuthCookieService.getAccessToken();
    const refreshToken = AuthCookieService.getRefreshToken();

    if (!refreshToken || typeof refreshToken !== 'string') throw new Error('Refresh token is invalid');

    if ((accessToken && isTokenExpired(accessToken)) || !accessToken) {
      const { access_token: newAccessToken, refresh_token: newRefreshToken, expires_in } = await generateNewAccessToken({ refreshToken });

      const now = new Date(getHalifaxDateTime()).getTime();
      const oneDay = now + expires_in * 1000;
      const oneWeek = now + 604800 * 1000;

      AuthCookieService.setAccessToken(newAccessToken, oneDay);
      AuthCookieService.setRefreshToken(encodeURIComponent(newRefreshToken), oneWeek);
    }
  } catch (e) {
    logoutUser();
  }
};

export const useBackgroundRefreshToken = () => {
  const initialInterval = 0;
  const ref = useRef(refreshTokenFlow);

  useEffect(() => {
    ref.current = refreshTokenFlow;
  }, [initialInterval]);

  useEffect(() => {
    let timeOut;

    const refreshToken = async () => {
      await ref.current();

      const accessToken = AuthCookieService.getAccessToken();
      const decodedJwt = jwt_decode<{ exp: number }>(accessToken ?? '');

      const expiryMilliseconds = decodedJwt.exp * 1000;
      const nextRunTime = expiryMilliseconds - Date.now();

      if (nextRunTime < 1) {
        logoutUser();
      } else {
        timeOut = setTimeout(refreshToken, nextRunTime);
      }
    };

    timeOut = setTimeout(refreshToken, initialInterval);
    return () => timeOut && clearTimeout(timeOut);
  }, [initialInterval]);
};
