import { Heading, Icon, Paragraph } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isIframe } from 'utils/isIframe';

interface Props {
  subHeading?: string;
}

export const ProposalShareHeader = ({ subHeading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (isIframe()) {
      window.parent.postMessage(JSON.stringify({ action: 'DONE' }), '*');
    } else {
      navigate(-1);
    }
  };

  return (
    <header className="header-section">
      <div>
        <Heading level={4}>{t('share.title')}</Heading>
        <Paragraph size="md" data-testid="share-header-client">
          {subHeading}
        </Paragraph>
      </div>
      <div>
        <button type="button" className="close-button" onClick={handleBackButton}>
          <Icon name="IcoCloseX" title={t('close')} />
        </button>
      </div>
    </header>
  );
};
