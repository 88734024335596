import { useSearchParams } from 'react-router-dom';
import { trackEvents } from 'utils/tracking';

export const TERM_QUERY_PARAM = 'term';
export const STATUS_QUERY_PARAM = 'status';
export const PAGE_QUERY_PARAM = 'page';

type HandleQueryParamsProps = {
  filterTerm?: string;
  filterStatus?: string;
  filterPage?: string;
};

export function useUrlSearch() {
  const [currentQueryParams, setQueryParams] = useSearchParams();
  const newQueryParameters = new URLSearchParams();

  const currentSearchTerm = decodeURIComponent(currentQueryParams.get(TERM_QUERY_PARAM) ?? '');
  const currentStatusFilter = decodeURIComponent(currentQueryParams.get(STATUS_QUERY_PARAM) ?? '');
  const currentPageFilter = decodeURIComponent(currentQueryParams.get(PAGE_QUERY_PARAM) ?? '');

  const handleQueryParams = ({
    filterTerm = currentSearchTerm,
    filterStatus = currentStatusFilter,
    filterPage = currentPageFilter,
  }: HandleQueryParamsProps) => {
    if (filterTerm === '') {
      newQueryParameters.delete(TERM_QUERY_PARAM);
    } else {
      newQueryParameters.append(TERM_QUERY_PARAM, encodeURIComponent(filterTerm));
      trackEvents('documents_search', { searchTerm: filterTerm });
    }

    if (filterStatus === '') {
      newQueryParameters.delete(STATUS_QUERY_PARAM);
    } else {
      newQueryParameters.set(STATUS_QUERY_PARAM, encodeURIComponent(filterStatus));
    }

    if (filterPage === '') {
      newQueryParameters.delete(PAGE_QUERY_PARAM);
    } else {
      newQueryParameters.set(PAGE_QUERY_PARAM, encodeURIComponent(filterPage));
    }

    setQueryParams(newQueryParameters);
  };

  const clearQueryParams = () => {
    newQueryParameters.delete(TERM_QUERY_PARAM);
    newQueryParameters.delete(STATUS_QUERY_PARAM);
    newQueryParameters.delete(PAGE_QUERY_PARAM);
    setQueryParams(newQueryParameters);
  };

  return { currentSearchTerm, currentStatusFilter, currentPageFilter, handleQueryParams, clearQueryParams };
}
