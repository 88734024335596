import { ImageUploadErrorDetails } from '../shared/models/ImageUploadErrorDetails';
import { StatusCodes } from './StatusCodes';

export function getImageStatusError(code: number, t): ImageUploadErrorDetails {
  switch (code) {
    case StatusCodes.UnsupportedType:
      return {
        title: t('document.image.errors.not_supported_types_title'),
        desc: t('document.image.errors.not_supported_types_desc'),
      };
    case StatusCodes.SizeTooLarge:
      return {
        title: t('document.image.errors.size_restriction_title'),
        desc: t('document.image.errors.size_restriction_desc'),
      };
    default:
      return {
        title: t('document.image.errors.generic_error_title'),
        desc: t('document.image.errors.generic_error_desc'),
      };
  }
}
