import { COLORS } from './MuiDataGrid';

export const MuiIconButton = {
  styleOverrides: {
    root: {
      '&.py-icon-button-blue': {
        backgroundColor: '#C5E7FF',
        '&:hover': {
          backgroundColor: COLORS.iconBgColor,
        },
        '&.Mui-disabled': {
          opacity: 0.6,
          cursor: 'inherit',
          backgroundColor: COLORS.disabledIcon,
        },
        '&.show-row': {
          backgroundColor: '#EAF7FF',
        },
      },
    },
  },
};
