import { FormControl, InputLabel, MenuItem, TextField, Select, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PaymentFormLineProps } from './interface';

const PaymentFormLine = ({ formItems, validation }: PaymentFormLineProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
      }}
    >
      {formItems.map((formItem) => {
        const {
          id,
          value,
          onChange,
          options = [],
          adornments,
          required = true,
          disabled = false,
          hidden = false,
          maskInputComponent,
          error = '',
          shrinkLabel,
        } = formItem;

        const sharedProps = {
          id: id,
          label: t(`settings.billing.payment_method_form.${id}`),
          disabled: !!disabled,
          required: typeof required === 'undefined' || !!required,
          value: value,
          hidden: !!hidden,
          placeholder: t(`settings.billing.payment_method_form.${id}_placeholder`),
        };

        if (hidden) {
          return null;
        }

        return options && options.length > 0 ? (
          <FormControl key={`payment-method-form-control-${id}`} required={required} error={!!error} sx={{ flex: '1 0 0' }}>
            <InputLabel id={`payment-method-form-field-label-${id}`} error={!!error} shrink={shrinkLabel}>
              {t(`settings.billing.payment_method_form.${id}`)}
            </InputLabel>
            <Select
              {...sharedProps}
              key={`payment-method-form-field-${id}`}
              onChange={(event) => {
                onChange(event.target.value);
                if (error) {
                  validation({ ...formItem, ...sharedProps }, event.target.value);
                }
              }}
              labelId={`payment-method-form-field-label-${id}`}
              sx={{
                width: '100%',
              }}
              onBlur={(event) => validation(formItem, event.target.value)}
            >
              {options.map((option) => (
                <MenuItem key={`payment-method-form-field-option-${id}-${option.id}`} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl key={`payment-method-form-control-${id}`} error={!!error} required={required} sx={{ flex: '1 0 0' }}>
            <TextField
              {...sharedProps}
              key={`payment-method-form-field-${id}`}
              size="medium"
              InputLabelProps={{
                shrink: shrinkLabel,
              }}
              InputProps={{
                endAdornment: adornments || undefined,
                inputComponent: (maskInputComponent as any) || undefined,
                error: !!error,
              }}
              sx={{
                flex: '1 0 0',
              }}
              onChange={(event) => {
                onChange(event.target.value);
                if (error) {
                  validation({ ...formItem, ...sharedProps }, event.target.value);
                }
              }}
              helperText={error || undefined}
              onBlur={(event) => {
                validation({ ...formItem, ...sharedProps }, event.target.value);
              }}
              FormHelperTextProps={{
                error: !!error,
              }}
              placeholder={sharedProps.placeholder}
            />
          </FormControl>
        );
      })}
    </Stack>
  );
};

export default PaymentFormLine;
