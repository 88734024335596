import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import WithLoadingIndicator from 'components/page-loader/page-loader';

import './gridStyles.less';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import { useTranslation } from 'react-i18next';
import { PreviewMenu } from 'components/preview/preview-menu';
import { CINDER_URLS } from 'utils/cinder-urls';
import { PreviewGrid } from 'components/preview';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import Banner from 'components/preview/banner';
import { Col, Row } from 'antd';
import { isAuthenticated } from 'utils/authentication';
import { PreviewActionMenu } from 'components/preview/action-menu/action-menu';
import { useGetPreviewByDocId } from './useGetPreviewByDocId';
import { useGetPreviewGridByDocId } from './useGetPreviewGridByDocId';
import ProposifyTheme from '../../muiTheme/PyTheme';
import { ThemeProvider } from '@mui/material/styles';
import PreviewSections from '../../components/preview/PreviewSections';
import { useGetContentStyleByContentId } from './useGetContentStyleByContentId';
import FontLoader from '../../components/font';

export const PreviewGridPage: React.FC = () => {
  const { t } = useTranslation();
  const { documentId: documentIdFromParam } = useParams<{ documentId: string }>();
  const documentId = documentIdFromParam as string;
  const [, setPreviewConfig] = useState<any>(undefined);
  const { data: documentData, status } = useGetPreviewByDocId(documentId);
  const { data: sectionData, status: gridQueryStatus } = useGetPreviewGridByDocId(documentId);
  const { data: documentStyleApiResponse, status: documentStyleQueryStatus } = useGetContentStyleByContentId(documentId);
  let documentStyle: React.CSSProperties = {};
  const documentTitle = documentData?.title || 'Untitled Document';
  const documentPrice = documentData?.price || 0.0;
  const documentProspectName = documentData?.prospect?.name || '';
  const sections = sectionData ? sectionData.sections : [];
  const contentId = documentData?.contentId || '';
  const [isFontLoaded, setIsFontLoaded] = useState<boolean>(false);

  if (documentStyleQueryStatus === 'success') {
    documentStyle = {
      backgroundColor: documentStyleApiResponse.backgroundColor,
    };
  }

  const editorConfigHandler = (newConfig) => {
    setPreviewConfig(newConfig);
  };

  switch (status) {
    case 'error':
      return <NavigateToCinder path={CINDER_URLS.error} />;
    case 'success':
      if (gridQueryStatus === 'error') return <NavigateToCinder path={CINDER_URLS.error} />;

      if (!isFontLoaded) {
        return (
          <>
            <WithLoadingIndicator isLoading={true} isLoaded={false} />
            <FontLoader contentId={contentId} editorConfigHandler={editorConfigHandler} fontsFetchedCb={() => setIsFontLoaded(true)} />
          </>
        );
      }

      return (
        <ThemeProvider theme={ProposifyTheme}>
          {/*This is a quick fix, we need to remove this once we migrate preview page to contentId URL.*/}
          <FontLoader contentId={contentId} editorConfigHandler={editorConfigHandler} />
          <PreviewGrid
            header={
              <PreviewMenu
                documentId={documentId}
                documentTitle={documentTitle}
                documentProspectName={documentProspectName}
                documentPrice={documentPrice}
                isDocumentBelongsToSameAccount={documentData.isDocumentBelongsToSameAccount}
              />
            }
            banner={<Banner documentId={documentId}>{t('document.preview.banner')}</Banner>}
            main={<PreviewSections documentStyle={documentStyle} documentId={documentId} sections={sections} />}
            footer={
              <Row justify="space-between" align="middle">
                <Col>
                  <div className="preview__footer__document_price">
                    {documentData.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </div>
                </Col>
                {isAuthenticated() ? (
                  <Col>
                    <PreviewActionMenu documentId={documentId} documentTitle={documentTitle} documentProspectName={documentProspectName} />
                  </Col>
                ) : null}
              </Row>
            }
          />
        </ThemeProvider>
      );
    case 'loading':
    default:
      return <WithLoadingIndicator isLoading={true} isLoaded={false}></WithLoadingIndicator>;
  }
};
