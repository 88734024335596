import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'components';
import usePreviewRedirect from '../../../pages/editor/usePreviewRedirect';
import { URLS } from '../../../utils/urls';

type DocumentLockedModalProps = {
  isDocumentLocked: boolean;
  documentId: string;
};

const DocumentLockedModal: React.FC<DocumentLockedModalProps> = ({ isDocumentLocked, documentId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePreviewRedirect = usePreviewRedirect(documentId ? documentId : '');

  const handlePipelineRedirect = () => {
    navigate(URLS.pipeline);
  };

  return (
    <Modal
      isVisible={isDocumentLocked}
      handleOk={handlePreviewRedirect}
      handleCancel={handlePipelineRedirect}
      affirmText={t('document.locked.go_to_preview')}
      cancelText={t('document.locked.go_to_pipeline')}
      title={t('document.locked.title')}
      closable={false}
      maskClosable={false}
      escClosable={false}
    >
      <p>{t('document.locked.message')}</p>
    </Modal>
  );
};

export default DocumentLockedModal;
