import * as React from 'react';
const SvgIcoHelp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M12 3.746a8.254 8.254 0 1 0 0 16.508 8.254 8.254 0 0 0 0-16.508M2.246 12c0-5.387 4.367-9.754 9.754-9.754S21.754 6.613 21.754 12 17.387 21.754 12 21.754 2.246 17.387 2.246 12" />
      <path d="M13.4 10.021a1.387 1.387 0 0 0-1.459-1.274h-.072a1.43 1.43 0 0 0-1.454 1.107.75.75 0 1 1-1.46-.339 2.93 2.93 0 0 1 2.94-2.267A2.887 2.887 0 0 1 14.9 9.999c0 1.146-.835 1.851-1.352 2.272l-.19.153c-.205.164-.358.287-.481.415a.6.6 0 0 0-.128.17.75.75 0 0 1-1.5-.009c0-.538.293-.936.544-1.198.2-.207.454-.412.669-.583l.14-.112c.546-.445.788-.737.798-1.086M12.59 15.162a.75.75 0 0 0-.59-.286.875.875 0 1 0 .875.876.75.75 0 0 0-.286-.59" />
      <path d="M11.25 15.627a.75.75 0 0 1 .75-.75c.483 0 .875.391.875.874a.75.75 0 0 1-1.34.465.75.75 0 0 1-.285-.59" />
    </g>
  </svg>
);
export default SvgIcoHelp;
