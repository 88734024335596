import { useTranslation } from 'react-i18next';
import prismatic from '@prismatic-io/embedded';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { getPrismaticJWT } from 'services/integrations/repositories/GetJWTRepository';
import { openNotification } from 'components';

interface UserInfoProps {
  authenticatedUser: {
    customer: {
      id: string;
      allowEmbeddedDesigner: boolean;
    };
  };
}
interface AuthConfig {
  authenticated: boolean;
  token: string | null;
  userinfo: UserInfoProps;
}

interface JWTData {
  token: string;
}

const getUserInfo = async (): Promise<UserInfoProps> => {
  const query = `{
    authenticatedUser {
      customer {
        id
        allowEmbeddedDesigner
      }
    }
  }`;
  const result = await prismatic.graphqlRequest({ query });
  return result.data;
};

/**
 * Authenticate with Prismatic and return an auth token and info about the authenticated user.
 */
const usePrismaticAuth = (): AuthConfig => {
  const { t } = useTranslation();
  const [userinfo, setUserinfo] = React.useState<UserInfoProps>({
    authenticatedUser: {
      customer: {
        id: '',
        allowEmbeddedDesigner: false,
      },
    },
  });

  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const { data } = useQuery<JWTData>(QueryKeys.getPrismaticJWT, getPrismaticJWT);

  const token = useMemo(() => data?.token ?? null, [data?.token]);

  useEffect(() => {
    let mounted = true;
    if (token) {
      const authenticate = async () => {
        try {
          prismatic.init({
            prismaticUrl: 'https://na-integrations.proposify.com',
          });
          await prismatic.authenticate({ token });
          if (mounted) {
            setAuthenticated(true);
          }
          const userinfo = await getUserInfo();
          if (mounted) {
            setUserinfo(userinfo);
          }
        } catch (err) {
          openNotification({
            type: 'error',
            title: t('library.error.create_error_msg'),
            description: t('library.error.general_error_description'),
            placement: 'top',
          });
        }
        return () => {
          mounted = false;
        };
      };
      authenticate();
    }
  }, [token]);

  return {
    authenticated,
    token,
    userinfo,
  };
};

export default usePrismaticAuth;
