import React from 'react';
import { deepmerge } from '@mui/utils';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import lightTheme from 'muiTheme/lightTheme';
import { FenixTheme } from 'muiTheme/FenixTheme';

const theme: Theme = deepmerge(lightTheme, FenixTheme);

export const FenixThemeProvider = ({ children }: React.PropsWithChildren) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
