type CallbackReturnType = Promise<boolean>;
type CallbackType = (undoRedoCommand: UndoRedoCommand) => CallbackReturnType;

export interface UndoRedoCommandInterface {
  undo: () => CallbackReturnType;
  redo: () => CallbackReturnType;
}
export class UndoRedoCommand implements UndoRedoCommandInterface {
  private readonly undoCallback: CallbackType;
  private readonly redoCallback: CallbackType;

  constructor(undoCallback: CallbackType, redoCallback: CallbackType) {
    this.undoCallback = undoCallback;
    this.redoCallback = redoCallback;
  }

  public async undo(): CallbackReturnType {
    return this.undoCallback(this);
  }

  public async redo(): CallbackReturnType {
    return this.redoCallback(this);
  }
}

type BlockIdMappingType = {
  [key: string]: string;
};

type CallbackForBlocksType = (undoRedoCommand: UndoRedoCommandForBlocks) => CallbackReturnType;
export class UndoRedoCommandForBlocks implements UndoRedoCommandInterface {
  private static blockIdMapping: BlockIdMappingType = {};
  private readonly firstBlockId: string;
  private readonly undoCallback: CallbackForBlocksType;
  private readonly redoCallback: CallbackForBlocksType;
  constructor(blockId: string, undoCallback: CallbackForBlocksType, redoCallback: CallbackForBlocksType) {
    this.undoCallback = undoCallback;
    this.redoCallback = redoCallback;
    this.firstBlockId = blockId;
    UndoRedoCommandForBlocks.blockIdMapping[blockId] = blockId;
  }
  public getMappedBlockId(blockId: string): string {
    return UndoRedoCommandForBlocks.blockIdMapping[blockId];
  }

  public patchBlockId(newBlockId: string): void {
    UndoRedoCommandForBlocks.blockIdMapping[this.firstBlockId] = newBlockId;
  }

  public async undo(): CallbackReturnType {
    return this.undoCallback(this);
  }
  public async redo(): CallbackReturnType {
    return this.redoCallback(this);
  }
}
