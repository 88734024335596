export interface UsedVariable {
  name: VariableName | string;
  type: VariableType | string;
}

export enum VariableType {
  Proposal = 'proposal',
  User = 'user',
  Client = 'client',
}

export enum VariableName {
  ProposalName = 'proposal_name',
  ClientName = 'client_name',
  CompanyName = 'company_name',
  Total = 'total',
}
