import { DropdownSelect, OptionProp } from 'pages/crm/document-create/blocks/form/dropdown-select';
import { Button, Icon } from 'components';
import './styles.less';
import { useState } from 'react';
import { postAccessAllowances } from 'services/settings/respositories/implementations/ApiWorkspaceAccountsRepository';
import { AccessAllowance } from 'services/settings/entities/Workspaces';
import { useTranslation } from 'react-i18next';

export type Props = {
  rolesOptions: OptionProp[];
  usersOptions: OptionProp[];
  selectedOpenAccount?: string;
  onAddNewUser: (handleAddNewUser: any) => void;
  accessAllowanceList?: AccessAllowance[];
};

export const AddUser = ({ selectedOpenAccount, rolesOptions, usersOptions, onAddNewUser, accessAllowanceList }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [selectedOptionUser, setSelectedOptionUser] = useState<OptionProp | null>();
  const [userRequiredError, setUserRequiredError] = useState<boolean>(false);
  const [userInListError, setUserInListError] = useState<boolean>(false);
  const adminRole = 0;
  const defaultAdminRole = rolesOptions[0];

  const handleUserChange = (selectedOption: OptionProp) => {
    setSelectedOptionUser(selectedOption);
    setUserRequiredError(false);
    setUserInListError(false);
  };

  const handleAddNewUserClick = async () => {
    if (!selectedOptionUser) {
      return setUserRequiredError(true);
    }

    const userInList = accessAllowanceList?.some((user) => user.userName === selectedOptionUser?.name);

    if (userInList) {
      return setUserInListError(true);
    }

    if (selectedOptionUser) {
      const userData = {
        userEmail: selectedOptionUser.email,
        targetAccountSubdomain: selectedOpenAccount,
        roleId: adminRole,
      };
      const newUser = await postAccessAllowances(userData);
      onAddNewUser(newUser);
      setSelectedOptionUser(null);
    }
  };

  return (
    <>
      <DropdownSelect
        className={'add-user__users'}
        options={usersOptions}
        onChange={handleUserChange}
        data-testid={'add-user__users'}
        value={selectedOptionUser || null}
      />
      <DropdownSelect
        className={'add-user__roles'}
        options={rolesOptions}
        onChange={() => {
          //do nothing
        }}
        data-testid={'add-user__roles'}
        disabled={true}
        defaultValue={defaultAdminRole}
      />
      <Button
        onClick={handleAddNewUserClick}
        className={'add-user__add-button'}
        variant={'emphasis'}
        type={'primary'}
        icon={<Icon name={'IcoCirclePlusSolid'} />}
        data-testid={'add-user'}
      />
      {userRequiredError && <p className="error-message">{t('settings.workspaces.user_required_error_msg')}</p>}
      {userInListError && <p className="error-message">{t('settings.workspaces.user_exists_error_msg')}</p>}
    </>
  );
};
