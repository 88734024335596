export const convertToOption = (options) => {
  if (!options) return [];
  return options.map((option) => ({ label: option, value: option }));
};

export const convertToBooleanOption = (value: boolean): { label: string; value: 0 | 1 } => {
  if (value) {
    return {
      label: 'true',
      value: 1,
    };
  }

  return {
    label: 'false',
    value: 0,
  };
};

export const theme = (basethem) => ({
  ...basethem,
  colors: {
    ...basethem.colors,
    primary25: '#d2f5fa',
    primary50: '#aaeefa',
    primary: '#004053',
  },
});
