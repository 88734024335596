import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiSsoConfigRepository } from 'services/settings/respositories/implementations/ApiSsoConfigRepository';

export const useSsoSchemaQuery = () => {
  return useQuery(QueryKeys.ssoSchema, ApiSsoConfigRepository.getSsoSchema);
};

export const useSsoRefSchemaQuery = (reference: string | undefined): any => {
  return useQuery(QueryKeys.ssoRefSchema(reference), () => ApiSsoConfigRepository.getSsoRefSchema(reference));
};
