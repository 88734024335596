import { Modal, ModalProps } from 'antd';
import Icon from 'components/icon';
import { useTranslation } from 'react-i18next';

import './styles.less';

export function DocumentShareModal({ children, ...props }: ModalProps) {
  const { t } = useTranslation();
  return (
    <Modal {...props} className="document-share-modal" width={1024} footer={null} closeIcon={<Icon name="IcoCloseX" title={t('close')} />}>
      {children}
    </Modal>
  );
}
