import { useMemo } from 'react';
import './styles.less';

type Props = {
  name: string;
};

export const Name = ({ name }: Props) => {
  const initials = useMemo(() => {
    if (name) {
      return name
        .split(' ')
        .map((n) => n[0])
        .join('')
        .slice(0, 2);
    }
    return null;
  }, [name]);

  return (
    <>
      {initials && <span className="name__initials">{initials}</span>}
      {name}
    </>
  );
};
