import { CrmSalesforceRepository } from '../CrmSalesforceRepository';
import { Endpoints } from 'utils/endpoints';
import { apiClient } from 'services/api';
import { salesforceObjectSettings } from 'pages/crm/salesforce/interface';

class ApiCrmSalesforceRepositoryClass implements CrmSalesforceRepository {
  public async getSalesforceObjectTypes() {
    return await apiClient.get(Endpoints.getSalesforceObjectTypes);
  }

  public async getSalesforceObjectTypesPrefix(prefix: string) {
    return await apiClient.get(Endpoints.getSalesforceObjectTypesPrefix(prefix));
  }

  public async saveSalesforceObjectSettings(payload: salesforceObjectSettings) {
    return await apiClient.post<void>(Endpoints.salesforceObjectSettings, payload);
  }

  public async getSalesforceObjectSettings(): Promise<salesforceObjectSettings> {
    return await apiClient.get(Endpoints.salesforceObjectSettings);
  }
}

export const ApiCrmSalesforceRepository = new ApiCrmSalesforceRepositoryClass();
