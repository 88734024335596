import { Box, Fade, ListItem, ListItemText, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { type Document } from 'services/documents/entities/Document';
import { BetaChip } from '../components/BetaChip';
import { DocumentAvatar } from '../components/DocumentAvatar';
import { DocumentStatusChip } from '../components/DocumentStatusChip';
import { getAssignedProspect } from '../utils/getAssignedProspect';
import { statusColors } from '../utils/statusColors';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import { useGoToDocument } from '../utils/useGoToDocument';
import { DuplicateDocumentAction } from './document-actions/DuplicateDocumentAction';
import { InsightsAction } from './document-actions/InsightsAction';
import { MoreDocumentAction } from './document-actions/MoreDocumentAction';
import { TrashDocumentAction } from './document-actions/TrashDocumentAction';

interface DocumentListItemProps {
  document: Document;
  refreshDocumentList: () => Promise<void>;
  viewCreatedDocument: (createdDocumentId: string) => void;
}

export function DocumentListItem({ document, refreshDocumentList, viewCreatedDocument }: DocumentListItemProps) {
  const listItemRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const { formatDocumentDateTime } = useFormatDateTime();
  const { goToDocument } = useGoToDocument({ document });

  const handleOnHover = (e: React.MouseEvent) => {
    if (e?.target === listItemRef.current) {
      setIsHovered(true);
    }
  };

  return (
    <ListItem
      id={document.id}
      key={document.id}
      ref={listItemRef}
      tabIndex={0}
      data-testid={`list-item-${document.id}`}
      divider
      sx={{
        gap: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        '&:focus': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        },
        borderLeft: `4px solid ${statusColors[document.status]}`,
      }}
      onMouseEnter={handleOnHover}
      onMouseLeave={() => setIsHovered(false)}
      onClick={goToDocument}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          goToDocument();
        }
      }}
    >
      <ListItemText
        sx={{ flex: '1 1 150px' }}
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BetaChip />
            <Typography
              variant="body1"
              fontWeight={600}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              data-testid="document-title"
            >
              {document.title}
            </Typography>
          </Box>
        }
        secondary={
          <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {getAssignedProspect(document)}
          </Typography>
        }
      />
      <Box sx={{ flex: '0 1 100px' }}>
        {!isHovered && (
          <Typography variant="labelSmall" textAlign="right" whiteSpace="nowrap" data-testid="document-created-date">
            {formatDocumentDateTime(document.created_at)}
          </Typography>
        )}
      </Box>
      <Box sx={{ flex: '0 1 50px' }}>{!isHovered && <DocumentAvatar document={document} />}</Box>
      <Box sx={{ flex: '0 1 200px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Fade in={!isHovered}>
          <Box>{!isHovered && <DocumentStatusChip status={document.status} />}</Box>
        </Fade>
        <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', gap: '8px' }}>
          <InsightsAction
            visible={isHovered}
            documentId={document.id}
            refreshDocumentList={refreshDocumentList}
            onClose={() => setIsHovered(false)}
          />
          {isHovered && <DuplicateDocumentAction documentToDuplicate={document} viewCreatedDocument={viewCreatedDocument} />}
          {isHovered && <TrashDocumentAction documentToTrash={document} />}
          <MoreDocumentAction
            documentId={document.id}
            documentStatus={document.status}
            documentTitle={document.title}
            refreshDocumentList={refreshDocumentList}
            onMenuClose={() => setIsHovered(false)}
          />
        </Box>
      </Box>
    </ListItem>
  );
}
