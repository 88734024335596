import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components';
import { SalesforceObjects } from './blocks/SalesForceObject';

export const SalesforceCrmPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t('crm.salesforce.title')} paragraph={t('crm.salesforce.subtitle')} data-testid="crm-salesforce-header" />
      <SalesforceObjects />
    </>
  );
};
