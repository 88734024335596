import { IconButton, styled, Tooltip, Typography } from '@mui/material';
import IcoInsightsPreview from 'components/icon/icons/IcoInsightsPreview';
import { useTranslation } from 'react-i18next';
import { useInsightsPanelHeaderNavigation } from './useInsightsPanelHeaderNavigation';

interface InsightsHeaderPreviewButtonProps {
  documentId: string;
  isExpanded?: boolean;
}

export function InsightsHeaderPreviewButton({ documentId, isExpanded = false }: InsightsHeaderPreviewButtonProps) {
  const { t } = useTranslation();
  const { navigateToPreview } = useInsightsPanelHeaderNavigation(documentId);

  const IconButtonComponent = isExpanded ? ExpandedIconButton : ShrunkIconButton;

  return (
    <Tooltip title={t('pipeline.insights.preview_tooltip')}>
      <IconButtonComponent
        onClick={navigateToPreview}
        data-testid="insights-preview-button"
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(0, 40, 58, 0.04)',
            color: '#00283ADE',
          },
        }}
      >
        <IcoInsightsPreview />
        {isExpanded && <Typography variant="labelMedium">{t('preview')}</Typography>}
      </IconButtonComponent>
    </Tooltip>
  );
}

const ShrunkIconButton = styled(IconButton)({
  border: '1px solid #00283A',
  padding: '0.3125rem',
});

const ExpandedIconButton = styled(IconButton)({
  borderRadius: '6.25rem',
  border: '1px solid #00283A',
  padding: '0.375rem 1rem',
  gap: '0.5rem',
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
});
