import { Button, Icon, openNotification } from 'components';
import useCreatePersonMutation from 'hooks/useCreatePersonMutation';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Person } from 'services/entities/PersonEntity';
import { CINDER_URLS } from 'utils/cinder-urls';
import goToCinder from 'utils/goToCinder';
import { QueryKeys } from 'utils/queryKeys';

interface Props {
  email: string;
  companyId?: number | null;
  onSuccess?: (person: Person) => void;
  contactId: number | null;
}

export const ContactAction = ({ email, companyId, onSuccess, contactId }: Props) => {
  const { t } = useTranslation();
  const [person, setPerson] = useState<number | null>(contactId);
  const { mutate, isLoading } = useCreatePersonMutation();
  const queryClient = useQueryClient();

  const addContact: MouseEventHandler<HTMLButtonElement> = () => {
    const [firstName, lastName] = email.split('@');
    mutate(
      {
        companyId,
        email,
        firstName,
        lastName,
      },
      {
        onSuccess: async (response, payload) => {
          setPerson(response.id);
          openNotification({
            type: 'success',
            title: t('contact.add.success.notification.title'),
            description: '',
          });
          await queryClient.invalidateQueries(QueryKeys.getCompanyById(payload.companyId));
          onSuccess?.(response);
        },
        onError: (error) => {
          openNotification({
            title: t('error'),
            description: error.message,
            type: 'error',
          });
        },
      }
    );
  };

  const editContact: MouseEventHandler<HTMLButtonElement> = () => {
    if (person) {
      goToCinder(CINDER_URLS.editClient(person), { target: '_blank' });
    }
  };
  return person ? (
    <Button type="default" variant="neutral" icon={<Icon name="IcoEdit" title={t('edit')} />} onClick={editContact} />
  ) : (
    <Button
      type="default"
      variant="neutral"
      icon={<Icon name="IcoSingleUserAddPlus" title={t('add')} />}
      onClick={addContact}
      disabled={isLoading}
    />
  );
};
