import React from 'react';
import { Loading } from 'components';
import { TemplateCard } from '../template-card';
import { TemplateFolderCard } from '../template-folder-card';
import { useTemplateFolderQuery } from '../../../hooks/useTemplate';
import { TemplateFolder } from 'services/templates/entities/TemplateRepository';
import './styles.less';

interface Props {
  currentTemplateData: TemplateFolder;
  navigateToFolder: (folderData: TemplateFolder) => void;
  selectPresetTemplate: (id: number) => void;
}

export const TemplateList: React.FC<Props> = ({ currentTemplateData, navigateToFolder, selectPresetTemplate }): JSX.Element => {
  const { data: templateFolders, status } = useTemplateFolderQuery();

  switch (status) {
    case 'success':
      return (
        <div className="template-list__pills">
          {currentTemplateData.childFolderIds.length > 0 &&
            currentTemplateData.childFolderIds.map((id) => (
              <TemplateFolderCard
                templateData={templateFolders.find((data) => data.id == id)}
                navigateToFolder={navigateToFolder}
                key={id}
              />
            ))}

          {currentTemplateData.templates.length > 0 &&
            currentTemplateData.templates.map(({ name, id, thumbLarge }) => (
              <TemplateCard name={name} thumbnail={thumbLarge} key={id} selectPresetTemplate={selectPresetTemplate} id={id} />
            ))}
        </div>
      );
    default:
      return (
        <div className="template-list__loader">
          <Loading isLoading />
        </div>
      );
  }
};
